import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, Button, Badge, Row, Col } from 'react-bootstrap';

const LocalUsers = ({ user }) => {
  const [usr, setUser] = useState('');
  const [email, setEmail] = useState('');
  const [surname, setSurname] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [code, setCode] = useState('');
  useEffect(() => {
    if (user) {
      if (user) {
        setEmail(user.local.email);
        setUser(user.local.name);
        setSurname(user.local.surname);
        setCellphone(user.cellphone);
        setCode(user.code);
      }
    }
  }, [user, setUser]);
  return (
    <ListGroup.Item>
      <h5>
        <Row>
          <Col>
            <i className="fas fa-user-circle"></i>
            <Badge bg="secondary">
              {usr} {surname}
            </Badge>
          </Col>
          <Col style={{ width: '100%' }}>
            <Button variant="outline-primary text-center ml-5" block disabled>
              <i className="fa-solid fa-house"></i>: {code}
            </Button>
          </Col>
          <Col>
            <Button variant="outline-primary text-center ml-5" block disabled>
              <i className="fas fa-envelope"></i>: {email}
            </Button>
          </Col>
          <Col>
            <Button variant="outline-primary text-center ml-5" block disabled>
              <i class="fa-solid fa-phone"></i>: {cellphone}
            </Button>
          </Col>
          <Col>
            <Button
              as={Link}
              to={`/admin/admin_user/${user._id}`}
              variant="outline-success ml-5"
            >
              More...
            </Button>
          </Col>
        </Row>
      </h5>
    </ListGroup.Item>
  );
};

export default LocalUsers;
