import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { notBooked } from '../../../actions/admin/orders';
import { Alert, Spinner, Button, ListGroup } from 'react-bootstrap';
import OrderItems from './OrderItems';

const OrderNotAllocated = ({ notBooked, orders }) => {
  useEffect(() => {
    notBooked();
  }, [notBooked]);
  let history = useHistory();

  if (orders === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  const goback = () => {
    history.push('/admin/order-management');
  };
  return (
    <div className="container">
      <Alert variant="secondary text-center">
        <Button
          variant="outline-secondary float-left"
          size="sm"
          onClick={() => goback()}
        >
          Operations
        </Button>
        Unallocated Cleaners
      </Alert>
      <ListGroup variant="flush">
        {orders.length === 0 ? (
          <Alert variant="success">There are no unallocated orders</Alert>
        ) : (
          orders.map((order) => <OrderItems key={order._id} order={order} />)
        )}
      </ListGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.admin_orders.not_booked,
});

export default connect(mapStateToProps, { notBooked })(OrderNotAllocated);
