import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import PostCodeFinder from '../../Layout/PostCodeFinder';

const AddAddress = ({
  code,
  address,
  setAddress,
  postcodeFinder,
  setCode,
  setCoordinates,
  setNote,
  addAddress,
  a,
  note,
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (note.length > 0) {
      setShow(false);
    }
  }, [note]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="outline-success float-right" onClick={handleShow}>
        Add Address...
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add address to your profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Group>
              <Form.Label>Enter Address:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your Address"
                name="address"
                value={address}
                setAddress={setAddress}
                onChange={(e) => setAddress(e.target.value)}
              />
              <Form.Text className="text-muted">
                Please enter your Address
              </Form.Text>
            </Form.Group>
          </Form.Group>
          <Form.Group>
            <Form.Label>Enter post code:</Form.Label>
            <PostCodeFinder
              value={code}
              postcodeFinder={postcodeFinder}
              setCode={setCode}
              setCoordinates={setCoordinates}
              setNote={setNote}
            />
          </Form.Group>
          <Form.Text className="text-muted">
            {code ? (
              <b className="text-success">Your postcode is {code}</b>
            ) : (
              <b className="text-dark">Please enter your UK postcode</b>
            )}
          </Form.Text>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {a ? (
            <Button variant="primary" onClick={addAddress}>
              Save Address
            </Button>
          ) : (
            ''
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddAddress;
