import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  payingDusters,
  customPayRange,
} from '../../../../../actions/admin/main';
import { Container, Spinner, Alert, Col, Row, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ListDuster from './ListDuster';
import { setNote } from '../../../../../actions/note';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const PayingDusters = ({
  main: { paying_dusters, custom_pay_cycle },
  dusters,
  setNote,
  customPayRange,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  useEffect(() => {
    payingDusters();
  }, [payingDusters]);

  useEffect(() => {
    if (custom_pay_cycle) {
      btn.current.removeAttribute('disabled');
    }
  }, [custom_pay_cycle]);

  let history = useHistory();
  let btn = useRef();

  const goBack = () => {
    history.push('/admin/admin-accounts');
  };

  if (paying_dusters === null)
    return <Spinner animation="grow" variant="primary" />;

  const fetchDateRange = () => {
    if (startDate > endDate) {
      return setNote('Start date is after end date', 'warning');
    }
    customPayRange({ startDate, endDate });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <Container>
      <Alert variant="info text-center">
        <Button variant="light float-left" size="sm" onClick={goBack}>
          Accounts
        </Button>
        Payroll
      </Alert>
      <Row>
        <Col>
          {paying_dusters.length === 0
            ? 'No dusters to pay'
            : paying_dusters.map((duster) => (
                <ListDuster key={duster._id} duster={duster} />
              ))}
        </Col>
        <Col>
          <h4>Custom Pay View..</h4>
          <Row className="mt-4">
            <b className="mr-3">Start Date: </b>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </Row>
          <Row className="mt-3">
            <b className="mr-4">End Date: </b>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Row>

          <Button variant="primary mt-4" onClick={fetchDateRange} ref={btn}>
            Fetch custom Pay Range
          </Button>
          <Row className="mt-3">
            {custom_pay_cycle === null
              ? 'View custom pay-cycle'
              : custom_pay_cycle.length === 0
              ? 'No dusters to pay in this cycle'
              : custom_pay_cycle.map((duster) => (
                  <ListDuster key={duster._id} duster={duster} />
                ))}
          </Row>
        </Col>{' '}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  main: state.main,
});
export default connect(mapStateToProps, {
  payingDusters,
  setNote,
  customPayRange,
})(PayingDusters);
