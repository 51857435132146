import axios from 'axios';
import {
  CLEAR_COUPONS,
  MY_COUPONS,
  COUPON,
  COUPON_TOTAL,
  CLEAR_COUPON,
} from '../types';

export const myCoupons = () => async (dispatch) => {
  dispatch({ type: CLEAR_COUPONS });
  try {
    const res = await axios.get('/api/user/coupons');
    dispatch({ type: MY_COUPONS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const getCoupon = (couponId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/coupons/specific/${couponId}`);
    dispatch({ type: COUPON, payload: res.data });
  } catch (error) {
    console.error('Error Fetching Coupon: ', error.message);
  }
};

export const setCouponTotal = (couponTotal) => async (dispatch) => {
  try {
    dispatch({ type: COUPON_TOTAL, payload: couponTotal });
  } catch (error) {
    console.error(error.message);
  }
};

export const clearCoupon = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_COUPON });
  } catch (error) {
    console.log(error.message);
  }
};
