import React, { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';

const DeleteOrder = ({ delOrder, deleteBtn }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="danger float-right mr-2" size="sm" onClick={handleShow}>
        <i className="fas fa-trash-alt"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Alert variant="danger">Delete Order</Alert>
        <Modal.Body className="text-center">You cannot undo this!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" ref={deleteBtn} onClick={delOrder}>
            Delete Order
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteOrder;
