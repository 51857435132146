import {
  MY_COUPONS,
  CLEAR_COUPONS,
  CLEAR_COUPON,
  COUPON,
  COUPON_TOTAL,
} from '../../actions/types';

const initialState = {
  coupons: null,
  coupon: null,
  loading: false,
  couponTotal: null,
};

function couponsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case MY_COUPONS:
      return {
        ...state,
        coupons: payload,
        loading: false,
      };
    case CLEAR_COUPONS:
      return {
        ...state,
        coupons: null,
        loading: true,
      };
    case COUPON:
      return {
        ...state,
        coupon: payload,
      };
    case COUPON_TOTAL:
      return {
        ...state,
        couponTotal: payload,
      };
    case CLEAR_COUPON:
      return {
        ...state,
        coupon: null,
      };
    default:
      return state;
  }
}

export default couponsReducer;
