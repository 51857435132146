import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spinner, Row, Alert, Col, ListGroup } from 'react-bootstrap';
import { localUsers } from '../../../../actions/admin/admin_user';
import LocalUsers from './LocalUsers';

import './User.css';

const AdminUsers = ({
  admin_users: { local },

  localUsers,
}) => {
  useEffect(() => {
    localUsers();
  }, [localUsers]);
  const [searchLocal, setSearchLocal] = useState('');

  if (local === null) {
    return <Spinner animation="border" variant="primary" />;
  }

  return (
    <div className="container">
      <Alert variant="info">
        Dusting Down Users
        <span className="float-right">
          <input
            className="text-center"
            placeholder="search by email"
            style={{ border: 'none' }}
            onChange={(e) => setSearchLocal(e.target.value)}
          />
        </span>
      </Alert>
      <div>
        <Row className="justify-content-around">
          <Col>
            <ListGroup variant="flush">
              {local.length === 0 ? (
                <Alert variant="secondary">No Profiles Available</Alert>
              ) : (
                local
                  .filter((val) => {
                    if (searchLocal === '') {
                      return val;
                    } else if (
                      val.local.email
                        .toLowerCase()
                        .includes(searchLocal.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((user) => <LocalUsers key={user._id} user={user} />)
              )}
            </ListGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  admin_users: state.admin_users,
});

export default connect(mapStateToProps, {
  localUsers,
})(AdminUsers);
