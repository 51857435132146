import React, { useState } from 'react';
import PlacesAutoComplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const AddressAutocomplete = ({ setCoordinates, setAddress }) => {
  const [adrs, setAdrs] = useState('');

  const handleSelect = async (value) => {
    const res = await geocodeByAddress(value);
    const ll = await getLatLng(res[0]);
    setAdrs(value);
    setAddress(value);
    setCoordinates(ll);
  };

  const searchOptions = {
    componentRestrictions: { country: ['uk'] },
    types: ['address'],
  };

  return (
    <>
      <PlacesAutoComplete
        value={adrs}
        onChange={setAdrs}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div key={suggestions.description}>
            <input
              {...getInputProps({
                placeholder: 'Please Type Your Street Address',
              })}
              style={{
                width: '100%',
                borderColor: '#dadada',
                borderWidth: '1px',
              }}
            />
            <div>
              {loading ? <div>...loading</div> : null}
              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                };

                return (
                  <div
                    key={suggestion._id}
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutoComplete>
    </>
  );
};

export default AddressAutocomplete;
