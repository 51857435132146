import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { completeOrders } from '../../../actions/admin/orders';
import { Container, Spinner, Alert, Button, ListGroup } from 'react-bootstrap';
import OrderItems from './OrderItems';

const CompleteOrders = ({ completeOrders, orders }) => {
  useEffect(() => {
    completeOrders();
  }, [completeOrders]);

  let history = useHistory();

  if (orders === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  const goback = () => {
    history.push('/admin/order-management');
  };
  return (
    <div>
      <Container>
        <Alert variant="secondary text-center">
          <Button
            variant="outline-secondary float-left"
            size="sm"
            onClick={() => goback()}
          >
            Operations
          </Button>
          Completed Orders
        </Alert>
        <ListGroup variant="flush text-center">
          {orders.length === 0 ? (
            <Alert variant="success">No Complete Orders</Alert>
          ) : (
            orders.map((order) => <OrderItems key={order._id} order={order} />)
          )}
        </ListGroup>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.admin_orders.complete_orders,
});

export default connect(mapStateToProps, { completeOrders })(CompleteOrders);
