import React, { useEffect, useState } from 'react';
import moment from 'moment';
import RepeatCleans from './RepeatCleans';
import { ListGroup } from 'react-bootstrap';

const PayslipItem = ({
  index,
  pay,
  rate,
  calculateTotalHours,
  calculateRepeatHours,
}) => {
  const [client, setClient] = useState(null);
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [hoursWorked, setHoursWorked] = useState(null);
  const [checkInArr, setCheckInArr] = useState(null);
  const [checkOutArr, setCheckOutArr] = useState(null);
  const [cleans, setCleans] = useState(null);
  const [repeat, setRepeat] = useState(null);
  const splitAt = (index, xs) => [xs.slice(0, index), xs.slice(index)];
  const ref = splitAt(5, pay._id);

  useEffect(() => {
    if (pay) {
      if (pay.user.local) setClient(pay.user.local);
      if (pay.user.facebook) setClient(pay.user.facebook);
      if (pay.user.google) setClient(pay.user.google);
      if (pay.orderTime) {
        setRepeat(false);
        setCheckIn(pay.checkIn);
        setCheckOut(pay.checkOut);
      }
      if (pay.schedule) {
        setRepeat(true);
        setCheckInArr(pay.checkIn);
        setCheckOutArr(pay.checkOut);
        setCleans(pay.schedule.length);
      }
    }
  }, [pay]);

  useEffect(() => {
    if (checkIn && checkOut) {
      const date1 = new Date(checkIn);
      const date2 = new Date(checkOut);
      const diffTime = Math.abs(date2 - date1);
      const hw = (diffTime / (1000 * 60 * 60)).toFixed(1);
      setHoursWorked(parseFloat(hw).toFixed(1));
      calculateTotalHours(parseFloat(hw).toFixed(1));
    }
  }, [checkIn, checkOut, calculateTotalHours]);

  return (
    <tr>
      <th>{index + 1}</th>
      <th>{ref[0]}...</th>
      <th>
        {repeat === false ? (
          `${moment(pay.orderTime).format('MMM Do YY')}`
        ) : (
          <>
            <ListGroup.Item>
              First Date; {moment(pay.firstDate).format('MMM Do YY')}
            </ListGroup.Item>
            <ListGroup.Item>
              {cleans} cleans; {checkOutArr && checkOutArr.length} complete
            </ListGroup.Item>
          </>
        )}
      </th>
      <th>{client && client.name}</th>
      <th>{pay.invoiceHours} hours</th>
      <th>£ {rate}/hr</th>
      <th>{checkOut && `${hoursWorked} hrs`}</th>
      <th>
        {repeat &&
          checkOutArr.map((checkout, index) => (
            <RepeatCleans
              checkInArr={checkInArr}
              checkout={checkout}
              key={checkout}
              index={index}
              calculateRepeatHours={calculateRepeatHours}
            />
          ))}
      </th>
      <th>Tip</th>
      <th>{repeat ? 'repeat' : `£ ${(hoursWorked * rate).toFixed(2)}`}</th>
    </tr>
  );
};

export default PayslipItem;
