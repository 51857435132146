import React, { useEffect, useState, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Container,
  Spinner,
  Form,
  Button,
  Alert,
  Col,
  Row,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import { currentUser, updateInfo } from '../../../actions/user';

import AddressAutocomplete from '../../Layout/AddressAutocomplete';
import Bottom from '../../Layout/Bottom';

const GGCallback = ({
  match,
  currentUser,
  user: { isUser, user },
  updateInfo,
  note,
}) => {
  const [show, setShow] = useState(false);
  const [complex, setComplex] = useState(null);
  useEffect(() => {
    const token = match.params.token;
    if (token) {
      localStorage.setItem('token', token);
      currentUser();
    }
  }, [match, currentUser]);
  const [cellphone, setCellphone] = useState(null);
  const [address, setAddress] = useState(null);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  let btn = useRef();

  useEffect(() => {
    if (user) {
      setAddress(user.address);
      setCellphone(user.cellphone);
      setCoordinates(user.coordinates);
      setComplex(user.complex);
    }
  }, [user]);

  if (note.length > 0) {
    return <Redirect to="/user/booking" />;
  }

  if (isUser === null) {
    return <Spinner animation="grow" variant="dark" />;
  }

  if (user.address && user.cellphone) {
    return <Redirect to="/user/booking" />;
  }

  const handleClose = () => {
    setComplex(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const saveComplex = () => setShow(false);

  const onSubmit = (e) => {
    e.preventDefault();
    updateInfo({ address, coordinates, complex, cellphone });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <div align="center">
      <Container>
        <Form onSubmit={onSubmit} style={{ width: '25rem' }}>
          <Alert variant="warning">Please give us more info:</Alert>
          {user.cellphone ? (
            <Alert>You have added a celphone number</Alert>
          ) : (
            <Form.Group>
              <Form.Label>Cellphone Number:</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Your Cellphone Number"
                name="cellphone"
                value={cellphone}
                onChange={(e) => setCellphone(e.target.value)}
              />
              <Form.Text className="text-muted">
                Please enter your cellphone number
              </Form.Text>
            </Form.Group>
          )}

          {user.address ? (
            <Alert variant="info">You have added a primary address</Alert>
          ) : (
            <>
              <Form.Group>
                <Form.Label>
                  Please enter the address you want to clean
                </Form.Label>
                <AddressAutocomplete
                  value={address}
                  setAddress={setAddress}
                  setCoordinates={setCoordinates}
                />
              </Form.Group>
              <Form.Group>
                <InputGroup>
                  <Button variant="dark" disabled>
                    Do you live in complex?
                  </Button>
                  <InputGroup.Append>
                    <Button
                      variant={complex ? 'info' : 'outline-info'}
                      onClick={handleShow}
                    >
                      Yes
                    </Button>
                    <Button
                      variant={complex ? 'outline-primary' : 'primary'}
                      onClick={() => setComplex(null)}
                    >
                      No
                    </Button>
                    {complex ? (
                      <Button variant="info" className="ml-3">
                        Your Complex details: {complex}
                      </Button>
                    ) : (
                      ''
                    )}
                  </InputGroup.Append>
                </InputGroup>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Please enter you complex details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Control
                      type="text"
                      placeholder="Please enter complex details"
                      name="complex"
                      value={complex}
                      onChange={(e) => setComplex(e.target.value)}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                      Dont save
                    </Button>
                    <Button variant="primary" onClick={saveComplex}>
                      Save Details
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form.Group>
            </>
          )}

          <Row>
            <Col>
              <Button
                variant="success"
                type="submit"
                className="float-right"
                onSubmit={onSubmit}
                ref={btn}
                block
              >
                Update Info
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      <Bottom />
    </div>
  );
};

const mapstateToProps = (state) => ({
  user: state.user,
  note: state.note,
});

export default connect(mapstateToProps, { currentUser, updateInfo })(
  GGCallback
);
