import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Col, Row, ListGroup } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring';
import Bottom from './Bottom';
import './Home.css';

const Home = ({ animate: { how, contact } }) => {
  const [isShownBathroom, setIsShownBathroom] = useState(false);
  const [isShownBedroom, setIsShownBedroom] = useState(false);
  const [isShownLounge, setIsShownLounge] = useState(false);
  const [isShownKitchen, setIsShownKitchen] = useState(false);

  const inView = useRef();
  const howItWorks = useRef();
  const bottom = useRef();

  useEffect(() => {
    if (how && howItWorks.current) {
      window.scrollTo(0, howItWorks.current.offsetTop);
    }
  }, [how, howItWorks]);

  useEffect(() => {
    if (contact && bottom.current) {
      window.scrollTo(0, bottom.current.offsetTop);
    }
  }, [contact, bottom]);

  const [isSelect, SetSelected] = useState(false);
  const selectBtn = useSpring({
    opacity: isSelect ? 1 : 0,
    delay: 100,
  });
  const jumbo = useSpring({
    opacity: 1,
    from: {
      opacity: 0,
    },
    delay: 300,
  });

  const headerText = useSpring({
    marginTop: 0,
    from: {
      marginTop: -1000,
    },
    config: {
      friction: 52,
    },
    delay: 400,
  });
  const underjam = useSpring({
    opacity: 1,
    delay: 400,
  });

  return (
    <div>
      <animated.div className="row mb-4" id="jumbotron" style={jumbo}>
        <div className="container">
          <div className="col text-center" id="header-text">
            <animated.div style={headerText}>
              <h1 className="display-4 mb-3" id="welcome-text">
                <b>Cleaning made</b>
              </h1>
              <h1 className="display-4" id="welcome-text">
                <b>Smarter. Simpler. Safer.</b>
              </h1>
            </animated.div>
          </div>
          <div className="col" id="service-selector">
            <Button
              variant="info mt-4"
              onClick={() => SetSelected(!isSelect)}
              block
            >
              Select A Service
              {isSelect ? (
                <i className="ml-3 fas fa-chevron-circle-up"></i>
              ) : (
                <i className="ml-3 fas fa-chevron-circle-down"></i>
              )}
            </Button>
            <animated.div style={selectBtn}>
              <ButtonGroup id="service-options" vertical>
                <Button variant="outline-success mb-1">
                  <Link to="/user/booking/" className="text-dark">
                    <b>
                      <i className="mr-3 fas fa-home"></i> Clean My House
                    </b>
                  </Link>
                </Button>

                <Button variant="outline-success">
                  <Link to="/user/booking/" className="text-dark">
                    <b>
                      <i className="mr-3 fas fa-building"></i> Clean My Office
                    </b>
                  </Link>
                </Button>
              </ButtonGroup>
            </animated.div>
          </div>
        </div>
      </animated.div>
      <div className="container">
        <animated.div style={underjam}>
          <Col>
            <h1
              className="mt-5 display-3 text-center text-dark mb-5"
              style={{ fontWeight: 'bold' }}
            >
              <b>How we do it</b>
            </h1>
            <Row ref={inView}>
              <Col align="center" md={6} xs={12}>
                <img
                  src="https://res.cloudinary.com/dfz6nmjfw/image/upload/v1635246460/map_efd8mx.png"
                  alt="address"
                />
                <h3 className="Display-5 text-dark">
                  <b>Tell us where you live</b>
                </h3>
                <b className="text-secondary">
                  This helps us match you with the best cleaner in your area
                </b>
              </Col>

              <Col align="center" md={6} xs={12}>
                <img
                  src="https://res.cloudinary.com/dfz6nmjfw/image/upload/v1635246460/person_sfjojt.png"
                  alt="personalize"
                />
                <h3 className="text-dark">
                  <b>Personalize your clean</b>
                </h3>
                <b className="text-secondary">
                  Tell us about your home & any requirements you have
                </b>
                <p>
                  <b className="text-secondary">
                    From this we can give you the best price and time
                  </b>
                </p>
              </Col>
            </Row>
            <Row className="mt-4" ref={howItWorks}>
              <Col md={4} align="center">
                <img
                  src="https://res.cloudinary.com/dfz6nmjfw/image/upload/v1635246460/book_vou5xo.png"
                  alt="bookatime"
                />
                <h3 className="text-dark">
                  <b>Book a date & time</b>
                </h3>
                <b className="text-secondary">
                  Pick a recurring or once-off order
                </b>
              </Col>

              <Col md={4} align="center">
                <img
                  src="https://res.cloudinary.com/dfz6nmjfw/image/upload/v1635246460/checkout_hqlusn.png"
                  alt="checkout"
                />
                <h3 className="text-dark">
                  <b>Check Out</b>
                </h3>
                <b className="text-secondary">Confirm & easily pay online</b>
              </Col>

              <Col md={4} align="center">
                <img
                  src="https://res.cloudinary.com/dfz6nmjfw/image/upload/v1635246460/enjoy_viwupv.png"
                  alt="Enjoy"
                />
                <h3 className="text-dark">
                  <b>Enjoy</b>
                </h3>
                <b className="text-secondary">Tell us about your experience</b>
              </Col>
            </Row>

            <h1
              className="mt-5 display-3 text-center text-dark mb-5"
              style={{ fontWeight: 'bold' }}
            >
              <b>Why Dusting Down</b>
            </h1>
            <Row className="text-center">
              <Col>
                <img
                  src="https://res.cloudinary.com/dfz6nmjfw/image/upload/v1635247938/vetted_pmhnq1.png"
                  alt="Vetted Cleaners"
                />
                <h3 className="text-dark">
                  <b>Vetted Cleaners</b>
                </h3>
              </Col>
              <Col>
                <img
                  src="https://res.cloudinary.com/dfz6nmjfw/image/upload/v1635247658/training_nkgy4i.png"
                  alt="target"
                />
                <h3 className="text-dark">
                  <b>Intense Training</b>
                </h3>
              </Col>
              <Col>
                <img
                  src="https://res.cloudinary.com/dfz6nmjfw/image/upload/v1635247658/Personilized_qzbzgq.png"
                  alt="clipboard"
                />
                <h3 className="text-dark">
                  <b>Personalized</b>
                </h3>
              </Col>
            </Row>
            <Row align="center">
              <Col className="mt-3">
                <img
                  src="https://res.cloudinary.com/dfz6nmjfw/image/upload/v1635247663/flexibility_lz5wqg.png"
                  alt="schedule"
                />
                <h3 className="text-dark">
                  <b>Flexibility</b>
                </h3>
              </Col>
              <Col>
                <img
                  src="https://res.cloudinary.com/dfz6nmjfw/image/upload/v1635247658/trusted_lgtyye.png"
                  alt="person"
                />
                <h3 className="text-dark">
                  <b>Trusted & Committed</b>
                </h3>
              </Col>
            </Row>
          </Col>
        </animated.div>
      </div>
      <div align="center">
        <h1
          className="mt-5 display-3 text-center text-dark mb-5"
          style={{ fontWeight: 'bold' }}
        >
          <b>Included</b>
        </h1>

        <Row>
          <Col className="mt-2" md={3}>
            <h4>
              <b>Bathroom</b>
            </h4>
            {isShownBathroom ? (
              <Col id="bathroom" onMouseLeave={() => setIsShownBathroom(false)}>
                <ListGroup variant="flush">
                  <ListGroup.Item id="bathroom-listgroup">
                    Sink Cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Stainless steel polish
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Under furniture cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Floor cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Doors and handles cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Lights and switches
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Empty waste containers
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Toilet & toilet lid cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Showers & bath cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Cleaning glass surfaces
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Change and replace bath towels
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            ) : (
              <img
                src={
                  'https://res.cloudinary.com/dfz6nmjfw/image/upload/e_improve,w_300,h_600,c_thumb,g_auto/v1643887923/Dusting%20Images/Bathroom_qsqpji.jpg'
                }
                alt="bathroom"
                onMouseEnter={() => setIsShownBathroom(true)}
              />
            )}
          </Col>
          <Col md={3} className="mt-2">
            <h4>
              <b>Bedroom</b>
            </h4>
            {isShownBedroom ? (
              <Col id="bedroom" onMouseLeave={() => setIsShownBedroom(false)}>
                <ListGroup variant="flush">
                  <hr id="bed-list" />
                  <ListGroup.Item id="bathroom-listgroup">
                    Clean all furnishings
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Door frames, window sill cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Dust all accessible surfaces
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Light switches cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Doors & handles cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Floors & carpert cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Bed making & changing sheets
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            ) : (
              <img
                src="https://res.cloudinary.com/dfz6nmjfw/image/upload/e_improve,w_300,h_600,c_thumb,g_auto/v1643887917/Dusting%20Images/Bedroom_zgatvf.png"
                alt="bedroom"
                onMouseEnter={() => setIsShownBedroom(true)}
              />
            )}
          </Col>
          <Col md={3} className="mt-2">
            <h4>
              <b>Lounge</b>
            </h4>
            {isShownLounge ? (
              <Col id="lounge" onMouseLeave={() => setIsShownLounge(false)}>
                <ListGroup variant="flush">
                  <hr id="bed-list" />
                  <ListGroup.Item id="bathroom-listgroup">
                    Dust all accessible surfaces
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Cleaningthe floor entrance
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Light switches cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Floor cleaning & vacuum carperts
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Rubbish removal
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Cleaning stairs and railing
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            ) : (
              <img
                src="https://res.cloudinary.com/dfz6nmjfw/image/upload/e_improve,w_300,h_600,c_thumb,g_auto/v1643887917/Dusting%20Images/Lounge_ni1fv5.jpg"
                alt="lounge"
                onMouseEnter={() => setIsShownLounge(true)}
              />
            )}
          </Col>
          <Col md={3} className="mt-2">
            <h4>
              <b>Kitchen</b>
            </h4>
            {isShownKitchen ? (
              <Col id="kitchen" onMouseLeave={() => setIsShownKitchen(false)}>
                <ListGroup variant="flush">
                  <ListGroup.Item id="bathroom-listgroup">
                    Dish washing
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Microwave cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Oven wipe down
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Kitchen furniture cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Dust all accessible areas
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Sweepng and washing floors
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Rubbish removal
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Clean glass surfaces
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Doors and handles cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Under furniture cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Light switches cleaning
                  </ListGroup.Item>
                  <ListGroup.Item id="bathroom-listgroup">
                    Clean Fridge
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            ) : (
              <img
                src="https://res.cloudinary.com/dfz6nmjfw/image/upload/e_improve,w_300,h_600,c_thumb,g_auto/v1643887917/Dusting%20Images/Kitchen_rwfowt.jpg"
                alt="kitchen"
                onMouseEnter={() => setIsShownKitchen(true)}
              />
            )}
          </Col>
        </Row>
      </div>
      <div ref={bottom}>
        <Bottom />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  animate: state.animate,
});
export default connect(mapStateToProps)(Home);
