import axios from 'axios';
import { SET_USERS, CLEAR_USERS } from '../types';
import { setNote } from '../note';

export const GenerateCoupons =
  ({ percentage, validity }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_USERS });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ percentage, validity });
    try {
      const res = await axios.post(
        '/api/admin/coupons/all-user-token',
        body,
        config
      );
      dispatch({ type: SET_USERS, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

export const CreateUserCoupon =
  ({ percentage, validity, userId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ percentage, validity, userId });
    try {
      const res = await axios.post(
        '/api/admin/coupons/create-user-token',
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }

      dispatch();
    } catch (error) {
      console.error(error.message);
    }
  };
