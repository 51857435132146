import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, InputGroup, FormControl, Form } from 'react-bootstrap';

const AdminUser = ({ createAdmin, note }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (note.length > 0) {
      btn.current.removeAttribute('disabled');
      setShow(false);
    }
  }, [note]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
  });

  let btn = useRef();

  const { name, email, password, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const [showPassword, setShowPassword] = useState('password');

  const togglePassword = () => {
    if (showPassword === 'password') setShowPassword('text');
    if (showPassword === 'text') setShowPassword('password');
  };

  const create = (e) => {
    e.preventDefault();
    createAdmin({
      name,
      email,
      password,
    });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <>
      <Button
        variant="secondary float-right ml-3"
        size="sm"
        onClick={handleShow}
      >
        Add Admin
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add new admin user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              name="name"
              value={name}
              onChange={(e) => onChange(e)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
              validate
            />
          </Form.Group>
          Create Password:
          <InputGroup className="mb-3">
            <FormControl
              type={showPassword}
              placeholder="Create password for Duster"
              value={password}
              name="password"
              onChange={(e) => onChange(e)}
            />
            <InputGroup.Text>
              {showPassword === 'password' ? (
                <i className="fas fa-eye-slash" onClick={togglePassword}></i>
              ) : (
                <i
                  className="fas fa-eye text-success"
                  onClick={togglePassword}
                ></i>
              )}
            </InputGroup.Text>
          </InputGroup>
          Confirm Password:
          <InputGroup className="mb-3">
            <FormControl
              type={showPassword}
              placeholder="Confirm password for Duster"
              value={password2}
              name="password2"
              onChange={(e) => onChange(e)}
            />
            <InputGroup.Text>
              {showPassword === 'password' ? (
                <i className="fas fa-eye-slash" onClick={togglePassword}></i>
              ) : (
                <i
                  className="fas fa-eye text-success"
                  onClick={togglePassword}
                ></i>
              )}
            </InputGroup.Text>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {password.length > 7 && password === password2 ? (
            <Button variant="success" onClick={create} ref={btn}>
              Create admin
            </Button>
          ) : (
            <Button variant="light">Fill in admin details</Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminUser;
