import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Alert, Container, Spinner } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ordersOnAccount } from '../../../../../actions/admin/main';
import ListOrder from './ListOrder';

const OrderOnAccount = ({ ordersOnAccount, orders }) => {
  useEffect(() => {
    ordersOnAccount();
  }, [ordersOnAccount]);
  let history = useHistory();

  if (orders == null) return <Spinner animation="grow" variant="primary" />;

  const goBack = () => {
    history.push('/admin/admin-accounts');
  };
  return (
    <Container>
      <Alert variant="info text-center">
        <Button variant="light float-left" size="sm" onClick={goBack}>
          Accounts
        </Button>
        Orders on Account
      </Alert>
      {orders.length === 0
        ? 'No Orders'
        : orders.map((order) => <ListOrder key={order._id} order={order} />)}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  orders: state.main.monthEndOrders,
});

export default connect(mapStateToProps, { ordersOnAccount })(OrderOnAccount);
