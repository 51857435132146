import { useState, useEffect } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';

const PostCodeFinder = ({
  postcodeFinder,
  setCode,
  setCoordinates,
  setNote,
  code,
}) => {
  const [ready, setReady] = useState(false);
  const [mycode, setMyCode] = useState('');
  useEffect(() => {
    if (code) {
      setMyCode(code);
      setReady(true);
    }
  }, [code]);

  const fetchAddress = async () => {
    let code = mycode;
    const data = await postcodeFinder(code);
    if (data) {
      setCode(data.postcode);
      setMyCode(data.postcode);
      const lat = data.latitude;
      const lng = data.longitude;
      const coord = { lat, lng };
      setCoordinates(coord);
      setReady(true);
    } else {
      setNote('Please enter a valid post code', 'danger');
    }
  };
  const changePC = () => {
    setCode('');
    const lat = null;
    const lng = null;
    const coord = { lat, lng };
    setCoordinates(coord);
    setReady(false);
  };
  return (
    <InputGroup>
      <Form.Control
        required
        value={mycode}
        onChange={(e) => setMyCode(e.target.value)}
        placeholder={code ? `${code}` : 'Type Your Code'}
      />
      <InputGroup.Append>
        {mycode.length > 3 ? (
          ready ? (
            <Button variant="success text-white" onClick={changePC}>
              Change Post Code
              <i className="fa-solid fa-location-dot ml-2"></i>
            </Button>
          ) : (
            <Button variant="info text-white" onClick={fetchAddress}>
              Find Post Code
              <i className="fa-solid fa-location-dot ml-2"></i>
            </Button>
          )
        ) : (
          <Button variant="secondary text-white" disabled>
            Enter post code
            <i className="fa-solid fa-location-dot ml-2"></i>
          </Button>
        )}
      </InputGroup.Append>
    </InputGroup>
  );
};

export default PostCodeFinder;
