import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Alert, Modal } from 'react-bootstrap';

import { CreateUserCoupon } from '../../../../actions/admin/coupon';

const Coupon = ({ currentUser, userId, CreateUserCoupon }) => {
  const [show, setShow] = useState();
  const [formData, setFormData] = useState({
    percentage: 0,
    validity: 0,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let btn = useRef();

  const { percentage, validity } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    CreateUserCoupon({ percentage, validity, userId });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
    setShow(false);
  };

  return (
    <div>
      <Button variant="outline-success mb-3" onClick={handleShow} block>
        Create a coupon
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Alert variant="warning">Create Coupon for {currentUser}</Alert>
            <Form.Group className="mb-3">
              <Form.Label>Coupon Percentage</Form.Label>
              <Form.Control
                type="number"
                placeholder="Percentage"
                name="percentage"
                value={percentage}
                onChange={(e) => onChange(e)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Validity Period in Weeks</Form.Label>
              <Form.Control
                type="number"
                placeholder="Validity"
                value={validity}
                name="validity"
                onChange={(e) => onChange(e)}
                required
              />
            </Form.Group>
            <p>This will create this coupon for all users</p>
            <Button variant="primary" type="submit" ref={btn} block>
              Create the coupon
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default connect(null, { CreateUserCoupon })(Coupon);
