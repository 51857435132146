import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

const TeamRoute = ({
  component: Component,
  team: { isTeam, loading },
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      loading === true ? (
        <Spinner animation="border" variant="primary" />
      ) : isTeam ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

TeamRoute.propTypes = {
  team: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  team: state.team,
});

export default connect(mapStateToProps)(TeamRoute);
