import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { editRateCard } from '../../../../actions/admin/main';
import { Button, Modal, Form } from 'react-bootstrap';

const EditRateCard = ({ currentUser, rate, editRateCard }) => {
  const [show, setShow] = useState(false);
  const [two, SetTwo] = useState('');
  const [twop, SetTwoP] = useState('');
  const [three, SetThree] = useState('');
  const [threep, SetThreeP] = useState('');
  const [four, SetUserFour] = useState('');
  const [fourp, SetUserFourP] = useState('');
  const [five, SetUserFive] = useState('');
  const [fivep, SetUserFiveP] = useState('');
  const [six, SetUserSix] = useState('');
  const [sixp, SetUserSixP] = useState('');
  const [seven, SetUserSeven] = useState('');
  const [sevenp, SetUserSevenP] = useState('');
  const [eight, SetUserEight] = useState('');

  const [rateCard, SetRateCard] = useState('');

  useEffect(() => {
    if (rate) {
      SetRateCard(rate._id);
      SetTwo(rate.two);
      SetTwoP(rate.twop);
      SetThree(rate.three);
      SetThreeP(rate.threep);
      SetUserFour(rate.four);
      SetUserFourP(rate.fourp);
      SetUserFive(rate.five);
      SetUserFiveP(rate.fivep);
      SetUserSix(rate.six);
      SetUserSixP(rate.sixp);
      SetUserSeven(rate.seven);
      SetUserSevenP(rate.sevenp);
      SetUserEight(rate.eight);
    }
  }, [rate]);

  const editCard = (e) => {
    e.preventDefault();
    editRateCard({
      two,
      twop,
      three,
      threep,
      four,
      fourp,
      five,
      fivep,
      six,
      sixp,
      seven,
      sevenp,
      eight,
      rateCard,
    });
    setShow(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Button variant="primary mt-3" onClick={handleShow} block>
        Edit Custom Rate Card
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={editCard}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Rate Card for {currentUser}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>2 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={two}
                name="two"
                value={two}
                onChange={(e) => SetTwo(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>2.5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={twop}
                name="twop"
                value={twop}
                onChange={(e) => SetTwoP(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>3 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={three}
                name="three"
                value={three}
                onChange={(e) => SetThree(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>3.5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={threep}
                name="threep"
                value={threep}
                onChange={(e) => SetThreeP(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>4 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={four}
                name="four"
                value={four}
                onChange={(e) => SetUserFour(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>4.5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={fourp}
                name="fourp"
                value={fourp}
                onChange={(e) => SetUserFourP(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={five}
                name="five"
                value={five}
                onChange={(e) => SetUserFive(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>5.5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={fivep}
                name="fivep"
                value={fivep}
                onChange={(e) => SetUserFiveP(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>6 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={six}
                name="six"
                value={six}
                onChange={(e) => SetUserSix(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>6.5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={sixp}
                name="sixp"
                value={sixp}
                onChange={(e) => SetUserSixP(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>7 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={seven}
                name="seven"
                value={seven}
                onChange={(e) => SetUserSeven(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>7.5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={sevenp}
                name="sevenp"
                value={sevenp}
                onChange={(e) => SetUserSevenP(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>8 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={eight}
                name="eight"
                value={eight}
                onChange={(e) => SetUserEight(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Edit Custom Rate
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  rate: state.main.clientRate,
});

export default connect(mapStateToProps, { editRateCard })(EditRateCard);
