import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createAdmin } from '../../../../actions/admin';
import {
  numberDusters,
  numberUsers,
  ordersTotal,
} from '../../../../actions/admin/main';
import { Row, Col, Card, Spinner, Button, Alert } from 'react-bootstrap';
import MotherCard from './MotherCard';
import AdminUser from './AdminUser';

const Main = ({
  main: { allUsers, allDusters, orderTotal },
  numberDusters,
  numberUsers,
  ordersTotal,
  createAdmin,
  note,
}) => {
  useEffect(() => {
    numberDusters();
    numberUsers();
    ordersTotal();
  }, [numberUsers, numberDusters, ordersTotal]);

  if (allUsers === null || allDusters === null || orderTotal === null) {
    return <Spinner animation="grow" variant="primary" />;
  }
  return (
    <div className="container" align="center">
      <Alert variant="info">
        Main Dashboard <AdminUser createAdmin={createAdmin} note={note} />
        <MotherCard />
      </Alert>
      <Row>
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>
                <span style={{ fontSize: '4em' }} className="float-left">
                  <i className="fas fa-hand-sparkles text-primary"></i>
                </span>
                Dust Busters
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <b className="display-4">{allDusters}</b>
              </Card.Subtitle>
              <Card.Text>Dust Busters have created accounts</Card.Text>
              <Button
                as={Link}
                to="/admin/admin-dusters"
                variant="outline-secondary"
              >
                Closer Look...
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fas fa-user-tag text-info"></i>
              </span>
              <Card.Title>Customers</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <b className="display-4">{allUsers}</b>
              </Card.Subtitle>
              <Card.Text>
                Customers have created accounts on Dusting Down
              </Card.Text>
              <Button
                as={Link}
                to="/admin/admin_users/"
                variant="outline-secondary"
              >
                Closer Look...
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fas fa-coins text-success"></i>
              </span>
              <Card.Title>Accounts</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                £<b className="display-4">{orderTotal}</b>
              </Card.Subtitle>
              <Card.Text>
                In total Orders Transactions on Dusting Down
              </Card.Text>
              <Button
                as={Link}
                to="/admin/admin-accounts/"
                variant="outline-secondary"
              >
                Closer Look...
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  main: state.main,
  note: state.note,
});

export default connect(mapStateToProps, {
  numberDusters,
  numberUsers,
  ordersTotal,
  createAdmin,
})(Main);
