import { CLEAR_PAY, STRIPE_URL } from '../../actions/types';

const initState = {
  paymentUrl: null,
  loading: true,
};

function paymentReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case STRIPE_URL:
      return {
        paymentUrl: payload,
        loading: false,
      };
    case CLEAR_PAY:
      return {
        paymentUrl: null,
        loading: true,
      };
    default:
      return state;
  }
}

export default paymentReducer;
