import axios from 'axios';
import {
  ALL_DUSTERS,
  ALL_USERS,
  ALL_ORDER_TOTAL,
  SET_USER,
  RATE_CARD,
  CLEAR_RATE,
  THE_MOTHER_CARD,
  ALL_INVOICES,
  ORDER_INVOICE,
  INVOICE,
  CLEAR_ORDER_INVOICE,
  PAYING_DUSTERS,
  PAYSLIP,
  CLEAR_PAYSLIP,
  TOTAL_HOURS,
  TOTAL_REPEAT_HOURS,
  MONTH_END_ORDERS,
  CUSTOM_PAYCYLE,
  RECURRING_ORDER_,
} from '../types';

import { setNote } from '../note';

export const numberDusters = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/all-dusters');
    dispatch({ type: ALL_DUSTERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const numberUsers = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/all-users');
    dispatch({ type: ALL_USERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const ordersTotal = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/all-order-total');
    dispatch({ type: ALL_ORDER_TOTAL, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const monthlyAcc =
  ({ data, userId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ data, userId });
    try {
      const res = await axios.put(
        '/api/admin/main/toggle-monthly',
        body,
        config
      );
      dispatch({ type: SET_USER, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////////
//Get The MotherCard//
//////////////////////
export const theMotherCard = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/the-mother-card');
    dispatch({ type: THE_MOTHER_CARD, payload: res.data });
  } catch (error) {
    console.errror(error.message);
  }
};

//Edit The Mother Card//
export const editMotherCard =
  ({
    motherCard,
    two,
    twop,
    three,
    threep,
    four,
    fourp,
    five,
    fivep,
    six,
    sixp,
    seven,
    sevenp,
    eight,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      two,
      twop,
      three,
      threep,
      four,
      fourp,
      five,
      fivep,
      six,
      sixp,
      seven,
      sevenp,
      eight,
    });
    try {
      const res = await axios.put(
        `/api/admin/main/the-mother-card/${motherCard}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(theMotherCard());
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////////////
///Create Custom Rate/////
//////////////////////////
export const customRate =
  ({
    two,
    twop,
    three,
    threep,
    four,
    fourp,
    five,
    fivep,
    six,
    sixp,
    seven,
    sevenp,
    eight,
    userId,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      two,
      twop,
      three,
      threep,
      four,
      fourp,
      five,
      fivep,
      six,
      sixp,
      seven,
      sevenp,
      eight,
      userId,
    });
    try {
      const res = await axios.post(
        '/api/admin/main/custom-rate-card',
        body,
        config
      );

      dispatch({ type: SET_USER, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

/////////////////////////
//Edit User Rate Card////
/////////////////////////
export const editRateCard =
  ({ four, five, six, seven, eight, nine, ten, rateCard }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      four,
      five,
      six,
      seven,
      eight,
      nine,
      ten,
      rateCard,
    });
    try {
      const res = await axios.put(
        `/api/admin/main/user-rate-card/${rateCard}`,
        body,
        config
      );
      dispatch({ type: SET_USER, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

/////////////////////////
///Get Users Rate Card///
/////////////////////////
export const userRate = (userId) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_RATE });
    const res = await axios.get(`/api/admin/main/user-rate-card/${userId}`);
    dispatch({ type: RATE_CARD, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const invoices = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/invoices');
    dispatch({ type: ALL_INVOICES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Order Invoice//
export const orderInvoice = (orderId) => async (dispatch) => {
  dispatch({ type: CLEAR_ORDER_INVOICE });
  try {
    const res = await axios.get(`/api/admin/main/order-invoice/${orderId}`);
    dispatch({ type: ORDER_INVOICE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Invoice//
export const getInvoice = (orderId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/admin/main/invoice/${orderId}`);
    dispatch({ type: INVOICE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const payingDusters = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/paying-dusters');

    dispatch({ type: PAYING_DUSTERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const DusterPayslip = (dusterId) => async (dispatch) => {
  dispatch({ type: CLEAR_PAYSLIP });
  try {
    const res = await axios.get(`/api/admin/main/payslip/${dusterId}`);
    dispatch({ type: PAYSLIP, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const calculateTotalHours = (num) => async (dispatch) => {
  try {
    dispatch({ type: TOTAL_HOURS, payload: num });
  } catch (error) {
    console.error(error.message);
  }
};

export const calculateRepeatHours = (num) => async (dispatch) => {
  try {
    dispatch({ type: TOTAL_REPEAT_HOURS, payload: num });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Orders on account//
export const ordersOnAccount = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/orders-on-account');
    dispatch({ type: MONTH_END_ORDERS, payload: res.data });
  } catch (error) {
    console.log(error.message);
  }
};

//Get custom pay range//
export const customPayRange =
  ({ startDate, endDate }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ startDate, endDate });
    try {
      const res = await axios.put(
        '/api/admin/main/custom-pay-cycle',
        body,
        config
      );
      dispatch({ type: CUSTOM_PAYCYLE, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//recurring order management//
export const recurringOrder = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/main/recurring-order-management');
    dispatch({ type: RECURRING_ORDER_, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//run recurring function//
export const runRepeatFunctions = () => async (dispatch) => {
  try {
    const res = await axios.put('/api/admin/main/run-recurring-function');

    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    console.error(error.message);
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
  }
};
