import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  addIconExtra,
  removeIconExtra,
  calcIconExtras,
} from '../../../actions/user/bookings';
import bedafter from '../../../images/BedAfter.png';
import bedbefore from '../../../images/BedBefore.png';
import ironafter from '../../../images/IronAfter.png';
import ironbefore from '../../../images/IronBefore.png';
import kitchenafter from '../../../images/KitchenAfter.png';
import kitchenbefore from '../../../images/KitchenBefore.png';
import stoveafter from '../../../images/StoveAfter.png';
import stovebefore from '../../../images/StoveBefore.png';
import fridgeafter from '../../../images/FridgeAfter.png';
import fridgbefore from '../../../images/FridgeBefore.png';
import windowafter from '../../../images/WindowsAfter.png';
import windowbefore from '../../../images/WindowsBefore.png';
import { setNote } from '../../../actions/note';

const IconExtras = ({
  iconExtras,
  removeIconExtra,
  addIconExtra,
  calcIconExtras,
  invoiceHours,
  setNote,
}) => {
  useEffect(() => {
    let arr = iconExtras.filter((ex) => ex.chosen !== false);
    let time = arr.map((a) => a.time);
    let tm = time.reduce((total, time) => {
      return total + time;
    }, 0);
    calcIconExtras(tm);
  }, [calcIconExtras, iconExtras]);
  const [ironing, SetIron] = useState('');
  const [bedding, SetBed] = useState('');
  const [kitchening, SetKitchen] = useState('');
  const [ovening, SetOven] = useState('');
  const [fridging, SetFridge] = useState('');
  const [windowing, SetWindows] = useState('');

  useEffect(() => {
    if (iconExtras) {
      SetIron(iconExtras[0]);
      SetBed(iconExtras[1]);
      SetKitchen(iconExtras[2]);
      SetOven(iconExtras[3]);
      SetFridge(iconExtras[4]);
      SetWindows(iconExtras[5]);
    }
  }, [iconExtras]);

  const iron = (e) => {
    if (ironing.chosen === true) {
      removeIconExtra(1);
    } else {
      if (invoiceHours === 8) {
        return setNote(
          'Please call the office to book over 8 hours: +44 1615 430101',
          'warning'
        );
      }

      addIconExtra(1);
    }
  };

  const bed = () => {
    if (bedding.chosen === true) {
      removeIconExtra(2);
    } else {
      if (invoiceHours === 8) {
        return setNote(
          'Please call the office to book over 8 hours: +44 1615 430101',
          'warning'
        );
      }
      addIconExtra(2);
    }
  };
  const kitchen = () => {
    if (kitchening.chosen === true) {
      removeIconExtra(3);
    } else {
      if (invoiceHours === 8) {
        return setNote(
          'Please call the office to book over 8 hours: +44 1615 430101',
          'warning'
        );
      }
      addIconExtra(3);
    }
  };

  const oven = () => {
    if (ovening.chosen === true) {
      removeIconExtra(4);
    } else {
      if (invoiceHours === 8) {
        return setNote(
          'Please call the office to book over 8 hours: +44 1615 430101',
          'warning'
        );
      }
      addIconExtra(4);
    }
  };

  const fridge = () => {
    if (fridging.chosen === true) {
      removeIconExtra(5);
    } else {
      if (invoiceHours === 8) {
        return setNote(
          'Please call the office to book over 8 hours: +44 1615 430101',
          'warning'
        );
      }
      addIconExtra(5);
    }
  };
  const windows = () => {
    if (windowing.chosen === true) {
      removeIconExtra(6);
    } else {
      if (invoiceHours === 8) {
        return setNote(
          'Please call the office to book over 8 hours +44 1615 430101',
          'warning'
        );
      }
      addIconExtra(6);
    }
  };

  return (
    <div>
      <h2 className="text-center text-info">Choose Extras</h2>
      <Row align="center">
        <Col xs={12} md={2}>
          <Card.Header> Ironing& Laundry</Card.Header>
          {ironing.chosen ? (
            <div id="selected-img">
              <img
                src={ironafter}
                width={100}
                height={100}
                alt="1"
                onClick={iron}
              />
            </div>
          ) : (
            <div id="img" onClick={iron}>
              <img src={ironbefore} width={100} height={100} alt="1" />
            </div>
          )}
        </Col>
        <Col xs={12} md={2}>
          <Card.Header>Bedding Change</Card.Header>
          {bedding.chosen ? (
            <div id="selected-img" onClick={bed}>
              <img src={bedafter} width={100} height={100} alt="2" />
            </div>
          ) : (
            <div id="img" onClick={bed}>
              <img src={bedbefore} width={100} height={100} alt="2" />
            </div>
          )}
        </Col>
        <Col xs={12} md={2}>
          <Card.Header>Kitchen Cupboards</Card.Header>
          {kitchening.chosen ? (
            <div id="selected-img" onClick={kitchen}>
              <img src={kitchenafter} width={100} height={100} alt="3" />
            </div>
          ) : (
            <div id="img" onClick={kitchen}>
              <img src={kitchenbefore} width={100} height={100} alt="3" />
            </div>
          )}
        </Col>
        <Col xs={12} md={2}>
          <Card.Header>Inside Oven</Card.Header>
          {ovening.chosen ? (
            <div id="selected-img" onClick={oven}>
              <img src={stoveafter} width={100} height={100} alt="4" />
            </div>
          ) : (
            <div id="img" onClick={oven}>
              <img src={stovebefore} width={100} height={100} alt="4" />
            </div>
          )}
        </Col>
        <Col xs={12} md={2}>
          <Card.Header>Inside Fridge</Card.Header>
          {fridging.chosen ? (
            <div id="selected-img" onClick={fridge}>
              <img src={fridgeafter} width={100} height={100} alt="5" />
            </div>
          ) : (
            <div id="img" onClick={fridge}>
              <img src={fridgbefore} width={100} height={100} alt="5" />
            </div>
          )}
        </Col>
        <Col md={2} xs={12}>
          <Card.Header>Internal Windows</Card.Header>
          {windowing.chosen ? (
            <div id="selected-img" onClick={windows}>
              <img src={windowafter} width={100} height={100} alt="6" />
            </div>
          ) : (
            <div id="img" onClick={windows}>
              <img src={windowbefore} width={100} height={100} alt="6" />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default connect(null, {
  addIconExtra,
  removeIconExtra,
  calcIconExtras,
  setNote,
})(IconExtras);
