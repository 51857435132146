import {
  RATE_CARD,
  ALL_DUSTERS,
  ALL_USERS,
  ALL_ORDER_TOTAL,
  CLEAR_RATE,
  THE_MOTHER_CARD,
  ALL_INVOICES,
  ORDER_INVOICE,
  INVOICE,
  CLEAR_ORDER_INVOICE,
  PAYING_DUSTERS,
  PAYSLIP,
  CLEAR_PAYSLIP,
  TOTAL_HOURS,
  TOTAL_REPEAT_HOURS,
  MONTH_END_ORDERS,
  CUSTOM_PAYCYLE,
  RECURRING_ORDER_,
} from '../../actions/types';
const initialState = {
  loading: false,
  allDusters: null,
  allUsers: null,
  orderTotal: null,
  clientRate: null,
  mother: null,
  invoices: null,
  order_invoice: null,
  invoice: null,
  paying_dusters: null,
  payslip: null,
  onceOffHours: [],
  totalOnceOffHours: 0,
  repeatHours: [],
  totalRepeatHours: 0,
  monthEndOrders: null,
  custom_pay_cycle: null,
  reOrderManagement: null,
};

function mainReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RECURRING_ORDER_:
      return {
        ...state,
        reOrderManagement: payload,
        loading: false,
      };
    case CUSTOM_PAYCYLE:
      return {
        ...state,
        custom_pay_cycle: payload,
        loading: false,
      };
    case MONTH_END_ORDERS:
      return {
        ...state,
        monthEndOrders: payload,
        loading: false,
      };
    case CLEAR_ORDER_INVOICE:
      return {
        ...state,
        order_invoice: null,
      };
    case THE_MOTHER_CARD:
      return {
        ...state,
        mother: payload,
      };
    case ALL_DUSTERS:
      return {
        ...state,
        allDusters: payload,
      };
    case ALL_USERS:
      return {
        ...state,
        allUsers: payload,
      };
    case ALL_ORDER_TOTAL:
      return {
        ...state,
        orderTotal: payload,
      };
    case RATE_CARD:
      return {
        ...state,
        clientRate: payload,
      };
    case CLEAR_RATE:
      return {
        ...state,
        clientRate: null,
      };
    case ALL_INVOICES:
      return {
        ...state,
        invoices: payload,
      };
    case ORDER_INVOICE:
      return {
        ...state,
        order_invoice: payload,
      };
    case INVOICE:
      return {
        ...state,
        invoice: payload,
      };
    case PAYING_DUSTERS:
      return {
        ...state,
        paying_dusters: payload,
      };
    case PAYSLIP:
      return {
        ...state,
        payslip: payload,
        loading: false,
      };
    case CLEAR_PAYSLIP:
      return {
        ...state,
        payslip: null,
        totalOnceOffHours: 0,
        totalRepeatHours: 0,
        onceOffHours: [],
        repeatHours: [],
        loading: true,
      };
    case TOTAL_HOURS:
      state.onceOffHours.push(parseFloat(payload));
      let total = state.onceOffHours.reduce((a, b) => a + b, 0);
      return {
        ...state,
        totalOnceOffHours: total,
        loading: false,
      };
    case TOTAL_REPEAT_HOURS:
      state.repeatHours.push(parseFloat(payload));
      let totalRepeat = state.repeatHours.reduce((a, b) => a + b, 0);
      return {
        ...state,
        totalRepeatHours: totalRepeat,
        loading: false,
      };
    default:
      return state;
  }
}

export default mainReducer;
