import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import moment from 'moment';

const RepeatCleans = ({
  checkInArr,
  checkout,
  index,
  calculateRepeatHours,
}) => {
  const [hours, setHours] = useState(0);
  useEffect(() => {
    const date1 = new Date(checkInArr[index]);
    const date2 = new Date(checkout);
    const diffTime = Math.abs(date2 - date1);
    const hoursWorked = (diffTime / (1000 * 60 * 60)).toFixed(0);
    calculateRepeatHours(parseFloat(hoursWorked).toFixed(1));
    setHours(parseFloat(hoursWorked).toFixed(1));
  }, [checkInArr, checkout, index, calculateRepeatHours]);

  return (
    <ListGroup.Item>
      {hours}/hrs; {moment(checkout).format('MMM Do YY')}
    </ListGroup.Item>
  );
};

export default RepeatCleans;
