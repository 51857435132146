import React, { useEffect, useState, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  ListGroup,
  Col,
  Row,
  Card,
  Alert,
  Spinner,
} from 'react-bootstrap';
import moment from 'moment';
import { paymentUrl } from '../../../../actions/user/payment';
import { Order, AccOrder } from '../../../../actions/user/order';
import { setBookingAddress } from '../../../../actions/user/bookings';
import ExtraItem from './ExtraItem';
import Coupon from './Coupon';
import Bottom from '../../../Layout/Bottom';
import AddressButton from './AddressButton';

const CheckOut = ({
  bookings: {
    orderTime,
    invoice,
    invoiceHours,
    changeHours,
    bedroom,
    bathroom,
    nityGrity,
    address,
    coordinates,
    complex,
    whyLess,
    iconExtras,
    postCode,
  },
  Order,
  user,
  orders: { order },
  couponTotal,
  AccOrder,
  setBookingAddress,
  paymentUrl,
  stripeUrl,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [mrx, SetMrx] = useState([]);
  useEffect(() => {
    if (stripeUrl) {
      window.location.href = stripeUrl;
    }
  }, [stripeUrl]);

  const [coupon, SetCoupon] = useState(null);
  const [inv, SetInv] = useState(null);
  const [couponOrder, SetCouponOrder] = useState(null);
  const [freeRedirect, SetFreeRedirect] = useState(false);
  const [monthlyRedirect, SetMonthlyRedirect] = useState(false);
  const [adr, SetAddress] = useState(address);
  const [code, setCode] = useState(postCode);
  const [cmplex, SetComplex] = useState(complex);
  const [coupons, SetCoupons] = useState(null);
  const [coo, SetCoordinates] = useState(coordinates);
  const [monthly, SetMonthly] = useState(null);
  const [payText, SetPayText] = useState('Make Payment');

  useEffect(() => {
    if (adr && coo) {
      let address = adr;
      let coordinates = coo;
      let complex = cmplex;
      let postCode = code;
      setBookingAddress({ address, coordinates, complex, postCode });
    }
  }, [adr, coo, cmplex, setBookingAddress, code]);

  useEffect(() => {
    if (user) {
      SetCoupons(user.coupons);
      SetMonthly(user.monthlyAcc);
    }
  }, [user]);

  useEffect(() => {
    if (coupon) {
      SetCouponOrder(coupon);
      SetInv(couponTotal);
    } else {
      SetInv(invoice);
    }
  }, [SetInv, couponTotal, invoice, coupon]);

  useEffect(() => {
    if (order) {
      let orderId = order._id;

      paymentUrl(orderId);
    }
  }, [order, paymentUrl]);

  let stripeBtn = useRef();
  let freeBtn = useRef();
  let monthBtn = useRef();

  const monthlyCheckOut = () => {
    let monthEnd = true;
    let address = adr;
    let coordinates = coo;
    let postCode = code;
    let time = orderTime;
    AccOrder({
      time,
      postCode,
      address,
      coordinates,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      invoice,
      monthEnd,
      couponOrder,
      complex,
      nityGrity,
      whyLess,
      changeHours,
    });
    if (monthBtn.current) {
      monthBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const stripePayment = () => {
    let invoice = inv;
    let postCode = code;
    let address = adr;
    let coordinates = coo;
    let complex = cmplex;
    let time = orderTime;
    Order({
      time,
      postCode,
      address,
      coordinates,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      invoice,
      couponOrder,
      complex,
      nityGrity,
      whyLess,
      changeHours,
    });

    SetPayText('Processing payment...');

    if (stripeBtn.current) {
      stripeBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const freeOrder = () => {
    let invoice = inv;
    let address = adr;
    let postCode = code;
    let coordinates = coo;
    let complex = cmplex;
    let time = orderTime;
    Order({
      time,
      address,
      postCode,
      coordinates,
      complex,
      bedroom,
      bathroom,
      mrx,
      invoiceHours,
      couponOrder,
      invoice,
      nityGrity,
      whyLess,
      changeHours,
    });

    if (freeBtn.current) {
      freeBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  useEffect(() => {
    let arr = iconExtras.filter((ex) => ex.chosen !== false);
    SetMrx(arr);
  }, [iconExtras, SetMrx]);

  const ChangeAddress = (a) => {
    SetAddress(a.text);
    SetCoordinates(a.coordinates);
    SetComplex(a.complex);
    setCode(a.code);
  };

  const defaultAddress = () => {
    SetAddress(user.address);
    SetCoordinates(user.coordinates);
    SetComplex(user.complex);
    setCode(user.code);
  };

  const invoiceTime = moment.utc(orderTime).format('ddd MMM D, h:mm a');

  if (monthlyRedirect === true) {
    return <Redirect to="/payments/monthly-order" />;
  }

  if (freeRedirect === true) {
    return <Redirect to="/payments/free-order" />;
  }
  if (address === null) {
    return <Spinner animation="grow" variant="dark" />;
  }

  return (
    <div>
      <div className="container">
        <Row>
          <Col xs={12} md={8}>
            <Alert variant="info">
              <Row>
                <Col>
                  <h3 className="text-info text-center">
                    Review booking and make payment
                  </h3>
                </Col>
                <Col>
                  <AddressButton
                    ChangeAddress={ChangeAddress}
                    defaultAddress={defaultAddress}
                    user={user}
                  />
                </Col>
              </Row>
            </Alert>
            <ListGroup variant="flush">
              <ListGroup.Item variant="secondary">
                {invoiceHours > 8 ? (
                  <Alert variant="info text-center">
                    Please note that this is a 2 day booking...
                  </Alert>
                ) : (
                  ''
                )}
                <p className="float-left">Time & Location </p>
                <p className="float-right">
                  {invoiceTime} | {address}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="float-left">
                  {bedroom} Bedroom/s and {bathroom} Bathroom/s
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="float-left"> Included Cost</p>
                <p className="float-right">£ 30.50</p>
              </ListGroup.Item>
              <ListGroup.Item align="center">
                <div className="float-left">Your Extras;</div>
                {mrx.length === 0 ? (
                  <span>No Extras</span>
                ) : (
                  mrx.map((x) => {
                    return (
                      <div key={x.id} style={{ width: '20rem' }}>
                        <ExtraItem x={x} />
                      </div>
                    );
                  })
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="float-left"> Service Fee </p>
                <p className="float-right">£ 3.50</p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="float-left">Total Time: </p>
                <p className="float-right">{invoiceHours} hours</p>
              </ListGroup.Item>
            </ListGroup>
            <Row className="mt-3">
              <Col>
                <Link to="/user/booking">
                  <Alert variant="secondary text-center">
                    <i className="mr-3 fas fa-step-backward"></i> Edit Order!
                  </Alert>
                </Link>
              </Col>
              <Col>
                <Alert variant="info text-info text-center text-white">
                  Your total is: <b>£ {inv}</b>
                  <i className="far fa-credit-card ml-2"></i>
                </Alert>
              </Col>
            </Row>
          </Col>

          <Col>
            <Card className="text-center">
              <Alert variant="success">
                <h5>
                  Pay Here: <i className="ml-3 fas fa-cash-register"></i>
                </h5>
              </Alert>
              <Card.Body>
                <Card.Title>
                  <span style={{ fontSize: '4em' }}>
                    <i className="fas fa-credit-card text-success"></i>
                  </span>
                </Card.Title>
                <Card.Subtitle className="mb-2 text-success">
                  Thank you for considering our services
                </Card.Subtitle>
                <Card.Text>
                  <b>Please select your prefered payment method</b>
                </Card.Text>
                {coupon ? (
                  <Button
                    variant="warning"
                    onClick={() => SetCoupon(null)}
                    block
                  >
                    Cancel Coupon
                  </Button>
                ) : (
                  <Coupon coupons={coupons} SetCoupon={SetCoupon} />
                )}

                <hr />
                <b>***Please Note***</b>
                <p>You will be re-direcrected to make payment.</p>
                {inv === 0 ? (
                  <Button
                    variant="success"
                    onClick={freeOrder}
                    ref={freeBtn}
                    block
                  >
                    Confirm Free Order
                  </Button>
                ) : monthly === true ? (
                  <Button
                    variant="outline-info"
                    onClick={monthlyCheckOut}
                    ref={monthBtn}
                    block
                  >
                    Confirm Monthly Acc Order
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="success mt-2"
                      ref={stripeBtn}
                      block
                      onClick={stripePayment}
                    >
                      {payText} £ {inv}
                    </Button>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  bookings: state.bookings,
  user: state.user.user,
  orders: state.orders,
  couponTotal: state.coupons.couponTotal,
  stripeUrl: state.payment.paymentUrl,
});

export default connect(mapStateToProps, {
  Order,
  AccOrder,
  setBookingAddress,
  paymentUrl,
})(CheckOut);
