import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { levelOne, nullDuster } from '../../../../actions/admin/team_members';
import { Spinner, Row, Alert, Button } from 'react-bootstrap';
import TeamItem from './TeamItem';
import './AdminDusters.css';

const StageOne = ({ levelOne, teams, nullDuster }) => {
  const [searchEmail, setSearchEmail] = useState('');
  useEffect(() => {
    levelOne();
    nullDuster(null);
  }, [levelOne, nullDuster]);

  let history = useHistory();
  if (teams === null) {
    return <Spinner animation="border" variant="primary" />;
  }

  const goBack = () => {
    history.push('/admin/admin-dusters');
  };
  return (
    <div className="container">
      <Alert variant="info text-center">
        <Button
          variant="outline-info float-left"
          size="sm"
          onClick={() => goBack()}
        >
          Duster Dashboard
        </Button>
        Duster in Stage One
        <span className="float-right">
          <input
            className="text-center"
            placeholder="search by email"
            style={{ border: 'none' }}
            onChange={(e) => setSearchEmail(e.target.value)}
          />
        </span>
      </Alert>
      <Row id="dust-buster">
        {teams.length === 0 ? (
          <Alert variant="secondary">No Dusters In Stage One</Alert>
        ) : (
          teams
            .filter((val) => {
              if (searchEmail === '') {
                return val;
              } else if (
                val.email.toLowerCase().includes(searchEmail.toLowerCase())
              ) {
                return val;
              }
            })
            .map((team) => <TeamItem team={team} key={team._id} />)
        )}
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  teams: state.team_members.stageOne,
});
export default connect(mapStateToProps, { levelOne, nullDuster })(StageOne);
