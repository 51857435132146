import React from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, Col, Row } from 'react-bootstrap';

const TeamItem = ({ team: { name, surname, email, _id, cellphone } }) => {
  return (
    <ListGroup.Item style={{ width: '100%' }}>
      <Row>
        <Col className="float-left mr-4">
          <i className="fas fa-user-circle"></i>: {name} {surname}
        </Col>
        <Col className="mr-4">
          <i className="fas fa-envelope"></i>: {email}
        </Col>
        <Col>
          <i className="fas fa-mobile"></i>: {cellphone}
        </Col>
        <Link
          to={`/admin/team-member/${_id}`}
          className="btn btn-outline-info btn-sm float-right"
        >
          View Dust Buster
        </Link>
      </Row>
    </ListGroup.Item>
  );
};

export default TeamItem;
