import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Alert } from 'react-bootstrap';

import {
  getCoupon,
  setCouponTotal,
  clearCoupon,
} from '../../../../actions/user/coupons';
import CouponItem from './CouponItem';

const Coupon = ({
  getCoupon,
  coupons: { coupon, couponTotal },
  bookings: { invoice },
  setCouponTotal,
  SetCoupon,
  clearCoupon,
}) => {
  const [show, setShow] = useState(false);
  const [couponId, SetCouponId] = useState('');
  const [percentage, SetPercentage] = useState(null);
  const [weeks, SetWeeks] = useState(null);
  useEffect(() => {
    if (couponId.length > 0) {
      getCoupon(couponId);
    }
  }, [getCoupon, couponId]);

  useEffect(() => {
    if (coupon) {
      SetPercentage(coupon.percentage);
      SetWeeks(coupon.validity);
    } else {
      SetPercentage(null);
      SetWeeks(null);
    }
  }, [SetPercentage, coupon]);

  useEffect(() => {
    if (percentage) {
      if (percentage === 100) {
        return setCouponTotal(0);
      }
      let price = parseFloat(invoice);
      let fp = parseFloat(`.${percentage}`);
      let calcTotal = (price - price * fp).toFixed(2);
      setCouponTotal(calcTotal);
    }
  }, [percentage, invoice, setCouponTotal]);

  const handleClose = () => {
    SetCouponId('');
    setShow(false);
  };
  const handleShow = () => {
    clearCoupon();
    setShow(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    SetCoupon(couponId);
    SetCouponId('');
    setShow(false);
  };

  return (
    <div className="mb-2">
      <Button variant="info" onClick={handleShow} block>
        I have a coupon!
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={onSubmit}>
          <Alert variant="info">
            <h4 className="text-center">Choose Coupon</h4>
          </Alert>
          <Modal.Body>
            <div className="input-field">
              <select
                name="coupon"
                value={couponId}
                className="browser-default"
                style={{ width: '100%' }}
                onChange={(e) => SetCouponId(e.target.value)}
              >
                <option value="" disabled>
                  Select Coupon
                </option>
                <CouponItem />
              </select>
            </div>
            {percentage ? (
              <div className="text-center text-info">
                <b>
                  This coupon will give you {percentage}% discount it will make
                  your current total R{couponTotal}, Coupon is valid for {weeks}
                  weeks.
                </b>
              </div>
            ) : (
              <p className="text-center">
                Select a coupon to view the discount
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Dismiss
            </Button>
            <Button variant="primary" type="submit">
              Use Coupon
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  coupons: state.coupons,
  bookings: state.bookings,
});

export default connect(mapStateToProps, {
  getCoupon,
  setCouponTotal,
  clearCoupon,
})(Coupon);
