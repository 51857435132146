import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setNote } from '../../../actions/note';
import {
  SetSchedule,
  SetWeekDays,
  clearSchedule,
  SetRegular,
  repeatCalender,
} from '../../../actions/user/bookings';
import { setDate } from '../../../actions/user/bookings';
import 'react-datepicker/dist/react-datepicker.css';
import NewDateAndTime from './NewDateAndTime';
import ConfirmDateAndTime from './ConfirmDateAndTime';
import moment from 'moment';

const DateAndTime = ({
  SetRegular,
  regular,
  setNote,
  SetSchedule,
  bookings: { weekdays, schedule, invoiceHours, frequency, orderTime },
  SetWeekDays,
  clearSchedule,
  setDate,
  setStage,
  repeatCalender,
  arrivalTime,
  setArrivalTime,
  page,
  setPage,
  setPrettyDay,
  prettyDay,
  invoiceTime,
}) => {
  useEffect(() => {
    if (arrivalTime) {
      window.scrollTo(2, 5);
    }
  }, [arrivalTime]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [open, setOpen] = useState(false);
  const [daysLeft, setDaysLeft] = useState(null);
  const [OnceAWeek, SetOnceAWeek] = useState(false);
  const [TwiceAWeek, SetTwiceAWeek] = useState(false);
  const [ThreeAWeek, SetThreeAWeek] = useState(false);
  const [FullTime, SetFullTime] = useState(false);
  const [Mon, SetMon] = useState(false);
  const [Tue, SetTue] = useState(false);
  const [Wed, SetWed] = useState(false);
  const [Thu, SetThu] = useState(false);
  const [Fri, SetFri] = useState(false);
  const [Sat, SetSat] = useState(false);
  const [Sun, SetSun] = useState(false);
  const [days, Setdays] = useState(0);
  const [tomorrow, setTomorrow] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);

  useEffect(() => {
    if (orderTime) {
      let pretty = moment(orderTime).format('MMM Do YY');
      setPrettyDay(pretty);
      setPage(true);
    } else {
      setPage(false);
    }
  }, [orderTime, page, setPage, setPrettyDay, setArrivalTime]);

  useEffect(() => {
    if (daysLeft) {
      repeatCalender({ daysLeft, weekdays, invoiceHours });
    }
  }, [repeatCalender, daysLeft, weekdays, invoiceHours]);

  useEffect(() => {
    if (regular === true) {
      setOpen(true);
      if (frequency === 1) {
        SetOnceAWeek(true);
      }
      if (frequency === 2) {
        SetTwiceAWeek(true);
      }
      if (frequency === 3) {
        SetThreeAWeek(true);
      }
      if (frequency === 5) {
        SetFullTime(true);
      }
      weekdays.forEach((d) => {
        if (d === 'Mon') {
          SetMon(true);
        }
        if (d === 'Tue') {
          SetTue(true);
        }
        if (d === 'Wed') {
          SetWed(true);
        }
        if (d === 'Thu') {
          SetThu(true);
        }
        if (d === 'Fri') {
          SetFri(true);
        }
        if (d === 'Sat') {
          SetSat(true);
        }
        if (d === 'Sun') {
          SetSun(true);
        }
      });
    }
  }, [regular, frequency, weekdays]);

  useEffect(() => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    date.setMinutes(0);
    date.setHours(8);
    setTomorrow(date);
  }, [setTomorrow]);

  const hideRegular = () => {
    SetOnceAWeek(false);
    SetTwiceAWeek(false);
    SetThreeAWeek(false);
    SetFullTime(false);
    SetMon(false);
    SetTue(false);
    SetWed(false);
    SetThu(false);
    SetFri(false);
    SetSat(false);
    SetSun(false);
    SetRegular(false);
    setOpen(false);
    SetWeekDays([]);
    Setdays(0);
    clearSchedule([]);
  };

  const showRegular = () => {
    SetOnceAWeek(true);
    SetRegular(true);
    setOpen(true);
  };

  const once = () => {
    if (days >= 2) return setNote('You need to select less days!', 'warning');
    if (TwiceAWeek === true) SetTwiceAWeek(false);
    if (ThreeAWeek === true) SetThreeAWeek(false);
    if (FullTime === true) SetFullTime(false);
    SetSchedule(1);
    SetOnceAWeek(true);
  };

  const twice = () => {
    if (days >= 3) return setNote('You need to select less days!', 'warning');
    if (OnceAWeek === true) SetOnceAWeek(false);
    if (ThreeAWeek === true) SetThreeAWeek(false);
    if (FullTime === true) SetFullTime(false);
    SetSchedule(2);
    SetTwiceAWeek(true);
  };

  const three = () => {
    if (days >= 4) return setNote('You need to select less days!', 'warning');
    if (TwiceAWeek === true) SetTwiceAWeek(false);
    if (OnceAWeek === true) SetOnceAWeek(false);
    if (FullTime === true) SetFullTime(false);
    SetSchedule(3);
    SetThreeAWeek(true);
  };

  const full = () => {
    if (TwiceAWeek === true) SetTwiceAWeek(false);
    if (ThreeAWeek === true) SetThreeAWeek(false);
    if (OnceAWeek === true) SetOnceAWeek(false);
    SetSchedule(5);
    SetFullTime(true);
    Setdays(5);
  };

  const resetDT = () => {
    setSelectedDay(null);
    setArrivalTime(null);
    let date = new Date();
    date.setDate(date.getDate() + 1);
    date.setMinutes(0);
    date.setHours(8);
    setTomorrow(date);
    setDate(null);
  };
  if (orderTime === null) {
    return (
      <NewDateAndTime
        regular={regular}
        hideRegular={hideRegular}
        showRegular={showRegular}
        daysLeft={daysLeft}
        schedule={schedule}
        weekdays={weekdays}
        invoiceHours={invoiceHours}
        days={days}
        Setdays={Setdays}
        once={once}
        twice={twice}
        three={three}
        full={full}
        open={open}
        setOpen={setOpen}
        SetMon={SetMon}
        Mon={Mon}
        SetTue={SetTue}
        Tue={Tue}
        SetWed={SetWed}
        Wed={Wed}
        SetThu={SetThu}
        Thu={Thu}
        SetFri={SetFri}
        Fri={Fri}
        SetSat={SetSat}
        Sat={Sat}
        SetSun={SetSun}
        Sun={Sun}
        OnceAWeek={OnceAWeek}
        SetOnceAWeek={SetOnceAWeek}
        TwiceAWeek={TwiceAWeek}
        SetTwiceAWeek={SetTwiceAWeek}
        ThreeAWeek={ThreeAWeek}
        SetThreeAWeek={SetThreeAWeek}
        FullTime={FullTime}
        SetFullTime={SetFullTime}
        orderTime={orderTime}
        setDate={setDate}
        arrivalTime={arrivalTime}
        setArrivalTime={setArrivalTime}
        setStage={setStage}
        tomorrow={tomorrow}
        setSelectedDay={setSelectedDay}
        selectedDay={selectedDay}
        setPrettyDay={setPrettyDay}
        prettyDay={prettyDay}
        frequency={frequency}
        setNote={setNote}
        setDaysLeft={setDaysLeft}
        repeatCalender={repeatCalender}
      />
    );
  }
  if (orderTime) {
    return (
      <ConfirmDateAndTime
        regular={regular}
        prettyDay={prettyDay}
        arrivalTime={arrivalTime}
        resetDT={resetDT}
        schedule={schedule}
        setStage={setStage}
        setPage={setPage}
        invoiceTime={invoiceTime}
      />
    );
  }
};

const mapStateToProps = (state) => ({
  bookings: state.bookings,
});

export default connect(mapStateToProps, {
  setNote,
  SetSchedule,
  SetWeekDays,
  clearSchedule,
  SetRegular,
  setDate,
  repeatCalender,
})(DateAndTime);
