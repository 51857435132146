import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const RateCard = ({ dusterId, cleanerRate, dustBuster, note }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (note.length > 0) {
      setShow(false);
    }
  }, [note]);
  const [rate, setRate] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let btn = useRef();

  const createRate = () => {
    cleanerRate({ dusterId, rate });
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <>
      <Button variant="primary ml-1 mr-1" onClick={handleShow} size="sm">
        Create Rate
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Custom Rate for {dustBuster.name} {dustBuster.surname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Enter Rate</Form.Label>
            <Form.Control
              type="number"
              placeholder="enter Rate"
              value={rate}
              name="rate"
              onChange={(e) => setRate(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {rate.length > 0 ? (
            <Button variant="primary" ref={btn} onClick={createRate}>
              Create
            </Button>
          ) : (
            <Button variant="outline-secondary" disabled>
              Enter Rate
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RateCard;
