import React, { useState } from 'react';
import { connect } from 'react-redux';
import { removeFromOrder } from '../../../actions/admin/orders';
import { Alert, Button, ListGroup, Modal } from 'react-bootstrap';
import moment from 'moment';
import CheckOutCleaner from './CheckOutCleaner';

const Duster = ({
  duster,
  removeFromOrder,
  orderId,
  setLoading,
  checkOut,
  checkIn,
  checkOutCleaner,
  invoiceHours,
  orderTime,
  dusterCheckOut,
  note,
  omw,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const remove = () => {
    removeFromOrder(orderId);
    setLoading(true);
    setShow(false);
  };

  const timeout = moment(checkOut).format('ddd MMM D, h:mm a');
  const timein = moment(checkIn).format('ddd MMM D, h:mm a');

  return (
    <div>
      <Alert variant="info">This order has a Duster</Alert>
      <ListGroup>
        <ListGroup.Item>
          <b>Duster Name & Surname:</b>
          <p className="float-right">
            {duster.name} {duster.surname}
          </p>
        </ListGroup.Item>
        <ListGroup.Item>
          <b>Cellphone: </b> <p className="float-right">{duster.cellphone}</p>
        </ListGroup.Item>
        <ListGroup.Item>
          <b>Email: </b>
          <p className="float-right">{duster.email}</p>
        </ListGroup.Item>
        {checkOutCleaner === true && !checkOut && (
          <ListGroup.Item>
            Cleaner has not checked out yet:
            <CheckOutCleaner
              orderId={orderId}
              invoiceHours={invoiceHours}
              orderTime={orderTime}
              dusterCheckOut={dusterCheckOut}
              note={note}
            />
          </ListGroup.Item>
        )}
        {omw && (
          <ListGroup.Item align="center">
            <Button variant="outline-info" size="sm">
              Dust Buster is {omw}
            </Button>
          </ListGroup.Item>
        )}
      </ListGroup>
      {checkOut ? (
        <Button variant="success" disabled block>
          Order Complete on {timeout}
        </Button>
      ) : checkIn ? (
        <Button variant="info" disabled block>
          {duster.name} checked in on {timein}
          <Button variant="warning float-right" disabled size="sm">
            In Progress...
          </Button>
        </Button>
      ) : (
        <Button variant="danger" onClick={handleShow} block>
          Remove Duster From the Order
        </Button>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove {duster.name} from order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove {duster.name} from this order?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={remove}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default connect(null, { removeFromOrder })(Duster);
