import React, { useState } from 'react';
import { connect } from 'react-redux';
import { customRate } from '../../../../actions/admin/main';
import { Button, Form, Modal } from 'react-bootstrap';

const CustomRateCard = ({ currentUser, customRate, userId }) => {
  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    two: '',
    twop: '',
    three: '',
    threep: '',
    four: '',
    fourp: '',
    five: '',
    fivep: '',
    six: '',
    sixp: '',
    seven: '',
    sevenp: '',
    eight: '',
  });

  const {
    two,
    twop,
    three,
    threep,
    four,
    fourp,
    five,
    fivep,
    six,
    sixp,
    seven,
    sevenp,
    eight,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const createCard = (e) => {
    e.preventDefault();
    customRate({
      two,
      twop,
      three,
      threep,
      four,
      fourp,
      five,
      fivep,
      six,
      sixp,
      seven,
      sevenp,
      eight,
      userId,
    });
    setShow(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <>
        <Button variant="outline-primary mt-3" onClick={handleShow} block>
          Create Custom Rate Card
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Form onSubmit={createCard}>
            <Modal.Header closeButton>
              <Modal.Title>Custom Rate Card for {currentUser}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>2 hours rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="How Much?"
                  name="two"
                  value={two}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>2.5 hours rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="How Much?"
                  name="twop"
                  value={twop}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>3 hours rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="How Much?"
                  name="three"
                  value={three}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>3.5 hours rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="How Much?"
                  name="threep"
                  value={threep}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>4 hours rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="How Much?"
                  name="four"
                  value={four}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>4.5 hours rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="How Much?"
                  name="fourp"
                  value={fourp}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>5 hours rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="How Much?"
                  name="five"
                  value={five}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>5.5 hours rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="How Much?"
                  name="fivep"
                  value={fivep}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>6 hours rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="How Much?"
                  name="six"
                  value={six}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>6.5 hours rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="How Much?"
                  name="sixp"
                  value={sixp}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>7 hours rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="How Much?"
                  name="seven"
                  value={seven}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>7.5 hours rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="How Much?"
                  name="sevenp"
                  value={sevenp}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>8 hours rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="How Much?"
                  name="eight"
                  value={eight}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Create Custom Rate
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    </div>
  );
};

export default connect(null, { customRate })(CustomRateCard);
