import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getDuster,
  fetchSkills,
  hrApp,
  getUploads,
  dusterBookings,
  nullDuster,
  getRate,
} from '../../../actions/admin/team_members';
import {
  completeApp,
  deleteID,
  deletePOA,
  deleteBL,
  deletePP,
  deleteWP,
  deleteEC,
  readyToWork,
  addDusterProfile,
  updateDusterUploads,
  deleteDuster,
} from '../../../actions/admin/team_admin';
import {
  adminIDUpload,
  adminPOAUpload,
  adminBankLetterUpload,
  adminProPicUpload,
  adminECUpload,
  adminWorkPermitUpload,
} from '../../../actions/admin/admin_file_upload';
import { deleteImage } from '../../../actions/fileupload';
import { Spinner } from 'react-bootstrap';
import StageOne from './StageOne';
import StageTwo from './StageTwo';
import StageThree from './StageThree';
import WorkingDuster from './WorkingDuster';
import moment from 'moment';

const TeamProfile = ({
  duster: {
    dustBuster,
    skills,
    interview,
    uploads,
    orders,
    bookings,
    reorders,
    theBooking,
    rate,
  },
  uploaded: {
    id_uploaded,
    poa_uploaded,
    bl_uploaded,
    pp_uploaded,
    ec_uploaded,
    wp_uploaded,
  },
  note,
  getDuster,
  match,
  fetchSkills,
  completeApp,
  hrApp,
  getUploads,
  deleteImage,
  deleteID,
  deletePOA,
  deleteBL,
  deletePP,
  deleteWP,
  deleteEC,
  adminIDUpload,
  adminPOAUpload,
  adminBankLetterUpload,
  adminProPicUpload,
  adminECUpload,
  adminWorkPermitUpload,
  readyToWork,
  addDusterProfile,
  updateDusterUploads,
  deleteDuster,
  dusterBookings,
  nullDuster,
  getRate,
}) => {
  useEffect(() => {
    getDuster(match.params.dusterId);
    hrApp(match.params.dusterId);
    getRate(match.params.dusterId);
    setUpdateUploads(false);
    setUpdate(false);
    setIdDoc(null);
    setPoa(null);
    setBankLetter(null);
    setPic(null);
    setWorkPermit(null);
    setEc(null);
  }, [getDuster, match.params.dusterId, getRate, hrApp]);
  const [stage, setStage] = useState(null);
  const [work, setWork] = useState(null);
  const [dusterId, setDusterId] = useState(null);
  const [update, setUpdate] = useState(false);
  const [updateUploads, setUpdateUploads] = useState(false);
  const [idDoc, setIdDoc] = useState(null);
  const [missed, setMissed] = useState(false);
  const [rtw, setRtw] = useState(null);
  const [poa, setPoa] = useState(null);
  const [bankLetter, setBankLetter] = useState(null);
  const [pic, setPic] = useState(null);
  const [ec, setEc] = useState(null);
  const [workPermit, setWorkPermit] = useState(null);
  const [msg, setMsg] = useState(note);

  let idBtn = useRef();
  let poaBtn = useRef();
  let blBtn = useRef();
  let ppBtn = useRef();
  let ecBtn = useRef();
  let wpBtn = useRef();

  let IDbtn = useRef();
  let POAbtn = useRef();
  let BLbtn = useRef();
  let PPbtn = useRef();
  let WPbtn = useRef();
  let ECbtn = useRef();

  let deleteBtn = useRef();
  let history = useHistory();

  useEffect(() => {
    if (interview) {
      let intDate = moment.utc(interview.start).format().split('T').shift();
      let rightNow = moment.utc().format().split('T').shift();

      if (intDate < rightNow) {
        if (rtw === false) {
          setMissed(true);
        } else {
          setMissed(false);
        }
      } else {
        setMissed('Future Interview');
      }
    }
  }, [interview, rtw]);

  useEffect(() => {
    if (id_uploaded) {
      setIdDoc(id_uploaded);
      setUpdateUploads(true);
    }
    if (poa_uploaded) {
      setPoa(poa_uploaded);
      setUpdateUploads(true);
    }
    if (bl_uploaded) {
      setBankLetter(bl_uploaded);
      setUpdateUploads(true);
    }
    if (pp_uploaded) {
      setPic(pp_uploaded);
      setUpdateUploads(true);
    }
    if (wp_uploaded) {
      setWorkPermit(wp_uploaded);
      setUpdateUploads(true);
    }
    if (ec_uploaded) {
      setEc(ec_uploaded);
      setUpdateUploads(true);
    }
  }, [
    id_uploaded,
    poa_uploaded,
    bl_uploaded,
    pp_uploaded,
    wp_uploaded,
    ec_uploaded,
  ]);

  useEffect(() => {
    if (dustBuster) {
      setStage(dustBuster.stage);
      setWork(true);
      setRtw(dustBuster.readyToWork);
      setDusterId(dustBuster._id);
    }
  }, [dustBuster, setStage]);

  useEffect(() => {
    if (dusterId) {
      fetchSkills(dusterId);
      getUploads(dusterId);
    }
  }, [dusterId, fetchSkills, getUploads]);

  useEffect(() => {
    if (uploads) {
      setIdDoc(uploads.idDoc);
      setPoa(uploads.poa);
      setBankLetter(uploads.bankLetter);
      setPic(uploads.pic);
      setEc(uploads.ec);
      setWorkPermit(uploads.workPermit);
    } else {
      setIdDoc(null);
      setPoa(null);
      setBankLetter(null);
      setPic(null);
      setEc(null);
      setWorkPermit(null);
    }
  }, [uploads]);

  useEffect(() => {
    if (note && deleteBtn.current) {
      nullDuster();
      setMsg(note);
      deleteBtn.current.removeAttribute('disabled');
      history.push('/admin/admin-dusters');
    }
  }, [setMsg, note, msg, history, nullDuster]);

  //////////////////
  //Upload Buttons//
  //////////////////

  //Upload ID
  useEffect(() => {
    if (note && IDbtn.current) {
      setMsg(note);
      IDbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadID = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminIDUpload(formData);

    if (IDbtn.current) {
      IDbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Upload Proof Of Address//
  useEffect(() => {
    if (note && POAbtn.current) {
      setMsg(note);
      POAbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadPOA = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminPOAUpload(formData);

    if (POAbtn.current) {
      POAbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Handle Bank Letter Button//
  useEffect(() => {
    if (note && BLbtn.current) {
      setMsg(note);
      BLbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadBL = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminBankLetterUpload(formData);

    if (BLbtn.current) {
      BLbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Handle Profile Pic Button//
  useEffect(() => {
    if (note && PPbtn.current) {
      setMsg(note);
      PPbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadPP = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminProPicUpload(formData);

    if (PPbtn.current) {
      PPbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Handle Work Permit Button//
  useEffect(() => {
    if (note && WPbtn.current) {
      setMsg(note);
      WPbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadWP = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminWorkPermitUpload(formData);

    if (WPbtn.current) {
      WPbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Upload Employment Contract Button//
  useEffect(() => {
    if (note && ECbtn.current) {
      setMsg(note);
      ECbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadEC = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminECUpload(formData);

    if (ECbtn.current) {
      ECbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  if (dustBuster === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  ///////////////////////////
  //Delete Button Functions//
  ///////////////////////////
  const idTrash = () => {
    let imgID = idDoc.split('/').pop();
    let public_id = imgID.split('.').shift();
    deleteImage(public_id);
    deleteID(dusterId);
    setUpdate(true);
    if (idBtn.current) {
      idBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const poaTrash = () => {
    let imgID = poa.split('/').pop();
    let public_id = imgID.split('.').shift();
    deleteImage(public_id);
    deletePOA(dusterId);
    setUpdate(true);
    if (poaBtn.current) {
      poaBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const blTrash = () => {
    let imgID = bankLetter.split('/').pop();
    let public_id = imgID.split('.').shift();

    deleteImage(public_id);
    deleteBL(dusterId);
    setUpdate(true);
    if (blBtn.current) {
      blBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const ppTrash = () => {
    let imgID = pic.split('/').pop();
    let public_id = imgID.split('.').shift();

    deleteImage(public_id);
    deletePP(dusterId);
    setUpdate(true);
    if (ppBtn.current) {
      ppBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const wpTrash = () => {
    const imgID = workPermit.split('/').pop();
    const public_id = imgID.split('.').shift();
    deleteImage(public_id);
    deleteWP(dusterId);
    setUpdate(true);
    if (wpBtn.current) {
      wpBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const ecTrash = () => {
    const imgID = ec.split('/').pop();
    const public_id = imgID.split('.').shift();
    deleteImage(public_id);
    deleteEC(dusterId);
    setUpdate(true);
    if (ecBtn.current) {
      ecBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Delete Dustbuster//
  const removeDuster = () => {
    deleteDuster(dusterId);

    if (deleteBtn.current) {
      deleteBtn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <div className="container">
      {stage === 1 ? (
        <StageOne
          dustBuster={dustBuster}
          completeApp={completeApp}
          removeDuster={removeDuster}
          dusterId={dusterId}
          deleteBtn={deleteBtn}
        />
      ) : stage === 2 ? (
        <StageTwo
          dustBuster={dustBuster}
          skills={skills}
          dusterId={dusterId}
          completeApp={completeApp}
          removeDuster={removeDuster}
          deleteBtn={deleteBtn}
        />
      ) : stage === 3 ? (
        <StageThree
          dustBuster={dustBuster}
          dusterId={dusterId}
          skills={skills}
          interview={interview}
          uploads={uploads}
          deleteImage={deleteImage}
          deleteID={deleteID}
          deletePOA={deletePOA}
          deleteBL={deleteBL}
          deletePP={deletePP}
          deleteWP={deleteWP}
          deleteEC={deleteEC}
          adminIDUpload={adminIDUpload}
          adminPOAUpload={adminPOAUpload}
          id_uploaded={id_uploaded}
          poa_uploaded={poa_uploaded}
          bl_uploaded={bl_uploaded}
          pp_uploaded={pp_uploaded}
          ec_uploaded={ec_uploaded}
          wp_uploaded={wp_uploaded}
          adminBankLetterUpload={adminBankLetterUpload}
          adminProPicUpload={adminProPicUpload}
          adminECUpload={adminECUpload}
          adminWorkPermitUpload={adminWorkPermitUpload}
          readyToWork={readyToWork}
          addDusterProfile={addDusterProfile}
          getUploads={getUploads}
          updateDusterUploads={updateDusterUploads}
          removeDuster={removeDuster}
          deleteBtn={deleteBtn}
          missed={missed}
        />
      ) : (
        work === true && (
          <WorkingDuster
            dustBuster={dustBuster}
            dusterId={dusterId}
            idTrash={idTrash}
            idBtn={idBtn}
            poaBtn={poaBtn}
            blBtn={blBtn}
            ppBtn={ppBtn}
            ecBtn={ecBtn}
            wpBtn={wpBtn}
            poaTrash={poaTrash}
            blTrash={blTrash}
            ppTrash={ppTrash}
            wpTrash={wpTrash}
            ecTrash={ecTrash}
            idDoc={idDoc}
            poa={poa}
            bankLetter={bankLetter}
            pic={pic}
            ec={ec}
            workPermit={workPermit}
            adminIDUpload={adminIDUpload}
            adminPOAUpload={adminPOAUpload}
            adminBankLetterUpload={adminBankLetterUpload}
            adminProPicUpload={adminProPicUpload}
            adminECUpload={adminECUpload}
            adminWorkPermitUpload={adminWorkPermitUpload}
            id_uploaded={id_uploaded}
            poa_uploaded={poa_uploaded}
            bl_uploaded={bl_uploaded}
            pp_uploaded={pp_uploaded}
            ec_uploaded={ec_uploaded}
            wp_uploaded={wp_uploaded}
            addDusterProfile={addDusterProfile}
            updateDusterUploads={updateDusterUploads}
            uploadID={uploadID}
            IDbtn={IDbtn}
            uploadPOA={uploadPOA}
            POAbtn={POAbtn}
            uploadBL={uploadBL}
            BLbtn={BLbtn}
            uploadPP={uploadPP}
            PPbtn={PPbtn}
            uploadWP={uploadWP}
            WPbtn={WPbtn}
            uploadEC={uploadEC}
            ECbtn={ECbtn}
            update={update}
            setUpdate={setUpdate}
            getUploads={getUploads}
            updateUploads={updateUploads}
            setUpdateUploads={setUpdateUploads}
            dusterBookings={dusterBookings}
            orders={orders}
            reorders={reorders}
            bookings={bookings}
            deleteBtn={deleteBtn}
            theBooking={theBooking}
            rate={rate}
            note={note}
          />
        )
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  duster: state.team_members,
  uploaded: state.adminUpload,
  note: state.note,
});

export default connect(mapStateToProps, {
  getDuster,
  fetchSkills,
  completeApp,
  hrApp,
  getUploads,
  deleteID,
  deleteImage,
  deletePOA,
  deleteBL,
  deletePP,
  deleteWP,
  deleteEC,
  adminIDUpload,
  adminPOAUpload,
  adminBankLetterUpload,
  adminProPicUpload,
  adminECUpload,
  adminWorkPermitUpload,
  readyToWork,
  addDusterProfile,
  updateDusterUploads,
  deleteDuster,
  dusterBookings,
  nullDuster,
  getRate,
})(TeamProfile);
