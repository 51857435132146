import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Image, Row, Button } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
//PDF imports//
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const DusterDocs = ({
  setUpdate,
  note,
  deleteImage,
  deleteID,
  deletePOA,
  dusterId,
  deleteBL,
  deletePP,
  deleteWP,
  deleteEC,
  adminECUpload,
  ec_uploaded,
  adminIDUpload,
  id_uploaded,
  adminPOAUpload,
  poa_uploaded,
  adminBankLetterUpload,
  bl_uploaded,
  adminProPicUpload,
  pp_uploaded,
  adminWorkPermitUpload,
  wp_uploaded,
  nationality,
  idDoc,
  poa,
  bankLetter,
  pic,
  ec,
  workPermit,
}) => {
  const [msg, setMsg] = useState(note);

  let idBtn = useRef();
  let poaBtn = useRef();
  let blBtn = useRef();
  let ppBtn = useRef();
  let ecBtn = useRef();
  let wpBtn = useRef();

  let IDbtn = useRef();
  let POAbtn = useRef();
  let BLbtn = useRef();
  let PPbtn = useRef();
  let WPbtn = useRef();
  let ECbtn = useRef();

  ///////////////////////////
  //Delete Button Functions//
  ///////////////////////////
  const idTrash = () => {
    let imgID = idDoc.split('/').pop();
    let public_id = imgID.split('.').shift();
    deleteImage(public_id);
    deleteID(dusterId);
    setUpdate(true);
    if (idBtn.current) {
      idBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const poaTrash = () => {
    let imgID = poa.split('/').pop();
    let public_id = imgID.split('.').shift();
    deleteImage(public_id);
    deletePOA(dusterId);
    setUpdate(true);
    if (poaBtn.current) {
      poaBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const blTrash = () => {
    let imgID = bankLetter.split('/').pop();
    let public_id = imgID.split('.').shift();

    deleteImage(public_id);
    deleteBL(dusterId);
    setUpdate(true);
    if (blBtn.current) {
      blBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const ppTrash = () => {
    let imgID = pic.split('/').pop();
    let public_id = imgID.split('.').shift();

    deleteImage(public_id);
    deletePP(dusterId);
    setUpdate(true);
    if (ppBtn.current) {
      ppBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const wpTrash = () => {
    const imgID = workPermit.split('/').pop();
    const public_id = imgID.split('.').shift();
    deleteImage(public_id);
    deleteWP(dusterId);
    setUpdate(true);
    if (wpBtn.current) {
      wpBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const ecTrash = () => {
    const imgID = ec.split('/').pop();
    const public_id = imgID.split('.').shift();
    deleteImage(public_id);
    deleteEC(dusterId);
    setUpdate(true);
    if (ecBtn.current) {
      ecBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //////////////////
  //Upload Buttons//
  //////////////////

  //Upload ID
  useEffect(() => {
    if (note && IDbtn.current) {
      setMsg(note);
      IDbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadID = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminIDUpload(formData);

    if (IDbtn.current) {
      IDbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Upload Proof Of Address//
  useEffect(() => {
    if (note && POAbtn.current) {
      setMsg(note);
      POAbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadPOA = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminPOAUpload(formData);

    if (POAbtn.current) {
      POAbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Handle Bank Letter Button//
  useEffect(() => {
    if (note && BLbtn.current) {
      setMsg(note);
      BLbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadBL = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminBankLetterUpload(formData);

    if (BLbtn.current) {
      BLbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Handle Profile Pic Button//
  useEffect(() => {
    if (note && PPbtn.current) {
      setMsg(note);
      PPbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadPP = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminProPicUpload(formData);

    if (PPbtn.current) {
      PPbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Handle Work Permit Button//
  useEffect(() => {
    if (note && WPbtn.current) {
      setMsg(note);
      WPbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadWP = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminWorkPermitUpload(formData);

    if (WPbtn.current) {
      WPbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Upload Employment Contract Button//
  useEffect(() => {
    if (note && ECbtn.current) {
      setMsg(note);
      ECbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadEC = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminECUpload(formData);

    if (ECbtn.current) {
      ECbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <div>
      <Row>
        <Col xs={12} md={6}>
          {idDoc ? (
            <>
              <Alert variant="secondary">
                ID Documents:
                <Button
                  variant="danger"
                  className="float-right"
                  size="sm"
                  onClick={idTrash}
                  ref={idBtn}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </Alert>
              {idDoc.split('.').pop() === 'pdf' ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                  <Viewer fileUrl={idDoc} />
                </Worker>
              ) : (
                <Image src={idDoc} alt="Id Document" fluid />
              )}
            </>
          ) : (
            <Col>
              {id_uploaded ? (
                <Button variant="success mt-3" disabled block>
                  1. ID Uploaded <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <div>
                  <Dropzone onDrop={uploadID}>
                    {({ getRootProps, getInputProps }) => (
                      <Button
                        variant="warning mt-3"
                        {...getRootProps()}
                        ref={IDbtn}
                        block
                      >
                        <input {...getInputProps()} />
                        1. ID Document
                        <i className="fas fa-cloud-upload-alt ml-2"></i>
                      </Button>
                    )}
                  </Dropzone>
                </div>
              )}
            </Col>
          )}
        </Col>
        <Col xs={12} md={6}>
          {poa ? (
            <>
              <Alert variant="secondary">
                Proof Of Address:
                <Button
                  variant="danger"
                  className="float-right"
                  size="sm"
                  onClick={poaTrash}
                  ref={poaBtn}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </Alert>
              {poa.split('.').pop() === 'pdf' ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                  <Viewer fileUrl={poa} />
                </Worker>
              ) : (
                <Image src={poa} alt="Id Document" fluid />
              )}
            </>
          ) : (
            <Col>
              {poa_uploaded ? (
                <Button variant="success mt-3" disabled block>
                  2. Proof Of Address Uploaded
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <div>
                  <Dropzone onDrop={uploadPOA}>
                    {({ getRootProps, getInputProps }) => (
                      <Button
                        variant="warning mt-3"
                        {...getRootProps()}
                        ref={POAbtn}
                        block
                      >
                        <input {...getInputProps()} />
                        2. Proof Of Address
                        <i className="fas fa-cloud-upload-alt ml-2"></i>
                      </Button>
                    )}
                  </Dropzone>
                </div>
              )}
            </Col>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          {bankLetter ? (
            <>
              <Alert variant="secondary mt-3">
                Bank Letter:
                <Button
                  variant="danger"
                  className="float-right"
                  size="sm"
                  onClick={blTrash}
                  ref={blBtn}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </Alert>
              {bankLetter.split('.').pop() === 'pdf' ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                  <Viewer fileUrl={bankLetter} />
                </Worker>
              ) : (
                <Image src={bankLetter} alt="Id Document" fluid />
              )}
            </>
          ) : (
            <Col>
              {bl_uploaded ? (
                <Button variant="success mt-3" disabled block>
                  3. Bank Letter Uploaded
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <div>
                  <Dropzone onDrop={uploadBL}>
                    {({ getRootProps, getInputProps }) => (
                      <Button
                        variant="warning mt-3"
                        {...getRootProps()}
                        ref={BLbtn}
                        block
                      >
                        <input {...getInputProps()} />
                        3. Bank Letter
                        <i className="fas fa-cloud-upload-alt ml-2"></i>
                      </Button>
                    )}
                  </Dropzone>
                </div>
              )}
            </Col>
          )}
        </Col>
        <Col xs={12} md={6}>
          {pic ? (
            <>
              <Alert variant="secondary mt-3">
                Profile Picture:
                <Button
                  variant="danger"
                  className="float-right"
                  size="sm"
                  onClick={ppTrash}
                  ref={ppBtn}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </Alert>
              {pic.split('.').pop() === 'pdf' ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                  <Viewer fileUrl={pic} />
                </Worker>
              ) : (
                <Image src={pic} alt="Id Document" fluid />
              )}
            </>
          ) : (
            <Col>
              {pp_uploaded ? (
                <Button variant="success mt-3" disabled block>
                  4. Profile Picture Uploaded
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <div>
                  <Dropzone onDrop={uploadPP}>
                    {({ getRootProps, getInputProps }) => (
                      <Button
                        variant="warning mt-3"
                        {...getRootProps()}
                        ref={PPbtn}
                        block
                      >
                        <input {...getInputProps()} />
                        4. Profile Picture
                        <i className="fas fa-cloud-upload-alt ml-2"></i>
                      </Button>
                    )}
                  </Dropzone>
                </div>
              )}
            </Col>
          )}
        </Col>
        <Col>
          {ec ? (
            <>
              <Alert variant="secondary mt-3">
                Employment Contract
                <Button
                  variant="danger"
                  className="float-right"
                  size="sm"
                  onClick={ecTrash}
                  ref={ecBtn}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </Alert>
              {ec.split('.').pop() === 'pdf' ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                  <Viewer fileUrl={ec} />
                </Worker>
              ) : (
                <Image src={ec} alt="Id Document" fluid />
              )}
            </>
          ) : (
            <Col>
              {ec_uploaded ? (
                <Button variant="success mt-3" disabled block>
                  5. Employment Contract Uploaded
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <div>
                  <Dropzone onDrop={uploadEC}>
                    {({ getRootProps, getInputProps }) => (
                      <Button
                        variant="warning mt-3"
                        {...getRootProps()}
                        ref={ECbtn}
                        block
                      >
                        <input {...getInputProps()} />
                        5. Employment Contract
                        <i className="fas fa-cloud-upload-alt ml-2"></i>
                      </Button>
                    )}
                  </Dropzone>
                </div>
              )}
            </Col>
          )}
        </Col>

        <Col md={6} xs={12}>
          {nationality === 'Foreign Citizen' && (
            <>
              {workPermit ? (
                <>
                  <Alert variant="secondary mt-3">
                    Work Permit
                    <Button
                      variant="danger"
                      className="float-right"
                      size="sm"
                      onClick={wpTrash}
                      ref={wpBtn}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </Alert>
                  {workPermit.split('.').pop() === 'pdf' ? (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                      <Viewer fileUrl={workPermit} />
                    </Worker>
                  ) : (
                    <Image src={workPermit} alt="Id Document" fluid />
                  )}
                </>
              ) : wp_uploaded ? (
                <Button variant="success mt-3" disabled block>
                  6. Work Permit Uploaded
                  <i className="fas fa-check-circle ml-2"></i>
                </Button>
              ) : (
                <div>
                  <Dropzone onDrop={uploadWP}>
                    {({ getRootProps, getInputProps }) => (
                      <Button
                        variant="warning mt-3"
                        {...getRootProps()}
                        ref={WPbtn}
                        block
                      >
                        <input {...getInputProps()} />
                        6. Work Permit
                        <i className="fas fa-cloud-upload-alt ml-2"></i>
                      </Button>
                    )}
                  </Dropzone>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps)(DusterDocs);
