import {
  GET_ORDERS,
  GET_RE_ORDERS,
  GET_ORDER,
  GET_RE_ORDER,
  PAID_ORDERS,
  NO_CLEANER,
  UNPAID_ORDERS,
  CLEAR_ORDER,
  COMPLETED_ORDERS,
  ACTIVE_ORDERS,
  REPEAT_ORDER,
  UNPAID_REORDERS,
  NO_RECLEANER,
  COMPLETED_REORDERS,
  ACTIVE_REORDERS,
  CLEANER_ALLOCATED,
  RECLEANER_ALLOCATED,
  ASSIGNED_DUSTER,
  ALL_ORDERS,
  ALL_RE_ORDERS,
  ORDER_COMMENTS,
  RE_ORDER_COMMENTS,
  CLEAR_COMMENTS,
} from '../../actions/types';

const initState = {
  myOrders: null,
  myReOrders: null,
  allOrders: null,
  allReOrders: null,
  paidOrders: null,
  paidReOrders: null,
  unpaidOrders: null,
  unpaidReOrders: null,
  needCleanerOrders: null,
  needCleanerReOrders: null,
  completedOrders: null,
  allocatedOrders: null,
  allocatedReOrders: null,
  completedReOrders: null,
  reOrder: null,
  busyOrders: null,
  busyReOrders: null,
  order: null,
  bookedCleaner: null,
  repeatOrder: null,
  loading: true,
  comments: null,
  re_comments: null,
};

function orderReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_COMMENTS:
      return {
        ...state,
        re_comments: null,
        comment: null,
      };
    case RE_ORDER_COMMENTS:
      return {
        ...state,
        re_comments: payload,
      };
    case ORDER_COMMENTS:
      return {
        ...state,
        comments: payload,
      };
    case GET_ORDERS:
      return {
        ...state,
        myOrders: payload,
      };
    case ALL_ORDERS:
      return {
        ...state,
        allOrders: payload,
      };
    case ALL_RE_ORDERS:
      return {
        ...state,
        allReOrders: payload,
      };
    case GET_RE_ORDERS:
      return {
        ...state,
        myReOrders: payload,
      };
    case ACTIVE_ORDERS:
      return {
        ...state,
        busyOrders: payload,
      };
    case ACTIVE_REORDERS:
      return {
        ...state,
        busyReOrders: payload,
      };
    case NO_CLEANER:
      return {
        ...state,
        needCleanerOrders: payload,
      };
    case CLEANER_ALLOCATED:
      return {
        ...state,
        allocatedOrders: payload,
      };
    case NO_RECLEANER:
      return {
        ...state,
        needCleanerReOrders: payload,
      };
    case COMPLETED_ORDERS:
      return {
        ...state,
        completedOrders: payload,
      };
    case COMPLETED_REORDERS:
      return {
        ...state,
        completedReOrders: payload,
      };
    case UNPAID_REORDERS:
      return {
        ...state,
        unpaidReOrders: payload,
      };
    case PAID_ORDERS:
      return {
        ...state,
        paidOrders: payload,
      };
    case UNPAID_ORDERS:
      return {
        ...state,
        unpaidOrders: payload,
      };
    case GET_ORDER:
      return {
        ...state,
        order: payload,
      };
    case ASSIGNED_DUSTER:
      return {
        ...state,
        bookedCleaner: payload,
      };
    case RECLEANER_ALLOCATED:
      return {
        ...state,
        allocatedReOrders: payload,
      };
    case GET_RE_ORDER:
      return {
        ...state,
        repeatOrder: payload,
      };
    case REPEAT_ORDER:
      return {
        ...state,
        reOrder: payload,
      };
    case CLEAR_ORDER:
      return {
        ...state,
        order: null,
        myOrders: null,
        reOrder: null,
      };
    default:
      return state;
  }
}

export default orderReducer;
