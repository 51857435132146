import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { teamLogout } from '../../actions/team/';
import { userLogout } from '../../actions/user/';
import { adminLogout } from '../../actions/admin/';
import { animateToDo, animateToContact } from '../../actions/animate';
import { Navbar, Nav, Button, Dropdown } from 'react-bootstrap';

import { useSpring, animated } from 'react-spring';

import './Header.css';
import DustingDownLogo from '../../images/HeaderLogo.png';

const Header = ({
  team: { isTeam, team },
  user: { isUser, user },
  admin: { isAdmin, admin },
  teamLogout,
  userLogout,
  adminLogout,
  animateToDo,
  animateToContact,
}) => {
  const [activeUser, SetActiveUser] = useState(null);
  const [display, setDisplay] = useState(true);
  useEffect(() => {
    const link = window.location.href;
    const compare = link.split('/');
    const string = `${compare[0]}//${compare[2]}/${compare[3]}/${compare[4]}`;
    const url = `${process.env.REACT_APP_BASE_URL}user/peach-app-payment`;

    if (string === url) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  }, [setDisplay]);
  const springHeader = useSpring({
    opacity: 1,
    marginRight: 0,
    from: {
      opacity: 0,
      marginRight: -1000,
    },
    config: {
      friction: 50,
    },
    delay: 800,
  });
  useEffect(() => {
    if (user) {
      if (user.local) SetActiveUser(user.local.name);
      if (user.facebook) SetActiveUser(user.facebook.name);
      if (user.google) SetActiveUser(user.google.name);
    }
  }, [user, SetActiveUser]);

  const howWeWork = () => {
    let myRef = 'howItWorks';
    animateToDo(myRef);
  };
  const contact = () => {
    let myRef = 'contactUs';
    animateToContact(myRef);
  };

  return display ? (
    <animated.div style={springHeader}>
      <Navbar className="container mb-2" expand="lg">
        <Navbar.Brand>
          <Link to="/">
            <img src={DustingDownLogo} height="50" alt="Dusting Down" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end text-light">
          {isTeam ? (
            <Nav>
              <Navbar.Text>
                <Link
                  to="/team/dust-buster-profile"
                  className="btn btn-md"
                  id="regular-btn"
                >
                  Hello: {team.name}
                </Link>
              </Navbar.Text>
              <Navbar.Text>
                <Link
                  to="/team/my-pay"
                  className="btn btn-md ml-2"
                  id="regular-btn"
                >
                  My Pay
                </Link>
              </Navbar.Text>
              <Navbar.Text>
                <Link
                  to="/team/work-feed"
                  className="btn btn-md ml-2"
                  id="regular-btn"
                >
                  Work Feed
                </Link>
              </Navbar.Text>
              <Nav.Link>
                <Button variant="outline-danger" size="md" onClick={teamLogout}>
                  <i className="fa-solid fa-arrow-right-from-bracket"></i>
                </Button>
              </Nav.Link>
            </Nav>
          ) : isUser ? (
            <Nav>
              <Navbar.Text>
                <Link
                  to="/user/my-profile"
                  className="btn btn-md btn-outline-info text-info mr-2"
                >
                  Hello: {activeUser}
                </Link>
              </Navbar.Text>

              <Navbar.Text>
                <Link
                  to="/user/booking"
                  className="btn btn-md btn-outline-info text-info mr-2"
                >
                  Book A Cleaner
                </Link>
              </Navbar.Text>
              <Nav.Link>
                <Button variant="outline-danger" size="md" onClick={userLogout}>
                  <i className="fa-solid fa-arrow-right-from-bracket"></i>
                </Button>
              </Nav.Link>
            </Nav>
          ) : isAdmin ? (
            <Nav>
              <Navbar.Text>
                <Link
                  to="/admin/main"
                  className="btn btn-md btn-outline-info text-info"
                >
                  Hello: {admin.name}
                </Link>
              </Navbar.Text>
              <Navbar.Text>
                <Link
                  to="/admin/order-management"
                  className="btn btn-md btn-outline-info text-info ml-2"
                >
                  Operations
                </Link>
              </Navbar.Text>

              <Navbar.Text>
                <Link
                  to="/admin/admin-dusters"
                  className="btn btn-md btn-outline-info text-info ml-2"
                >
                  HR
                </Link>
              </Navbar.Text>
              <Navbar.Text>
                <Link
                  to="/admin/admin_users/"
                  className="btn btn-md btn-outline-info text-info ml-2"
                >
                  Customers
                </Link>
              </Navbar.Text>
              <Nav.Link>
                <Button
                  variant="outline-danger"
                  size="md"
                  onClick={adminLogout}
                >
                  <i className="fa-solid fa-arrow-right-from-bracket"></i>
                </Button>
              </Nav.Link>
            </Nav>
          ) : (
            <Nav>
              <Dropdown>
                <Dropdown.Toggle id="menu-button">
                  Book A Service
                </Dropdown.Toggle>

                <Dropdown.Menu id="menu-bg">
                  <Dropdown.Item as={Link} to="/user/booking">
                    <i className="mr-2 fas fa-home"></i> For Home
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/user/booking">
                    <i className="mr-2 fas fa-building"></i> For Office
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Navbar.Text>
                <Link
                  to="/"
                  className="btn btn-md mr-2"
                  id="regular-btn"
                  onClick={howWeWork}
                >
                  <u>How we do it?</u>
                </Link>
              </Navbar.Text>
              <Dropdown>
                <Dropdown.Toggle className="mt-2" id="regular-btn">
                  Our Services
                </Dropdown.Toggle>

                <Dropdown.Menu id="menu-bg">
                  <Dropdown.Item>Home Cleaning</Dropdown.Item>
                  <Dropdown.Item>End Of Tenancy Cleaning</Dropdown.Item>
                  <Dropdown.Item>Office Cleaning</Dropdown.Item>
                  <Dropdown.Item>Deep Cleaning</Dropdown.Item>
                  <Dropdown.Item>Carpert Cleaning</Dropdown.Item>
                  <Dropdown.Item>Windows Cleaning</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Navbar.Text>
                <Link
                  to="/"
                  className="btn btn-md ml-2 mr-2"
                  id="regular-btn"
                  onClick={contact}
                >
                  <u>Contact</u>
                </Link>
              </Navbar.Text>

              <Navbar.Text>
                <Link
                  to="/user/signin"
                  className="btn btn-md text-white ml-2"
                  id="menu-signin"
                >
                  Sign In
                </Link>
              </Navbar.Text>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </animated.div>
  ) : (
    ''
  );
};
const mapStateToProps = (state) => ({
  team: state.team,
  user: state.user,
  admin: state.admin,
});
export default connect(mapStateToProps, {
  teamLogout,
  userLogout,
  adminLogout,
  animateToDo,
  animateToContact,
})(Header);
