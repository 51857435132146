import { HOW_WE_DO, CONTACT, CLEAR_ANIMATION } from './types';

export const animateToDo = (ref) => (dispatch) => {
  dispatch({ type: CLEAR_ANIMATION });
  dispatch({ type: HOW_WE_DO, payload: ref });
};

export const animateToContact = (ref) => (dispatch) => {
  dispatch({ type: CLEAR_ANIMATION });
  dispatch({ type: CONTACT, payload: ref });
};
