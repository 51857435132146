import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  DusterPayslip,
  calculateTotalHours,
  calculateRepeatHours,
} from '../../../../../actions/admin/main';
import { getDuster, getRate } from '../../../../../actions/admin/team_members';
import {
  Container,
  Alert,
  Spinner,
  Table,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PayslipItem from './PayslipItem';

const Payslip = ({
  DusterPayslip,
  match,
  main: { payslip, totalOnceOffHours, totalRepeatHours, loading },
  getDuster,
  team_members: { dustBuster, rate },
  calculateTotalHours,
  calculateRepeatHours,
  getRate,
}) => {
  const [cRate, setRate] = useState(10);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    DusterPayslip(match.params.dusterId);
    getDuster(match.params.dusterId);
    getRate(match.params.dusterId);
  }, [DusterPayslip, match]);

  useEffect(() => {
    if (rate) {
      setRate(rate.rate);
    } else {
      setRate(10);
    }
  }, [rate]);
  let history = useHistory();

  useEffect(() => {
    let totalHours = totalOnceOffHours + totalRepeatHours;
    setTotal(totalHours * cRate);
  });

  if (loading || dustBuster === null)
    return <Spinner animation="grow" variant="primary" />;

  const goBack = () => {
    history.push('/admin/admin-accounts/paying-dusters');
  };

  return (
    <Container>
      <Alert variant="info text-center">
        Payslip remittance for {dustBuster.name} {dustBuster.surname}
        <Button size="sm" variant="secondary float-left" onClick={goBack}>
          <i className="fa-solid fa-backward-step"></i>
        </Button>
      </Alert>
      <Table bordered>
        <thead>
          <tr>
            <th>Item</th>
            <th>Ref</th>
            <th>Date</th>
            <th>Customer</th>
            <th>Hours</th>
            <th>Rate</th>
            <th>Once Off</th>
            <th>Monthly</th>
            <th>Tips</th>
            <th>Pay</th>
          </tr>
        </thead>
        <tbody>
          {payslip.map((pay, index) => (
            <PayslipItem
              key={pay._id}
              index={index}
              pay={pay}
              rate={cRate}
              calculateTotalHours={calculateTotalHours}
              calculateRepeatHours={calculateRepeatHours}
            />
          ))}
        </tbody>
      </Table>
      <hr />
      <Row>
        <Col>Payment Notice</Col>
        <Col>
          Rate of Pay <hr />£ {cRate}
        </Col>
        <Col>
          Once Off Hours <hr />
          {totalOnceOffHours}
        </Col>
        <Col>
          Monthly Hours <hr /> {totalRepeatHours}
        </Col>
        <Col>
          Tips <hr />
        </Col>
        <Col>
          Total <hr />
          {total.toFixed(2)}
          <hr />
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  main: state.main,
  team_members: state.team_members,
});

export default connect(mapStateToProps, {
  DusterPayslip,
  getDuster,
  calculateTotalHours,
  calculateRepeatHours,
  getRate,
})(Payslip);
