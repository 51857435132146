import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Card,
  Form,
  Button,
  Spinner,
  ListGroup,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import {
  newAddress,
  getAddress,
  addComplex,
  removeComplex,
  deleteAddress,
  updateComplex,
  postcodeFinder,
} from '../../../actions/user/';
import { setNote } from '../../../actions/note';
import AddAddress from './AddAddress';
import AddressItem from './AddressItem';
import './MyProfile.css';

const Address = ({
  newAddress,
  myAddress,
  getAddress,
  addComplex,
  removeComplex,
  deleteAddress,
  user,
  updateComplex,
  postcodeFinder,
  setNote,
  note,
}) => {
  const [code, setCode] = useState('');

  useEffect(() => {
    getAddress();
  }, [getAddress]);
  const [adrs, setAdrs] = useState([]);
  const [address, setAddress] = useState(null);
  const [a, setA] = useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [complex, setComplex] = useState(user.complex);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (myAddress) {
      setAdrs(myAddress);
    }
  }, [myAddress]);

  useEffect(() => {
    if (address && code) {
      setA(true);
    } else {
      setA(false);
    }
  }, [address, code]);

  const addAddress = () => {
    let text = address;
    newAddress({ text, coordinates, code });
    setAddress(null);
    setCode('');
  };
  if (myAddress === null) {
    return <Spinner animation="grow" variant="dark" />;
  }

  const handleShow = () => {
    setShow(true);
  };

  const clearComplex = () => {
    let complex = null;
    updateComplex({ complex });
  };

  const handleClose = () => {
    setShow(false);
  };

  const saveComplex = () => {
    updateComplex({ complex });
    setShow(false);
  };

  return (
    <div className="mt-4">
      <Card>
        <Alert variant="light">
          My Addresses!
          <AddAddress
            code={code}
            address={address}
            setAddress={setAddress}
            postcodeFinder={postcodeFinder}
            setCode={setCode}
            setCoordinates={setCoordinates}
            setNote={setNote}
            addAddress={addAddress}
            a={a}
            note={note}
          />
        </Alert>
        <ListGroup className="mb-2" id="my-profile">
          <ListGroup.Item>
            <h6>
              Post code: {user.code}, Address: {user.address}
            </h6>
            <InputGroup>
              <Button variant="dark" disabled>
                Do you live in complex?
              </Button>
              <InputGroup.Append>
                <Button
                  variant={complex ? 'info' : 'outline-info'}
                  onClick={handleShow}
                >
                  Yes
                </Button>
                <Button
                  variant={complex ? 'outline-primary' : 'primary'}
                  onClick={clearComplex}
                >
                  {complex ? 'Clear' : 'No'}
                </Button>
                {complex ? (
                  <Button variant="info" className="ml-3">
                    Your Complex details: {complex}
                  </Button>
                ) : (
                  ''
                )}
              </InputGroup.Append>
            </InputGroup>
          </ListGroup.Item>
          {adrs.length === 0 ? (
            <ListGroup.Item>You only have a main address</ListGroup.Item>
          ) : (
            adrs.map((adr) => (
              <AddressItem
                key={adr._id}
                adr={adr}
                addComplex={addComplex}
                removeComplex={removeComplex}
                deleteAddress={deleteAddress}
              />
            ))
          )}
        </ListGroup>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Please enter you complex details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Please enter complex details"
            name="complex"
            value={complex}
            onChange={(e) => setComplex(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Dont save
          </Button>
          <Button variant="primary" onClick={saveComplex}>
            Save Details
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  myAddress: state.user.myAddress,
  note: state.note,
});

export default connect(mapStateToProps, {
  newAddress,
  getAddress,
  addComplex,
  removeComplex,
  deleteAddress,
  updateComplex,
  postcodeFinder,
  setNote,
})(Address);
