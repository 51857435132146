import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { orderInvoice, getInvoice } from '../../../../../actions/admin/main';
import {
  Container,
  Row,
  Col,
  Spinner,
  ListGroup,
  ListGroupItem,
  Alert,
  Image,
  Button,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import DustingLogo from '../../../../../images/DustingLogo.png';
import moment from 'moment';

const Invoice = ({
  orderInvoice,
  match,
  order: { order_invoice, invoice },
  getInvoice,
}) => {
  const [billTo, setBillTo] = useState(null);
  const [repeat, setRepeat] = useState(null);
  const [extras, setExtras] = useState(null);
  const [cleans, setCleans] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [duster, setDuster] = useState(null);
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  let history = useHistory();
  useEffect(() => {
    orderInvoice(match.params.orderId);
    getInvoice(match.params.orderId);
  }, [orderInvoice, match, getInvoice]);

  useEffect(() => {
    if (order_invoice) {
      setDuster(order_invoice.team);
      setCheckIn(order_invoice.checkIn);
      setCheckOut(order_invoice.checkOut);
      if (order_invoice.user.local) setBillTo(order_invoice.user.local.name);
      if (order_invoice.user.facebook)
        setBillTo(order_invoice.user.facebook.name);
      if (order_invoice.user.google) setBillTo(order_invoice.user.google.name);
      if (order_invoice.schedule) {
        setRepeat(order_invoice.schedule);
        setCleans(order_invoice.schedule.length);
      } else {
        setRepeat(null);
      }

      if (order_invoice.orderTime) setPaymentDate(order_invoice.orderTime);
      if (order_invoice.firstDate) setPaymentDate(order_invoice.firstDate);
      setExtras(order_invoice.mrx);
    }
  }, [order_invoice]);

  if (order_invoice === null)
    return <Spinner animation="grow" variant="primary" />;

  const goBack = () => {
    history.push('/admin/admin-accounts/invoices');
  };
  return (
    <Container>
      <Alert variant="secondary text-center">
        This invoice was created by Dusting Down (Pty) Ltd on behalf of;{' '}
        {duster ? <b>{duster.name}</b> : <b>No Duster assigned</b>}
      </Alert>
      <Row className="mb-4">
        <Col>
          <ListGroup variant="flush">
            <Alert variant="info">
              {repeat ? 'Repeat clean' : 'Once off clean'}{' '}
              <Button
                size="sm"
                variant="secondary float-right"
                onClick={goBack}
              >
                <i className="fa-solid fa-backward-step"></i>
              </Button>
            </Alert>
            <ListGroupItem>Copyright 2022 Dusting Down</ListGroupItem>
            <ListGroupItem>
              {repeat ? ` Repeat clean` : ' Once off clean'}
            </ListGroupItem>
            <ListGroupItem>Dusting Down Pty Ltd</ListGroupItem>
            <ListGroupItem>
              19 Western Street, Manchester, M18 8GG
            </ListGroupItem>
            <ListGroupItem>Phone: +44 1615 430101</ListGroupItem>
            <ListGroupItem>Website: www.dustingdown.co.uk</ListGroupItem>
            <ListGroupItem>
              <b>Bill to:</b>
            </ListGroupItem>
            <ListGroupItem>{billTo}</ListGroupItem>
            <ListGroupItem>
              Booking Address: {order_invoice.address}
            </ListGroupItem>
          </ListGroup>
        </Col>
        <Col className="text-right">
          <Image src={DustingLogo} alt="Dusting Loggo" />
          <Alert variant="info mt-3"> Invoice Number: {invoice.invoice}</Alert>
          <Alert variant="info mt-3">
            Invoice Date: <b>{moment(paymentDate).format('MMMM Do YYYY')}</b>
          </Alert>
        </Col>
      </Row>
      <hr />
      <Row bg="blue" className="text-center">
        <Col md={8}>
          <b>Cleaning Description</b>
        </Col>
        <Col>
          <b>Hours</b>
        </Col>
        <Col>
          <b>Amount</b>
        </Col>
      </Row>
      <hr />

      <Row className="text-center">
        <Col md={8}>
          {repeat
            ? ` Repeat clean with ${cleans} cleans and `
            : ' Once-off clean with'}
          {extras && extras.length > 0 ? ' we have extras' : ' no extras'}
          <hr />
          {duster ? <b>{duster.name}</b> : <b>No Duster assigned</b>}
          <hr />
          {repeat ? checkOut.length : checkOut ? 1 : 0} completed clean/s
          <hr />
          <b>Cleaner Checked In:</b>
          {repeat ? (
            <ListGroup>
              {checkIn.length > 0
                ? checkIn.map((time) => (
                    <ListGroupItem key={time}>
                      {moment(time).format('dd Do, h:mm a')}
                    </ListGroupItem>
                  ))
                : 'Cleaner has not checkedIn'}
            </ListGroup>
          ) : (
            <>
              <b>
                {checkIn && moment(checkIn).format('MMMM Do YYYY, h:mm:ss a')}
              </b>
            </>
          )}
          <hr />
          <b>Cleaner Checked Out:</b>
          {repeat ? (
            <ListGroup>
              {checkOut.map((time) => (
                <ListGroupItem key={time}>
                  {moment(time).format('dd Do, h:mm a')}
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <>
              <b>
                {checkOut && moment(checkOut).format('MMMM Do YYYY, h:mm:ss a')}
              </b>
            </>
          )}
        </Col>
        <Col>{order_invoice.invoiceHours}</Col>
        <Col>£ {order_invoice.invoice}</Col>
      </Row>
      <hr />
      <Row className="mt-3 text-right">
        <Col md={{ span: 4, offset: 8 }}>
          <ListGroupItem>
            <b className="float-left">Total:</b>£ {order_invoice.invoice}
          </ListGroupItem>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  order: state.main,
});

export default connect(mapStateToProps, { orderInvoice, getInvoice })(Invoice);
