import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getTheOrder,
  getCleanersForOrder,
  validateCheckin,
  acceptCheckingRequest,
  changeDate,
  dusterCheckOut,
  deleteOrder,
} from '../../../actions/admin/orders';
import {
  Alert,
  Col,
  Container,
  ListGroup,
  Row,
  Spinner,
  Button,
  Image,
} from 'react-bootstrap';
import moment from 'moment';
import Cleaner from './Cleaner';
import Duster from './Duster';
import ReSchedule from './ReSchedule';
import Validate from './Validate';
import DeleteOrder from './DeleteOrder';

const OrderAdmin = ({
  getTheOrder,
  match,
  admin_orders: { order, cleaners, validationDistance },
  getCleanersForOrder,
  validateCheckin,
  acceptCheckingRequest,
  dusterCheckOut,
  note,
  changeDate,
  deleteOrder,
}) => {
  const [orderId, SetOrderId] = useState(null);
  const [customer, SetCustomer] = useState(null);
  const [customerEmail, SetCustomerEmail] = useState(null);
  const [cellphone, SetCellphone] = useState(null);
  const [code, setCode] = useState('');
  const [nityGrity, setNitygrity] = useState(null);
  const [duster, SetDuster] = useState(undefined);
  const [orderTime, SetOrderTime] = useState(null);
  const [invoiceHours, setInvoiceHours] = useState(null);
  const [areaCleaners, SetAreaCleaners] = useState([]);
  const [validate, setValidate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [checkOut, setCheckout] = useState(null);
  const [checkIn, setCheckin] = useState(null);
  const [tomorrow, setTomorrow] = useState(null);
  const [prettyDay, setPrettyDay] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [omw, setOMW] = useState(null);
  const [checkOutCleaner, setCheckOutCleaner] = useState();
  let history = useHistory();
  let deleteBtn = useRef();

  useEffect(() => {
    if (note && deleteBtn.current) {
      history.goBack();
    }
  }, [note, deleteBtn]);

  useEffect(() => {
    if (note.length > 0) {
      getTheOrder(match.params.orderId);
    }
  }, [getTheOrder, match, note]);

  useEffect(() => {
    if (orderTime) {
      let checkoutTime = new Date(orderTime);
      checkoutTime.setHours(checkoutTime.getHours() + invoiceHours);
      let rightnow = new Date();

      if (rightnow > checkoutTime) {
        setCheckOutCleaner(true);
      } else {
        setCheckOutCleaner(false);
      }
    }
  }, [orderTime, invoiceHours]);

  useEffect(() => {
    getTheOrder(match.params.orderId);
  }, [getTheOrder, match]);

  useEffect(() => {
    if (validate) {
      validateCheckin(orderId);
    }
  }, [validate, validateCheckin, orderId]);

  useEffect(() => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    setTomorrow(date);
  }, [setTomorrow]);

  useEffect(() => {
    if (order) {
      SetOrderTime(order.orderTime);
      setInvoiceHours(order.invoiceHours);
      SetOrderId(order._id);
      SetDuster(order.team);
      setValidate(order.officeRequest);
      setCheckout(order.checkOut);
      setCheckin(order.checkIn);
      setOMW(order.onMyWay);
      setCode(order.code);
      setNitygrity(order.nityGrity);
      SetCustomer(order.user.local.name);
      SetCustomerEmail(order.user.local.email);
      SetCellphone(order.user.cellphone);

      setLoading(false);
    }
    if (!order) {
      setLoading(true);
    }
  }, [order]);

  useEffect(() => {
    if (orderId !== null) {
      getCleanersForOrder(orderId);
    }
  }, [getCleanersForOrder, orderId]);

  useEffect(() => {
    if (cleaners) {
      SetAreaCleaners(cleaners);
    }
  }, [cleaners]);

  if (loading === true || order === null) {
    return <Spinner variant="primary" animation="grow" />;
  }
  const time = moment.utc(orderTime).format('ddd MMM D, h:mm a');

  const delOrder = () => {
    deleteOrder(orderId);
    if (deleteBtn.current) {
      deleteBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <div>
      <Container>
        <Row>
          <Col md={5} xs={12}>
            <ListGroup>
              <Alert variant="info">
                <Button
                  variant="outline-info float-right"
                  size="sm"
                  onClick={history.goBack}
                >
                  back
                </Button>
                Order Details
                <DeleteOrder delOrder={delOrder} deleteBtn={deleteBtn} />
              </Alert>
              <ListGroup.Item variant="secondary">
                <b>Customer Name:</b> {customer}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Customer Email:</b> {customerEmail}
              </ListGroup.Item>
              {cellphone && (
                <ListGroup.Item>
                  <b>Cellphone: </b> {cellphone}
                </ListGroup.Item>
              )}
              <ListGroup.Item>
                <b>Post code:</b> {order.postCode}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Order Address:</b> {order.address}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Order Time:</b> {time} | {invoiceHours} hrs clean
              </ListGroup.Item>
              <ListGroup.Item>
                <b> How to get in:</b> {nityGrity.a}{' '}
                {nityGrity.b && `${nityGrity.b}`}
              </ListGroup.Item>
              <Image src={code} style={{ height: '250px', width: '250px' }} />
            </ListGroup>

            <ReSchedule
              orderTime={orderTime}
              tomorrow={tomorrow}
              setPrettyDay={setPrettyDay}
              setSelectedDay={setSelectedDay}
              prettyDay={prettyDay}
              selectedDay={selectedDay}
              orderId={orderId}
              changeDate={changeDate}
              invoiceHours={invoiceHours}
              duster={duster}
            />
          </Col>
          <Col>
            <Alert variant="secondary">Manage The Order!</Alert>
            {duster === undefined && (
              <>
                <Alert variant="warning">Order Does not have a cleaner</Alert>
                <Alert variant="info">Cleaners in the area below</Alert>
              </>
            )}

            {duster === undefined ? (
              areaCleaners.length > 0 ? (
                areaCleaners.map((ac) => (
                  <Cleaner
                    key={ac._id}
                    ac={ac}
                    orderId={orderId}
                    orderTime={orderTime}
                    setLoading={setLoading}
                    invoiceHours={invoiceHours}
                  />
                ))
              ) : (
                <Alert variant="danger">
                  No Dusters Avavailable for this order!
                </Alert>
              )
            ) : (
              <>
                {validate && (
                  <Validate
                    validationDistance={validationDistance}
                    acceptCheckingRequest={acceptCheckingRequest}
                    orderId={orderId}
                    note={note}
                  />
                )}
                <Duster
                  duster={duster}
                  orderId={orderId}
                  setLoading={setLoading}
                  checkOut={checkOut}
                  checkIn={checkIn}
                  checkOutCleaner={checkOutCleaner}
                  invoiceHours={invoiceHours}
                  orderTime={orderTime}
                  dusterCheckOut={dusterCheckOut}
                  note={note}
                  omw={omw}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  admin_orders: state.admin_orders,
  note: state.note,
});

export default connect(mapStateToProps, {
  getTheOrder,
  getCleanersForOrder,
  validateCheckin,
  acceptCheckingRequest,
  changeDate,
  dusterCheckOut,
  deleteOrder,
})(OrderAdmin);
