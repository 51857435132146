import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  ListGroup,
  Alert,
  Button,
  Spinner,
  Modal,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import RedFlags from './RedFlags';
import DusterDocs from './DusterDocs';
import UploadDocs from './UploadDocs';
import DeleteDuster from './DeleteDuster';
import moment from 'moment';

const StageThree = ({
  dustBuster,
  dusterId,
  skills,
  interview,
  uploads,
  deleteImage,
  deleteID,
  deletePOA,
  deleteBL,
  deletePP,
  deleteWP,
  deleteEC,
  adminIDUpload,
  adminPOAUpload,
  adminBankLetterUpload,
  adminProPicUpload,
  adminECUpload,
  adminWorkPermitUpload,
  addDusterProfile,
  readyToWork,
  id_uploaded,
  poa_uploaded,
  bl_uploaded,
  pp_uploaded,
  ec_uploaded,
  wp_uploaded,
  getUploads,
  updateDusterUploads,
  deleteBtn,
  removeDuster,
  missed,
}) => {
  const [office, setOffice] = useState(null);
  const [update, setUpdate] = useState(false);
  const [updateUploads, setUpdateUploads] = useState(false);
  const [hotel, setHotel] = useState(null);
  const [home, setHome] = useState(null);
  const [medical, setMedical] = useState(null);
  const [eot, setEOT] = useState(null);
  const [retail, setRetail] = useState(null);
  const [flags, setFlags] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [gender, setGender] = useState(null);
  const [show, setShow] = useState(false);
  const [idDoc, setIdDoc] = useState(null);
  const [poa, setPoa] = useState(null);
  const [bankLetter, setBankLetter] = useState(null);
  const [pic, setPic] = useState(null);
  const [ec, setEc] = useState(null);
  const [workPermit, setWorkPermit] = useState(null);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPassword, setShowPassword] = useState('password');
  const [canApprove, setCanApprove] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let uploadBtn = useRef();
  let updateBtn = useRef();
  let history = useHistory();

  useEffect(() => {
    if (uploads === null) {
      setCanApprove(false);
    }
  }, [uploads]);

  useEffect(() => {
    if (idDoc && poa && bankLetter && pic && ec) {
      setCanApprove(true);
    }
    return () => {
      setCanApprove(false);
    };
  }, [idDoc, poa, bankLetter, pic, ec, setCanApprove]);

  useEffect(() => {
    if (id_uploaded) {
      setIdDoc(id_uploaded);
      setUpdateUploads(true);
    }
    if (poa_uploaded) {
      setPoa(poa_uploaded);
      setUpdateUploads(true);
    }
    if (bl_uploaded) {
      setBankLetter(bl_uploaded);
      setUpdateUploads(true);
    }
    if (pp_uploaded) {
      setPic(pp_uploaded);
      setUpdateUploads(true);
    }
    if (wp_uploaded) {
      setWorkPermit(wp_uploaded);
      setUpdateUploads(true);
    }
    if (ec_uploaded) {
      setEc(ec_uploaded);
      setUpdateUploads(true);
    }
  }, [
    id_uploaded,
    poa_uploaded,
    bl_uploaded,
    pp_uploaded,
    wp_uploaded,
    ec_uploaded,
  ]);

  useEffect(() => {
    if (uploads) {
      setIdDoc(uploads.idDoc);
      setPoa(uploads.poa);
      setBankLetter(uploads.bankLetter);
      setPic(uploads.pic);
      setEc(uploads.ec);
      setWorkPermit(uploads.workPermit);
    }
  }, [uploads]);

  useEffect(() => {
    if (dustBuster) {
      setFlags(dustBuster.redflags);
      if (dustBuster.local === true) {
        setNationality('UK Citizen');
      } else {
        setNationality('Foreign Citizen');
      }
      if (dustBuster.female === true) {
        setGender('Female');
      } else {
        setGender('Male');
      }
      if (skills) {
        if (skills.office === true) setOffice('Office Cleaning');
        if (skills.hotel === true) setHotel('Hotel Cleaning');
        if (skills.home === true) setHome('Home Cleaning');
        if (skills.medical === true) setMedical('Medical Cleaning');
        if (skills.eot === true) setEOT('End Of Tenancy Cleaning');
        if (skills.retail === true) setRetail('Retail Cleaning');
      }
    }
    setUpdate(false);
  }, [dustBuster, skills]);

  if (skills === null || interview === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  const { ref1, ref2 } = skills;
  const slot = interview.start.split('G').shift();

  const togglePassword = () => {
    if (showPassword === 'password') setShowPassword('text');
    if (showPassword === 'text') setShowPassword('password');
  };

  const confirmDuster = () => {
    readyToWork({ dusterId, password });
  };

  const refresh = () => {
    getUploads(dusterId);
    setUpdate(false);
  };

  const uploadsUpdate = () => {
    updateDusterUploads({
      idDoc,
      poa,
      bankLetter,
      pic,
      workPermit,
      ec,
      dusterId,
    });
    setUpdateUploads(false);
  };

  const goBack = () => {
    history.push('/admin/team/stage-three');
  };
  return (
    <Row>
      <Col md={4} xs={12}>
        <Alert variant="info">
          Duster Info
          {ec && (
            <Button
              variant="success float-right ml-2"
              size="sm"
              onClick={handleShow}
            >
              Approve
            </Button>
          )}
          <DeleteDuster
            dusterId={dusterId}
            dustBuster={dustBuster}
            deleteBtn={deleteBtn}
            removeDuster={removeDuster}
          />
          <Button size="sm" variant="warning float-right" disabled>
            Stage {dustBuster.stage}
          </Button>
        </Alert>
        <ListGroup variant="flush">
          <ListGroup.Item>Name: {dustBuster.name}</ListGroup.Item>
          <ListGroup.Item>Surname: {dustBuster.surname} </ListGroup.Item>
          <ListGroup.Item>ID Number: {dustBuster.idNum} </ListGroup.Item>
          <ListGroup.Item>Cellphone: {dustBuster.cellphone} </ListGroup.Item>
          <ListGroup.Item>Email: {dustBuster.email} </ListGroup.Item>
          <ListGroup.Item>Post code: {dustBuster.code} </ListGroup.Item>
          <ListGroup.Item>Address: {dustBuster.address} </ListGroup.Item>
          <ListGroup.Item>Gender: {gender} </ListGroup.Item>
          <ListGroup.Item>Nationality: {nationality} </ListGroup.Item>
          <Button variant="info float-right mt-3" disabled>
            Interview date:{' '}
            {moment.utc(slot).format('dddd, MMMM Do YYYY, h:mm:ss a')}
          </Button>
          {missed === 'Future Interview' ? (
            <Button variant="info mt-3" disabled>
              Future Interview
            </Button>
          ) : missed === true ? (
            <Button variant="danger mt-3" disabled>
              Cleaner Missed the interview
            </Button>
          ) : (
            <Button variant="success mt-3" disabled>
              Cleaner Arrived for interview
            </Button>
          )}
        </ListGroup>
        {flags
          ? flags.length > 0 && (
              <Col>
                <Alert variant="warning mt-3">
                  Duster has flags
                  <i className="fab fa-font-awesome-flag float-right"></i>
                </Alert>
              </Col>
            )
          : ''}
        {flags && flags.map((flag) => <RedFlags flag={flag} key={flag} />)}
      </Col>
      <Col md={8} xs={12}>
        <Alert variant="secondary">
          Dust Buster work experience
          <Button
            variant="secondary float-right"
            size="sm"
            onClick={() => goBack()}
          >
            back
          </Button>
        </Alert>
        <ListGroup variant="flush">
          <ListGroup.Item>Work Experience: {skills.exp} </ListGroup.Item>
        </ListGroup>
        <Row>
          <Col>
            <Alert variant="secondary">Reference 1</Alert>
            <ListGroup variant="flush">
              <ListGroup.Item>Name: {ref1.name1}</ListGroup.Item>
              <ListGroup.Item>Contact: {ref1.contact1}</ListGroup.Item>
              <ListGroup.Item>Duration: {ref1.duration1}</ListGroup.Item>
            </ListGroup>
          </Col>
          <Col>
            <Alert variant="secondary">Reference 2</Alert>
            <ListGroup variant="flush">
              <ListGroup.Item>Name: {ref2.name2}</ListGroup.Item>
              <ListGroup.Item>Contact: {ref2.contact2}</ListGroup.Item>
              <ListGroup.Item>Duration: {ref2.duration2}</ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
        <Alert variant="secondary mt-4">Type Of Cleaning</Alert>
        <ListGroup variant="flush">
          {office && <ListGroup.Item>{office}</ListGroup.Item>}
          {hotel && <ListGroup.Item>{hotel}</ListGroup.Item>}
          {home && <ListGroup.Item>{home}</ListGroup.Item>}
          {medical && <ListGroup.Item>{medical}</ListGroup.Item>}
          {eot && <ListGroup.Item>{eot}</ListGroup.Item>}
          {retail && <ListGroup.Item>{retail}</ListGroup.Item>}
        </ListGroup>

        <Col>
          <Alert variant="secondary mt-4">
            Profile Documentation
            {update === true && (
              <Button
                variant="primary float-right"
                size="sm"
                ref={updateBtn}
                onClick={refresh}
              >
                Update
              </Button>
            )}
            {updateUploads === true && (
              <Button
                variant="warning float-right"
                size="sm"
                onClick={uploadsUpdate}
                ref={uploadBtn}
              >
                Update Uploads
              </Button>
            )}
          </Alert>
          <>
            {uploads ? (
              <DusterDocs
                dusterId={dusterId}
                nationality={nationality}
                deleteImage={deleteImage}
                deleteID={deleteID}
                deletePOA={deletePOA}
                deleteBL={deleteBL}
                deletePP={deletePP}
                deleteWP={deleteWP}
                deleteEC={deleteEC}
                adminECUpload={adminECUpload}
                ec_uploaded={ec_uploaded}
                adminIDUpload={adminIDUpload}
                id_uploaded={id_uploaded}
                adminPOAUpload={adminPOAUpload}
                poa_uploaded={poa_uploaded}
                adminBankLetterUpload={adminBankLetterUpload}
                bl_uploaded={bl_uploaded}
                adminProPicUpload={adminProPicUpload}
                pp_uploaded={pp_uploaded}
                adminWorkPermitUpload={adminWorkPermitUpload}
                wp_uploaded={wp_uploaded}
                setUpdate={setUpdate}
                idDoc={idDoc}
                poa={poa}
                bankLetter={bankLetter}
                pic={pic}
                ec={ec}
                workPermit={workPermit}
              />
            ) : (
              <UploadDocs
                adminIDUpload={adminIDUpload}
                adminPOAUpload={adminPOAUpload}
                adminBankLetterUpload={adminBankLetterUpload}
                adminProPicUpload={adminProPicUpload}
                adminECUpload={adminECUpload}
                adminWorkPermitUpload={adminWorkPermitUpload}
                id_uploaded={id_uploaded}
                poa_uploaded={poa_uploaded}
                bl_uploaded={bl_uploaded}
                pp_uploaded={pp_uploaded}
                ec_uploaded={ec_uploaded}
                wp_uploaded={wp_uploaded}
                nationality={nationality}
                dusterId={dusterId}
                addDusterProfile={addDusterProfile}
              />
            )}
          </>
        </Col>
        <Modal show={show} onHide={handleClose}>
          <Alert variant="success">
            Create password and approve {dustBuster.name}
          </Alert>
          <Modal.Body>
            Create Password:
            <InputGroup className="mb-3">
              <FormControl
                type={showPassword}
                placeholder="Create password for Duster"
                value={password}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputGroup.Text>
                {showPassword === 'password' ? (
                  <i className="fas fa-eye-slash" onClick={togglePassword}></i>
                ) : (
                  <i
                    className="fas fa-eye text-success"
                    onClick={togglePassword}
                  ></i>
                )}
              </InputGroup.Text>
            </InputGroup>
            Confirm Password:
            <InputGroup className="mb-3">
              <FormControl
                type={showPassword}
                placeholder="Confirm password for Duster"
                value={password2}
                name="password2"
                onChange={(e) => setPassword2(e.target.value)}
              />
              <InputGroup.Text>
                {showPassword === 'password' ? (
                  <i className="fas fa-eye-slash" onClick={togglePassword}></i>
                ) : (
                  <i
                    className="fas fa-eye text-success"
                    onClick={togglePassword}
                  ></i>
                )}
              </InputGroup.Text>
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleClose}>
              <i className="far fa-window-close"></i>
            </Button>
            {password.length > 0 ? (
              password === password2 ? (
                <Button variant="success" onClick={confirmDuster}>
                  Approve Duster
                </Button>
              ) : (
                <Button variant="secondary" disabled>
                  Match Passwords
                </Button>
              )
            ) : (
              <Button variant="secondary" disabled>
                Match Passwords
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </Col>
    </Row>
  );
};

export default StageThree;
