import {
  BEDROOM,
  BEDTIME,
  BATHROOM,
  BATHTIME,
  BBHOURS,
  BBTOTAL,
  EXHOURS,
  EXTOTAL,
  ADD_EXTRA,
  REMOVE_EXTRA,
  OVER_NINE,
  INV_TOTAL,
  SET_DATE,
  OVER_ALLHOURS,
  SET_SCHEDULE,
  WEEKDAYS,
  REG_INV_TOTAL,
  DEFAULT_FREQUENCY,
  INITIAL_DATE,
  CREATE_SCHEDULE,
  CLEAR_SCHEDULE,
  REGULAR,
  DUSTERS_NEAR_ME,
  NITTY_GRITTY,
  ORDER_ADDRESS,
  ORDER_COORDINATES,
  ORDER_POSTCODE,
  CHANGE_HOURS,
  WHY_LESS,
  ORDER_COMPLEX,
  ADD_ICON_EXTRA,
  REMOVE_ICON_EXTRA,
  BIG_RESET,
} from '../../actions/types';

const initState = {
  regular: false,
  frequency: 1,
  weekdays: [],
  schedule: [],
  address: null,
  complex: null,
  postCode: null,
  coordinates: null,
  orderTime: null,
  firstDate: null,
  orderHours: 0,
  invoiceHours: 0,
  changeHours: null,
  bedroom: 0,
  bedroomTime: 0,
  bathroom: 0,
  bathroomTime: 0,
  bedbathTotal: 0,
  bedbathHours: 0,
  iconExtras: [
    {
      id: 1,
      name: 'Ironing & Laundry',
      chosen: false,
      time: 30,
    },
    {
      id: 2,
      name: 'Bedding Change',
      chosen: false,
      time: 30,
    },
    {
      id: 3,
      name: 'Kitchen Cupboards',
      chosen: false,
      time: 30,
    },
    {
      id: 4,
      name: 'Inside Oven',
      chosen: false,
      time: 30,
    },
    {
      id: 5,
      name: 'Inside Fridge',
      chosen: false,
      time: 30,
    },
    {
      id: 6,
      name: 'Internal Windows',
      chosen: false,
      time: 30,
    },
  ],
  extras: [
    { id: '1', name: 'Ironing Folding', chosen: false, time: 30 },
    { id: '2', name: 'Laundry Packing', chosen: false, time: 30 },
    { id: '3', name: 'Inside Fridge', chosen: false, time: 30 },
    { id: '4', name: 'Kitchen Cupboards', chosen: false, time: 30 },
    { id: '5', name: 'Inside Oven', chosen: false, time: 30 },
    { id: '6', name: 'Bedding Change', chosen: false, time: 15 },
  ],
  extraHours: 0,
  extraTotal: 0,
  overnine: false,
  invoice: 0,
  regInvoice: 0,
  availableCleaners: null,
  nityGrity: null,
  whyLess: null,
};
function bookingReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case NITTY_GRITTY:
      return {
        ...state,
        nityGrity: payload,
      };
    case DUSTERS_NEAR_ME:
      return {
        ...state,
        availableCleaners: payload,
      };
    case CHANGE_HOURS:
      return {
        ...state,
        changeHours: payload,
      };

    case REGULAR:
      return {
        ...state,
        regular: payload,
      };
    case CLEAR_SCHEDULE:
      return {
        ...state,
        schedule: payload,
      };
    case SET_DATE:
      return {
        ...state,
        orderTime: payload,
      };
    case BEDROOM:
      return {
        ...state,
        bedroom: payload,
      };
    case BEDTIME:
      return {
        ...state,
        bedroomTime: payload,
      };
    case BATHROOM:
      return {
        ...state,
        bathroom: payload,
      };
    case BATHTIME:
      return {
        ...state,
        bathroomTime: payload,
      };
    case BBHOURS:
      return {
        ...state,
        bedbathHours: payload,
      };
    case BBTOTAL:
      return {
        ...state,
        bedbathTotal: payload,
      };
    case EXHOURS:
      return {
        ...state,
        extraHours: payload,
      };
    case EXTOTAL:
      return {
        ...state,
        extraTotal: payload,
      };
    case ADD_ICON_EXTRA:
      let choose = state.iconExtras.find((ie) => ie.id === payload);
      choose.chosen = true;
      let chosenArr = state.iconExtras.filter((ie) => ie.id !== payload);
      chosenArr.push(choose);
      chosenArr.sort((a, b) => {
        return a.id - b.id;
      });
      return {
        ...state,
        iconExtras: chosenArr,
      };
    case ADD_EXTRA:
      let chosen = state.extras.find((ex) => ex.id === payload);
      chosen.chosen = true;
      let myArr = state.extras.filter((a) => a.id !== payload);
      myArr.push(chosen);
      myArr.sort((a, b) => {
        return a.id - b.id;
      });
      return {
        ...state,
        extras: myArr,
      };
    case REMOVE_ICON_EXTRA:
      let remove = state.iconExtras.find((ie) => ie.id === payload);
      remove.chosen = false;
      let arr = state.iconExtras.filter((a) => a.id !== payload);
      arr.push(remove);
      arr.sort((a, b) => {
        return a.id - b.id;
      });
      return {
        ...state,
        iconExtras: arr,
      };
    case REMOVE_EXTRA:
      let extra = state.extras.find((ex) => ex.id === payload);
      extra.chosen = false;
      let newArr = state.extras.filter((a) => a.id !== payload);
      newArr.push(extra);
      newArr.sort((a, b) => {
        return a.id - b.id;
      });
      return {
        ...state,
        extras: newArr,
      };
    case OVER_NINE:
      return {
        ...state,
        overnine: payload,
      };
    case OVER_ALLHOURS:
      return {
        ...state,
        invoiceHours: payload,
      };
    case INV_TOTAL:
      return {
        ...state,
        invoice: payload,
      };
    case REG_INV_TOTAL:
      return {
        ...state,
        regInvoice: payload,
      };
    case SET_SCHEDULE:
      return {
        ...state,
        frequency: payload,
      };
    case WEEKDAYS:
      return {
        ...state,
        weekdays: payload,
      };
    case DEFAULT_FREQUENCY:
      return {
        ...state,
        frequency: payload,
      };
    case INITIAL_DATE:
      return {
        ...state,
        firstDate: payload,
      };
    case CREATE_SCHEDULE:
      return {
        ...state,
        schedule: payload,
      };
    case ORDER_POSTCODE:
      return {
        ...state,
        postCode: payload,
      };
    case ORDER_ADDRESS:
      return {
        ...state,
        address: payload,
      };
    case ORDER_COORDINATES:
      return {
        ...state,
        coordinates: payload,
      };
    case ORDER_COMPLEX:
      return {
        ...state,
        complex: payload,
      };
    case WHY_LESS:
      return {
        ...state,
        whyLess: payload,
      };
    case BIG_RESET:
      return {
        ...state,
        regular: false,
        frequency: 1,
        weekdays: [],
        schedule: [],
        address: null,
        complex: null,
        coordinates: null,
        orderTime: null,
        firstDate: null,
        orderHours: 0,
        invoiceHours: 0,
        changeHours: null,
        bedroom: 0,
        bedroomTime: 0,
        bathroom: 0,
        bathroomTime: 0,
        bedbathTotal: 0,
        bedbathHours: 0,
        iconExtras: [
          {
            id: 1,
            name: 'Ironing & Laundry',
            chosen: false,
            time: 30,
          },
          {
            id: 2,
            name: 'Bedding Change',
            chosen: false,
            time: 30,
          },
          {
            id: 3,
            name: 'Kitchen Cupboards',
            chosen: false,
            time: 30,
          },
          {
            id: 4,
            name: 'Inside Oven',
            chosen: false,
            time: 30,
          },
          {
            id: 5,
            name: 'Inside Fridge',
            chosen: false,
            time: 30,
          },
          {
            id: 6,
            name: 'Internal Windows',
            chosen: false,
            time: 30,
          },
        ],
        extras: [
          { id: '1', name: 'Ironing Folding', chosen: false, time: 30 },
          { id: '2', name: 'Laundry Packing', chosen: false, time: 30 },
          { id: '3', name: 'Inside Fridge', chosen: false, time: 30 },
          { id: '4', name: 'Kitchen Cupboards', chosen: false, time: 30 },
          { id: '5', name: 'Inside Oven', chosen: false, time: 30 },
          { id: '6', name: 'Bedding Change', chosen: false, time: 15 },
        ],
        extraHours: 0,
        extraTotal: 0,
        overnine: false,
        invoice: 0,
        regInvoice: 0,
        availableCleaners: null,
        nityGrity: null,
        whyLess: null,
      };
    default:
      return state;
  }
}

export default bookingReducer;
