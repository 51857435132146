import { combineReducers } from 'redux';
import note from './note';
import team from './team';
import team_profile from './team/team_profile';
import team_skills from './team/team_skills';
import team_work from './team/team_work';
import user from './user';
import file from './fileupload';
import adminUpload from './admin/adminUpload';
import admin from './admin';
import team_members from './admin/team_members';
import admin_users from './admin/admin_user';
import bookings from './user/bookings';
import orders from './user/orders';
import invoice from './user/invoice';
import coupons from './user/coupons';
import payment from './user/payment';
import main from './admin/main';
import animate from './animate';
import user_reviews from './user/reviews';
import duster_reviews from './team/review';
import admin_orders from './admin/admin_orders';

export default combineReducers({
  note,
  team,
  team_profile,
  team_skills,
  team_work,
  user,
  file,
  adminUpload,
  admin,
  team_members,
  admin_users,
  bookings,
  orders,
  invoice,
  coupons,
  main,
  animate,
  duster_reviews,
  user_reviews,
  admin_orders,
  payment,
});
