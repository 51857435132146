import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { signinUser } from '../../../actions/user';
import Bottom from '../../Layout/Bottom';

const SignIn = ({ isUser, signinUser, note }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [nte, setMsg] = useState(note);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  let btn = useRef();

  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, nte]);

  const { username, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    signinUser({ username, password });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  if (isUser) {
    return <Redirect to="/user/booking" />;
  }
  return (
    <div>
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <Card className="card bg-primary text-white mb-2" align="center">
              <Card.Body>
                <Card.Title>Welcome To Dusting Down</Card.Title>
                <Card.Subtitle>
                  Sign into book one of our fabulous clean-up.
                </Card.Subtitle>
                <span style={{ fontSize: '7em' }}>
                  <i className="fas fa-calendar-check"></i>
                </span>
                <p className="text-white">Sign in or create an account</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card.Header className="text-white" id="welcome-sign">
              <h5 className="text-center">Sign into your account</h5>
            </Card.Header>
            <Form className="mt-2" onSubmit={onSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="username"
                  value={username}
                  onChange={(e) => onChange(e)}
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Button
                variant="btn text-white"
                id="welcome-sign"
                type="submit"
                block
                ref={btn}
              >
                Submit <i className="fas fa-comment ml-3"></i>
              </Button>
            </Form>
            <hr />
            <Row>
              <Col>
                <Button
                  variant="outline-primary"
                  as={Link}
                  to="/user/signup"
                  block
                >
                  Create an account
                </Button>
              </Col>
              <Col>
                <Button
                  variant="outline-warning"
                  as={Link}
                  to="/user/password-reset-request"
                  block
                >
                  Reset Password
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isUser: state.user.isUser,
  note: state.note,
});
export default connect(mapStateToProps, { signinUser })(SignIn);
