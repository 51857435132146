import React, { useEffect, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  levelOneNum,
  levelTwoNum,
  levelThreeNum,
  readyToWorkNum,
  intCalendar,
  whoInterview,
  nullDuster,
} from '../../../../actions/admin/team_members';
import { Col, Row, Alert, Card, Button, Spinner, Modal } from 'react-bootstrap';
import moment from 'moment';

import IntCalender from './IntCalender';
import FlushStageOne from './FlushStageOne';
import FlushStageTwo from './FlushStageTwo';

const AdminDusters = ({
  isAdmin,
  readyToWorkNum,
  levelOneNum,
  levelTwoNum,
  levelThreeNum,
  intCalendar,
  whoInterview,
  nullDuster,
  team_members: {
    stageOneNumber,
    stageTwoNumber,
    stageThreeNumber,
    readyNumber,
    interviews,
    dustBuster,
  },
  note,
}) => {
  const [int, setInt] = useState(null);
  const [show, setShow] = useState(false);
  const [intDate, setIntDate] = useState(null);
  const [missed, setMissed] = useState(false);
  const [name, setName] = useState(null);
  const [rtw, setRtw] = useState(null);
  const [dusterId, setDusterId] = useState(null);
  const handleClose = () => {
    nullDuster(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (intDate === null) {
      nullDuster(null);
    }
  }, [intDate]);
  useEffect(() => {
    if (dustBuster) {
      setDusterId(dustBuster._id);
      setName(dustBuster.name);
      setRtw(dustBuster.readyToWork);
      handleShow();
    } else {
      handleClose();
    }
  }, [dustBuster]);

  useEffect(() => {
    if (intDate) {
      let rightNow = moment.utc().format().split('T').shift();
      if (intDate < rightNow) {
        if (rtw === false) {
          setMissed(true);
        } else {
          setMissed(false);
        }
      } else {
        setMissed('Future Interview');
      }
    }
  }, [intDate, rtw]);

  useEffect(() => {
    levelOneNum();
    levelTwoNum();
    levelThreeNum();
    readyToWorkNum();
    intCalendar();
  }, [levelOneNum, levelTwoNum, levelThreeNum, readyToWorkNum, intCalendar]);

  useEffect(() => {
    if (interviews) {
      const a = interviews.map((int) => {
        let c = moment.utc(int.start).format();

        return { title: 'intreview', start: c, dusterId: int.team };
      });
      setInt(a);
    }
  }, [interviews]);

  if (!isAdmin) {
    return <Redirect to="/admin/signin" />;
  }

  if (
    stageOneNumber === null ||
    stageTwoNumber === null ||
    stageThreeNumber === null ||
    readyNumber === null ||
    int === null
  ) {
    return <Spinner animation="grow" variant="primary" />;
  }
  return (
    <div className="container" align="center">
      <Alert variant="info text-center">
        <h5>Welcome to the Dust Buster Dashboard</h5>
      </Alert>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>
                <span
                  style={{ fontSize: '3em' }}
                  className="float-left mr-2 text-danger"
                >
                  <i className="fas fa-envelope"></i>
                </span>
                Step 1
              </Card.Title>
              <Card.Subtitle className="text-muted">
                <b className="display-4">{stageOneNumber}</b>
              </Card.Subtitle>
              <Card.Text>Dust Busters in Step 1</Card.Text>
              <Button
                as={Link}
                to="/admin/team/stage-one"
                variant="outline-secondary"
              >
                View Dust Busters
              </Button>
              <FlushStageOne note={note} />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>
                <span
                  style={{ fontSize: '3em' }}
                  className="float-left mr-2 text-warning"
                >
                  <i className="fas fa-info-circle"></i>
                </span>
                Step 2
              </Card.Title>
              <Card.Subtitle className=" text-muted">
                <b className="display-4">{stageTwoNumber}</b>
              </Card.Subtitle>
              <Card.Text>Dust Busters in Step 2</Card.Text>
              <Button
                as={Link}
                to="/admin/team/stage-two"
                variant="outline-secondary"
              >
                View Duster Busters
              </Button>

              <FlushStageTwo note={note} />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>
                <span
                  style={{ fontSize: '3em' }}
                  className="float-left mr-2 text-info"
                >
                  <i className="fas fa-calendar-alt"></i>
                </span>
                Step 3
              </Card.Title>
              <Card.Subtitle className="text-muted">
                <b className="display-4">{stageThreeNumber}</b>
              </Card.Subtitle>
              <Card.Text>Dust Busters in Step 3</Card.Text>
              <Button
                as={Link}
                to="/admin/team/stage-three"
                variant="outline-secondary"
              >
                View Duster Busters
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Body>
              <Card.Title>
                <span
                  style={{ fontSize: '3em' }}
                  className="float-left mr-2 text-success"
                >
                  <i className="fas fa-user-check"></i>
                </span>
                Working
              </Card.Title>
              <Card.Subtitle className="text-muted">
                <b className="display-4">{readyNumber}</b>
              </Card.Subtitle>
              <Card.Text>Dust Busters working</Card.Text>
              <Button
                as={Link}
                to="/admin/team/ready-to-work"
                variant="outline-secondary"
              >
                View Duster Busters
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4 mt-4">
        <Col></Col>
        <Col md={6}>
          <Alert variant="secondary">Interview Calender</Alert>
          <IntCalender
            int={int}
            whoInterview={whoInterview}
            setIntDate={setIntDate}
          />
        </Col>
        <Col></Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Interview for {name} | {intDate}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {missed === 'Future Interview' ? (
            <Button variant="info" disabled>
              Future Interview
            </Button>
          ) : missed === true ? (
            <Button variant="danger" disabled>
              Cleaner Missed the interview
            </Button>
          ) : (
            <Button variant="success" disabled>
              Cleaner Arrived for interview
            </Button>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            as={Link}
            to={`/admin/team-member/${dusterId}`}
            onClick={handleClose}
          >
            View Duster
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.admin.isAdmin,
  team_members: state.team_members,
  note: state.note,
});

export default connect(mapStateToProps, {
  levelOneNum,
  levelTwoNum,
  levelThreeNum,
  readyToWorkNum,
  intCalendar,
  whoInterview,
  nullDuster,
})(AdminDusters);
