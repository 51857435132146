import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Spinner } from 'react-bootstrap';
import { fromEmail, myInt } from '../../../actions/team';
import ConfettiExplosion from 'react-confetti-explosion';
import moment from 'moment';
import './SignUp.css';

const CompleteApplication = ({
  fromEmail,
  match,
  team: { team, int },
  myInt,
}) => {
  useEffect(() => {
    fromEmail(match.params.dusterId);
  }, [fromEmail, match]);

  useEffect(() => {
    if (team) {
      let dusterId = team._id;
      myInt(dusterId);
    }
  }, [team, myInt]);

  if (team === null || int === null)
    return <Spinner variant="primary" animation="grow" />;

  let time = moment.utc(int.start).format('ddd MMM D, h:mm a');

  return (
    <div className="container" align="center" id="signup">
      <Card bg="info">
        <Card.Header className="text-white">
          Welcome <b>{team.name}</b>
        </Card.Header>
        <Card.Body>
          <Card.Title className="text-white">
            Congratulations on filling out your application
          </Card.Title>
          <div align="center">
            <ConfettiExplosion duration="5000" particleCount="200" />
          </div>
          <Card.Text className="text-white">
            The last step is to log on for your zoom interveiw on <b>{time}</b>.
            Please rememeber to have your supporting documentation with you at
            the interview.
            <hr />
            We have sent an email to your address with confirmation of your
            interview appointment
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-white">
          We look forward to welcoming you to our team!
        </Card.Footer>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  team: state.team,
});

export default connect(mapStateToProps, { fromEmail, myInt })(
  CompleteApplication
);
