import React, { useEffect, useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interActionPlugin from '@fullcalendar/interaction';
import { Alert, Modal, Button, Row, Col, Card, Spinner } from 'react-bootstrap';
const moment = require('moment-timezone');
moment().tz('Africa/Johannesburg').format();

const UploadLater = ({ stepThreeNoDocs, dusterId, hr, app }) => {
  const [tomorrow, setTomorrow] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [interviewTime, setInterviewTime] = useState(null);
  const [interviewDate, setInterviewDate] = useState(null);
  const [appointment, setAppoinment] = useState(null);
  const [slots, setSlots] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let btn = useRef();
  let eight = useRef();
  let ten = useRef();
  let twelve = useRef();
  let two = useRef();

  useEffect(() => {
    if (slots) {
      if (slots.length > 0) {
        slots.forEach((slot) => {
          let start = slot.start.split(' ');
          let time = start[4].split(':').shift();
          if (time === '08') {
            eight.current.setAttribute('disabled', 'disabled');
          } else if (time === '10') {
            ten.current.setAttribute('disabled', 'disabled');
          } else if (time === '12') {
            twelve.current.setAttribute('disabled', 'disabled');
          } else if (time === '14')
            two.current.setAttribute('disabled', 'disabled');
        });
      }
    }
  }, [slots]);

  useEffect(() => {
    if (app) {
      setLoading(false);
      handleShow();
      setSlots(app);
    }
  }, [app]);

  useEffect(() => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    date.setMinutes(0);
    date.setHours(8);
    setTomorrow(date);
  }, [setTomorrow]);

  const selectDay = (e) => {
    let jam = e.dateStr;
    let day = moment.utc(jam).format();

    hr({ day });
    setInterviewDate(day);
    setSlots(null);
    setLoading(true);
  };
  const confirmTime = () => {
    let a = moment.utc(interviewDate).add('hours', interviewTime).format();
    setAppoinment(a);
    handleClose();
  };

  const finishApp = () => {
    let et = moment.utc(appointment).format('llll');
    stepThreeNoDocs({ appointment, dusterId, et });
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  const reset = () => {
    setInterviewDate(null);
    setInterviewTime(null);
    setAppoinment(null);
  };

  if (loading === true) {
    return <Spinner variant="primary" animation="grow" />;
  }
  return (
    <div>
      {appointment ? (
        <>
          <Alert variant="info mt-4">
            <Row>
              <Col className="float-left">
                You have chosen your interview time to be:
                <b> {moment.utc(appointment).format('llll')}</b>
              </Col>
              <Col>
                <Button variant="warning float-right" size="sm" onClick={reset}>
                  Change Time
                </Button>
              </Col>
            </Row>
          </Alert>
          <Card.Body>
            <h5 className="mb-2">
              We will send you a link to the meeting via email
            </h5>
            <Alert variant="warning">
              Remember to bring your documents to the interview
            </Alert>
          </Card.Body>
          <Button variant="primary" onClick={finishApp} ref={btn} block>
            Finish Application<i className="fas fa-sign-in-alt ml-3"></i>
          </Button>
        </>
      ) : (
        <>
          <Alert variant="info mt-3">
            On which day would you like to have your zoom interview
          </Alert>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interActionPlugin]}
            initialView="dayGridMonth"
            dateClick={selectDay}
            validRange={{ start: tomorrow }}
            selectable={true}
          />

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-center">
                What time on{' '}
                <span className="text-success">
                  {moment(interviewDate).format('ll')}
                </span>{' '}
                would you like to book a zoom interview?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Button
                    variant={interviewTime === 8 ? 'dark' : 'outline-dark'}
                    onClick={() => setInterviewTime(8)}
                    ref={eight}
                    block
                  >
                    8:00am
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={interviewTime === 10 ? 'dark' : 'outline-dark'}
                    onClick={() => setInterviewTime(10)}
                    ref={ten}
                    block
                  >
                    10:00am
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={interviewTime === 12 ? 'dark' : 'outline-dark'}
                    onClick={() => setInterviewTime(12)}
                    ref={twelve}
                    block
                  >
                    12:00pm
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={interviewTime === 14 ? 'dark' : 'outline-dark'}
                    onClick={() => setInterviewTime(14)}
                    ref={two}
                    block
                  >
                    14:00pm
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="warning" onClick={handleClose}>
                <i className="fas fa-times"></i>
              </Button>
              <Button variant="success" onClick={confirmTime}>
                Confirm Time
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
};

export default UploadLater;
