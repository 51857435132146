import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';
import { theMotherCard, editMotherCard } from '../../../../actions/admin/main';

const MotherCard = ({ theMotherCard, mother, editMotherCard }) => {
  useEffect(() => {
    theMotherCard();
  }, [theMotherCard]);
  const [show, setShow] = useState(false);
  const [two, SetUserTwo] = useState('');
  const [twop, SetUserTwoP] = useState('');
  const [three, SetUserThree] = useState('');
  const [threep, SetUserThreeP] = useState('');
  const [four, SetUserFour] = useState('');
  const [fourp, SetUserFourP] = useState('');
  const [five, SetUserFive] = useState('');
  const [fivep, SetUserFiveP] = useState('');
  const [six, SetUserSix] = useState('');
  const [sixp, SetUserSixP] = useState('');
  const [seven, SetUserSeven] = useState('');
  const [sevenp, SetUserSevenP] = useState('');
  const [eight, SetUserEight] = useState('');

  const [motherCard, SetMotherCard] = useState('');

  useEffect(() => {
    if (mother) {
      SetMotherCard(mother._id);
      SetUserTwo(mother.two);
      SetUserTwoP(mother.twop);
      SetUserThree(mother.three);
      SetUserThreeP(mother.threep);
      SetUserFour(mother.four);
      SetUserFourP(mother.fourp);
      SetUserFive(mother.five);
      SetUserFiveP(mother.fivep);
      SetUserSix(mother.six);
      SetUserSixP(mother.sixp);
      SetUserSeven(mother.seven);
      SetUserSevenP(mother.sevenp);
      SetUserEight(mother.eight);
    }
  }, [mother]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const editMother = (e) => {
    e.preventDefault();
    editMotherCard({
      motherCard,
      two,
      twop,
      three,
      threep,
      four,
      fourp,
      five,
      fivep,
      six,
      sixp,
      seven,
      sevenp,
      eight,
    });
    setShow(false);
  };

  return (
    <>
      <Button
        variant="outline-primary float-right"
        size="sm"
        onClick={handleShow}
      >
        Mother Card
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={editMother}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Rate Card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>2 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={two}
                name="two"
                value={two}
                onChange={(e) => SetUserTwo(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>2.5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={twop}
                name="twop"
                value={twop}
                onChange={(e) => SetUserTwoP(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>3 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={three}
                name="three"
                value={three}
                onChange={(e) => SetUserThree(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>3.5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={threep}
                name="threep"
                value={threep}
                onChange={(e) => SetUserThreeP(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>4 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={four}
                name="four"
                value={four}
                onChange={(e) => SetUserFour(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>4.5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={fourp}
                name="fourp"
                value={fourp}
                onChange={(e) => SetUserFourP(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={five}
                name="five"
                value={five}
                onChange={(e) => SetUserFive(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>5.5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={fivep}
                name="fivep"
                value={fivep}
                onChange={(e) => SetUserFiveP(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>6 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={six}
                name="six"
                value={six}
                onChange={(e) => SetUserSix(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>6.5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={sixp}
                name="sixp"
                value={sixp}
                onChange={(e) => SetUserSixP(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>7 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={seven}
                name="seven"
                value={seven}
                onChange={(e) => SetUserSeven(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>7.5 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={sevenp}
                name="sevenp"
                value={sevenp}
                onChange={(e) => SetUserSevenP(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>8 hours rate</Form.Label>
              <Form.Control
                type="number"
                placeholder={eight}
                name="eight"
                value={eight}
                onChange={(e) => SetUserEight(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Edit Main Rate
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  mother: state.main.mother,
});

export default connect(mapStateToProps, { theMotherCard, editMotherCard })(
  MotherCard
);
