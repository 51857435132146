import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interActionPlugin from '@fullcalendar/interaction';
import './Booking.css';
import moment from 'moment';

const Calender = ({
  setDate,
  arrivalTime,
  setArrivalTime,
  regular,
  weekdays,
  setNote,
  setDaysLeft,
  tomorrow,
  setSelectedDay,
  selectedDay,
  setPrettyDay,
  prettyDay,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setSelectedDay(null);
    setArrivalTime(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const selectDay = (e) => {
    if (regular) {
      let valid = weekdays.filter((w) => {
        let sd = e.date.toString().split(' ').shift();
        const splitAt = (index) => (x) => [x.slice(0, index), x.slice(index)];
        let day = splitAt(3)(w);
        return sd === day[0];
      });

      if (valid.length === 0) {
        return setNote(
          'Please choose a day that matches your schedule',
          'danger'
        );
      } else {
        let jam = e.dateStr;
        let peanut = moment.utc(jam).format('llll');
        let a = peanut.split(',');
        let date = a[0] + a[1];

        let day = moment.utc(jam).format();

        setPrettyDay(date);
        setSelectedDay(day);
        handleShow();
      }
    } else {
      let jam = e.dateStr;
      let peanut = moment.utc(jam).format('llll');
      let a = peanut.split(',');
      let date = a[0] + a[1];

      let day = moment.utc(jam).format();

      setPrettyDay(date);
      setSelectedDay(day);
      handleShow();
    }
  };

  const confirmTime = () => {
    let a = moment.utc(selectedDay).add('hours', arrivalTime).format();

    setDate(a);
    setShow(false);
  };

  const confirmFirstDate = () => {
    let a = moment.utc(selectedDay).add('hours', arrivalTime).format();

    var today = new Date();
    var lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    var mytime = lastday.setDate(lastday.getDate() + 1);

    var getDaysArray = function (s, e) {
      for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
        a.push(new Date(d));
      }
      setDaysLeft(a);
    };
    getDaysArray(a, mytime);
    setDate(a);
    setShow(false);
  };

  return (
    <div className="mt-4">
      <>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interActionPlugin]}
          initialView="dayGridMonth"
          dateClick={selectDay}
          validRange={{ start: tomorrow }}
          selectable={true}
        />
        <Button variant="secondary mt-3" id="next" disabled>
          Select cleaner arrival date and time to continue
          <i className="fas fa-exclamation-circle ml-2"></i>
        </Button>
      </>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            What time would you like the cleaner to arrive on {prettyDay}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3} xs={12}>
              <Button
                variant={arrivalTime === 8 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(8)}
                block
              >
                8:00am
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={arrivalTime === 10 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(10)}
                block
              >
                10:00am
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={arrivalTime === 12 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(12)}
                block
              >
                12:00pm
              </Button>
            </Col>
            <Col md={3} xs={12}>
              <Button
                variant={arrivalTime === 14 ? 'dark' : 'outline-dark'}
                onClick={() => setArrivalTime(14)}
                block
              >
                14:00pm
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-warning" onClick={handleClose}>
            <i className="far fa-window-close"></i>
          </Button>
          {arrivalTime ? (
            regular ? (
              <Button variant="outline-success" onClick={confirmFirstDate}>
                Confirm First Date
              </Button>
            ) : (
              <Button variant="outline-success" onClick={confirmTime}>
                Confirm Arrival Time
              </Button>
            )
          ) : (
            <Button variant="secondary" disabled>
              Select Arrival Time
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Calender;
