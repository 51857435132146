import axios from 'axios';
import {
  ADMIN_ID_UPLOAD,
  ADMIN_WP_UPLOAD,
  ADMIN_BL_UPLOAD,
  ADMIN_POA_UPLOAD,
  ADMIN_PP_UPLOAD,
  ADMIN_EC_UPLOAD,
  ADMIN_FILE_UPLOAD_ERROR,
} from '../types';
import { setNote } from '../note';

//Upload ID//
export const adminIDUpload = (formData) => async (dispatch) => {
  const config = {
    header: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    const res = await axios.post(
      '/api/admin/duster_upload/id',
      formData,
      config
    );
    dispatch({ type: ADMIN_ID_UPLOAD, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: ADMIN_FILE_UPLOAD_ERROR });
  }
};

//upload Work Permit//
export const adminWorkPermitUpload = (formData) => async (dispatch) => {
  const config = {
    header: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    const res = await axios.post(
      '/api/admin/duster_upload/work_permit',
      formData,
      config
    );
    dispatch({ type: ADMIN_WP_UPLOAD, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: ADMIN_FILE_UPLOAD_ERROR });
  }
};

//Upload Bank Letter//
export const adminBankLetterUpload = (formData) => async (dispatch) => {
  const config = {
    header: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    const res = await axios.post(
      '/api/admin/duster_upload/bank_letter',
      formData,
      config
    );
    dispatch({ type: ADMIN_BL_UPLOAD, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: ADMIN_FILE_UPLOAD_ERROR });
  }
};

//Upload Bank Letter//
export const adminProPicUpload = (formData) => async (dispatch) => {
  const config = {
    header: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    const res = await axios.post(
      '/api/admin/duster_upload/pro_pic',
      formData,
      config
    );
    dispatch({ type: ADMIN_PP_UPLOAD, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: ADMIN_FILE_UPLOAD_ERROR });
  }
};

//Upload Bank Letter//
export const adminPOAUpload = (formData) => async (dispatch) => {
  const config = {
    header: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    const res = await axios.post(
      '/api/admin/duster_upload/proof_address',
      formData,
      config
    );
    dispatch({ type: ADMIN_POA_UPLOAD, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: ADMIN_FILE_UPLOAD_ERROR });
  }
};

export const adminECUpload = (formData) => async (dispatch) => {
  const config = {
    header: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    const res = await axios.post(
      '/api/admin/duster_upload/employment',
      formData,
      config
    );
    dispatch({ type: ADMIN_EC_UPLOAD, payload: res.data });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: ADMIN_FILE_UPLOAD_ERROR });
  }
};

//Delete Image//
export const adminDeleteImage = (public_id) => async (dispatch) => {
  try {
    await axios.delete(`/api/admin/duster_upload/delete_upload/${public_id}`);
    setNote('This image has been deleted', 'warning');
  } catch (error) {
    console.error(error.message);
  }
};
