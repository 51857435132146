import React from 'react';
import { ListGroup } from 'react-bootstrap';

const AddressItem = ({ userAdr }) => {
  return (
    <div>
      <ListGroup.Item>{userAdr.text}</ListGroup.Item>
    </div>
  );
};

export default AddressItem;
