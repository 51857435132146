import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Spinner,
  Col,
  Card,
  Alert,
  ListGroup,
  Button,
  Modal,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  GetUser,
  GetUserAddress,
  userOrders,
  deleteUser,
  pauseUser,
  updateClient,
} from '../../../../actions/admin/admin_user';
import { userRate } from '../../../../actions/admin/main';
import { postcodeFinder } from '../../../../actions/user';
import { setNote } from '../../../../actions/note';
import OrderItem from './OrderItem';
import AddressItem from './AddressItem';
import Monthly from './Monthly';
import CustomRateCard from './CustomRateCard';
import EditRateCard from './EditRateCard';
import EditUser from './EditUser';
import Coupon from './Coupon';

const AdminUser = ({
  GetUser,
  GetUserAddress,
  match,
  admin_users: { user, orders, address },
  deleteUser,
  userOrders,
  pauseUser,
  note,
  userRate,
  postcodeFinder,
  setNote,
  updateClient,
}) => {
  const [nte, setMsg] = useState(note);
  const [month, SetMonth] = useState(false);
  const [custom, SetCustom] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [lastName, setLastname] = useState(null);
  const [email, setEmail] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [code, setCode] = useState(null);
  const [userNumber, setUserNumber] = useState(null);
  const [blocked, setBlockedUser] = useState(null);
  const [usrOrders, setUserOrders] = useState([]);
  const [userAddresses, setUserAddresses] = useState([]);

  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let blockBtn = useRef();

  useEffect(() => {
    if (custom === true) {
      userRate(userId);
    }
  }, [custom, userRate, userId]);

  useEffect(() => {
    if (note && blockBtn.current) {
      GetUser(match.params.userId);
      setMsg(note);
      blockBtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, nte, match, GetUser]);

  useEffect(() => {
    GetUser(match.params.userId);
    userOrders(match.params.userId);
    GetUserAddress(match.params.userId);
  }, [GetUser, match, userOrders, GetUserAddress]);

  useEffect(() => {
    if (user && orders && address) {
      setUserId(user._id);
      setBlockedUser(user.blocked);
      setUserAddress(user.address);
      setCode(user.code);
      setUserNumber(user.cellphone);
      setUserOrders(orders);
      setUserAddresses(address);
      setEmail(user.local.email);
      setCurrentUser(user.local.name);
      setLastname(user.local.surname);
      if (user.monthlyAcc === true) {
        SetMonth(true);
      } else {
        SetMonth(false);
      }
      if (user.customRate === true) {
        SetCustom(true);
      } else {
        SetCustom(false);
      }
    }
  }, [user, orders, address, setCurrentUser]);

  const trashUser = () => {
    deleteUser(userId);
  };

  const blockUser = () => {
    pauseUser({ userId });
    if (blockBtn.current) {
      blockBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  if (user === null || orders === null || address === null) {
    return <Spinner animation="grow" variant="primary" />;
  }
  return (
    <div className="container" align="center">
      <Row>
        <Col>
          <Card style={{ width: '20rem' }}>
            <Card.Body>
              <span style={{ fontSize: '4em' }} className="float-left">
                <i className="fas fa-user"></i>
              </span>
              <Card.Title>
                Name: {currentUser} {lastName}
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Manage User
              </Card.Subtitle>
              <Card.Text>
                <Col>
                  {blocked ? (
                    <Button
                      variant="outline-success mr-2 mt-2"
                      onClick={blockUser}
                      ref={blockBtn}
                      size="sm"
                    >
                      Un-block <i className="fas fa-pause"></i>
                    </Button>
                  ) : (
                    <Button
                      variant="outline-warning mr-2 mt-2"
                      onClick={blockUser}
                      ref={blockBtn}
                      size="sm"
                    >
                      Block <i className="fas fa-pause"></i>
                    </Button>
                  )}
                  <Button
                    variant="outline-danger mt-2"
                    onClick={handleShow}
                    size="sm"
                  >
                    Delete <i className="fas fa-trash"></i>
                  </Button>
                  <EditUser
                    user={user}
                    postcodeFinder={postcodeFinder}
                    setNote={setNote}
                    updateClient={updateClient}
                    note={note}
                  />
                </Col>
              </Card.Text>
              <ListGroup.Item>Email: {email}</ListGroup.Item>
              <ListGroup.Item>Post Code: {code}</ListGroup.Item>
              <ListGroup.Item>Address: {userAddress}</ListGroup.Item>
              {userNumber && (
                <ListGroup.Item>Cellphone: {userNumber}</ListGroup.Item>
              )}
            </Card.Body>
            <Coupon currentUser={currentUser} userId={userId} />
            <Monthly currentUser={currentUser} month={month} userId={userId} />
            {custom === true ? (
              <EditRateCard
                currentUser={currentUser}
                month={month}
                custom={custom}
              />
            ) : (
              <CustomRateCard
                currentUser={currentUser}
                month={month}
                userId={userId}
                custom={custom}
              />
            )}
          </Card>
        </Col>
        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Header
              style={{ backgroundColor: '#b0e0e6' }}
              className="text-white"
            >
              <b>User Addresses</b>
            </Card.Header>
            <ListGroup variant="flush">
              {userAddresses.length === 0 ? (
                <ListGroup.Item>Customer only has 1 addess</ListGroup.Item>
              ) : (
                userAddresses.map((userAdr) => (
                  <AddressItem key={userAdr._id} userAdr={userAdr} />
                ))
              )}
            </ListGroup>
          </Card>
        </Col>

        <Col>
          <Card style={{ width: '18rem' }}>
            <Card.Header
              style={{ backgroundColor: 'green' }}
              className="text-white"
            >
              <b>Order History</b>
            </Card.Header>
            <ListGroup variant="flush">
              {usrOrders.length === 0 ? (
                <ListGroup.Item>Customer has no orders</ListGroup.Item>
              ) : (
                usrOrders.map((order) => (
                  <OrderItem key={order._id} order={order} />
                ))
              )}
            </ListGroup>
          </Card>
        </Col>
        <Modal align="center" show={show} onHide={handleClose}>
          <Alert variant="danger">
            <h4 className="text-secondary">
              You are about to delete {currentUser}
            </h4>
          </Alert>
          <Modal.Body>
            <h5 className="text-danger">
              <u> You cannot undo this! </u>
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={trashUser}>
              Delete User
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  admin_users: state.admin_users,
  note: state.note,
});

export default connect(mapStateToProps, {
  GetUser,
  GetUserAddress,
  deleteUser,
  userOrders,
  pauseUser,
  userRate,
  postcodeFinder,
  setNote,
  updateClient,
})(AdminUser);
