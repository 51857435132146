import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { setNote } from '../../../actions/note';
import { resetPassword } from '../../../actions/team/';
import Bottom from '../../Layout/Bottom';

const ResetPassword = ({ setNote, resetPassword, note, match }) => {
  const [nte, setMsg] = useState(note);
  const [token, setToken] = useState('');
  useEffect(() => {
    setToken(match.params.token);
  }, [token, setToken, match.params.token]);

  const [formData, setFormData] = useState({
    newPassword: '',
    password2: '',
  });

  let btn = useRef();

  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, nte]);

  const { newPassword, password2 } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== password2) {
      setNote('Passwords Do Not Match', 'danger');
    } else {
      resetPassword({ newPassword, token });

      if (btn.current) {
        btn.current.setAttribute('disabled', 'disabled');
      }
    }
  };

  return (
    <div>
      <div className="container">
        <Form onSubmit={onSubmit}>
          <Form.Label>
            <h4>Password Reset Page:</h4>
          </Form.Label>

          <Form.Group controlId="formPassword">
            <Form.Control
              type="password"
              name="newPassword"
              value={newPassword}
              placeholder="Please Type Your New Password here:"
              onChange={(e) => onChange(e)}
            />
          </Form.Group>
          <Form.Group controlId="formPasswordConfirm">
            <Form.Control
              type="password"
              name="password2"
              value={password2}
              placeholder="Please Confirm New Password:"
              onChange={(e) => onChange(e)}
            />
          </Form.Group>
          <Button variant="primary btn btn-block" type="submit" ref={btn}>
            Reset Password <i className="fas fa-backward ml-2"></i>
          </Button>
        </Form>
      </div>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});
export default connect(mapStateToProps, { resetPassword, setNote })(
  ResetPassword
);
