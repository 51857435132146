import React from 'react';
import { Link } from 'react-router-dom';
import { Button, ListGroupItem } from 'react-bootstrap';
import moment from 'moment';

const InvoiceItem = ({ invoice }) => {
  return (
    <ListGroupItem as="li" className="d-flex justify-content-between ">
      <b>Invoice Number:</b> {invoice.invoice} <b>Date:</b>
      {moment(invoice.timestamp).format('MMMM Do YYYY')}
      <Button
        className="float-right"
        size="sm"
        as={Link}
        to={`/admin/admin-accounts/order-invoice/${invoice.order}`}
      >
        View Invoice
      </Button>
    </ListGroupItem>
  );
};

export default InvoiceItem;
