import {
  SET_DATE,
  BEDROOM,
  BEDTIME,
  BATHROOM,
  BATHTIME,
  BBHOURS,
  BBTOTAL,
  EXHOURS,
  EXTOTAL,
  ADD_EXTRA,
  REMOVE_EXTRA,
  INV_TOTAL,
  OVER_NINE,
  OVER_ALLHOURS,
  SET_SCHEDULE,
  WEEKDAYS,
  REG_INV_TOTAL,
  INITIAL_DATE,
  CREATE_SCHEDULE,
  CLEAR_SCHEDULE,
  REGULAR,
  BIG_RESET,
  NITTY_GRITTY,
  ORDER_ADDRESS,
  ORDER_COORDINATES,
  ORDER_POSTCODE,
  CHANGE_HOURS,
  WHY_LESS,
  ORDER_COMPLEX,
  ADD_ICON_EXTRA,
  REMOVE_ICON_EXTRA,
} from '../types';
import moment from 'moment';

///////////////////////////
//Set the booking address//
///////////////////////////
export const setBookingAddress =
  ({ address, coordinates, complex, postCode }) =>
  (dispatch) => {
    dispatch({ type: ORDER_ADDRESS, payload: address });
    dispatch({ type: ORDER_COORDINATES, payload: coordinates });
    dispatch({ type: ORDER_COMPLEX, payload: complex });
    dispatch({ type: ORDER_POSTCODE, payload: postCode });
    try {
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////
//Handle the bed//
//////////////////
export const handleBed = (newBeds) => (dispatch) => {
  dispatch({ type: BEDROOM, payload: newBeds });
  let bedtime = 0;
  try {
    if (newBeds === 0) bedtime = 0;
    if (newBeds === 1) bedtime = 30;
    if (newBeds === 2) bedtime = 60;
    if (newBeds === 3) bedtime = 120;
    if (newBeds === 4) bedtime = 180;
    if (newBeds === 5) bedtime = 180;
    if (newBeds === 6) bedtime = 180;
    dispatch({ type: BEDTIME, payload: bedtime });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////
//Handle the bath//
///////////////////

export const handleBath = (newBath) => (dispatch) => {
  dispatch({ type: BATHROOM, payload: newBath });
  let bathtime = 0;
  try {
    if (newBath === 0) bathtime = 0;
    if (newBath === 1) bathtime = 30;
    if (newBath === 2) bathtime = 30;
    if (newBath === 3) bathtime = 45;
    if (newBath === 4) bathtime = 60;
    dispatch({ type: BATHTIME, payload: bathtime });
  } catch (error) {
    console.log(error.messge);
  }
};

///////////////////////////
//Calculate  bed and bath//
///////////////////////////

export const calcBedBath = (bedroomTime, bathroomTime) => (dispatch) => {
  try {
    let totalTime = bedroomTime + bathroomTime;
    let hours = (totalTime / 60).toFixed(2);
    dispatch({ type: BBHOURS, payload: hours });

    let bedBathTotal = (hours * 17).toFixed(2);
    dispatch({ type: BBTOTAL, payload: bedBathTotal });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////
//Remove extras//
/////////////////
export const removeExtra = (extraID) => (dispatch) => {
  try {
    dispatch({ type: REMOVE_EXTRA, payload: extraID });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////
///Add Extra///
//////////////////
export const addExtra = (extraID) => (dispatch) => {
  try {
    dispatch({ type: ADD_EXTRA, payload: extraID });
  } catch (error) {
    console.error(error.message);
  }
};

//Add Icon Extra//
export const addIconExtra = (id) => (dispatch) => {
  dispatch({ type: ADD_ICON_EXTRA, payload: id });
};
//Remove Icon Extra//
export const removeIconExtra = (id) => (dispatch) => {
  dispatch({ type: REMOVE_ICON_EXTRA, payload: id });
};

//Calculate icon extras//
export const calcIconExtras = (totalMinutes) => (dispatch) => {
  let hours = (totalMinutes / 60).toFixed(2);
  let extraTotal = (hours * 53).toFixed(2);
  dispatch({ type: EXHOURS, payload: hours });
  dispatch({ type: EXTOTAL, payload: extraTotal });
};
////////////////////////
//Calculate the extras//
////////////////////////
export const calcExtras = (totalMinutes) => (dispatch) => {
  try {
    let hours = (totalMinutes / 60).toFixed(2);
    let extraTotal = (hours * 53).toFixed(2);
    dispatch({ type: EXHOURS, payload: hours });
    dispatch({ type: EXTOTAL, payload: extraTotal });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////
/////Set the date////
/////////////////////
export const setDate = (orderTime) => (dispatch) => {
  if (orderTime) {
    const chosenD = moment.utc(orderTime).format();
    try {
      dispatch({ type: SET_DATE, payload: chosenD });
    } catch (error) {
      console.error(error.message);
    }
  } else {
    dispatch({ type: SET_DATE, payload: orderTime });
  }
};

////////////////////
///Clear Schedule///
////////////////////
export const clearSchedule = (data) => (dispatch) => {
  try {
    dispatch({ type: CLEAR_SCHEDULE, payload: data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////
//On Which Days//
/////////////////
export const SetWeekDays = (weekdays) => (dispatch) => {
  try {
    dispatch({ type: WEEKDAYS, payload: weekdays });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////
///Set Schedule///
//////////////////
export const SetSchedule = (schedule) => (dispatch) => {
  try {
    dispatch({ type: SET_SCHEDULE, payload: schedule });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////
//Set Regular///
////////////////
export const SetRegular = (data) => (dispatch) => {
  try {
    dispatch({ type: REGULAR, payload: data });
  } catch (error) {
    console.error(error.message);
  }
};

export const repeatCalender =
  ({ daysLeft, weekdays, invoiceHours }) =>
  async (dispatch) => {
    try {
      let data = [];
      const weekly = daysLeft.filter((dl, index) => {
        let string = dl.toString().split(' ');
        const match = weekdays.filter((dow, index) => {
          if (string[0] === dow) {
            return dl;
          }
        });
        if (match.length > 0) {
          return dl;
        }
      });

      function createSchedule(weekly, data) {
        weekly.forEach((w) => {
          let hello = moment.utc(w).format();
          let goodbye = moment.utc(hello).add(invoiceHours, 'hours').format();

          let title = 'Cleaning';
          let start = hello;
          let end = goodbye;
          let event = { title, start, end };
          data.push(event);
        });
      }

      createSchedule(weekly, data);
      dispatch({ type: CREATE_SCHEDULE, payload: data });
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////////////
/////Fulltime cleaner/////
//////////////////////////
export const fullTimeCleaner =
  ({ daysLeft, invoiceHours }) =>
  (dispatch) => {
    try {
      let data = [];
      const weekly = daysLeft.filter((dl, index) => {
        let string = dl.toString().split(' ');
        let DaysOfTheWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
        const match = DaysOfTheWeek.filter((dow, index) => {
          if (string[0] === dow) {
            return dl;
          }
        });
        if (match.length > 0) {
          return dl;
        }
      });

      function createSchedule(weekly, data) {
        weekly.forEach((w) => {
          let title = 'Cleaning';
          let start = w;
          let end = moment(start).add(invoiceHours, 'hours').format('llll');
          let event = { title, start, end };
          data.push(event);
        });
      }
      createSchedule(weekly, data);
      dispatch({ type: CREATE_SCHEDULE, payload: data });
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////
///Initial Date///
//////////////////
export const initalDate = (schedule) => (dispatch) => {
  try {
    let a = schedule.sort(function (a, b) {
      return a.start - b.start;
    });
    let date = a[0].start;
    dispatch({ type: INITIAL_DATE, payload: date });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////////
///Remove From Schedule//
/////////////////////////
export const removeSchedule =
  ({ remove, schedule }) =>
  (dispatch) => {
    try {
      let newEvents = schedule.filter((s, index) => {
        let string = s.start.toString().split(' ');
        if (string[0] !== remove) {
          return s;
        }
      });
      dispatch({ type: CREATE_SCHEDULE, payload: newEvents });
    } catch (error) {
      console.error('Error Removing Schedule', error.message);
    }
  };

//////////////////
//The Nity Grity//
//////////////////
export const theNityGrity = (nityGrity) => async (dispatch) => {
  try {
    dispatch({ type: NITTY_GRITTY, payload: nityGrity });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////
//////Rate Card////////
///////////////////////
export const rateCard =
  ({ invoiceHours, mother }) =>
  (dispatch) => {
    const {
      two,
      twop,
      three,
      threep,
      four,
      fourp,
      five,
      fivep,
      six,
      sixp,
      seven,
      sevenp,
      eight,
    } = mother;
    try {
      //The Rate Card//
      if (invoiceHours <= 2) {
        let price = 2 * two;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 2 });
        dispatch({ type: OVER_NINE, payload: false });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 2.5) {
        let price = 2.5 * twop;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 2.5 });
        dispatch({ type: OVER_NINE, payload: true });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours <= 3) {
        let price = 3 * three;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 3 });
        dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours <= 3.5) {
        let price = 3.5 * threep;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 3.5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours <= 4) {
        let price = 4 * four;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 4 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 4.5) {
        let price = 4.5 * fourp;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 4.5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 5) {
        let price = 5 * five;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 5.5) {
        let price = 5.5 * fivep;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 5.5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 6) {
        let price = 6 * six;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 6 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours <= 6.5) {
        let price = 6.5 * sixp;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 6.5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 7) {
        let price = 7 * seven;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 7 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 7.5) {
        let price = 7.5 * sevenp;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 7.5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 8) {
        let price = 8 * eight;

        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 8 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

//////////////////////
/////Custom Rate//////
//////////////////////
export const customRate =
  ({ myRateCard, invoiceHours }) =>
  (dispatch) => {
    try {
      const {
        two,
        twop,
        three,
        threep,
        four,
        fourp,
        five,
        fivep,
        six,
        sixp,
        seven,
        sevenp,
        eight,
      } = myRateCard;

      if (invoiceHours <= 2) {
        let price = 2 * two;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 2 });
        dispatch({ type: OVER_NINE, payload: false });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 2.5) {
        let price = 2.5 * twop;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 2.5 });
        dispatch({ type: OVER_NINE, payload: true });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours <= 3) {
        let price = 3 * three;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 3 });
        dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours <= 3.5) {
        let price = 3.5 * threep;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 3.5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours <= 4) {
        let price = 4 * four;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 4 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 4.5) {
        let price = 4.5 * fourp;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 4.5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 5) {
        let price = 5 * five;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 5.5) {
        let price = 5.5 * fivep;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 5.5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 6) {
        let price = 6 * six;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 6 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours <= 6.5) {
        let price = 6.5 * sixp;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 6.5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 7) {
        let price = 7 * seven;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 7 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 7.5) {
        let price = 7.5 * sevenp;
        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 7.5 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 8) {
        let price = 8 * eight;

        let invpri = price.toFixed(2);
        dispatch({ type: OVER_ALLHOURS, payload: 8 });
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

///////////////////////////////
//Client has changed the rate//
///////////////////////////////
export const changeRate =
  ({ invoiceHours, mother }) =>
  (dispatch) => {
    const {
      two,
      twop,
      three,
      threep,
      four,
      fourp,
      five,
      fivep,
      six,
      sixp,
      seven,
      sevenp,
      eight,
    } = mother;
    try {
      let jam = parseInt(invoiceHours);
      dispatch({ type: CHANGE_HOURS, payload: jam });
      if (invoiceHours <= 2) {
        let price = 2 * two;
        let invpri = price.toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 2.5) {
        let price = 2.5 * twop;
        let invpri = price.toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours <= 3) {
        let price = 3 * three;
        let invpri = price.toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours <= 3.5) {
        let price = 3.5 * threep;
        let invpri = price.toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours <= 4) {
        let price = 4 * four;
        let invpri = price.toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 4.5) {
        let price = 4.5 * fourp;
        let invpri = price.toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 5) {
        let price = 5 * five;
        let invpri = price.toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 5.5) {
        let price = 5.5 * fivep;
        let invpri = price.toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 6) {
        let price = 6 * six;
        let invpri = price.toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
      if (invoiceHours <= 6.5) {
        let price = 6.5 * sixp;
        let invpri = price.toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 7) {
        let price = 7 * seven;
        let invpri = price.toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 7.5) {
        let price = 7.5 * sevenp;
        let invpri = price.toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }

      if (invoiceHours <= 8) {
        let price = 8 * eight;
        let invpri = price.toFixed(2);
        return dispatch({ type: INV_TOTAL, payload: invpri });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

////////////////////////
//Why are you changing//
////////////////////////
export const whyChangeHours = (whyLess) => (dispatch) => {
  try {
    dispatch({ type: WHY_LESS, payload: whyLess });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////
//Regular Rate Card///
//////////////////////
export const regularRate = (amount) => (dispatch) => {
  try {
    dispatch({ type: REG_INV_TOTAL, payload: amount });
  } catch (error) {
    console.error(error.message);
  }
};

export const resetBooking = () => (dispatch) => {
  console.log('lets reset!');
  dispatch({ type: BIG_RESET });
};
