import React, { useEffect, useState, useRef } from 'react';
import {
  Spinner,
  Row,
  Col,
  Alert,
  ListGroup,
  Card,
  Button,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { checkPayment, paymentUrl } from '../../../../actions/user/payment';
import moment from 'moment';
import ExtraItem from './ExtraItem';
import Schedule from '../../Profile/Schedule';
import Bottom from '../../../Layout/Bottom';

const PaymentResponse = ({
  match,
  checkPayment,
  order,
  paymentUrl,
  stripeUrl,
}) => {
  const [orderId, SetOrderId] = useState('');
  const [mrx, SetMrx] = useState([]);
  const [it, SetIT] = useState('');
  const [bed, SetBed] = useState('');
  const [bath, SetBath] = useState('');
  const [inv, SetIV] = useState('');
  const [invoiceHours, SetIH] = useState('');
  const [code, SetCode] = useState('');
  const [address, SetAdress] = useState('');
  const [cleans, SetCleans] = useState('');
  const [weekdays, SetWeekDays] = useState([]);
  const [schedule, SetSchedule] = useState([]);
  const [payText, setPayText] = useState('Make payment');
  useEffect(() => {
    checkPayment(match.params.orderId);
  }, [match, checkPayment]);

  useEffect(() => {
    if (stripeUrl) {
      window.location.href = stripeUrl;
    }
  }, [stripeUrl]);

  useEffect(() => {
    if (order) {
      SetOrderId(order._id);
      SetMrx(order.mrx);
      SetIT(order.orderTime);
      SetBed(order.bedroom);
      SetBath(order.bathroom);
      SetIV(order.invoice.toFixed(2));
      SetIH(order.invoiceHours);
      SetCode(order.code);
      SetAdress(order.address);
      SetCleans(order.cleans);
      SetWeekDays(order.weekdays);
      SetSchedule(order.schedule);
    }
  }, [order]);

  let stripBtn = useRef();

  if (order === null) return <Spinner animation="grow" variant="primary" />;

  const downloadCode = () => {
    const link = document.createElement('a');
    link.href = code;
    link.setAttribute('download', 'DDCode.png');
    document.body.appendChild(link);
    link.click();
  };

  const invoiceTime = moment.utc(it).format('ddd MMM D, h:mm a');

  const tryAgain = () => {
    paymentUrl(orderId);
    setPayText('Processing payment...');
    if (stripBtn.current) {
      stripBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  console.log(order);

  return (
    <div>
      <div className="container">
        <Row>
          <Col className="mt-3">
            <Alert variant="primary">
              <h5>Thank you for your order</h5>
            </Alert>
            <ListGroup variant="flush" className="mt-2">
              <ListGroup.Item variant="info">
                {invoiceHours}hr Order Summary!
                <p className="float-right">Date: {invoiceTime}</p>
              </ListGroup.Item>
              <ListGroup.Item>
                {bed} Bedroom/s {bath} Bathroom/s
              </ListGroup.Item>
              <ListGroup.Item align="center">
                <div className="float-left">Your Extras;</div>
                {mrx.length === 0 ? (
                  <span>No Extras</span>
                ) : (
                  mrx.map((x) => {
                    return (
                      <div
                        className="float-right"
                        key={x.id}
                        style={{ width: '20rem' }}
                      >
                        <ExtraItem x={x} />
                      </div>
                    );
                  })
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                Address: <p className="float-right">{address}</p>
              </ListGroup.Item>
              {weekdays && weekdays.length > 0 && (
                <>
                  <ListGroup.Item>
                    <b>
                      The order has {cleans} cleans weekly on
                      {weekdays &&
                        weekdays.map((w) => (
                          <div className="float-right">{w}</div>
                        ))}
                    </b>
                  </ListGroup.Item>
                  <ListGroup.Item className="text-center">
                    On these dates:
                    <hr style={{ width: '10rem' }} />
                    {schedule &&
                      schedule.map((s) => <Schedule key={s.start} s={s} />)}
                  </ListGroup.Item>
                </>
              )}
              <ListGroup.Item variant="warning">
                Invoice Total; <b className="float-right">£{inv}</b>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col align="center">
            {order.code ? (
              <Card style={{ width: '15rem' }}>
                <Card.Img variant="top" src={code} />
                <Card.Body>
                  <Card.Title className="text-center">
                    Please download the qr code
                  </Card.Title>
                  <Button variant="primary" onClick={downloadCode}>
                    Download!
                  </Button>
                </Card.Body>
              </Card>
            ) : (
              <Card style={{ width: '20rem' }}>
                <Alert variant="danger">Your payment failed!</Alert>
                <Card.Body>
                  <Card.Title className="text-center">
                    <Alert variant="warning">Please try pay again</Alert>
                  </Card.Title>
                  <Button
                    variant="success"
                    ref={stripBtn}
                    onClick={tryAgain}
                    block
                  >
                    {payText} £{inv}
                    <i className="fas fa-cash-register float-right"></i>
                  </Button>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </div>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  order: state.orders.order,
  stripeUrl: state.payment.paymentUrl,
});

export default connect(mapStateToProps, { checkPayment, paymentUrl })(
  PaymentResponse
);
