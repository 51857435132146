import { CLEAR_PAY, GET_ORDER, STRIPE_URL, GET_RE_ORDER } from '../types';
import axios from 'axios';

export const paymentUrl = (orderId) => async (dispatch) => {
  dispatch({ type: CLEAR_PAY });
  try {
    const res = await axios.post(`/api/user/payments/payment-url/${orderId}`);
    dispatch({ type: STRIPE_URL, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const checkPayment = (orderId) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/user/payments/check-payment/${orderId}`);
    dispatch({ type: GET_ORDER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const paymentReUrl = (orderId) => async (dispatch) => {
  try {
    const res = await axios.post(
      `/api/user/payments/re-payment-url/${orderId}`
    );
    dispatch({ type: STRIPE_URL, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const checkRePayment = (orderId) => async (dispatch) => {
  try {
    const res = await axios.put(
      `/api/user/payments/check-re-payment/${orderId}`
    );
    dispatch({ type: GET_RE_ORDER, payload: res.data });
  } catch (error) {}
};
