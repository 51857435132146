//Animation//
export const HOW_WE_DO = 'HOW_WE_DO';
export const CONTACT = 'CONTACT';
export const CLEAR_ANIMATION = 'CLEAR_ANIMATION';

//notifications//
export const SET_NOTE = 'SET_NOTE';
export const REMOVE_NOTE = 'REMOVE_NOTE';

//team actions//
export const MY_INT = 'MY_INT';
export const APPLICATION = 'APPLICATION';
export const TEAM_LOADED = 'TEAM_LOADED';
export const TEAM_REGISTERED = 'TEAM_REGISTERED';
export const TEAM_LOGIN = 'TEAM_LOGIN';
export const TEAM_FAIL = 'TEAM_FAIL';
export const TEAM_LOGOUT = 'TEAM_LOGOUT';
export const CLEAR_TEAM = 'CLEAR_TEAM';
export const AVAILABLE_WORK = 'AVAILABLE_WORK';
export const AVAILABLE_RE_WORK = 'AVAILABLE_RE_WORK';
export const MY_SCHEDULE = 'MY_SCHEDULE';
export const CLEAR_WORK = 'CLEAR_WORK';
export const MY_BOOKING = 'MY_BOOKING';
export const MY_RE_BOOKING = 'MY_RE_BOOKING';
export const CLEAR_BOOKING = 'CLEAR_BOOKING';
export const CLEAR_REBOOKING = 'CLEAR_REBOOKING';
export const SET_DISTANCE = 'SET_DISTANCE';
export const MY_WORK = 'MY_WORK';
export const MY_RE_WORK = 'MY_RE_WORK';
export const HR_APP = 'HR_APP';
export const INTERVIEW = 'INTERVIEW';
export const CLEAR_APP = 'CLEAR_APP';

//user actions//
export const USER_LOADED = 'USER_LOADED';
export const CLEAR_USER = ' CLEAR_USER';
export const USER_REGISTERED = 'USER_REGISTERED';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_FAIL = 'USER_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const MY_COUPONS = 'MY_COUPONS';
export const CLEAR_COUPONS = 'CLEAR_COUPONS';
export const CLEAR_COUPON = 'CLEAR_COUPON';
export const COUPON = 'COUPON';
export const COUPON_TOTAL = 'COUPON_TOTAL';
export const MY_ADDRESSES = 'MY_ADDRESSES';
export const THIS_ADR = 'THIS_ADR';
export const CLEAR_ADDRESSES = 'CLEAR_ADDRESSES';
export const MY_RATE_CARD = 'MY_RATE_CARD';
export const MOTHER_CARD = 'MOTHER_CARD';
export const MY_ORDER_INV = 'MY_ORDER_INV';
export const MY_INVOICE = 'MY_INVOICE';

//Booking Actions//
export const ORDER_ADDRESS = 'ORDER_ADDRESS';
export const ORDER_COORDINATES = 'ORDER_COORDINATES';
export const ORDER_POSTCODE = 'ORDER_POSTCODE';
export const ORDER_COMPLEX = 'ORDER_COMPLEX';
export const CLEAR_PAY = 'CLEAR_PAY';
export const STRIPE_URL = 'STRIPE_URL';
export const DUSTERS_NEAR_ME = 'DUSTERS_NEAR_ME';
export const SET_DATE = 'SET_DATE';
export const BEDTIME = 'BEDTIME';
export const BEDROOM = 'BEDROOM';
export const BATHTIME = 'BATHTIME';
export const BATHROOM = 'BATHROOM';
export const BBHOURS = 'BBHOURS';
export const BBTOTAL = 'BBTOTAL';
export const ADD_EXTRA = 'ADD_EXTRA';
export const REMOVE_EXTRA = 'REMOVE_EXTRA';
export const ADD_ICON_EXTRA = 'ADD_ICON_EXTRA';
export const REMOVE_ICON_EXTRA = 'REMOVE_ICON_EXTRA';
export const EXHOURS = 'EXHOURS';
export const EXTOTAL = 'EXTOTAL';
export const SET_SCHEDULE = 'SET_SCHEDULE';
export const WEEKDAYS = 'WEEKDAYS';
export const OVER_NINE = 'OVER_NINE';
export const OVER_ALLHOURS = 'OVER_ALLHOURS';
export const DEFAULT_FREQUENCY = 'DEFAULT_FREQUENCY';
export const INV_TOTAL = 'INV_TOTAL';
export const REG_INV_TOTAL = 'REG_INV_TOTAL';
export const INITIAL_DATE = 'INITIAL_DATE';
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const CLEAR_SCHEDULE = 'CLEAR_SCHEDULE';
export const REGULAR = 'REGULAR';
export const NITTY_GRITTY = 'NITTY_GRITTY';
export const CHANGE_HOURS = 'CHANGE_HOURS';
export const WHY_LESS = 'WHY_LESS';
export const BIG_RESET = 'BIG_RESET';

//Order Actions//
export const GET_ORDER = 'GET_ORDER';
export const ASSIGNED_DUSTER = 'ASSIGNED_DUSTER';
export const GET_RE_ORDER = 'GET_RE_ORDER';
export const GET_ORDERS = 'GET_ORDERS';
export const ALL_ORDERS = 'ALL_ORDERS';
export const ALL_RE_ORDERS = 'ALL_RE_ORDERS';
export const GET_RE_ORDERS = 'GET_RE_ORDERS';
export const PAID_ORDERS = 'PAID_ORDERS';
export const UNPAID_ORDERS = 'UNPAID_ORDERS';
export const UNPAID_REORDERS = 'UNPAID_REORDERS';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const NO_CLEANER = 'NO_CLEANER';
export const CLEANER_ALLOCATED = 'CLEANER_ALLOCATED';
export const RECLEANER_ALLOCATED = 'RECLEANER_ALLOCATED';
export const NO_RECLEANER = 'NO_RECLEANER';
export const COMPLETED_ORDERS = 'COMPLETED_ORDERS';
export const COMPLETED_REORDERS = 'COMPLETED_REORDERS';
export const ACTIVE_ORDERS = 'ACTIVE_ORDERS';
export const ACTIVE_REORDERS = 'ACTIVE_REORDERS';
export const REPEAT_ORDER = 'REPEAT_ORDER';
export const GET_OZOW_ORDER = 'GET_OZOW_ORDER';
export const GET_REPEACH_ORDER = 'GET_REPEACH_ORDER';
export const ORDER_COMMENTS = 'ORDER_COMMENTS';
export const RE_ORDER_COMMENTS = 'RE_ORDER_COMMENTS';
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';

//Review Actions//
export const DUSTER_REVIEW = 'DUSTER_REVIEW';
export const USER_REVIEW = 'USER_REVIEW';

//Admin login actions//
export const ADMIN_LOADED = 'ADMIN_LOADED';
export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const ADMIN_FAIL = 'ADMIN_FAIL';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';

//Main Admin Actions//
export const ALL_DUSTERS = 'ALL_DUSTERS';
export const ALL_USERS = 'ALL_USERS';
export const ALL_ORDER_TOTAL = 'ALL_ORDER_TOTAL';
export const THE_MOTHER_CARD = 'THE_MOTHER_CARD';
export const ALL_INVOICES = 'ALL_INVOICES';
export const ORDER_INVOICE = 'ORDER_INVOICE';
export const CLEAR_ORDER_INVOICE = 'CLEAR_ORDER_INVOICE';
export const INVOICE = 'INVOICE';
export const PAYING_DUSTERS = 'PAYING_DUSTERS';
export const PAYSLIP = 'PAYSLIP';
export const CLEAR_PAYSLIP = 'CLEAR_PAYSLIP';
export const TOTAL_HOURS = 'TOTAL_HOURS';
export const TOTAL_REPEAT_HOURS = 'TOTAL_REPEAT_HOURS';
export const MONTH_END_ORDERS = 'MONTH_END_ORDERS';
export const CUSTOM_PAYCYLE = 'CUSTOM_PAYCYLE';
export const RECURRING_ORDER_ = 'RECURRING_ORDER_';

//Admin user management//
export const SET_USERS = 'SET_USERS';
export const CLEAR_ADMIN_USER = 'CLEAR_ADMIN_USER';
export const SET_USER = 'SET_USER';
export const USER_ORDERS = 'USER_ORDERS';
export const CLEAR_USERS = 'CLEAR_USERS';
export const RATE_CARD = 'RATE_CARD';
export const CLEAR_RATE = 'CLEAR_RATE';
export const FACEBOOK_USERS = 'FACEBOOK_USERS';
export const GOOGLE_USERS = 'GOOGLE_USERS';
export const LOCAL_USERS = 'LOCAL_USERS';

//Admin Order Actions//
export const ALLOCATED_ORDERS = 'ALLOCATED_ORDERS';
export const ALLOCATED_ORDERS_NUM = 'ALLOCATED_ORDERS_NUM';
export const ADMIN_ORDERS = 'ADMIN_ORDERS';
export const ADMIN_REORDERS = 'ADMIN_REORDERS';
export const NOT_BOOKED = 'NOT_BOOKED';
export const NOT_BOOKED_NUM = 'NOT_BOOKED_NUM';
export const RUNNING_LATE_NUM = 'RUNNING_LATE_NUM';
export const RUNNING_LATE = 'RUNNING_LATE';
export const ORDER_IN_PROGRESS_NUM = 'ORDER_IN_PROGRESS_NUM';
export const ORDER_IN_PROGRESS = 'ORDER_IN_PROGRESS';
export const TODAYS_ORDERS = 'TODAY_ORDERS';
export const TODAYS_ORDERS_NUM = 'TODAYS_ORDERS_NUM';
export const RE_NOT_BOOKED = 'RE_NOT_BOOKED';
export const RE_NOT_BOOKED_NUM = 'RE_NOT_BOOKED_NUM';
export const RE_IN_PROGRESS = 'RE_IN_PROGRESS';
export const RE_IN_PROGRESS_NUM = 'RE_IN_PROGRESS_NUM';
export const REPEAT_TODAY_NUM = 'REPEAT_TODAY_NUM';
export const REPEAT_TODAY = 'REPEAT_TODAY';
export const COMPLETE_ORDERS = 'COMPLETE_ORDERS';
export const COMPLETE_ORDERS_NUM = 'COMPLETE_ORDERS_NUM';
export const ADMIN_ORDER = 'ADMIN_ORDER';
export const AREA_CLEANERS = 'AREA_CLEANERS';
export const CLEAR_ADMIN_ORDER = 'CLEAR_ADMIN_ORDER';
export const MISSED_ORDERS = 'MISSED_ORDERS';
export const MISSED_ORDERS_NUM = 'MISSED_ORDERS_NUM';
export const REQUIRE_VALIDATION = 'REQUIRE_VALIDATION';
export const REQUIRE_VALIDATION_NUM = 'REQUIRE_VALIDATION_NUM';
export const MISSED_RE_ORDER = 'MISSED_RE_ORDER';
export const MISSED_RE_ORDERS_NUM = 'MISSED_RE_ORDERS_NUM';
export const ADMIN_REPEAT_ORDER = 'ADMIN_REPEAT_ORDER';
export const LATE_REORDERS_NUM = 'LATE_REORDERS_NUM';
export const RE_RUNNING_LATE = 'RE_RUNNING_LATE';
export const VALIDATE_DISTANCE = 'VALIDATE_DISTANCE';
export const REPEAT_VALIDATION_NUM = 'REPEAT_VALIDATION_NUM';
export const COMPLETE_RE_ORDERS = 'COMPLETE_RE_ORDER';
export const COMPLETE_RE_ORDERS_NUM = 'COMPLETE_RE_ORDERS_NUM';
export const REPEAT_VALIDATION = 'REPEAT_VALIDATION';
export const TO_COMPLETE = 'TO_COMPLETE';
export const TO_COMPLETE_NUM = 'TO_COMPLETE_NUM';
export const SWAP_CLEANERS = 'SWAP_CLEANERS';
export const CLEAR_SWAP_CLEANERS = 'CLEAR_SWAP_CLEANERS';
export const DUSTER_ON_BOOKING = 'DUSTER_ON_BOOKING';

//Admin team-member actions
export const TEAM_PROFILE = 'TEAM_PROFILE';
export const CLEAR_PROFILES = 'CLEAR_PROFILES';
export const CHECK_PROFILE = 'CHECK_PROFILE';
export const USER_ADDRESS = 'USER_ADDRESS';
export const CLEAR_MEMBER = 'CLEAR_MEMBER';
export const READY_TEAM = 'READY_TEAM';
export const READY_TEAM_NUMBER = 'READY_TEAM_NUMBER';
export const FETCH_SKILLS = 'FETCH_SKILLS';
export const CLEAR_SKILLS = 'CLEAR_SKILLS';
export const DUSTER_ORDERS = 'DUSTER_ORDERS';
export const WORKING_NUM = 'WORKING_NUM';
export const STAGE_ONE = 'STAGE_ONE';
export const STAGE_ONE_NUMBER = 'STAGE_ONE_NUMBER';
export const STAGE_TWO_NUMBER = 'STAGE_TWO_NUMBER';
export const STAGE_TWO = 'STAGE_TWO';
export const STAGE_THREE = 'STAGE_THREE';
export const STAGE_THREE_NUMBER = 'STAGE_THREE_NUMBER';
export const DUST_BUSTER = 'DUST_BUSTER';
export const DUSTER_BOOKINGS = 'DUSTER_BOOKINGS';
export const THE_BOOKING = 'THE_BOOKING';
export const CLEAR_THE_BOOKING = 'CLEAR_THE_BOOKING';
export const DUSTER_RE_ORDERS = 'DUSTER_RE_ORDERS';
export const CLEAR_DUSTERS = 'CLEAR_DUSTERS';
export const DUSTER_RATE = 'DUSTER_RATE';

//Admin team-admin actions
export const DELETE_ID = 'DELETE_ID';
export const DELETE_WP = 'DELETE_WP';
export const DELETE_BL = 'DELETE_BL';
export const DELETE_CL = 'DELETE_CL';
export const INTERVIEW_CALENDER = 'INTERVIEW_CALENDER';

//File Upload//
export const ID_UPLOAD = 'ID_UPLOAD';
export const WP_UPLOAD = 'WP_UPLOAD';
export const BL_UPLOAD = 'BL_UPLOAD';
export const PP_UPLOAD = 'PP_UPLOAD';
export const PA_UPLOAD = 'PA_UPLOAD';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';
export const CLEAR_FILE = 'CLEAR_FILE';

//ADMIN File Upload//
export const ADMIN_ID_UPLOAD = 'ADMIN_ID_UPLOAD';
export const ADMIN_WP_UPLOAD = 'ADMIN_WP_UPLOAD';
export const ADMIN_BL_UPLOAD = 'ADMIN_BL_UPLOAD';
export const ADMIN_POA_UPLOAD = 'ADMIN_POA_UPLOAD';
export const ADMIN_PP_UPLOAD = 'ADMIN_PP_UPLOAD';
export const ADMIN_EC_UPLOAD = 'ADMIN_EC_UPLOAD';
export const ADMIN_FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';
export const ADMIN_CLEAR_FILE = 'ADMIN_CLEAR_FILE';

//Team Profile Actions//
export const MY_TEAM_PROFILE = 'MY_TEAM_PROFILE';
export const ADD_TEAM_PROFILE = 'ADD_TEAM_PROFILE';
export const FIX_TEAM_PROFILE = 'FIX_TEAM_PROFILE';
export const TEAM_PROFILE_ERROR = 'TEAM_PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';

//SKILLS ACTIONS
export const ADD_SKILLS = 'ADD_SKILLS';
export const MYSKILLS = 'MYSKILLS';
export const SKILLS_ERROR = 'SKILLS_ERROR';
