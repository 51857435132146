import axios from 'axios';
import {
  USER_LOADED,
  CLEAR_USER,
  USER_REGISTERED,
  USER_FAIL,
  USER_LOGIN,
  USER_LOGOUT,
  MY_ADDRESSES,
  CLEAR_ADDRESSES,
  MY_RATE_CARD,
  MOTHER_CARD,
} from '../types';

import setUserToken from '../../utils/setUserToken';
import { setNote } from '../note';

//Find Current User//
export const currentUser = () => async (dispatch) => {
  if (localStorage.token) {
    setUserToken(localStorage.token);
  }
  dispatch({ type: CLEAR_USER });
  try {
    const res = await axios.get('/api/user');
    dispatch({ type: USER_LOADED, payload: res.data });
  } catch (error) {
    dispatch({ type: USER_FAIL });
  }
};

//////////////////////
//Postal Code Finder//
//////////////////////
export const postcodeFinder = (code) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/post-code-finder/${code}`);

    return res.data;
  } catch (error) {}
};

//Create user account//
export const signupUser = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(formData);
  try {
    const res = await axios.post('/api/user/signup', body, config);
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch({ type: USER_REGISTERED });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: USER_FAIL, payload: errors });
  }
};

//Verify user account//
export const verifyUser = (token) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(`/api/user/verify/${token}`, token, config);
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch({ type: USER_REGISTERED });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: USER_FAIL, payload: errors });
  }
};

//Log in the user//
export const signinUser = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(formData);
  try {
    const res = await axios.post('/api/user/login', body, config);
    dispatch({ type: USER_LOGIN, payload: res.data });
    dispatch(currentUser());
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
    }
    dispatch({ type: USER_FAIL, payload: errors });
  }
};
////////////////////
//The Mother  Card//
////////////////////
export const getMother = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/user/the-mother-rate');
    dispatch({ type: MOTHER_CARD, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////
////My Custom Rate///
/////////////////////
export const fetchMyRate = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/my-rate-card/${userId}`);
    dispatch({ type: MY_RATE_CARD, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Add Update CellPhone//
export const cellphoneDetails = (cell) => async (dispatch) => {
  try {
    const res = await axios.put('/api/user/my-cell-phone', cell);

    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(currentUser());
  } catch (error) {
    console.error(error.message);
  }
};

//Update Address
export const updateInfo = (address) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.put('/api/user/info-update', address, config);

    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(currentUser());
  } catch (error) {
    console.error(error.message);
  }
};

export const updateComplex = (complex) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(complex);
  try {
    const res = await axios.put('/api/user/complex-update', body, config);

    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(currentUser());
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch Addresses//
export const getAddress = () => async (dispatch) => {
  dispatch({ type: CLEAR_ADDRESSES });

  try {
    const res = await axios.get('/api/user/fetch-address');
    dispatch({ type: MY_ADDRESSES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Add An Address
export const newAddress =
  ({ text, coordinates, code }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_ADDRESSES });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ text, coordinates, code });

    try {
      const res = await axios.post('/api/user/create-address', body, config);
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getAddress());
    } catch (error) {
      console.error(error.message);
    }
  };

// Add complex to address//
export const addComplex =
  ({ adrId, complex }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_ADDRESSES });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ adrId, complex });
    try {
      const res = await axios.put('/api/user/address/complex', body, config);
      dispatch({ type: MY_ADDRESSES, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

// Remove complex
export const removeComplex = (adrId) => async (dispatch) => {
  dispatch({ type: CLEAR_ADDRESSES });
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ adrId });
  try {
    const res = await axios.put(
      '/api/user/address/remove-complex',
      body,
      config
    );
    console.log(res.data);
    dispatch({ type: MY_ADDRESSES, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Delete Address//
export const deleteAddress = (adrId) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/user/address/delete/${adrId}`);
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(getAddress());
  } catch (error) {
    console.error(error.message);
  }
};

//Request password reset///
export const forgotUserPassword =
  ({ email }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ email });
    try {
      const res = await axios.post('/api/user/forgot', body, config);
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };
//Reset Password//
export const userPasswordReset =
  ({ token, newPassword }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ token, newPassword });
    try {
      const res = await axios.post(
        `/api/user/reset-password/${token}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };
//Logout Member//
export const userLogout = () => (dispatch) => {
  dispatch({ type: USER_LOGOUT });
};
