import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Alert,
  Spinner,
  Button,
  ListGroup,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { completeRepeatOrders } from '../../../actions/admin/orders';
import ReOrderItems from './ReOrderItems';

const CompleteReOrders = ({ completeRepeatOrders, orders }) => {
  useEffect(() => {
    completeRepeatOrders();
  }, [completeRepeatOrders]);

  let history = useHistory();

  if (orders === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  const goback = () => {
    history.push('/admin/order-management');
  };
  return (
    <div>
      <Container>
        <Alert variant="success text-center">
          <Button
            variant="outline-success float-left"
            size="sm"
            onClick={() => goback()}
          >
            Operations
          </Button>
          Complete Orders
        </Alert>
        <ListGroup variant="flush text-center">
          {orders.length === 0 ? (
            <Alert variant="success">There are no complete orders</Alert>
          ) : (
            orders.map((order) => (
              <ReOrderItems key={order._id} order={order} />
            ))
          )}
        </ListGroup>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.admin_orders.complete_re_orders,
});

export default connect(mapStateToProps, { completeRepeatOrders })(
  CompleteReOrders
);
