import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert, ListGroup, Card, Button } from 'react-bootstrap';
import moment from 'moment';
import ExtraItem from './ExtraItem';
import Bottom from '../../../Layout/Bottom';
import { resetBooking } from '../../../../actions/user/bookings';

const FreeOrder = ({ order, resetBooking }) => {
  useEffect(() => {
    resetBooking();
  }, [resetBooking]);
  const [mrx, SetMrx] = useState([]);
  const [it, SetIT] = useState('');
  const [bed, SetBed] = useState('');
  const [bath, SetBath] = useState('');
  const [inv, SetIV] = useState('');
  const [invoiceHours, SetIH] = useState('');
  const [code, SetCode] = useState('');
  const [address, SetAdress] = useState('');

  useEffect(() => {
    if (order) {
      //SetOrderId(order._id);
      SetMrx(order.mrx);
      SetIT(order.orderTime);
      SetBed(order.bedroom);
      SetBath(order.bathroom);
      SetIV(order.invoice.toFixed(2));
      SetIH(order.invoiceHours);
      SetCode(order.code);
      SetAdress(order.address);
      resetBooking();
    }
  }, [SetMrx, SetIT, order]);

  const downloadCode = () => {
    const link = document.createElement('a');
    link.href = code;
    link.setAttribute('download', 'DDCode.png');
    document.body.appendChild(link);
    link.click();
  };

  const invoiceTime = moment.utc(it).format('ddd MMM D, h:mm a');
  return (
    <div>
      <div className="container">
        <Row>
          <Col className="mt-3">
            <Alert variant="primary">
              <h5>Thank you for your order</h5>
            </Alert>
            <ListGroup variant="flush" className="mt-2">
              <ListGroup.Item variant="info">
                {invoiceHours}hr Order Summary!{' '}
                <p className="float-right">Date: {invoiceTime}</p>
              </ListGroup.Item>
              <ListGroup.Item>
                {bed} Bedroom/s {bath} Bathroom/s{' '}
              </ListGroup.Item>
              <ListGroup.Item align="center">
                <div className="float-left">Your Extras;</div>
                {mrx.length === 0 ? (
                  <span>No Extras</span>
                ) : (
                  mrx.map((x) => {
                    return (
                      <div
                        className="float-right"
                        key={x.id}
                        style={{ width: '20rem' }}
                      >
                        <ExtraItem x={x} />
                      </div>
                    );
                  })
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                Address: <p className="float-right">{address}</p>
              </ListGroup.Item>
              <ListGroup.Item variant="warning">
                Invoice Total; <b className="float-right">R{inv}</b>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col className="text-center">
            <Card style={{ width: '15rem' }}>
              <Card.Img variant="top" src={code} />
              <Card.Body>
                <Card.Title className="text-center">
                  Please download the qr code
                </Card.Title>
                <Button variant="primary" onClick={downloadCode}>
                  Download!
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  order: state.orders.order,
});

export default connect(mapStateToProps, { resetBooking })(FreeOrder);
