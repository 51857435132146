import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fromEmail } from '../../../actions/team';

const EmailApply = ({ fromEmail, match, duster }) => {
  useEffect(() => {
    fromEmail(match.params.dusterId);
  }, [fromEmail, match]);

  if (duster === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  if (duster) {
    return <Redirect to="/team/signup" />;
  }
};

const mapStateToProps = (state) => ({
  duster: state.team.team,
});

export default connect(mapStateToProps, { fromEmail })(EmailApply);
