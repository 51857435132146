import {
  SET_USERS,
  CLEAR_USERS,
  SET_USER,
  USER_ORDERS,
  USER_ADDRESS,
  CLEAR_ADMIN_USER,
  FACEBOOK_USERS,
  LOCAL_USERS,
  GOOGLE_USERS,
} from '../../actions/types';

const initialState = {
  users: null,
  user: null,
  local: null,
  google: null,
  facebook: null,
  orders: null,
  address: null,
};

function adminUserReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_USERS:
      return {
        ...state,
        users: payload,
      };
    case SET_USER:
      return {
        ...state,
        user: payload,
      };
    case FACEBOOK_USERS:
      return {
        ...state,
        facebook: payload,
      };
    case GOOGLE_USERS:
      return {
        ...state,
        google: payload,
      };
    case LOCAL_USERS:
      return {
        ...state,
        local: payload,
      };
    case USER_ORDERS:
      return {
        ...state,
        orders: payload,
      };
    case USER_ADDRESS:
      return {
        ...state,
        address: payload,
      };
    case CLEAR_USERS:
      return {
        ...state,
        users: null,
        user: null,
      };
    case CLEAR_ADMIN_USER:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}

export default adminUserReducer;
