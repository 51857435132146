import React, { useState, useEffect } from 'react';
import { Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NityGrity = ({
  isUser,
  regular,
  theNityGrity,
  nityGrity,
  howToGetIn,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [how, setHow] = useState(null);
  const [note, setNote] = useState('');
  const [moreInfo, setMoreInfo] = useState(null);

  useEffect(() => {
    if (nityGrity) {
      if (nityGrity.a) {
        setHow(nityGrity.a);
      } else {
        setHow(nityGrity);
      }
    }
  }, [nityGrity, setHow]);

  useEffect(() => {
    if (how) {
      if (how === 'Some One Is At Home') {
        setMoreInfo('Some One Is At Home');
        setNote('');
        theNityGrity({ a: 'Some One Is At Home', b: '' });
      } else if (how === 'Key Safe') {
        setMoreInfo('What is the safe pin eg: 9876');
        setNote('');
        theNityGrity({ a: 'Key Safe', b: '' });
      } else if (how === 'Key Hidden') {
        setMoreInfo('Where is the key hidden');
        setNote('');
        theNityGrity({ a: 'Key Hidden', b: '' });
      } else if (how === 'Other') {
        setMoreInfo('Please let us know how to gain access for the clean');
        setNote('');
        theNityGrity({ a: 'Other', b: '' });
      }
    }
  }, [how, theNityGrity, setMoreInfo]);

  useEffect(() => {
    if (note) {
      let a = how;
      let b = note;
      let nityGrity = { a, b };
      theNityGrity(nityGrity);
    }
  }, [note, theNityGrity, how]);

  return (
    <div className="mb-3 mt-4">
      <h5 className="text-center">
        Please let us know how to access your property?
      </h5>
      <Row>
        <Col md={3} xs={12}>
          <Button
            variant={
              how === 'Some One Is At Home' ? 'info' : 'outline-secondary'
            }
            value="Some One Is At Home"
            block
            onClick={(e) => setHow(e.target.value)}
          >
            Someone at home
          </Button>
        </Col>
        <Col md={3} xs={12}>
          <Button
            variant={how === 'Key Safe' ? 'info' : 'outline-secondary'}
            value="Key Safe"
            block
            onClick={(e) => setHow(e.target.value)}
          >
            Key Safe
          </Button>
        </Col>
        <Col md={3} xs={12}>
          <Button
            variant={how === 'Key Hidden' ? 'info' : 'outline-secondary'}
            value="Key Hidden"
            onClick={(e) => setHow(e.target.value)}
            block
          >
            Key hidden
          </Button>
        </Col>
        <Col md={3} xs={12}>
          <Button
            variant={how === 'Other' ? 'info' : 'outline-secondary'}
            value="Other"
            onClick={(e) => setHow(e.target.value)}
            block
          >
            Other
          </Button>
        </Col>
      </Row>
      {moreInfo && how !== 'Some One Is At Home' && (
        <div className="mt-4" align="center">
          <h5 className="text-center">{how}</h5>
          <InputGroup>
            <FormControl
              as="textarea"
              placeholder={`${moreInfo}`}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </InputGroup>
        </div>
      )}

      {isUser ? (
        regular ? (
          how === 'Some One Is At Home' ? (
            <Button
              variant="outline-success mt-3"
              as={Link}
              to="/booking/repeat-checkout"
              block
            >
              Check out <i className="ml-2 fas fa-shopping-cart"></i>
            </Button>
          ) : note.length > 3 ? (
            <Button
              variant="outline-success mt-3"
              as={Link}
              to="/booking/repeat-checkout"
              block
            >
              Check out <i className="ml-2 fas fa-shopping-cart"></i>
            </Button>
          ) : (
            <Button variant="secondary mt-3" block disabled>
              How do we get into your home to clean?
            </Button>
          )
        ) : how === 'Some One Is At Home' ? (
          <Button
            variant="outline-success mt-3"
            as={Link}
            to="/booking/check-out"
            block
          >
            Check out <i className="ml-2 fas fa-shopping-cart"></i>
          </Button>
        ) : note.length > 3 ? (
          <Button
            variant="outline-success mt-3"
            as={Link}
            to="/booking/check-out"
            block
          >
            Check out <i className="ml-2 fas fa-shopping-cart"></i>
          </Button>
        ) : (
          <Button variant="secondary mt-3" block disabled>
            How do we get into your home to clean?
          </Button>
        )
      ) : (
        <Link to="/user/signin" target="_blank" rel="noopener noreferrer">
          <Button variant="outline-warning mt-2" block>
            Please create an account for a better boking experience
            <i className="ml-2 fas fa-shopping-cart"></i>
          </Button>
        </Link>
      )}
    </div>
  );
};

export default NityGrity;
