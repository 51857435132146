import React, { useEffect, useState, useRef } from 'react';
import {
  Spinner,
  Row,
  Col,
  Alert,
  ListGroup,
  Card,
  Button,
} from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  GetOrder,
  deleteOrder,
  fetchDuster,
  cleanDuster,
  fetchOrderComments,
  createComment,
} from '../../../actions/user/order';
import { getInvoice } from '../../../actions/user/invoice';
import { fetchOrderReview } from '../../../actions/user/review';
import { paymentUrl } from '../../../actions/user/payment';
import moment from 'moment';
import ExtraItem from '../Booking/Payments/ExtraItem';
import Coupon from '../Booking/Payments/Coupon';
import Bottom from '../../Layout/Bottom';
import DeleteOrder from './DeleteOrder';
import ReviewDuster from './ReviewDuster';
import EditReview from './EditReview';
import ChangeTheDate from './ChangeTheDate';
import AddComment from './AddComment';

const Order = ({
  match,
  GetOrder,
  orders: { order, bookedCleaner, comments },
  deleteOrder,
  note,
  fetchDuster,
  cleanDuster,
  review,
  fetchOrderReview,
  getInvoice,
  invoice,
  fetchOrderComments,
  createComment,
  paymentUrl,
  stripeUrl,
}) => {
  useEffect(() => {
    GetOrder(match.params.orderId);
    fetchOrderReview(match.params.orderId);
    fetchOrderComments(match.params.orderId);
    getInvoice(match.params.orderId);
  }, [GetOrder, fetchOrderReview, fetchOrderComments, getInvoice, match]);

  useEffect(() => {
    if (stripeUrl) {
      window.location.href = stripeUrl;
    }
  }, [stripeUrl]);

  const [nte, setMsg] = useState(note);
  const [orderId, SetOrderId] = useState('');
  const [mrx, SetMrx] = useState([]);
  const [it, SetIT] = useState(null);
  const [bed, SetBed] = useState('');
  const [bath, SetBath] = useState('');
  const [inv, SetIV] = useState('');
  const [invoiceHours, SetIH] = useState('');
  const [code, SetCode] = useState('');
  const [address, SetAdress] = useState('');
  const [complex, SetComplex] = useState(null);
  const [assigned, SetAssigned] = useState(null);
  const [remove, setRemove] = useState(false);
  const [complete, SetComplete] = useState(null);
  const [orderReview, setOrderReview] = useState(null);
  const [checkInTime, SetCheckInTime] = useState(null);
  const [inProgress, setInProgress] = useState(null);
  const [monthly, setMonthly] = useState(null);
  const [paymentRef, setPaymentRef] = useState(null);
  const [payText, setPayText] = useState('Make Payment');

  let history = useHistory();

  useEffect(() => {
    if (note && delBtn.current) {
      setMsg(note);
      delBtn.current.removeAttribute('disabled');
      history.push('/user/my-profile');
    }
  }, [note, nte, setMsg, history]);

  useEffect(() => {
    if (complete === undefined && checkInTime !== undefined) {
      setInProgress(true);
    }
  }, [checkInTime, complete]);

  useEffect(() => {
    if (complete) {
      setInProgress(null);
    }
  }, [complete]);

  useEffect(() => {
    if (order) {
      SetOrderId(order._id);
      SetMrx(order.mrx);
      SetIT(order.orderTime);
      SetBed(order.bedroom);
      SetBath(order.bathroom);
      SetIV(order.invoice.toFixed(2));
      SetIH(order.invoiceHours);
      SetCode(order.code);
      SetAdress(order.address);
      SetComplex(order.complex);
      SetAssigned(order.team);
      SetComplete(order.checkOut);
      SetCheckInTime(order.checkIn);
      setMonthly(order.monthEnd);
      setPaymentRef(order.paymentRef);
    }
  }, [SetMrx, SetIT, order]);

  useEffect(() => {
    if (it) {
      let tomorrow = moment().add(1, 'day').local().format();
      if (tomorrow < it) {
        setRemove(true);
      }
    }
    return () => {
      setRemove(false);
    };
  }, [it, assigned]);

  useEffect(() => {
    if (assigned) {
      let dusterId = assigned;
      fetchDuster(dusterId);
    }
    return () => {
      cleanDuster(null);
    };
  }, [assigned, fetchDuster, cleanDuster]);

  useEffect(() => {
    if (review) {
      setOrderReview(review);
    }
    return () => {
      setOrderReview(null);
    };
  }, [review]);

  let btn = useRef();
  let delBtn = useRef();

  const downloadCode = () => {
    const link = document.createElement('a');
    link.href = code;
    link.setAttribute('download', 'DDCode.png');
    document.body.appendChild(link);
    link.click();
  };

  const invoiceTime = moment.utc(it).format('ddd MMM D, h:mm a');

  if (order === null || comments === null) {
    return <Spinner animation="border" variant="primary" />;
  }

  const delOrder = () => {
    deleteOrder(orderId);
    if (delBtn.current) {
      delBtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const inTime = moment.utc(checkInTime).format('ddd MMM D, h:mm a');
  const outTime = moment.utc(complete).format('ddd MMM D, h:mm a');

  const tryAgain = () => {
    setPayText('Processing Payment...');
    paymentUrl(orderId);
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <div>
      <div className="container">
        <Row>
          <Col className="mt-3">
            <Alert variant="primary">
              <h5>
                Thank you for your order
                {!code ? (
                  <DeleteOrder delOrder={delOrder} delBtn={delBtn} />
                ) : invoice ? (
                  <Button
                    as={Link}
                    to={`/user/my-order-invoice/${orderId}`}
                    variant="info float-right"
                    size="sm"
                  >
                    Invoice
                  </Button>
                ) : (
                  ''
                )}
              </h5>
            </Alert>
            <ListGroup variant="flush" className="mt-2">
              <ListGroup.Item variant="info">
                {invoiceHours}hr Order Summary!{' '}
                <p className="float-right">Date: {invoiceTime}</p>
              </ListGroup.Item>
              <ListGroup.Item>
                {bed} Bedroom/s {bath} Bathroom/s{' '}
              </ListGroup.Item>
              <ListGroup.Item align="center">
                <div className="float-left">Your Extras;</div>
                {mrx.length === 0 ? (
                  <span>No Extras</span>
                ) : (
                  mrx.map((x) => {
                    return (
                      <div
                        className="float-right"
                        key={x.id}
                        style={{ width: '20rem' }}
                      >
                        <ExtraItem x={x} />
                      </div>
                    );
                  })
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                Address:{' '}
                <p className="float-right">
                  {address} {complex && `| ${complex}`}
                </p>
              </ListGroup.Item>
              <ListGroup.Item variant="warning">
                Invoice Total; <b className="float-right">£ {inv}</b>
              </ListGroup.Item>
              <ListGroup.Item>
                <ChangeTheDate />
              </ListGroup.Item>
              <ListGroup.Item>
                <Button variant="danger float-right" block>
                  I need help with my booking!
                </Button>
              </ListGroup.Item>
            </ListGroup>
            <AddComment
              createComment={createComment}
              orderId={orderId}
              note={note}
            />
            {comments && comments.length === 0 ? (
              <p className="text-center mt-2">No instructions</p>
            ) : (
              comments.map((comment) => (
                <ListGroup.Item className="mt-2">{comment.text}</ListGroup.Item>
              ))
            )}
          </Col>
          <Col align="center">
            {code ? (
              <>
                {monthly && paymentRef === undefined && (
                  <Card style={{ width: '20rem', marginBottom: '5%' }}>
                    <Alert variant="info">Make payment for your order</Alert>
                    <Card.Body>
                      <b>***Please Note***</b>
                      <p>You will be re-direcrected to make payment</p>

                      <Button
                        variant="success"
                        ref={btn}
                        onClick={tryAgain}
                        block
                      >
                        {payText} £ {inv}
                        <i className="fas fa-cash-register float-right"></i>
                      </Button>
                    </Card.Body>
                  </Card>
                )}
                <Card style={{ width: '20rem' }}>
                  <Card.Img variant="top" src={code} />
                  <Card.Body>
                    <Card.Title className="text-center">
                      Please download the qr code
                    </Card.Title>
                    <Button variant="primary" onClick={downloadCode}>
                      Download!
                    </Button>
                  </Card.Body>
                </Card>
              </>
            ) : (
              <Card style={{ width: '20rem' }}>
                <Alert variant="danger">Your payment failed!</Alert>
                <Card.Body>
                  <Card.Title className="text-center">
                    <Alert variant="warning">Please try pay again</Alert>
                  </Card.Title>
                  <Coupon />
                  <hr />
                  <b>***Please Note***</b>
                  <p>You will be re-direcrected to make payment.</p>
                  <Button variant="success" ref={btn} onClick={tryAgain} block>
                    {payText} £{inv}
                    <i className="fas fa-cash-register float-right"></i>
                  </Button>
                </Card.Body>
              </Card>
            )}
          </Col>
          {bookedCleaner ? (
            <Col>
              <Alert variant="info">
                Assigned Duster
                <i className="fas fa-user-check float-right"></i>
              </Alert>
              <ListGroup>
                <ListGroup.Item>
                  <b> Duster Name:</b>
                  <p className="float-right">
                    {bookedCleaner.name} {bookedCleaner.surname}
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Star Rating: </b>
                  <p className="float-right">3 Star Duster</p>
                </ListGroup.Item>
                {checkInTime ? (
                  <>
                    <ListGroup.Item>
                      <b>Check in time:</b>
                      <p className="float-right">{inTime}</p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>Check out time:</b>

                      <p className="float-right">
                        {inProgress ? (
                          <Button variant="warning" size="sm">
                            Cleaning In Progress...
                          </Button>
                        ) : (
                          <>{outTime}</>
                        )}
                      </p>
                    </ListGroup.Item>
                  </>
                ) : (
                  <Button variant="info" disabled block>
                    Dust Buster has not arrived yet
                  </Button>
                )}
              </ListGroup>
              {orderReview ? (
                <EditReview
                  orderReview={orderReview}
                  bookedCleaner={bookedCleaner}
                />
              ) : complete ? (
                <ReviewDuster bookedCleaner={bookedCleaner} orderId={orderId} />
              ) : (
                ''
              )}
            </Col>
          ) : (
            <Col>
              <Alert variant="warning text-center">Cleaner not assigned</Alert>
            </Col>
          )}
        </Row>
      </div>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders,
  note: state.note,
  review: state.user_reviews.review,
  invoice: state.invoice.invoice,
  stripeUrl: state.payment.paymentUrl,
});

export default connect(mapStateToProps, {
  GetOrder,
  deleteOrder,
  fetchDuster,
  cleanDuster,
  fetchOrderReview,
  getInvoice,
  fetchOrderComments,
  createComment,
  paymentUrl,
})(Order);
