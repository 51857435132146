import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  recurringOrder,
  runRepeatFunctions,
} from '../../../../../actions/admin/main';
import { Container, Spinner, Alert, Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import ListOrder from './ListOrder';

const ReOrderOverview = ({
  recurringOrder,
  orders,
  runRepeatFunctions,
  note,
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (note.length > 0) {
      btn.current.removeAttribute('disabled');
      setShow(false);
    }
  }, [note]);

  let btn = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    recurringOrder();
  }, [recurringOrder]);

  let history = useHistory();

  if (orders === null) return <Spinner animation="grow" variant="primary" />;

  const goBack = () => {
    history.push('/admin/admin-accounts');
  };

  const runRepeat = () => {
    runRepeatFunctions();

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };
  return (
    <Container>
      <Alert variant="warning text-center">
        <Button variant="light float-left" size="sm" onClick={goBack}>
          Accounts
        </Button>
        Recurring Order Management
        <Button variant="warning float-right" size="sm" onClick={handleShow}>
          Run Repeat
        </Button>
      </Alert>
      {orders.length === 0
        ? 'No Orders'
        : orders.map((order) => <ListOrder key={order._id} order={order} />)}
      <Modal show={show} onHide={handleClose}>
        <Alert variant="warning">
          You are about to repeat orders for next month
        </Alert>
        <Modal.Body>Make it happen</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={runRepeat} ref={btn}>
            Run Repeat
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  orders: state.main.reOrderManagement,
  note: state.note,
});

export default connect(mapStateToProps, { recurringOrder, runRepeatFunctions })(
  ReOrderOverview
);
