import {
  NOT_BOOKED_NUM,
  NOT_BOOKED,
  ADMIN_ORDERS,
  ADMIN_REORDERS,
  RUNNING_LATE,
  RUNNING_LATE_NUM,
  ORDER_IN_PROGRESS,
  ORDER_IN_PROGRESS_NUM,
  TODAYS_ORDERS,
  TODAYS_ORDERS_NUM,
  COMPLETE_ORDERS,
  COMPLETE_ORDERS_NUM,
  RE_NOT_BOOKED,
  RE_NOT_BOOKED_NUM,
  RE_IN_PROGRESS,
  RE_IN_PROGRESS_NUM,
  REPEAT_TODAY,
  REPEAT_TODAY_NUM,
  REQUIRE_VALIDATION,
  REQUIRE_VALIDATION_NUM,
  ADMIN_ORDER,
  AREA_CLEANERS,
  CLEAR_ADMIN_ORDER,
  MISSED_ORDERS,
  MISSED_ORDERS_NUM,
  MISSED_RE_ORDER,
  MISSED_RE_ORDERS_NUM,
  ADMIN_REPEAT_ORDER,
  LATE_REORDERS_NUM,
  RE_RUNNING_LATE,
  VALIDATE_DISTANCE,
  REPEAT_VALIDATION_NUM,
  REPEAT_VALIDATION,
  COMPLETE_RE_ORDERS,
  COMPLETE_RE_ORDERS_NUM,
  TO_COMPLETE_NUM,
  TO_COMPLETE,
  ALLOCATED_ORDERS,
  ALLOCATED_ORDERS_NUM,
  SWAP_CLEANERS,
  CLEAR_SWAP_CLEANERS,
  DUSTER_ON_BOOKING,
} from '../../actions/types';

const initState = {
  order: null,
  orders: null,
  allocated_num: null,
  allocated: null,
  not_booked: null,
  not_booked_num: null,
  running_late: null,
  running_late_num: null,
  in_progress: null,
  in_progress_num: null,
  todays_orders: null,
  missed_orders: null,
  missed_orders_num: null,
  todays_orders_num: null,
  require_validation: null,
  require_validation_num: null,
  complete_orders: null,
  complete_orders_num: null,
  re_orders_notbooked: null,
  re_orders_notbooked_num: null,
  re_in_progress: null,
  re_in_progress_num: null,
  re_today: null,
  re_today_num: null,
  re_orders_missed: null,
  re_orders_missed_num: null,
  re_orders_late_num: null,
  re_orders_late: null,
  reorders: null,
  re_order: null,
  validationDistance: null,
  repeat_validation: null,
  repeat_validation_num: null,
  complete_re_orders: null,
  complete_re_orders_num: null,
  to_complete_num: null,
  to_complete: null,
  swap_cleaners: null,
  bookedDuster: null,
};

function AdminOrderReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_ORDER:
      return {
        ...state,
        order: payload,
      };
    case VALIDATE_DISTANCE:
      return {
        ...state,
        validationDistance: payload,
      };
    case CLEAR_ADMIN_ORDER:
      return {
        ...state,
        order: null,
      };
    case AREA_CLEANERS:
      return {
        ...state,
        cleaners: payload,
      };
    case SWAP_CLEANERS:
      return {
        ...state,
        swap_cleaners: payload,
      };
    case CLEAR_SWAP_CLEANERS:
      return {
        ...state,
        swap_cleaners: null,
      };
    case DUSTER_ON_BOOKING:
      return {
        ...state,
        bookedDuster: payload,
      };
    case ALLOCATED_ORDERS_NUM:
      return {
        ...state,
        allocated_num: payload,
      };
    case ALLOCATED_ORDERS:
      return {
        ...state,
        allocated: payload,
      };
    case COMPLETE_ORDERS:
      return {
        ...state,
        complete_orders: payload,
      };
    case COMPLETE_ORDERS_NUM:
      return {
        ...state,
        complete_orders_num: payload,
      };
    case REQUIRE_VALIDATION:
      return {
        ...state,
        require_validation: payload,
      };
    case REQUIRE_VALIDATION_NUM:
      return {
        ...state,
        require_validation_num: payload,
      };
    case MISSED_ORDERS:
      return {
        ...state,
        missed_orders: payload,
      };
    case MISSED_ORDERS_NUM:
      return {
        ...state,
        missed_orders_num: payload,
      };
    case MISSED_RE_ORDER:
      return {
        ...state,
        re_orders_missed: payload,
      };
    case LATE_REORDERS_NUM:
      return {
        ...state,
        re_orders_late_num: payload,
      };
    case RE_RUNNING_LATE:
      return {
        ...state,
        re_orders_late: payload,
      };
    case ADMIN_ORDERS:
      return {
        ...state,
        orders: payload,
      };
    case ADMIN_REORDERS:
      return {
        ...state,
        reorders: payload,
      };
    case NOT_BOOKED:
      return {
        ...state,
        not_booked: payload,
      };
    case NOT_BOOKED_NUM:
      return {
        ...state,
        not_booked_num: payload,
      };
    case RUNNING_LATE:
      return {
        ...state,
        running_late: payload,
      };
    case RUNNING_LATE_NUM:
      return {
        ...state,
        running_late_num: payload,
      };
    case ORDER_IN_PROGRESS:
      return {
        ...state,
        in_progress: payload,
      };
    case ORDER_IN_PROGRESS_NUM:
      return {
        ...state,
        in_progress_num: payload,
      };
    case TODAYS_ORDERS:
      return {
        ...state,
        todays_orders: payload,
      };
    case TODAYS_ORDERS_NUM:
      return {
        ...state,
        todays_orders_num: payload,
      };
    case ADMIN_REPEAT_ORDER:
      return {
        ...state,
        re_order: payload,
      };
    case RE_NOT_BOOKED:
      return {
        ...state,
        re_orders_notbooked: payload,
      };
    case RE_NOT_BOOKED_NUM:
      return {
        ...state,
        re_orders_notbooked_num: payload,
      };
    case RE_IN_PROGRESS:
      return {
        ...state,
        re_in_progress: payload,
      };
    case RE_IN_PROGRESS_NUM:
      return {
        ...state,
        re_in_progress_num: payload,
      };
    case REPEAT_TODAY:
      return {
        ...state,
        re_today: payload,
      };
    case REPEAT_TODAY_NUM:
      return {
        ...state,
        re_today_num: payload,
      };
    case REPEAT_VALIDATION_NUM:
      return {
        ...state,
        repeat_validation_num: payload,
      };
    case REPEAT_VALIDATION:
      return {
        ...state,
        repeat_validation: payload,
      };
    case MISSED_RE_ORDERS_NUM:
      return {
        ...state,
        re_orders_missed_num: payload,
      };
    case TO_COMPLETE:
      return {
        ...state,
        to_complete: payload,
      };
    case TO_COMPLETE_NUM:
      return {
        ...state,
        to_complete_num: payload,
      };
    case COMPLETE_RE_ORDERS:
      return {
        ...state,
        complete_re_orders: payload,
      };
    case COMPLETE_RE_ORDERS_NUM:
      return {
        ...state,
        complete_re_orders_num: payload,
      };
    default:
      return state;
  }
}

export default AdminOrderReducer;
