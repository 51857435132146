import React from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, Card, Alert, Button } from 'react-bootstrap';
import moment from 'moment';

const ReOrderItems = ({ order }) => {
  let time = moment.utc(order.firstDate).format('ddd MMM D, h:mm a');
  let address = order.address.split(',');
  return (
    <div>
      <ListGroup.Item>
        Address: {address[0]} {address[1]}{' '}
        {order.complex && `| ${order.complex}`} Order Time: {time}
        <Button
          as={Link}
          to={`/admin/order-management/repeat-orders/order/${order._id}`}
          variant="outline-primary float-right"
          size="sm"
        >
          View Order
        </Button>
      </ListGroup.Item>
    </div>
  );
};

export default ReOrderItems;
