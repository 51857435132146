import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Modal, Button, Alert, Form } from 'react-bootstrap';
import AddressButton from './Payments/AddressButton';
import { setBookingAddress } from '../../../actions/user/bookings';
import { checkCleaners } from '../../../actions/user/order';
import { postcodeFinder } from '../../../actions/user';
import PostCodeFinder from '../../Layout/PostCodeFinder';

import './Booking.css';

const WhereYou = ({
  user,
  myAddress,
  checkCleaners,
  availableCleaners,
  setBookingAddress,
  address,
  complex,
  coordinates,
  setNote,
  postcodeFinder,
  postCode,
}) => {
  const [show, setShow] = useState(true);
  const [adr, setAddress] = useState(address);
  const [cmplex, setComplex] = useState(complex);
  const [coo, setCoordinates] = useState(coordinates);
  const [code, setCode] = useState(postCode);
  useEffect(() => {
    if (adr && coo) {
      let address = adr;
      let coordinates = coo;
      let complex = cmplex;
      let postCode = code;
      setBookingAddress({ address, coordinates, complex, postCode });
    }
  }, [adr, coo, setBookingAddress, cmplex]);

  useEffect(() => {
    if (adr === null && user) {
      setAddress(user.address);
      setCoordinates(user.coordinates);
      setComplex(user.complex);
      setCode(user.code);
    }
  }, [adr, user]);

  useEffect(() => {
    if (user) {
      setShow(false);
    }
  }, [user]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const ChangeAddress = (a) => {
    setAddress(a.text);
    setCoordinates(a.coordinates);
    setComplex(a.complex);
    setCode(a.postCode);
  };

  const defaultAddress = () => {
    setAddress(user.address);
    setCoordinates(user.coordinates);
    setComplex(user.complex);
    setCode(user.code);
  };

  const where = () => {
    const { lat, lng } = coo;
    let findme = '' + lat + ',' + lng;
    checkCleaners({ findme });
    setShow(false);
  };

  return (
    <div>
      <h5 className="text-center">Which Address Would You Want to Clean?</h5>
      {availableCleaners ? (
        availableCleaners.length === 0 ? (
          <>
            <Alert variant="secondary text-center">
              We currently do not have cleaners in your area
            </Alert>
            <Button variant="info" block onClick={handleShow}>
              Change Address
            </Button>
            <Button disabled block>
              Signup to get notified when we have cleaners in your area
            </Button>
          </>
        ) : (
          <>
            <Alert variant="info text-center">
              We have cleaners in the area: <b>{adr}</b>
            </Alert>
            <Button
              as={Link}
              to="/user/signin"
              target="_blank"
              rel="noopener noreferrer"
              align="center"
              variant="outline-warning"
              block
            >
              Please sign-in and for a better booking experience
            </Button>
          </>
        )
      ) : (
        ''
      )}
      {user ? (
        <div>
          <Alert variant="info">
            <Row>
              <Col className="text-center">
                <p>{adr}</p>
              </Col>
              <Col>
                <AddressButton
                  myAddress={myAddress}
                  user={user}
                  ChangeAddress={ChangeAddress}
                  defaultAddress={defaultAddress}
                />
              </Col>
            </Row>
          </Alert>
        </div>
      ) : (
        <Modal
          show={show}
          backdrop="static"
          keyboard={false}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title>Please enter your postcode...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Post Code:</Form.Label>
              <PostCodeFinder
                value={address}
                postcodeFinder={postcodeFinder}
                setAddress={setAddress}
                setCoordinates={setCoordinates}
                setNote={setNote}
                setCode={setCode}
                code={code}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {code ? (
              <Button variant="success" onClick={where} block>
                Confirm Post Code
              </Button>
            ) : (
              <Button variant="warning text" disabled block>
                Enter Post Code To Continue
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default connect(null, {
  checkCleaners,
  setBookingAddress,
  postcodeFinder,
})(WhereYou);
