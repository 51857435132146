import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, ListGroupItem } from 'react-bootstrap';

const ListOrder = ({ order }) => {
  const [link, setLink] = useState('');
  useEffect(() => {
    if (order.weekdays) {
      setLink(`/admin/order-management/repeat-orders/order/${order._id}`);
    } else {
      setLink(`/admin/order-management/once-off/order/${order._id}`);
    }
  }, [order]);

  return (
    <ListGroupItem>
      <b>Order Info:</b> {order.address}
      <Button variant="light float-right" size="sm" as={Link} to={link}>
        View Order
      </Button>
    </ListGroupItem>
  );
};

export default ListOrder;
