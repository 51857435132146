import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './store';

//Layout Pages//
import Header from './components/Layout/Header';
import Note from './components/Layout/Note';
import Home from './components/Layout/Home';
import Footer from './components/Layout/Footer';

//Team Pages//
import SignUp from './components/Team/Auth/SignUp';
import EmailApply from './components/Team/Auth/EmailApply';
import CompleteApplication from './components/Team/Auth/CompleteApplication';
import TeamVerify from './components/Team/Auth/Verify';
import SignIn from './components/Team/Auth/SignIn';
import ForgotPassword from './components/Team/Auth/ForgotPassword';
import ResetPassword from './components/Team/Auth/ResetPassword';
import TeamRoute from './components/routing/TeamRoute';
import ReadyToWork from './components/Team/Working/ReadyToWork';
import Agreement from './components/Team/Agreement';
import MyBooking from './components/Team/Working/MyBooking';
import MyReBooking from './components/Team/Working/MyReBooking';
import DustBuster from './components/Team/DustBuster';

//User Pages//
import UserSignUp from './components/User/Auth/SignUp';
import UserSignIn from './components/User/Auth/SignIn';
import UserVerify from './components/User/Auth/Verify';
import ForgotUserPassword from './components/User/Auth/ForgotUserPassword';
import ResetUserPassword from './components/User/Auth/ResetUserPassword';
import GGCallback from './components/User/Auth/GGCallback';
import FBCallback from './components/User/Auth/FBCallback';
import UserAgree from './components/User/UserAgree';
import UserRoute from './components/routing/UserRoute';
import Booking from './components/User/Booking/Booking';
import CheckOut from './components/User/Booking/Payments/CheckOut';
import RepeatCheckOut from './components/User/Booking/Payments/RepeatCheckOut';
import PaymentResponse from './components/User/Booking/Payments/PaymentResponse';
import RePaymentResponse from './components/User/Booking/Payments/RePaymentResponse';

import MonthlyOrder from './components/User/Booking/Payments/MonthlyOrder';
import FreeOrder from './components/User/Booking/Payments/FreeOrder';
import MyProfile from './components/User/Profile/MyProfile';
import MyOrders from './components/User/Profile/MyOrders';
import MyReOrders from './components/User/Profile/MyReOrders';
import MyOrder from './components/User/Profile/Order';
import MyReOrder from './components/User/Profile/MyReOrder';
import OrderHistory from './components/User/Profile/OrderHistory';
import MyInvoice from './components/User/Profile/MyInvoice';

//Admin Pages//
import AdminSignIn from './components/Admin/AdminSignin';
import AdminRoute from './components/routing/AdminRoute';
import TeamProfile from './components/Admin/TeamProfile/TeamProfile';
import AdminDusters from './components/Admin/Dashboard/DustBusters/AdminDusters';
import AllReady from './components/Admin/Dashboard/DustBusters/AllReady';
import StageOne from './components/Admin/Dashboard/DustBusters/StageOne';
import StageTwo from './components/Admin/Dashboard/DustBusters/StageTwo';
import StageThree from './components/Admin/Dashboard/DustBusters/StageThree';
import AdminUsers from './components/Admin/Dashboard/Users/AdminUsers';
import AdminUser from './components/Admin/Dashboard/Users/AdminUser';
import Coupon from './components/Admin/Dashboard/Users/Coupon';
import Main from './components/Admin/Dashboard/Main/Main';
import Accounts from './components/Admin/Dashboard/Main/Accounts';
import ManageOrders from './components/Admin/OrderManagement/ManageOrders';
import AllocatedOrders from './components/Admin/OrderManagement/AllocatedOrders';
import OrderNotAllocated from './components/Admin/OrderManagement/OrderNotAllocated';
import MissedOrders from './components/Admin/OrderManagement/MissedOrders';
import MissedReOrders from './components/Admin/OrderManagement/MissedReOrders';
import RunningLate from './components/Admin/OrderManagement/RunningLate';
import CurrentlyActive from './components/Admin/OrderManagement/CurrentlyActive';
import TodaysOrders from './components/Admin/OrderManagement/TodaysOrders';
import CompleteOrders from './components/Admin/OrderManagement/CompleteOrders';
import RequireValidation from './components/Admin/OrderManagement/RequireValidation';
import ReOrderNotAllocated from './components/Admin/OrderManagement/ReOrderNotAllocated';
import ReOrdersRunningLate from './components/Admin/OrderManagement/ReOrdersRunningLate';
import ReOrdersInProgress from './components/Admin/OrderManagement/ReOrdersInProgress';
import ReOrdersToday from './components/Admin/OrderManagement/ReOrdersToday';
import OrderAdmin from './components/Admin/OrderManagement/OrderAdmin';
import ReOrderAdmin from './components/Admin/OrderManagement/ReOrderAdmin';
import RepeatValidation from './components/Admin/OrderManagement/RepeatValidation';
import CompleteReOrders from './components/Admin/OrderManagement/CompleteReOrders';
import AllocatedReOrders from './components/Admin/OrderManagement/AllocatedReOrders';
import Invoices from './components/Admin/Dashboard/Main/Accounting/Invoices';
import Invoice from './components/Admin/Dashboard/Main/Accounting/Invoice';
import PayingDusters from './components/Admin/Dashboard/Main/Accounting/PayingDusters';
import Payslip from './components/Admin/Dashboard/Main/Accounting/Payslip';
import OrdersOnAccount from './components/Admin/Dashboard/Main/Accounting/OrdersOnAccount';
import ReOrderOverview from './components/Admin/Dashboard/Main/Accounting/ReOrderOverview';

import 'bootstrap/dist/css/bootstrap.min.css';

import { teamMember } from './actions/team';
import { currentUser } from './actions/user';
import { adminLoaded } from './actions/admin';
import setUserToken from './utils/setUserToken';

import './App.css';

if (localStorage.token) {
  setUserToken(localStorage.token);
}

function App() {
  useEffect(() => {
    setUserToken(localStorage.token);
    store.dispatch(teamMember());
  }, []);
  useEffect(() => {
    setUserToken(localStorage.token);
    store.dispatch(currentUser());
  }, []);
  useEffect(() => {
    setUserToken(localStorage.token);
    store.dispatch(adminLoaded());
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Header />
        <Route exact path="/" component={Home} />
        <Note />
        <Footer path="/" />
        <Switch>
          <Route exact path="/team/signup" component={SignUp} />
          <Route
            exact
            path="/click-email-application/:dusterId"
            component={EmailApply}
          />
          <Route
            exact
            path="/team/complete-application/:dusterId"
            component={CompleteApplication}
          />
          <Route exact path="/team/signin" component={SignIn} />
          <Route exact path="/verify/team/:token" component={TeamVerify} />
          <Route
            exact
            path="/team/forgot-password"
            component={ForgotPassword}
          />
          <Route
            exact
            path="/auth/team/reset-password/:token"
            component={ResetPassword}
          />
          <TeamRoute exact path="/team/work-feed" component={ReadyToWork} />
          <TeamRoute
            exact
            path="/team/my-booking/:orderId"
            component={MyBooking}
          />
          <TeamRoute
            exact
            path="/team/my-rebooking/:reOrderId"
            component={MyReBooking}
          />
          <TeamRoute
            exact
            path="/team/dust-buster-profile"
            component={DustBuster}
          />
          <Route exact path="/team/agreement" component={Agreement} />

          <Route exact path="/admin/signin" component={AdminSignIn} />
          <AdminRoute exact path="/admin/main" component={Main} />

          <AdminRoute
            exact
            path="/admin/order-management"
            component={ManageOrders}
          />
          <AdminRoute
            exact
            path="/admin/order-management/once-off/allocated"
            component={AllocatedOrders}
          />
          <AdminRoute
            exact
            path="/admin/order-management/once-off/not-allocated"
            component={OrderNotAllocated}
          />
          <AdminRoute
            exact
            path="/admin/order-management/once-off/require-validation"
            component={RequireValidation}
          />
          <AdminRoute
            exact
            path="/admin/order-management/repeat-orders/not-allocated"
            component={ReOrderNotAllocated}
          />
          <AdminRoute
            exact
            path="/admin/order-management/once-off/running-late"
            component={RunningLate}
          />
          <AdminRoute
            exact
            path="/admin/order-management/once-off/active-orders"
            component={CurrentlyActive}
          />
          <AdminRoute
            exact
            path="/admin/order-management/once-off/todays-orders"
            component={TodaysOrders}
          />
          <AdminRoute
            exact
            path="/admin/order-management/once-off/complete-orders"
            component={CompleteOrders}
          />
          <AdminRoute
            exact
            path="/admin/order-management/once-off/missed-orders"
            component={MissedOrders}
          />
          <AdminRoute
            exact
            path="/admin/order-management/repeat-orders/missed-reorders"
            component={MissedReOrders}
          />
          <AdminRoute
            exact
            path="/admin/order-management/repeat-orders/running-late"
            component={ReOrdersRunningLate}
          />
          <AdminRoute
            exact
            path="/admin/order-management/repeat-orders/in-progess"
            component={ReOrdersInProgress}
          />
          <AdminRoute
            exact
            path="/admin/order-management/repeat-orders/today"
            component={ReOrdersToday}
          />
          <AdminRoute
            exact
            path="/admin/order-management/repeat-orders/require-validation"
            component={RepeatValidation}
          />
          <AdminRoute
            exact
            path="/admin/order-management/repeat-orders/complete-orders"
            component={CompleteReOrders}
          />
          <AdminRoute
            exact
            path="/admin/order-management/repeat-orders/allocated-re-orders"
            component={AllocatedReOrders}
          />
          <AdminRoute
            exactr
            path="/admin/order-management/once-off/order/:orderId"
            component={OrderAdmin}
          />
          <AdminRoute
            exact
            path="/admin/order-management/repeat-orders/order/:reOrderId"
            component={ReOrderAdmin}
          />
          <AdminRoute exact path="/admin/admin-accounts" component={Accounts} />
          <AdminRoute
            exact
            path="/admin/admin-dusters"
            component={AdminDusters}
          />
          <AdminRoute
            exact
            path="/admin/team-member/:dusterId"
            component={TeamProfile}
          />
          <AdminRoute exact path="/admin/team/stage-one" component={StageOne} />
          <AdminRoute exact path="/admin/team/stage-two" component={StageTwo} />
          <AdminRoute
            exact
            path="/admin/team/stage-three"
            component={StageThree}
          />
          <AdminRoute
            exact
            path="/admin/team/ready-to-work"
            component={AllReady}
          />

          <AdminRoute exact path="/admin/admin_users/" component={AdminUsers} />
          <AdminRoute
            exact
            path="/admin/admin_user/:userId"
            component={AdminUser}
          />
          <AdminRoute
            exact
            path="/admin/coupons/create-coupon"
            component={Coupon}
          />
          <AdminRoute
            exact
            path="/admin/admin-accounts/invoices"
            component={Invoices}
          />
          <AdminRoute
            exact
            path="/admin/admin-accounts/order-invoice/:orderId"
            component={Invoice}
          />
          <AdminRoute
            exact
            path="/admin/admin-accounts/paying-dusters"
            component={PayingDusters}
          />
          <AdminRoute
            exact
            path="/admin/admin-accounts/orders-on-account"
            component={OrdersOnAccount}
          />
          <AdminRoute
            exact
            path="/admin/admin-accounts/duster-payslip/:dusterId"
            component={Payslip}
          />
          <AdminRoute
            exact
            path="/admin/admin-accounts/recurring-order-overview"
            component={ReOrderOverview}
          />

          <Route exact path="/user/signup" component={UserSignUp} />
          <Route exact path="/user/agreement" component={UserAgree} />
          <Route exact path="/user/signin" component={UserSignIn} />
          <Route exact path="/verify/user/:token" component={UserVerify} />
          <Route
            exact
            path="/user/password-reset-request"
            component={ForgotUserPassword}
          />
          <Route
            exact
            path="/user/password-reset/:token"
            component={ResetUserPassword}
          />
          <Route exact path="/google/callback/:token" component={GGCallback} />
          <Route
            exact
            path="/facebook/callback/:token"
            component={FBCallback}
          />
          <Route exact path="/user/booking/" component={Booking} />
          <UserRoute exact path="/booking/check-out" component={CheckOut} />
          <UserRoute
            exact
            path="/booking/repeat-checkout"
            component={RepeatCheckOut}
          />
          <Route
            exact
            path="/user/payment/payment-result/:orderId"
            component={PaymentResponse}
          />

          <Route
            exact
            path="/user/payment/re-payment-result/:orderId"
            component={RePaymentResponse}
          />

          <UserRoute exact path="/payments/free-order" component={FreeOrder} />
          <UserRoute
            exact
            path="/payments/monthly-order"
            component={MonthlyOrder}
          />
          <UserRoute exact path="/user/my-orders" component={MyOrders} />
          <UserRoute
            exact
            path="/user/my-repeat-orders"
            component={MyReOrders}
          />
          <UserRoute exact path="/user/my-order/:orderId" component={MyOrder} />
          <UserRoute
            exact
            path="/user/my-re-order/:orderId"
            component={MyReOrder}
          />
          <UserRoute exact path="/user/my-profile" component={MyProfile} />
          <UserRoute
            exact
            path="/user/my-order-history"
            component={OrderHistory}
          />
          <UserRoute
            exact
            path="/user/my-order-invoice/:orderId"
            component={MyInvoice}
          />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
