import React from 'react';
import Bottom from '../Layout/Bottom';
const UserAgree = () => {
  return (
    <>
      <div className="container">
        <div>
          <h3 className="text-center">
            Our Terms and Conditions for you as a user including our privacy and
            cancelation policy
          </h3>
        </div>
        <div>
          <b>Terms and conditions</b>
          <p>
            Welcome to the Dusting Down website terms and conditions for use;
          </p>
          <p>
            <b> I. (“Terms”).</b> These Terms apply to the use of this Website
            and by accessing this Website and/or making a Booking for Services
            you agree to be bound by the Terms set out below. By not agreeing
            the terms in the (I accept the terms tab, your order will be
            cancelled and not processed. You are welcome to contact us regarding
            the terms on the following email address legal@dustingdown.co.uk
          </p>
          <b>II. DEFINITIONS</b>
          <p>
            • “Booking” means a booking for Services made by You on our Website
            or mobile app
          </p>
          <p>
            • “Services” means any service, or advice or assistance or any other
            home, offices or personal services.
          </p>
          <p>
            • “Dusting Down” means a cleaner or employee, subcontractor or
            independent contractor who may provide a service through our website
            or mobile app
          </p>
          <p> • “We/us” means Dusting Down Limited(No 13530029) </p>
          <p>• “Our Address” means 19 Western Street, Manchester M18 8GG</p>
          <p>
            • “Website” means the website located at
            https://www.dustingdown.co.uk and or dustingdown.com and or
            dustingdown.co.uk And or any subsequent URL which may replace it.
          </p>
          <p>• “You” means a user of this Website.</p>
          <p>
            • “Dust Buster” means an independent contractor who uses our
            App/Platform and or Website to obtain temporary work or placement.
          </p>
          <b>1. USE OF OUR PLATFORM</b>
          <p>
            1.1. Access: -You are provided with access to this Website in
            accordance with these Terms and any Booking made by you must be
            placed strictly in accordance with these Terms.
          </p>
          <p>
            1.2. By ticking the terms and condition box you acknowledge our
            terms and conditions
          </p>
          <p>1.3. Registration </p>
          <p className="ml-4">
            1.3.1. By registering as a user of our website, you warrant that:
          </p>
          <p className="ml-4">
            1.3.2. the personal information which you provide when you register
            as a user will be the legal information used when we confirm a
            booking on your behalf, is true, accurate, current and complete in
            all respects.
          </p>
          <p className="ml-4">
            1.3.3. you will notify and update the Dusting Down user portal
            immediately of any changes to this personal information.
          </p>
          <p className="ml-4">
            1.3.4. This update is total and legally your responsibility and
            services render to the incorrect address will not render us
            responsible.
          </p>
          <p className="ml-4">
            1.3.5. You agree not to impersonate any other person or entity or to
            use a false name or a name that you are not authorised to use.
          </p>
          <p className="ml-4">
            1.3.6. You also knowledge that if you change your phone number you
            will have to re-register on the platform.
          </p>
          <b>2. OUR RIGHTS</b>
          <p>2.1. We reserve the right to:</p>
          <p>
            2.2. modify or withdraw, temporarily or permanently, this Website
            (or any part thereof) with or without notice to you and you confirm
            that we shall not be liable to you or any third party for any
            modification to or withdrawal of the Website; and/or the change
            these Terms and conditions from time to time.
          </p>
          <p>
            2.3. We have the right to revise and amend these terms from time to
            time. You will be subject to the policies and terms in force at the
            time that you book Services from us.
          </p>
          <p>
            2.4. Dusting Down and our business partners may change from time to
            time. As a result, at times it may be necessary for Dusting Down to
            make changes to our terms and conditions.
          </p>
          <b>3. YOUR RIGHTS</b>
          <p>
            3.1. As a British citizen, you have the right to ask us not to
            process your personal information for marketing purposes by
            contacting us.
          </p>
          <p>
            3.2. The Data Protection Act 2018 gives you the right to access
            information held about you. Any access request may be subject to an
            administrative fee of £65.00 per request.
          </p>
          <p>
            3.3. We may store information such as your name; your contact
            telephone numbers; your e-mail address; home and or work or postal
            address that you submitted; information about your home which you
            give us; your payment details; your IP address; and any other
            personal information which you give us in sing up pages. We will use
            commercially reasonable efforts to honour your request.
          </p>
          <p>
            3.4. Your request must be presented in a formal application along
            with a certified copy of your identity document.
          </p>
          <p>
            3.5. Dusting Down does not offer any refunds for services provided,
            which is why we always encourage our customers to fully inspect the
            cleaning before your Dust Buster leaves to ensure that you are
            completely satisfied with the results.
          </p>
          <p>
            3.6. If you are dissatisfied with the service, then any issue must
            be reported within 24 hours of completion of the service. We will
            then attempt to rectify these issues.
          </p>
          <b>4. PRIVACY</b>
          <p>
            4.1. We process information about you in accordance with our Privacy
            Policy. By using our website, you consent to such processing, and
            you warrant that all data provided by you is accurate.
          </p>
          <p>
            4.2. We may retain an archived copy of your records as required by
            law or for legitimate business purposes. Every effort has been made
            on this website, or mobile application to ensure that making a
            Booking is secure.
          </p>
          <p>
            4.3. The Website may be used only for lawful purposes and in a
            lawful manner. You agree to comply with all applicable laws,
            statutes and regulations regarding the Website and any transactions
            conducted on or through the Website
          </p>
          <b>5. THE DUSTING DOWN SERVICES WEB APPLICATION</b>
          <p>
            5.1. We function as a booking agency for self-employed independent
            contractors,
          </p>
          <p className="ml-4">
            5.1.1. Our contract with you is limited to provision of use of our
            website.
          </p>
          <p className="ml-4">
            5.1.2. Your Dusting Down contractor is responsible for providing the
            Services to you, as per the list you select on our platform.
          </p>
          <p className="ml-4">
            5.1.3. You have a direct contract between you and the Dusting Down
            contractor concerning the provision of Services.
          </p>
          <p className="ml-4">5.1.4. This contract does not include us.</p>
          <p className="ml-4">
            5.1.5. The Independent contractor has an independent contract with
            us allowing for all statutory deduction to be deducted from his/her
            invoice to us, and we pay the said amount over to the said
            authorities.
          </p>
          <p>
            5.2. Independent Contractors are responsible for all taxes arising
            as a result of the Service.
          </p>
          <p>
            5.3. We review the application process, conduct interviews, and
            undertake other checks. The independent contractors are also
            required to attend certain training update seminars each quarter to
            remain vetted by the Dusting Down.
          </p>
          <p>
            5.4. Some of these processes are reliant on information from third
            parties.
          </p>
          <p>
            5.5. We do not and cannot guarantee the accurateness or completeness
            of such checks, whether conducted by us or by third parties.
          </p>
          <p>
            5.6. You acknowledge that you use the Dusting Down ’s appointed
            independent contractors at your own risk.
          </p>
          <p>
            5.7. You agree to provide a safe working environment for the team
            and to take appropriate precautions to supervise your appointed
            Collaborator.
          </p>
          <b>6. BOOKING SERVICES</b>
          <p>
            6.1. By placing an order through our website, you warrant that you
            are: legally capable of entering into binding contracts, and at
            least 18 years old.
          </p>
          <p>6.2. All Bookings are subject to acceptance by us,</p>
          <p>
            6.3. We will confirm such acceptance to you by sending you an email
            and or push notification.
          </p>
          <p className="ml-2">
            6.3.1. The Notification will include the following
          </p>
          <p className="ml-4">
            6.3.1.1. Contractor’s name and telephone number.
          </p>
          <p className="ml-4">6.3.1.2. Total estimate cost</p>
          <p className="ml-4">
            6.3.1.3. Date and Time the service was booked for.
          </p>
          <p className="ml-2">
            6.3.2. The contracts between us will only be concluded when we send
            you the booking Confirmation.
          </p>
          <b>7. ENGAGING PRIVATELY WITH THE CONTRACTOR</b>
          <p>
            7.1. You warrant that you will not seek to book any DustingDown
            Services or contractor other than via our website for the duration
            of this agreement and in the 3 months after termination of this
            agreement.
          </p>
          <p>
            7.2. In the event that you offer our Team members or independent
            contractors’ alternative employment or opportunities you will be
            invoiced and agree to pay a placement fee of no less than that of
            150 hours of your average invoice amount.
          </p>
          <b>8. PRICE AND PAYMENT</b>
          <p>
            8.1. The price of any Services will be as quoted on our booking
            tool.
          </p>
          <p>
            8.2. Special cleans, deep cleans and office cleaning will be quoted
            by our office.
          </p>
          <p>
            8.3. Prices are liable to change at any time, but changes will not
            affect orders in respect of which we have already sent you a booking
            confirmation.
          </p>
          <p>
            8.4. We cannot be responsible for fraudulent use on our website of a
            lost payment card.
          </p>
          <p> 8.5. You must maintain a valid payment card with us. </p>
          <p>
            8.6. You must log in to the “Dusting Down portal” immediately if
            your payment card details are no longer valid.
          </p>
          <p>
            payment on acceptance of the booking of the service. You also
            authorise us to charge your payment card in full on the day of the
            scheduled Booking should the actual work change.
          </p>
          <p> 8.7. You authorise your</p>
          <p>
            8.8. Any refunds due to you will be processed at the end of the
            service and will be credited to the payment card.
          </p>
          <p>
            8.9. If any amount due by you is declined, the scheduled Booking
            will be cancelled, and you will be indebted to the company for 75%
            of the clean.
          </p>
          <p>
            8.10. Your services with us will be terminated till such time the
            payment has been made.
          </p>
          <p>
            8.11. A promotional code, credit, gift card or voucher may only be
            used by customers it is addressed to. Offer codes are based on once
            per household.
          </p>
          <p>
            8.12. Offers can only be redeemed once per customer and cannot be
            resold to or exchanged with a third party.
          </p>
          <p>
            8.13. Offers are not redeemable for cash and will not be replaced if
            lost, stolen, destroyed, or used without permission.
          </p>
          <p>
            8.14. Offers can be redeemed for a maximum of 3 working hours, one
            special offer per transaction.
          </p>
          <p>
            8.15. Dusting Down may at its sole discretion offer its own
            discounts and promotional offers (“Dusting Down promotional offers”)
            to potential and existing customers to promote Dusting Down ’s
            business as an agent.
          </p>
          <p>
            8.16. Offers will not reduce the consideration received by the
            cleaner.
          </p>
          <b>9. BOOKING CANCELLATION BY US</b>
          <p>
            9.1. If you violate any of the Terms, your permission to use the
            Services and Website will automatically terminated.
          </p>
          <p>
            9.2. We reserve the right to cancel any Booking without notice or
            cause. We will however send you such notification.
          </p>
          <b>10. BOOKING CANCELLATION BY YOU</b>
          <p>
            10.1. You may cancel or reschedule a Booking until 18:00 the day
            before the Booking for free.
          </p>
          <p>
            10.2. For cancellations after 18:00 the day before the Booking, or
            if your Dust Buster, arrives for the clean and is unable to gain
            entry or start the clean, you will be charged the full price of the
            Booking.
          </p>
          <b>11. INSURANCE AND DAMAGE</b>
          <p>
            11.1. We have a public liability insurance policy underwritten by a
            leading insurer. There are excesses and terms and conditions of
            loss, and these can be made available in the event of a claim.
          </p>
          <p>11.2. All excesses payables will be at your cost.</p>
          <p>
            11.3. This policy is only able to cover extreme circumstances, for
            example, severe injury to the Team player or severe damage to
            property such as fire.
          </p>
          <p>
            11.4. Other insurance is available, by clicking we accept insurance
            tab, when clicking this tab term and conditions will be attached to
            your invoice.
          </p>
          <p>
            11.5. The cover for insurance is only while we are on site and is
            limited to the term and conditions of the insurer.
          </p>
          <p>
            11.6. Dusting Down does not accept any liability for Loss, Damage or
            Theft at the location of the property in the order as a result of
            the (Cleaner/s) accessing the property to perform their duties. Our
            (Cleaner/s) will do their best to ensure that utmost care is taken
            when cleaning said property however sometimes accidents can happen.
            In this case we highly recommend that all households have the
            relevant insurance in place to cover any loss including theft or
            damages caused as a result of the service provided.
          </p>
          <b>12. THIRD PARTY LINKS AND SECURITY</b>
          <p>
            12.1. Links to third party websites on the Website are provided
            solely for your convenience.
          </p>
          <p>
            12.2. If you use these links, we do not control and are not
            responsible for these websites or their content or availability.
          </p>
          <p>
            12.3. We therefore do not endorse or make any representations about
            them, or any material found there, or any results that may be
            obtained from using them.
          </p>
          <p>
            12.4. If you decide to access any of the third-party websites linked
            to the Website, you do so entirely at your own risk.
          </p>
          <p>
            12.5. However, no Internet or e-mail transmission is ever fully
            secure or error free; any transmission is at your own risk. E-mail
            sent to or from the Services may not be secure. Therefore, you
            should take exceptional care in deciding what information you send
            to us via e-mail. Please keep this in mind when disclosing any
            Personal Data to Dusting Down via the Internet
          </p>
          <p>
            12.6. In operating the Services, we may use cookies. Dusting Down
            utilises first- and third-party cookies. This enables us to: a)
            Improve the User's experience on our website through various means
            such as storing preferences or determining aggregate usage behaviour
            through web analytics tools like Google Analytics.
          </p>
          <p>
            12.7. Most web browsers provide the option to block some, or all
            cookie types should you wish to.
          </p>
          <b>13. CHILDREN</b>
          <p>
            13.1. Dusting Down does not knowingly collect Personal Data from
            children (individuals under the age of 18).
          </p>
          <p>
            13.2. if you are under the age of eighteen, please do not submit any
            Personal Data through the Services. We encourage parents and legal
            guardians to monitor their children's Internet usage and to help
            enforce our Privacy Policy by instructing their children never to
            provide Personal Data on the Services without their permission.
          </p>
          <p>
            13.3. If you have reason to believe that a child under the age of 18
            has provided Personal Data to Dusting Down through the Services,
            please contact us, and we will endeavour to delete that information
            from our databases.
          </p>
          <b>14. LIABILITY</b>
          <p>
            14.1. Our liability for the losses you suffer as a result of us
            breaking this agreement is strictly limited to invoiced amount of
            the Services you purchased on the previous 3 invoices.
          </p>
          <p>
            14.2. This limitation does not include or limit in any way our
            liability for death or personal injury caused by our negligence,
            fraud or fraudulent misrepresentation, or any matter for which it
            would be illegal for us to exclude, or attempt to exclude, our
            liability.
          </p>
          <p>
            14.3. We are not responsible for indirect losses which happen as a
            side effect of the main loss or damage, including but not limited:
            to loss of income or revenue; loss of business; loss of profits or
            contracts; loss of anticipated savings; loss of data; or, waste of
            management or office time however arising and whether caused by tort
            (including negligence), breach of contract or otherwise.
          </p>
          <p>
            14.4. We will take all reasonable care, in so far as it is in our
            power to do so, to keep the details of your Booking and payment
            secure, but in the absence of negligence on our part we cannot be
            held liable for any loss you may suffer if a third party procures
            unauthorised access to any data you provide when accessing or
            ordering from the Website.
          </p>
          <b>15. EVENTS OUTSIDE OUR CONTROL</b>
          <p>
            15.1. We will not be liable or responsible for any failure to
            perform, or delay in performance of, any of our obligations under a
            Contract that is caused by events outside our reasonable control
            (“Force Majeure Event”).
          </p>
          <p>
            15.2. Each Party is excused from performing its obligations under
            this Agreement to the extent that such performance is prevented by
            an act or event (a "Force Majeure Event") whether or not foreseen,
            that: (i) is beyond the reasonable control of, and is not due to the
            fault or negligence of, such Party, and (ii) could not have been
            avoided by such Party's exercise of due diligence, including, but
            not limited to, a labour controversy, strike, lockout, boycott,
            transportation stoppage, action of a court or public authority,
            fire, flood, earthquake, storm, war, civil strife, terrorist action,
            epidemic, or act of God; provided that a Force Majeure Event will
            not include economic hardship, changes in market conditions, or
            insufficiency of funds. Notwithstanding the foregoing sentence, a
            Force Majeure Event does not excuse any obligation to make any
            payment required by this Agreement.
          </p>
          <p>
            15.3. Our performance under any Contract is deemed to be suspended
            for the period that the Force Majeure Event continues, and we will
            have an extension of time for performance for the duration of that
            period. We will use our reasonable endeavours to bring the Force
            Majeure Event to a close or to find a solution by which our
            obligations under the Contract may be performed despite the Force
            Majeure Event.
          </p>
          <b>16. GENERAL</b>
          <p>16.1. Intellectual property and right to use</p>
          <p>
            16.2. Unless otherwise stated, the copyright and other intellectual
            property rights in the content on the Website are owned by us or our
            licensors. Any access or use of the Website for any reason other
            than your personal, non-commercial use is prohibited.
          </p>
          <p>
            16.3. No part of the Website may be reproduced or stored in any
            other website or included in any public or private electronic
            retrieval system or Website without our prior written permission.
          </p>
          <p>
            16.4. If you publish any content on our website, such as reviews,
            comments, ratings, profiles, or listings, you grant us a worldwide,
            perpetual, non-exclusive, royalty-free licence to copy, alter, adapt
            or display such Content on our Website.
          </p>
          <p>
            16.5. We reserve the right to remove any comments from our social
            pages as well as any comments on paid adverts on any social media
            sites.
          </p>
          <p>
            16.6. All winners for social media competitions are chosen at our
            discretion.
          </p>
          <p>
            16.7. We try our best to reply to any queries on our social media
            sites as quickly as possible between 7am and 7pm.
          </p>
          <p>
            16.8. For any urgent queries outside of these hours, please contact
            us
          </p>
          <b>17. WRITTEN COMMUNICATIONS</b>
          <p>
            17.1. Applicable laws require that some of the information or
            communications we send to you should be in writing.
          </p>
          <p>
            17.2. When using our site, you accept that communication with us
            will be mainly electronic.
          </p>
          <p>
            17.3. We will contact you by email with the @dustingdown.co.uk.If
            you are unsure about an email received by us always call the number
            on the website.
          </p>
          <p>
            17.4. We will never ask you for your bank card number, pin, one time
            pine OTP or any such information. All banking transactions are
            concluded online.
          </p>
          <p>
            17.5. For contractual purposes, you agree to this electronic means
            of communication and you acknowledge that all contracts, notices,
            information, and other communications that we provide to you
            electronically comply with any legal requirement that such
            communications be in writing. This condition does not affect your
            statutory rights.
          </p>
          <b>18. NOTICES</b>
          <p>
            18.1. All notices given by you to us must be given to FibreClean
            limited, at 19 Western Street Manchester, M1 88GG. We may give
            notice to you at either the email or postal address you provide to
            us when making a Booking.
          </p>
          <p>
            18.2. Notice will be deemed received and properly served immediately
            when posted on our website, 24 hours after an email is sent.
          </p>
          <p>
            18.3. It will be sufficient to prove, in the case of an email that
            such email was sent to the specified email address of the addressee.
          </p>
          <b>19. TRANSFER OF RIGHTS AND OBLIGATIONS</b>
          <p>
            19.1. The contract between you and us is binding on you and us and
            on our respective successors and assigns.
          </p>
          <p>
            19.2. You may not transfer, assign, charge or otherwise dispose of a
            Contract, or any of your rights or obligations arising under it,
            without our prior written consent.
          </p>
          <p>
            19.3. We may transfer, assign, charge, sub-contract or otherwise
            dispose of a Contract, or any of our rights or obligations arising
            under it, at any time during the term of the Contract.
          </p>
          <b>20. SEVERANCE</b>
          <p>
            if any part of these Terms shall be deemed unlawful, void or for any
            reason unenforceable, then that provision shall be deemed to be
            severable from these Terms and shall not affect the validity and
            enforceability of any of the remaining provisions of these Terms.
          </p>
          <b>21. WAIVER</b>
          <p>
            No waiver by us shall be construed as a waiver of any proceeding or
            succeeding breach of any provision.
          </p>
          <b>22. ENTIRE AGREEMENT AND OR TERMS AND CONDITIONS OF USE</b>
          <p>
            These Terms and any document expressly referred to in them
            constitute the entire
          </p>
          <b>23. THE LAW OF THE LAND</b>
          <p>
            These Terms and the Contracts shall be governed by and construed in
            accordance with the laws of South Africa, and you irrevocably submit
            to the exclusive jurisdiction of the courts. Any dispute or claim
            arising out of or in connection with the Contracts or their
            formation (including non-contractual disputes or claims) shall be
            subject to the exclusive authority of the court. END - Updated
            January 2022
          </p>
        </div>
      </div>

      <Bottom />
    </>
  );
};

export default UserAgree;
