import axios from 'axios';
import {
  NOT_BOOKED_NUM,
  NOT_BOOKED,
  RUNNING_LATE,
  RUNNING_LATE_NUM,
  ADMIN_REPEAT_ORDER,
  ADMIN_REORDERS,
  ORDER_IN_PROGRESS,
  ORDER_IN_PROGRESS_NUM,
  TODAYS_ORDERS,
  TODAYS_ORDERS_NUM,
  COMPLETE_ORDERS,
  RE_NOT_BOOKED,
  RE_NOT_BOOKED_NUM,
  RE_IN_PROGRESS,
  RE_IN_PROGRESS_NUM,
  REPEAT_TODAY,
  REPEAT_TODAY_NUM,
  REQUIRE_VALIDATION,
  REQUIRE_VALIDATION_NUM,
  ADMIN_ORDER,
  AREA_CLEANERS,
  CLEAR_ADMIN_ORDER,
  MISSED_ORDERS,
  MISSED_ORDERS_NUM,
  MISSED_RE_ORDER,
  LATE_REORDERS_NUM,
  RE_RUNNING_LATE,
  COMPLETE_ORDERS_NUM,
  VALIDATE_DISTANCE,
  REPEAT_VALIDATION_NUM,
  MISSED_RE_ORDERS_NUM,
  COMPLETE_RE_ORDERS,
  COMPLETE_RE_ORDERS_NUM,
  REPEAT_VALIDATION,
  TO_COMPLETE_NUM,
  TO_COMPLETE,
  ALLOCATED_ORDERS,
  ALLOCATED_ORDERS_NUM,
  SWAP_CLEANERS,
  CLEAR_SWAP_CLEANERS,
  DUSTER_ON_BOOKING,
} from '../types';

import { setNote } from '../note';

//Null Day Cleaners//
export const setDayCleaners = () => async (dispatch) => {
  dispatch({ type: CLEAR_SWAP_CLEANERS });
};

////////////////////////////////////
//Get Avaialble cleaners for order//
///////////////////////////////////
export const getCleanersForOrder = (orderId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/order-management/once-off/area-dusters/${orderId}`
    );

    dispatch({ type: AREA_CLEANERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////
//Get a specific order//
////////////////////////
export const getTheOrder = (orderId) => async (dispatch) => {
  dispatch({ type: CLEAR_ADMIN_ORDER });
  try {
    const res = await axios.get(
      `/api/admin/order-management/once-off/order/${orderId}`
    );
    dispatch({ type: ADMIN_ORDER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////
//Reschedule a order///
///////////////////////
export const changeDate =
  ({ orderId, orderTime, invoiceHours, dusterId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ orderTime, invoiceHours, dusterId });
    try {
      const res = await axios.put(
        `/api/admin/order-management/once-off/re-schedule/${orderId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      getTheOrder(orderId);
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
    }
  };

/////////////////////////////
//Assign a cleaner to order//
/////////////////////////////
export const assignCleaner =
  ({ orderId, dusterId, start, end }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ orderId, dusterId, start, end });
    try {
      const res = await axios.put(
        '/api/admin/order-management/once-off/assign-duster',
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      getTheOrder(orderId);
    } catch (error) {
      console.error(error.message);
    }
  };

////////////////////////////
//Remove Duster Form Order//
////////////////////////////
export const removeFromOrder = (orderId) => async (dispatch) => {
  try {
    const res = await axios.put(
      `/api/admin/order-management/once-off/order/${orderId}`
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    getTheOrder(orderId);
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////
//Validate Checkin//
////////////////////
export const validateCheckin = (orderId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/order-management/once-off/validate-checkin/${orderId}`
    );
    dispatch({ type: VALIDATE_DISTANCE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////////////////
//Accept Manual Checkin request//
/////////////////////////////////
export const acceptCheckingRequest = (orderId) => async (dispatch) => {
  try {
    const res = await axios.put(
      `/api/admin/order-management/once-off/accept-checkin-request/${orderId}`
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    getTheOrder(orderId);
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////////////////////
//Check-out duster in one off clean//
/////////////////////////////////////
export const dusterCheckOut =
  ({ orderId, time }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ time });
    try {
      const res = await axios.put(
        `/api/admin/order-management/once-off/check-out-duster/${orderId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      getTheOrder(orderId);
    } catch (error) {
      console.error(error.message);
    }
  };

//Delete the booking//
export const deleteOrder = (orderId) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `/api/admin/order-management/once-off/delete-order/${orderId}`
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////
//Allocated Orders//
////////////////////
export const allocatedOrdersNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/allocated-orders-number'
    );
    dispatch({ type: ALLOCATED_ORDERS_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const allocatedOrders = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/allocated-orders'
    );
    dispatch({ type: ALLOCATED_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////
//Get Missed Orders//
/////////////////////
export const missedOrders = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/missed-orders'
    );
    dispatch({ type: MISSED_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const missedOrdersNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/missed-orders-number'
    );
    dispatch({ type: MISSED_ORDERS_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////////
//Orders without Cleaners//
///////////////////////////
export const notBooked = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/not-booked'
    );
    dispatch({ type: NOT_BOOKED, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const notBookedNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/not-booked-number'
    );
    dispatch({ type: NOT_BOOKED_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////////////////
//Orders that require validation//
//////////////////////////////////
export const validateOrders = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/require-validation'
    );
    dispatch({ type: REQUIRE_VALIDATION, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const validateOrdersNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/require-validation-number'
    );
    dispatch({ type: REQUIRE_VALIDATION_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////////
//Cleaners Running Late//
/////////////////////////
export const runningLate = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/running-late'
    );
    dispatch({ type: RUNNING_LATE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const runningLateNumber = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/running-late-number'
    );
    dispatch({ type: RUNNING_LATE_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////
//Orders in progress//
//////////////////////
export const inProgress = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/in-progress'
    );
    dispatch({ type: ORDER_IN_PROGRESS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const inProgressNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/in-progress-number'
    );
    dispatch({ type: ORDER_IN_PROGRESS_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////
//Todays Orders//
/////////////////
export const todaysOrders = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/todays-orders'
    );
    dispatch({ type: TODAYS_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const todaysOrdersNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/todays-orders-number'
    );

    dispatch({ type: TODAYS_ORDERS_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////
//Complete Orders//
///////////////////
export const completeOrders = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/complete-orders'
    );
    dispatch({ type: COMPLETE_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const completeOrdersNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/once-off/complete-orders-number'
    );
    dispatch({ type: COMPLETE_ORDERS_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////
/////////////////
//Repeat orders//
/////////////////
/////////////////
export const getRepeatOrder = (reOrderId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/order-management/repeat-orders/order/${reOrderId}`
    );
    dispatch({ type: ADMIN_REPEAT_ORDER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const getRepeatOrders = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/order-management/repeat-orders');
    dispatch({ type: ADMIN_REORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////
//Delete Repeat Order//
///////////////////////
export const delReOrder = (orderId) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `/api/admin/order-management/repeat-orders/order/${orderId}`
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    console.error(error.messsage);
  }
};

/////////////////////////////
//Reschedule a repeat order//
/////////////////////////////
export const reScheduleRepeat =
  ({ reOrderId, oldDate, newDate, invoiceHours, dusterId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ oldDate, newDate, invoiceHours, dusterId });
    try {
      const res = await axios.put(
        `/api/admin/order-management/repeat-orders/reschedule/${reOrderId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      getRepeatOrder(reOrderId);
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setNote(error.msg, 'danger')));
      }
      getRepeatOrder(reOrderId);
    }
  };

////////////////////////////////////
//Get Avaialble cleaners for order//
///////////////////////////////////
export const getCleanersForReOrder = (reOrderId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/order-management/repeat-order/area-dusters/${reOrderId}`
    );

    dispatch({ type: AREA_CLEANERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////////////////
//Assign Cleaner to repeat order//
//////////////////////////////////
export const assignReCleaner =
  ({ orderId, dusterId, schedule }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ orderId, dusterId, schedule });

    try {
      const res = await axios.put(
        '/api/admin/order-management/repeat-order/assign-duster',
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      getRepeatOrder(orderId);
    } catch (error) {
      console.error(error.message);
    }
  };

////////////////////////////////////
//Remove cleaner from repeat Order//
////////////////////////////////////
export const removeFromReOrder = (orderId) => async (dispatch) => {
  try {
    const res = await axios.put(
      `/api/admin/order-management/repeat-order/order/${orderId}`
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    getRepeatOrder(orderId);
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////
//Find for a day//
//////////////////
export const findForADay =
  ({ orderId, start }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_SWAP_CLEANERS });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ start });
    try {
      const res = await axios.put(
        `/api/admin/order-management/repeat-order/find-for-a-day/${orderId}`,
        body,
        config
      );
      dispatch({ type: SWAP_CLEANERS, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };
/////////////////////////////
//Find the selected booking//
/////////////////////////////
export const findTheBooking =
  ({ orderId, start }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ start });
    try {
      const res = await axios.put(
        `/api/admin/order-management/repeat-order/find-selected-booking/${orderId}`,
        body,
        config
      );
      dispatch({ type: DUSTER_ON_BOOKING, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };
//////////////////
//Swap for a day//
//////////////////
export const swapForADay =
  ({ orderId, swapDay, dusterId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ swapDay, dusterId });
    try {
      const res = await axios.put(
        `/api/admin/order-management/repeat-order/swap-for-a-day/${orderId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

////////////////////////////////////
//Assign Cleaner After Reschedule///
////////////////////////////////////
export const swapIn =
  ({ orderId, start, dusterId, invoiceHours }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ start, dusterId, invoiceHours });

    try {
      const res = await axios.put(
        `/api/admin/order-management/repeat-order/assign-new-duster/${orderId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

////////////////////
//Validate Checkin//
////////////////////
export const validateReCheckin = (orderId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/admin/order-management/repeat-orders/validate-checkin/${orderId}`
    );
    dispatch({ type: VALIDATE_DISTANCE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const acceptReCheckingRequest = (orderId) => async (dispatch) => {
  try {
    await axios.put(
      `/api/admin/order-management/repeat-orders/accept-checkin-request/${orderId}`
    );
    dispatch(getRepeatOrder(orderId));
  } catch (error) {
    console.error(error.message);
  }
};

/////////////////////////
//Check-out the cleaner//
/////////////////////////
export const checkOutDuster =
  ({ orderId, a }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ a });
    try {
      const res = await axios.put(
        `/api/admin/order-management/repeat-orders/check-out-duster/${orderId}`,
        body,
        config
      );
      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
      dispatch(getRepeatOrder(orderId));
    } catch (error) {
      console.error(error.message);
    }
  };

///////////////////////
//Missed Repeat Order//
///////////////////////
export const missedReOrders = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/missed-reorder'
    );
    dispatch({ type: MISSED_RE_ORDER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const missedReOrdersNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/missed-reorder-number'
    );
    dispatch({ type: MISSED_RE_ORDERS_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////////////////
//Repeat orders without Cleaners//
//////////////////////////////////
export const reNotBooked = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/not-booked'
    );
    dispatch({ type: RE_NOT_BOOKED, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const reNotBookedNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/not-booked-number'
    );
    dispatch({ type: RE_NOT_BOOKED_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////////////
//Repeat Cleaners Running Late//
////////////////////////////////
export const lateRepeatCleaners = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/running-late'
    );
    dispatch({ type: RE_RUNNING_LATE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const lateRepeatCleanersNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/running-late-number'
    );
    dispatch({ type: LATE_REORDERS_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////////////
//Currently Active Orders//
///////////////////////////
export const activeRepeatOrders = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/in-progress'
    );
    dispatch({ type: RE_IN_PROGRESS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const activeRepeatOrdersNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/in-progress-number'
    );
    dispatch({ type: RE_IN_PROGRESS_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

////////////////////////////////////
//Repeat Orders with booking today//
////////////////////////////////////
export const repatOrdersToday = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/todays-bookings'
    );
    dispatch({ type: REPEAT_TODAY, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const repeatOrdersTodayNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/todays-bookings-number'
    );
    dispatch({ type: REPEAT_TODAY_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////////////////
//Orders that require validation//
//////////////////////////////////
export const validateReOrders = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/require-validation'
    );
    dispatch({ type: REPEAT_VALIDATION, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const validateReOrdersNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/require-validation-number'
    );
    dispatch({ type: REPEAT_VALIDATION_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////////
//Orders to be completed//
//////////////////////////
export const toComplete = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/assigned-dusters'
    );
    dispatch({ type: TO_COMPLETE, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const toCompleteNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/assigned-dusters/number'
    );
    dispatch({ type: TO_COMPLETE_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//////////////////////////
//Complete Repeat Orders//
//////////////////////////
export const completeRepeatOrders = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/complete-orders'
    );
    dispatch({ type: COMPLETE_RE_ORDERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const completeRepeatOrdersNum = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/admin/order-management/repeat-orders/complete-orders-number'
    );
    dispatch({ type: COMPLETE_RE_ORDERS_NUM, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};
