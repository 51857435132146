import React, { useEffect } from 'react';
import { Spinner, Alert, ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { validateReOrders } from '../../../actions/admin/orders';
import ReOrderItems from './ReOrderItems';

function RepeatValidation({ validateReOrders, orders }) {
  useEffect(() => {
    validateReOrders();
  }, [validateReOrders]);

  if (orders === null) {
    return <Spinner variant="primary" animation="grow" />;
  }
  return (
    <div className="container">
      <Alert variant="warning text-center">
        Repeat Orders that need validation
      </Alert>
      <ListGroup variant="flush text-center">
        {orders.length === 0 ? (
          <Alert variant="success">There are no repeat orders today</Alert>
        ) : (
          orders.map((order) => <ReOrderItems key={order._id} order={order} />)
        )}
      </ListGroup>
    </div>
  );
}

const mapStateToProps = (state) => ({
  orders: state.admin_orders.repeat_validation,
});

export default connect(mapStateToProps, { validateReOrders })(RepeatValidation);
