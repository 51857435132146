import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { validateOrders } from '../../../actions/admin/orders';
import { Container, Spinner, Alert, ListGroup, Button } from 'react-bootstrap';
import OrderItems from './OrderItems';
import { useHistory } from 'react-router-dom';

const RequireValidation = ({ validateOrders, orders }) => {
  useEffect(() => {
    validateOrders();
  }, [validateOrders]);
  let history = useHistory();
  if (orders === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  const goback = () => {
    history.push('/admin/order-management');
  };
  return (
    <div>
      <Container>
        <Alert variant="secondary text-center">
          <Button
            variant="outline-secondary float-left"
            size="sm"
            onClick={() => goback()}
          >
            Operations
          </Button>{' '}
          Orders that require validation
        </Alert>
        <ListGroup variant="flush text-center">
          {orders.length === 0 ? (
            <Alert variant="success">
              There are no orders that require validation
            </Alert>
          ) : (
            orders.map((order) => <OrderItems key={order._id} order={order} />)
          )}
        </ListGroup>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.admin_orders.require_validation,
});

export default connect(mapStateToProps, { validateOrders })(RequireValidation);
