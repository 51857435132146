import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { setNote } from '../../../actions/note';
import {
  Form,
  Row,
  Col,
  Alert,
  Button,
  InputGroup,
  FormControl,
} from 'react-bootstrap';

const WorkExperience = ({ stepTwo, dusterId, note, setNote }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [msg, setMsg] = useState(note);
  const [exp, setExp] = useState('');
  const [home, setHome] = useState(false);
  const [hotel, setHotel] = useState(false);
  const [office, setOffice] = useState(false);
  const [medical, setMedical] = useState(false);
  const [retail, setRetail] = useState(false);
  const [available, setAvailable] = useState([]);
  const [Mon, SetMon] = useState(false);
  const [Tue, SetTue] = useState(false);
  const [Wed, SetWed] = useState(false);
  const [Thu, SetThu] = useState(false);
  const [Fri, SetFri] = useState(false);
  const [Sat, SetSat] = useState(false);
  const [Sun, SetSun] = useState(false);
  const [eot, setEOT] = useState(false);
  const [name1, setName1] = useState('');
  const [contact1, setContact1] = useState('');
  const [duration1, setDuration1] = useState('');
  const [name2, setName2] = useState('');
  const [contact2, setContact2] = useState('');
  const [duration2, setDuration2] = useState('');
  let btn = useRef();
  const ref1 = { name1, contact1, duration1 };
  const ref2 = { name2, contact2, duration2 };

  useEffect(() => {
    if (note && btn.current) {
      setMsg(note);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (available.length === 0) {
      return setNote(
        'Please select at least one day that you are available to work',
        'warning'
      );
    }
    stepTwo({
      dusterId,
      available,
      exp,
      home,
      hotel,
      office,
      medical,
      retail,
      eot,
      ref1,
      ref2,
    });

    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  const monday = () => {
    if (Mon) {
      SetMon(false);
      let a = available.filter((day) => day !== 'Monday');
      setAvailable(a);
    } else {
      SetMon(true);
      available.push('Monday');
    }
  };
  const tuesday = () => {
    if (Tue) {
      SetTue(false);
      let a = available.filter((day) => day !== 'Tuesday');
      setAvailable(a);
    } else {
      SetTue(true);
      available.push('Tuesday');
    }
  };
  const wednesday = () => {
    if (Wed) {
      SetWed(false);
      let a = available.filter((day) => day !== 'Wednesday');
      setAvailable(a);
    } else {
      SetWed(true);
      available.push('Wednesday');
    }
  };
  const thursday = () => {
    if (Thu) {
      SetThu(false);
      let a = available.filter((day) => day !== 'Thursday');
      setAvailable(a);
    } else {
      SetThu(true);
      available.push('Thursday');
    }
  };
  const friday = () => {
    if (Fri) {
      SetFri(false);
      let a = available.filter((day) => day !== 'Friday');
      setAvailable(a);
    } else {
      SetFri(true);
      available.push('Friday');
    }
  };
  const saturday = () => {
    if (Sat) {
      SetSat(false);
      let a = available.filter((day) => day !== 'Saturday');
      setAvailable(a);
    } else {
      SetSat(true);
      available.push('Saturday');
    }
  };
  const sunday = () => {
    if (Sun) {
      SetSun(false);
      let a = available.filter((day) => day !== 'Sunday');
      setAvailable(a);
    } else {
      SetSun(true);
      available.push('Sunday');
    }
  };

  return (
    <div align="center">
      <h5 className="text-center">2. Work Experience</h5>
      <Form onSubmit={onSubmit}>
        <div className="mt-4" align="center">
          <Alert variant="secondary text-center">
            Briefly describe your work experience
          </Alert>
          <InputGroup style={{ width: '100%' }}>
            <FormControl
              as="textarea"
              placeholder="eg: I cleaned houses for 1 year"
              value={exp}
              onChange={(e) => setExp(e.target.value)}
            />
          </InputGroup>
          <Form.Text className="text-danger">
            *** Please give us brief description of your work experience to
            continue ***
          </Form.Text>
        </div>
        <Alert variant="secondary mt-3">Type of work you want to do?</Alert>
        <Row className="mt-3">
          <Col>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Hotel Cleaning"
                checked={hotel}
                value={hotel}
                onChange={(e) => setHotel(!hotel)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Home Cleaning"
                checked={home}
                value={home}
                onChange={(e) => setHome(!home)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Office Cleaning"
                checked={office}
                value={office}
                onChange={(e) => setOffice(!office)}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Retail Cleaning"
                checked={retail}
                value={retail}
                onChange={(e) => setRetail(!retail)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="End Of Tenancy"
                checked={eot}
                value={eot}
                onChange={(e) => setEOT(!eot)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Hospital Cleaning"
                checked={medical}
                value={medical}
                onChange={(e) => setMedical(!medical)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Text className="text-danger">
          *** You have to select at least one of these options to continue ***
        </Form.Text>
        <Alert variant="secondary mt-3">
          Please select which days you would like to work
        </Alert>
        <Row className="mt-3">
          <Col>
            <Button
              variant={Mon ? 'info' : 'outline-info'}
              onClick={monday}
              block
            >
              Mon
            </Button>
          </Col>
          <Col>
            <Button
              variant={Tue ? 'info' : 'outline-info'}
              onClick={tuesday}
              block
            >
              Tue
            </Button>
          </Col>
          <Col>
            <Button
              variant={Wed ? 'info' : 'outline-info'}
              onClick={wednesday}
              block
            >
              Wed
            </Button>
          </Col>
          <Col>
            <Button
              variant={Thu ? 'info' : 'outline-info'}
              onClick={thursday}
              block
            >
              Thu
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button
              variant={Fri ? 'info' : 'outline-info'}
              onClick={friday}
              block
            >
              Fri
            </Button>
          </Col>
          <Col>
            <Button
              variant={Sat ? 'info' : 'outline-info'}
              onClick={saturday}
              block
            >
              Sat
            </Button>
          </Col>
          <Col>
            <Button
              variant={Sun ? 'info' : 'outline-info'}
              onClick={sunday}
              block
            >
              Sun
            </Button>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6} xs={12}>
            <Alert variant="secondary">Give us a 1 of 2 references</Alert>
            <Form.Group>
              <Form.Label>Reference Name</Form.Label>
              <Form.Control
                placeholder="Reference Name"
                value={name1}
                onChange={(e) => setName1(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Reference Contact Number</Form.Label>
              <Form.Control
                placeholder="Reference Contact Number"
                type="number"
                value={contact1}
                onChange={(e) => setContact1(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Duration Of Employment</Form.Label>
              <Form.Control
                placeholder="Enter number of days/weeks/months or years?"
                value={duration1}
                onChange={(e) => setDuration1(e.target.value)}
              />
            </Form.Group>
            <Form.Text className="text-danger">
              *** Please fill in all the info above to continue ***
            </Form.Text>
          </Col>
          <Col md={6} xs={12}>
            <Alert variant="secondary">Give us a 2 of 2 references</Alert>
            <Form.Group>
              <Form.Label>Reference Name</Form.Label>
              <Form.Control
                placeholder="Reference Name"
                value={name2}
                onChange={(e) => setName2(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Reference Contact Number</Form.Label>
              <Form.Control
                placeholder="Reference Contact Number"
                type="number"
                value={contact2}
                onChange={(e) => setContact2(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Duration Of Employment</Form.Label>
              <Form.Control
                placeholder="Enter number of days/weeks/months or years?"
                value={duration2}
                onChange={(e) => setDuration2(e.target.value)}
              />
            </Form.Group>
            <Form.Text className="text-danger">
              *** Please fill in all the info above to continue ***
            </Form.Text>
          </Col>
        </Row>
        <hr />

        <Button
          className="mt-2 text-white"
          variant="primary"
          type="submit"
          ref={btn}
          block
        >
          Next Step<i className="fas fa-sign-in-alt ml-3"></i>
        </Button>
      </Form>
    </div>
  );
};

export default connect(null, { setNote })(WorkExperience);
