import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Alert, Button } from 'react-bootstrap';

const Accounts = () => {
  return (
    <div className="container">
      <Alert variant="info text-center">Accounts Page </Alert>
      <Row>
        <Col>
          <Button as={Link} to="/admin/admin-accounts/invoices">
            View By Invoices
          </Button>
        </Col>
        <Col>
          <Button as={Link} to="/admin/admin-accounts/paying-dusters">
            Cleaners to be paid
          </Button>
        </Col>
        <Col>
          <Button as={Link} to="/admin/admin-accounts/orders-on-account">
            Orders On Account
          </Button>
        </Col>
        <Col>
          <Button as={Link} to="/admin/admin-accounts/recurring-order-overview">
            Recurring order overview
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Accounts;
