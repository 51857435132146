import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  ListGroup,
  Alert,
  Button,
  Spinner,
  Modal,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import RedFlags from './RedFlags';
import DeleteDuster from './DeleteDuster';

const StageTwo = ({
  dustBuster,
  skills,
  completeApp,
  dusterId,
  deleteBtn,
  removeDuster,
}) => {
  const [nationality, setNationality] = useState(null);
  const [gender, setGender] = useState(null);
  const [office, setOffice] = useState(null);
  const [hotel, setHotel] = useState(null);
  const [home, setHome] = useState(null);
  const [medical, setMedical] = useState(null);
  const [eot, setEOT] = useState(null);
  const [retail, setRetail] = useState(null);
  const [show, setShow] = useState(false);
  const [flags, setFlags] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let history = useHistory();

  useEffect(() => {
    setFlags(dustBuster.redflags);
    if (dustBuster.local === true) {
      setNationality('UK Citizen');
    } else {
      setNationality('Foreign Citizen');
    }
    if (dustBuster.female === true) {
      setGender('Female');
    } else {
      setGender('Male');
    }
    if (skills) {
      if (skills.office === true) setOffice('Office Cleaning');
      if (skills.hotel === true) setHotel('Hotel Cleaning');
      if (skills.home === true) setHome('Home Cleaning');
      if (skills.medical === true) setMedical('Medical Cleaning');
      if (skills.eot === true) setEOT('End Of Tenancy Cleaning');
      if (skills.retail === true) setRetail('Retail Cleaning');
    }
  }, [dustBuster, skills]);

  if (skills === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  const { ref1, ref2 } = skills;

  const sendEmail = () => {
    completeApp({ dusterId });
  };

  const goBack = () => {
    history.push('/admin/team/stage-two');
  };

  return (
    <>
      <Row>
        <Col md={4} xs={12}>
          <Alert variant="info">
            Duster Info
            <DeleteDuster
              dusterId={dusterId}
              dustBuster={dustBuster}
              deleteBtn={deleteBtn}
              removeDuster={removeDuster}
            />
            <Button
              variant="secondary float-right ml-2"
              size="sm"
              onClick={() => goBack()}
            >
              back
            </Button>
            <Button size="sm" variant="warning float-right" disabled>
              Stage {dustBuster.stage}
            </Button>
          </Alert>
          <ListGroup variant="flush">
            <ListGroup.Item>Name: {dustBuster.name}</ListGroup.Item>
            <ListGroup.Item>Surname: {dustBuster.surname} </ListGroup.Item>
            <ListGroup.Item>ID Number: {dustBuster.idNum} </ListGroup.Item>
            <ListGroup.Item>Cellphone: {dustBuster.cellphone} </ListGroup.Item>
            <ListGroup.Item>Email: {dustBuster.email} </ListGroup.Item>
            <ListGroup.Item>Post code: {dustBuster.code} </ListGroup.Item>
            <ListGroup.Item>Address: {dustBuster.address} </ListGroup.Item>
            <ListGroup.Item>Gender: {gender} </ListGroup.Item>
            <ListGroup.Item>Nationality: {nationality} </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={8} xs={12}>
          <Alert variant="secondary">
            Dust Buster work experience{' '}
            <Button
              variant="warning float-right"
              size="sm"
              onClick={handleShow}
            >
              Send Email <i className="far fa-paper-plane"></i>
            </Button>
          </Alert>
          <ListGroup variant="flush">
            <ListGroup.Item>Work Experience: {skills.exp} </ListGroup.Item>
          </ListGroup>
          <Row>
            <Col>
              <Alert variant="secondary">Reference 1</Alert>
              <ListGroup variant="flush">
                <ListGroup.Item>Name: {ref1.name1}</ListGroup.Item>
                <ListGroup.Item>Contact: {ref1.contact1}</ListGroup.Item>
                <ListGroup.Item>Duration: {ref1.duration1}</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col>
              <Alert variant="secondary">Reference 2</Alert>
              <ListGroup variant="flush">
                <ListGroup.Item>Name: {ref2.name2}</ListGroup.Item>
                <ListGroup.Item>Contact: {ref2.contact2}</ListGroup.Item>
                <ListGroup.Item>Duration: {ref2.duration2}</ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Alert variant="secondary mt-4">Type Of Cleaning</Alert>
          <ListGroup variant="flush">
            {office && <ListGroup.Item>{office}</ListGroup.Item>}
            {hotel && <ListGroup.Item>{hotel}</ListGroup.Item>}
            {home && <ListGroup.Item>{home}</ListGroup.Item>}
            {medical && <ListGroup.Item>{medical}</ListGroup.Item>}
            {eot && <ListGroup.Item>{eot}</ListGroup.Item>}
            {retail && <ListGroup.Item>{retail}</ListGroup.Item>}
          </ListGroup>
          <Row>
            {flags
              ? flags.length > 0 && (
                  <Col xs={12} md={4}>
                    <Alert variant="warning mt-3">
                      Duster has flags
                      <i className="fab fa-font-awesome-flag float-right"></i>
                    </Alert>
                  </Col>
                )
              : ''}
            <Col xs={12} md={8} className="mt-3">
              {flags && flags.map((flag) => <RedFlags flag={flag} />)}
            </Col>
          </Row>
        </Col>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Send {dustBuster.name} an email</Modal.Title>
          </Modal.Header>
          <Modal.Body>We noticed you started applying ...</Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleClose}>
              <i className="far fa-window-close"></i>
            </Button>
            <Button variant="primary" onClick={sendEmail}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </>
  );
};

export default StageTwo;
