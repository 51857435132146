import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import PostCodeFinder from '../../../Layout/PostCodeFinder';

const EditUser = ({ user, postcodeFinder, setNote, updateClient, note }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (note && btn.current) {
      btn.current.removeAttribute('disabled');
      setShow(false);
    }
  }, [note]);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [cellphone, setCellphone] = useState('');
  const [userId, setUserId] = useState('');
  let btn = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (user) {
      setName(user.local.name);
      setSurname(user.local.surname);
      setEmail(user.local.email);
      setCode(user.code);
      setAddress(user.address);
      setCoordinates(user.coordinates);
      setCellphone(user.cellphone);
      setUserId(user._id);
    }
  }, [user]);

  const updateUser = () => {
    updateClient({
      name,
      surname,
      email,
      code,
      cellphone,
      address,
      coordinates,
      userId,
    });
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <div>
      <Button variant="outline-primary mt-2" onClick={handleShow} size="sm">
        Edit user
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Name: </Form.Label>
            <Form.Control
              type="text"
              placeholder={name}
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Surname: </Form.Label>
            <Form.Control
              type="text"
              placeholder={surname}
              name="surname"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email: </Form.Label>
            <Form.Control
              type="email"
              placeholder={email}
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Cellphone: </Form.Label>
            <Form.Control
              type="number"
              placeholder={cellphone}
              name="cellphone"
              value={cellphone}
              onChange={(e) => setCellphone(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Post Code:</Form.Label>
            <PostCodeFinder
              value={code}
              postcodeFinder={postcodeFinder}
              setCode={setCode}
              setCoordinates={setCoordinates}
              setNote={setNote}
              code={code}
            />
            <Form.Text className="text-muted">
              {code ? (
                <b className="text-success">User postcode is {code}</b>
              ) : (
                <b className="text-dark">Please enter your UK postcode</b>
              )}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Address: </Form.Label>
            <Form.Control
              type="text"
              placeholder={address}
              name="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" ref={btn} onClick={updateUser}>
            Update user
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditUser;
