import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { todaysOrders } from '../../../actions/admin/orders';
import {
  Container,
  Spinner,
  Alert,
  Row,
  Button,
  ListGroup,
} from 'react-bootstrap';
import OrderItems from './OrderItems';
import { useHistory } from 'react-router-dom';

const TodaysOrders = ({ todaysOrders, orders }) => {
  useEffect(() => {
    todaysOrders();
  }, [todaysOrders]);

  let history = useHistory();

  if (orders === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  const goback = () => {
    history.push('/admin/order-management');
  };
  return (
    <div>
      <Container>
        <Alert variant="secondary text-center">
          <Button
            variant="outline-secondary float-left"
            size="sm"
            onClick={() => goback()}
          >
            Operations
          </Button>
          Orders booked for today
        </Alert>
        <ListGroup variant="flush text-center">
          {orders.length === 0 ? (
            <Alert variant="success">No Orders Booked for today</Alert>
          ) : (
            orders.map((order) => <OrderItems key={order._id} order={order} />)
          )}
        </ListGroup>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.admin_orders.todays_orders,
});

export default connect(mapStateToProps, { todaysOrders })(TodaysOrders);
