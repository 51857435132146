import React, { useEffect } from 'react';
import { Spinner, Alert, Row, Button, ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { repatOrdersToday } from '../../../actions/admin/orders';
import ReOrderItems from './ReOrderItems';

function ReOrdersToday({ repatOrdersToday, orders }) {
  useEffect(() => {
    repatOrdersToday();
  }, [repatOrdersToday]);

  let history = useHistory();

  if (orders === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  const goback = () => {
    history.push('/admin/order-management');
  };
  return (
    <div className="container">
      <Alert variant="primary text-center">
        <Button
          variant="outline-primary float-left"
          size="sm"
          onClick={() => goback()}
        >
          Operations
        </Button>
        Repeat Orders with booking today
      </Alert>
      <ListGroup variant="flush text-center">
        {orders.length === 0 ? (
          <Alert variant="success">There are no repeat orders today</Alert>
        ) : (
          orders.map((order) => <ReOrderItems key={order._id} order={order} />)
        )}
      </ListGroup>
    </div>
  );
}

const mapStateToProps = (state) => ({
  orders: state.admin_orders.re_today,
});

export default connect(mapStateToProps, { repatOrdersToday })(ReOrdersToday);
