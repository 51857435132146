import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';
import { dusterUpdate } from '../../../actions/admin/team_admin';
import AddressAutocomplete from '../../Layout/AddressAutocomplete';

const EditDuster = ({ dustBuster, dusterId, dusterUpdate }) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [idNum, setIdNum] = useState('');
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  useEffect(() => {
    if (dustBuster) {
      setName(dustBuster.name);
      setSurname(dustBuster.surname);
      setCellphone(dustBuster.cellphone);
      setAddress(dustBuster.address);
      setCoordinates(dustBuster.coordinates);
      setIdNum(dustBuster.idNum);
    }
  }, [dustBuster]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateDuster = () => {
    dusterUpdate({
      dusterId,
      name,
      surname,
      cellphone,
      idNum,
      address,
      coordinates,
    });

    setShow(false);
  };
  return (
    <>
      <Button variant="warning float-right" size="sm" onClick={handleShow}>
        Edit Duster
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit {name} {surname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder={name}
              name="name"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Surname:</Form.Label>
            <Form.Control
              type="text"
              placeholder={surname}
              name="surname"
              onChange={(e) => setSurname(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>ID Number:</Form.Label>
            <Form.Control
              type="text"
              placeholder={idNum}
              name="idNum"
              onChange={(e) => setIdNum(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Cellphone:</Form.Label>
            <Form.Control
              type="text"
              placeholder={cellphone}
              name="cellphone"
              onChange={(e) => setCellphone(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Duster Address: {address}</Form.Label>
            <AddressAutocomplete
              value={address}
              setAddress={setAddress}
              setCoordinates={setCoordinates}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="far fa-window-close"></i>
          </Button>
          <Button variant="primary" onClick={updateDuster}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(null, { dusterUpdate })(EditDuster);
