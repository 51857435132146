import React, { useState, useEffect, useRef } from 'react';
import { Alert, InputGroup, FormControl, Button, Modal } from 'react-bootstrap';
import moment from 'moment';

const CompleteRegular = ({ checkOutRep, dusterId, currentReOrder }) => {
  const [show, setShow] = useState(false);

  const [note, setNote] = useState('');
  const [kitchen, setKitchen] = useState(null);
  const [rooms, setRooms] = useState(null);
  const [bathrooms, setBathrooms] = useState(null);
  const [switches, setSwitches] = useState(null);
  const [special, setSpecial] = useState(null);
  const [submit, setSubmit] = useState(false);
  let btn = useRef();
  let btnWn = useRef();
  useEffect(() => {
    if (
      kitchen !== null &&
      rooms !== null &&
      bathrooms !== null &&
      switches !== null &&
      special !== null
    ) {
      setSubmit(true);
    }
  }, [kitchen, rooms, bathrooms, switches, special, setSubmit]);

  const handleClose = () => {
    setNote('');
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const complete = (e) => {
    e.preventDefault();
    const checkOut = moment.utc().format();

    let newArr = [kitchen, rooms, bathrooms, switches, special];
    let notes = newArr.filter((arr) => {
      return arr === false;
    });

    if (notes.length > 0) {
      handleShow();
    } else {
      checkOutRep({ checkOut, note, dusterId, currentReOrder });
      if (btn.current) {
        btn.current.setAttribute('disabled', 'disabled');
      }
    }
  };

  const completeWnote = () => {
    const checkOut = moment.utc().format();

    checkOutRep({ checkOut, note, dusterId, currentReOrder });
    if (btnWn.current) {
      btnWn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <>
      <Alert variant="dark">
        To checkout please answer the following questions
      </Alert>
      <InputGroup>
        <FormControl placeholder="Did you clean the kitchen?" disabled />
        <Button
          variant={kitchen === true ? 'success' : 'outline-success'}
          onClick={() => setKitchen(true)}
        >
          Yes
        </Button>
        <Button
          variant={kitchen === false ? 'warning' : 'outline-warning'}
          onClick={() => setKitchen(false)}
        >
          No
        </Button>
      </InputGroup>
      <InputGroup>
        <FormControl
          placeholder="Did you sweep and vacuum all the rooms?"
          disabled
        />
        <Button
          variant={rooms === true ? 'success' : 'outline-success'}
          onClick={() => setRooms(true)}
        >
          Yes
        </Button>
        <Button
          variant={rooms === false ? 'warning' : 'outline-warning'}
          onClick={() => setRooms(false)}
        >
          No
        </Button>
      </InputGroup>
      <InputGroup>
        <FormControl placeholder="Did you clean the bathrooms?" disabled />
        <Button
          variant={bathrooms === true ? 'success' : 'outline-success'}
          onClick={() => setBathrooms(true)}
        >
          Yes
        </Button>
        <Button
          variant={bathrooms === false ? 'warning' : 'outline-warning'}
          onClick={() => setBathrooms(false)}
        >
          No
        </Button>
      </InputGroup>
      <InputGroup>
        <FormControl
          placeholder="Did you clean door handles and light switches?"
          disabled
        />
        <Button
          variant={switches === true ? 'success' : 'outline-success'}
          onClick={() => setSwitches(true)}
        >
          Yes
        </Button>
        <Button
          variant={switches === false ? 'warning' : 'outline-warning'}
          onClick={() => setSwitches(false)}
        >
          No
        </Button>
      </InputGroup>
      <InputGroup>
        <FormControl
          placeholder="Did you complete all the special tasks?"
          disabled
        />
        <Button
          variant={special === true ? 'success' : 'outline-success'}
          onClick={() => setSpecial(true)}
        >
          Yes
        </Button>
        <Button
          variant={special === false ? 'warning' : 'outline-warning'}
          onClick={() => setSpecial(false)}
        >
          No
        </Button>
      </InputGroup>
      {submit ? (
        <Button variant="success mt-4" onClick={complete} ref={btn}>
          Complete the order
        </Button>
      ) : (
        <Button variant="secondary mt-3" disabled>
          Please complete the form to complete
        </Button>
      )}
      <Modal show={show} onHide={handleClose}>
        <Alert variant="warning">Work not done</Alert>
        <Modal.Body>
          <h6>Please tell us why some of the work was not done</h6>
          <InputGroup>
            <FormControl
              as="textarea"
              placeholder="Please tell us why some of the work was not done..."
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={completeWnote} ref={btnWn}>
            Save and complete clean
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CompleteRegular;
