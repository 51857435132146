import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import Dropzone from 'react-dropzone';

const UploadDocs = ({
  dusterId,
  note,
  nationality,
  poa_uploaded,
  id_uploaded,
  bl_uploaded,
  pp_uploaded,
  ec_uploaded,
  wp_uploaded,
  adminIDUpload,
  adminPOAUpload,
  adminBankLetterUpload,
  adminProPicUpload,
  adminECUpload,
  adminWorkPermitUpload,
  addDusterProfile,
}) => {
  const [msg, setMsg] = useState(note);
  const [update, setUpdate] = useState(false);
  let IDbtn = useRef();
  let POAbtn = useRef();
  let BLbtn = useRef();
  let PPbtn = useRef();
  let ECbtn = useRef();
  let WPbtn = useRef();
  let updateBtn = useRef();

  useEffect(() => {
    if (nationality === 'UK Citizen') {
      if (id_uploaded && bl_uploaded && pp_uploaded && ec_uploaded) {
        setUpdate(true);
      }
    } else if (nationality === 'Foreign Citizen') {
      if (id_uploaded && bl_uploaded && pp_uploaded && ec_uploaded) {
        setUpdate(true);
      }
    }
  }, [
    setUpdate,
    nationality,
    id_uploaded,
    bl_uploaded,
    pp_uploaded,
    ec_uploaded,
    wp_uploaded,
  ]);

  //Handle ID button functions//
  useEffect(() => {
    if (note && IDbtn.current) {
      setMsg(note);
      IDbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadID = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminIDUpload(formData);

    if (IDbtn.current) {
      IDbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Handle Proof Of Address button//
  useEffect(() => {
    if (note && POAbtn.current) {
      setMsg(note);
      POAbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadPOA = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminPOAUpload(formData);

    if (POAbtn.current) {
      POAbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Handle Bank Letter Button//
  useEffect(() => {
    if (note && BLbtn.current) {
      setMsg(note);
      BLbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadBL = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminBankLetterUpload(formData);

    if (BLbtn.current) {
      BLbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Handle Profile Pic Button//
  useEffect(() => {
    if (note && PPbtn.current) {
      setMsg(note);
      PPbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadPP = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminProPicUpload(formData);

    if (PPbtn.current) {
      PPbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Handle Employment Contract Button//
  useEffect(() => {
    if (note && ECbtn.current) {
      setMsg(note);
      ECbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadEC = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminECUpload(formData);

    if (ECbtn.current) {
      ECbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  //Handle Work Permit//
  useEffect(() => {
    if (note && WPbtn.current) {
      setMsg(note);
      WPbtn.current.removeAttribute('disabled');
    }
  }, [setMsg, note, msg]);

  const uploadWP = async (files) => {
    let formData = new FormData();
    formData.append('file', files[0]);
    adminWorkPermitUpload(formData);

    if (WPbtn.current) {
      WPbtn.current.setAttribute('disabled', 'disabled');
    }
  };

  const completeUpdate = () => {
    addDusterProfile({
      dusterId,
      poa_uploaded,
      id_uploaded,
      bl_uploaded,
      pp_uploaded,
      ec_uploaded,
      wp_uploaded,
    });
  };
  return (
    <div>
      <h3>
        Upload Docs
        {update === true && (
          <Button
            variant="info float-right"
            ref={updateBtn}
            onClick={completeUpdate}
          >
            Update User
          </Button>
        )}
      </h3>
      <hr />
      <Row className="justify-content-center">
        <Col md={6} xs={12}>
          {id_uploaded ? (
            <Button variant="success mb-3" disabled block>
              1. ID Uploaded <i className="fas fa-check-circle ml-2"></i>
            </Button>
          ) : (
            <div>
              <Dropzone onDrop={uploadID}>
                {({ getRootProps, getInputProps }) => (
                  <Button
                    variant="warning mb-3"
                    {...getRootProps()}
                    ref={IDbtn}
                    block
                  >
                    <input {...getInputProps()} />
                    1. ID Document
                    <i className="fas fa-cloud-upload-alt ml-2"></i>
                  </Button>
                )}
              </Dropzone>
            </div>
          )}
        </Col>
        <Col md={6} xs={12}>
          {poa_uploaded ? (
            <Button variant="success mb-3" disabled block>
              2. Proof Of Address Uploaded
              <i className="fas fa-check-circle ml-2"></i>
            </Button>
          ) : (
            <div>
              <Dropzone onDrop={uploadPOA}>
                {({ getRootProps, getInputProps }) => (
                  <Button
                    variant="warning mb-3"
                    {...getRootProps()}
                    ref={POAbtn}
                    block
                  >
                    <input {...getInputProps()} />
                    2. Proof Of Address
                    <i className="fas fa-cloud-upload-alt ml-2"></i>
                  </Button>
                )}
              </Dropzone>
            </div>
          )}
        </Col>
        <Col md={6} xs={12}>
          {bl_uploaded ? (
            <Button variant="success mb-3" disabled block>
              3. Bank Letter Uploaded
              <i className="fas fa-check-circle ml-2"></i>
            </Button>
          ) : (
            <div>
              <Dropzone onDrop={uploadBL}>
                {({ getRootProps, getInputProps }) => (
                  <Button
                    variant="warning mb-3"
                    {...getRootProps()}
                    ref={BLbtn}
                    block
                  >
                    <input {...getInputProps()} />
                    3. Bank Letter
                    <i className="fas fa-cloud-upload-alt ml-2"></i>
                  </Button>
                )}
              </Dropzone>
            </div>
          )}
        </Col>
        <Col md={6} xs={12}>
          {pp_uploaded ? (
            <Button variant="success mb-3" disabled block>
              4. Profile Picture Uploaded
              <i className="fas fa-check-circle ml-2"></i>
            </Button>
          ) : (
            <div>
              <Dropzone onDrop={uploadPP}>
                {({ getRootProps, getInputProps }) => (
                  <Button
                    variant="warning mb-3"
                    {...getRootProps()}
                    ref={PPbtn}
                    block
                  >
                    <input {...getInputProps()} />
                    4. Profile Picture
                    <i className="fas fa-cloud-upload-alt ml-2"></i>
                  </Button>
                )}
              </Dropzone>
            </div>
          )}
        </Col>
        <Col md={6} xs={12}>
          {ec_uploaded ? (
            <Button variant="success mb-3" disabled block>
              5. Employment Contract
              <i className="fas fa-check-circle ml-2"></i>
            </Button>
          ) : (
            <div>
              <Dropzone onDrop={uploadEC}>
                {({ getRootProps, getInputProps }) => (
                  <Button
                    variant="warning mb-3"
                    {...getRootProps()}
                    ref={ECbtn}
                    block
                  >
                    <input {...getInputProps()} />
                    5.Employment Contract
                    <i className="fas fa-cloud-upload-alt ml-2"></i>
                  </Button>
                )}
              </Dropzone>
            </div>
          )}
        </Col>
        {nationality === 'Foreign Citizen' && (
          <Col md={6} xs={12}>
            {wp_uploaded ? (
              <Button variant="success mb-3" disabled block>
                6. Work Permit Uploaded
                <i className="fas fa-check-circle ml-2"></i>
              </Button>
            ) : (
              <div>
                <Dropzone onDrop={uploadWP}>
                  {({ getRootProps, getInputProps }) => (
                    <Button
                      variant="warning mb-3"
                      {...getRootProps()}
                      ref={WPbtn}
                      block
                    >
                      <input {...getInputProps()} />
                      6. Work Permit
                      <i className="fas fa-cloud-upload-alt ml-2"></i>
                    </Button>
                  )}
                </Dropzone>
              </div>
            )}
          </Col>
        )}
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps)(UploadDocs);
