import React, { useEffect } from 'react';
import { Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';

const Time = ({
  invoiceHours,
  setChange,
  change,
  time,
  setTime,
  whyLess,
  setWhyLess,
}) => {
  useEffect(() => {
    if (change) {
      setTime(change);
    }
  }, [change, setTime]);

  useEffect(() => {
    if (invoiceHours) {
      setTime(invoiceHours);
    }
  }, [invoiceHours, setTime]);

  return (
    <div className="mb-3">
      <h5 className="text-info text-center">Based on your cleaning brief</h5>
      <h5 className="text-info text-center">
        We recommend <b>{invoiceHours}</b> hours for the clean
      </h5>
      <h6 className="text-center text-muted mb-4">
        Would you like to change the time?
      </h6>
      <Row className="text-center justify-content-center mb-4">
        <Col md={2} xs={12}>
          <Button
            variant={time === 2 ? 'info' : 'outline-secondary'}
            value={2}
            onClick={(e) => setChange(2)}
            block
          >
            2 hours
          </Button>
        </Col>
        <Col md={2} xs={12}>
          <Button
            variant={time === 2.5 ? 'info' : 'outline-secondary'}
            block
            value={2.5}
            onClick={(e) => setChange(2.5)}
          >
            2.5 hours
          </Button>
        </Col>
        <Col md={2} xs={12}>
          <Button
            variant={time === 3 ? 'info' : 'outline-secondary'}
            block
            value={3}
            onClick={(e) => setChange(3)}
          >
            3 hours
          </Button>
        </Col>
        <Col md={2} xs={12}>
          <Button
            variant={time === 3.5 ? 'info' : 'outline-secondary'}
            block
            value={3.5}
            onClick={(e) => setChange(3.5)}
          >
            3.5 hours
          </Button>
        </Col>
        <Col md={2} xs={12}>
          <Button
            variant={time === 4 ? 'info' : 'outline-secondary'}
            block
            value={4}
            onClick={(e) => setChange(4)}
          >
            4 hours
          </Button>
        </Col>
      </Row>
      <Row className="text-center justify-content-center mb-4">
        <Col md={2} xs={12}>
          <Button
            variant={time === 4.5 ? 'info' : 'outline-secondary'}
            value={4.5}
            onClick={(e) => setChange(4.5)}
            block
          >
            4.5 hours
          </Button>
        </Col>
        <Col md={2} xs={12}>
          <Button
            variant={time === 5 ? 'info' : 'outline-secondary'}
            block
            value={5}
            onClick={(e) => setChange(5)}
          >
            5 hours
          </Button>
        </Col>
        <Col md={2} xs={12}>
          <Button
            variant={time === 5.5 ? 'info' : 'outline-secondary'}
            block
            value={5.5}
            onClick={(e) => setChange(5.5)}
          >
            5.5 hours
          </Button>
        </Col>
        <Col md={2} xs={12}>
          <Button
            variant={time === 6 ? 'info' : 'outline-secondary'}
            block
            value={6}
            onClick={(e) => setChange(6)}
          >
            6 hours
          </Button>
        </Col>
        <Col md={2} xs={12}>
          <Button
            variant={time === 6.5 ? 'info' : 'outline-secondary'}
            block
            value={6.5}
            onClick={(e) => setChange(6.5)}
          >
            6.5 hours
          </Button>
        </Col>
      </Row>
      <Row className="text-center justify-content-center mb-4">
        <Col md={2} xs={12}>
          <Button
            variant={time === 7 ? 'info' : 'outline-secondary'}
            block
            value={7}
            onClick={(e) => setChange(7)}
          >
            7 hours
          </Button>
        </Col>
        <Col md={2} xs={12}>
          <Button
            variant={time === 7.5 ? 'info' : 'outline-secondary'}
            block
            value={7.5}
            onClick={(e) => setChange(7.5)}
          >
            7.5 hours
          </Button>
        </Col>
        <Col md={2} xs={12}>
          <Button
            variant={time === 8 ? 'info' : 'outline-secondary'}
            block
            value={8}
            onClick={(e) => setChange(8)}
          >
            8 hours
          </Button>
        </Col>
      </Row>
      {invoiceHours > time && (
        <>
          <h5 className="text-warning text-center">
            You have chosen less time than the reccomended...
          </h5>
          <InputGroup>
            <FormControl
              as="textarea"
              placeholder="Please tell us what to prioritise?"
              value={whyLess}
              onChange={(e) => setWhyLess(e.target.value)}
            />
          </InputGroup>
        </>
      )}
    </div>
  );
};

export default Time;
