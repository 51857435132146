import {
  CHECK_PROFILE,
  CLEAR_MEMBER,
  READY_TEAM,
  READY_TEAM_NUMBER,
  FETCH_SKILLS,
  CLEAR_SKILLS,
  DUSTER_ORDERS,
  STAGE_ONE,
  STAGE_ONE_NUMBER,
  STAGE_TWO,
  STAGE_TWO_NUMBER,
  STAGE_THREE,
  STAGE_THREE_NUMBER,
  DUST_BUSTER,
  CLEAR_DUSTERS,
  INTERVIEW,
  INTERVIEW_CALENDER,
  DUSTER_BOOKINGS,
  DUSTER_RE_ORDERS,
  THE_BOOKING,
  CLEAR_THE_BOOKING,
  DUSTER_RATE,
} from '../../actions/types';

const initialState = {
  uploads: null,
  orders: null,
  reorders: null,
  bookings: null,
  theBooking: null,
  ready: null,
  readyNumber: null,
  skills: null,
  loading: true,
  stageOne: null,
  stageOneNumber: null,
  stageTwo: null,
  stageTwoNumber: null,
  stageThree: null,
  stageThreeNumber: null,
  dustBuster: null,
  interview: null,
  interviews: null,
  rate: null,
};

function manageReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DUSTER_RATE:
      return {
        ...state,
        rate: payload,
      };
    case CLEAR_DUSTERS:
      return {
        ...state,
        stageOne: null,
        stageOneNumber: null,
        stageTwo: null,
        stageTwoNumber: null,
        stageThree: null,
        stageThreeNumber: null,
        dustBuster: null,
        uploads: null,
        ready: null,
        readyNumber: null,
      };
    case INTERVIEW:
      return {
        ...state,
        interview: payload,
      };
    case INTERVIEW_CALENDER:
      return {
        ...state,
        interviews: payload,
      };
    case DUST_BUSTER:
      return {
        ...state,
        dustBuster: payload,
      };
    case DUSTER_BOOKINGS:
      return {
        ...state,
        bookings: payload,
      };
    case THE_BOOKING:
      return {
        ...state,
        theBooking: payload,
      };
    case CLEAR_THE_BOOKING:
      return {
        ...state,
        theBooking: null,
      };
    case DUSTER_RE_ORDERS:
      return {
        ...state,
        reorders: payload,
      };
    case FETCH_SKILLS:
      return {
        ...state,
        skills: payload,
        loading: false,
      };
    case CLEAR_SKILLS:
      return {
        ...state,
        skills: null,
        loading: true,
      };
    case READY_TEAM:
      return {
        ...state,
        ready: payload,
        loading: false,
      };
    case READY_TEAM_NUMBER:
      return {
        ...state,
        readyNumber: payload,
      };
    case CHECK_PROFILE:
      return {
        ...state,
        uploads: payload,
        loading: false,
      };
    case DUSTER_ORDERS:
      return {
        ...state,
        orders: payload,
      };
    case CLEAR_MEMBER:
      return {
        ...state,
        uploads: null,
        loading: false,
      };
    case STAGE_ONE:
      return {
        ...state,
        stageOne: payload,
      };
    case STAGE_ONE_NUMBER:
      return {
        ...state,
        stageOneNumber: payload,
      };
    case STAGE_TWO:
      return {
        ...state,
        stageTwo: payload,
      };
    case STAGE_TWO_NUMBER:
      return {
        ...state,
        stageTwoNumber: payload,
      };
    case STAGE_THREE:
      return {
        ...state,
        stageThree: payload,
      };
    case STAGE_THREE_NUMBER:
      return {
        ...state,
        stageThreeNumber: payload,
      };
    default:
      return state;
  }
}

export default manageReducer;
