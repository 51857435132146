import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  dusterOrders,
  dusterRepeat,
  checkBooking,
  checkRebooking,
  cleanerRate,
  changeRate,
} from '../../../actions/admin/team_members';
import {
  Row,
  Col,
  ListGroup,
  Alert,
  Spinner,
  Button,
  Modal,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import CleanItem from './CleanItem';
import EditDuster from './EditDuster';
import Bookings from './Bookings';
import UpdateDusterDocs from './UpdateDusterDocs';
import moment from 'moment';
import RateCard from './RateCard';
import EditRate from './EditRate';

const WorkingDuster = ({
  dustBuster,
  dusterRepeat,
  dusterOrders,
  orders,
  bookings,
  reorders,
  dusterId,
  idTrash,
  poaTrash,
  blTrash,
  ppTrash,
  ecTrash,
  wpTrash,
  idDoc,
  poa,
  bankLetter,
  pic,
  ec,
  workPermit,
  idBtn,
  poaBtn,
  blBtn,
  ppBtn,
  ecBtn,
  wpBtn,
  id_uploaded,
  uploadID,
  IDbtn,
  uploadPOA,
  poa_uploaded,
  POAbtn,
  uploadBL,
  BLbtn,
  bl_uploaded,
  uploadPP,
  PPbtn,
  pp_uploaded,
  ec_uploaded,
  uploadEC,
  ECbtn,
  wp_uploaded,
  uploadWP,
  WPbtn,
  updateDusterUploads,
  update,
  setUpdate,
  getUploads,
  updateUploads,
  setUpdateUploads,
  dusterBookings,
  checkBooking,
  checkRebooking,
  theBooking,
  cleanerRate,
  rate,
  changeRate,
  note,
}) => {
  const [nationality, setNationality] = useState(null);
  const [gender, setGender] = useState(null);
  const [myOrders, setMyOrders] = useState(orders);
  const [myReOrders, setMyReOrders] = useState(reorders);
  const [myBookings, setMyBookings] = useState(bookings);
  const [showDocs, setShowDocs] = useState(false);
  const [showBookings, setShowBookings] = useState(false);
  const [address, setAddress] = useState(null);
  const [complex, setComplex] = useState(null);
  const [adr, setAdr] = useState('');
  const [go, setGo] = useState(null);
  const [bookingDate, setBookingDate] = useState(null);
  const [show, setShow] = useState(false);
  let uploadBtn = useRef();
  let updateBtn = useRef();
  let history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (dustBuster) {
      if (dustBuster.local === true) {
        setNationality('South African');
      } else {
        setNationality('Foreign Citizen');
      }
      if (dustBuster.female === true) {
        setGender('Female');
      } else {
        setGender('Male');
      }
    }
  }, [dustBuster]);

  useEffect(() => {
    dusterOrders(dusterId);
    dusterRepeat(dusterId);
    dusterBookings(dusterId);
    setUpdate(false);
  }, [dusterOrders, dusterRepeat, dusterBookings, dusterId, setUpdate]);

  useEffect(() => {
    if (theBooking) {
      setAddress(theBooking.address);
      setComplex(theBooking.complex);
    }
  }, [theBooking]);

  useEffect(() => {
    if (address) {
      setAdr(address.split(','));
    }
  }, [address]);

  useEffect(() => {
    if (orders) {
      setMyOrders(orders);
    }
    if (reorders) {
      setMyReOrders(reorders);
    }
    if (bookings) {
      setMyBookings(bookings);
    }
  }, [orders, reorders, bookings]);

  const refresh = () => {
    getUploads(dusterId);
    setUpdate(false);
  };

  const uploadsUpdate = () => {
    updateDusterUploads({
      idDoc,
      poa,
      bankLetter,
      pic,
      workPermit,
      ec,
      dusterId,
    });
    setUpdateUploads(false);
  };
  if (myOrders === null || myReOrders === null || myBookings === null) {
    return <Spinner animation="grow" variant="primary" />;
  }
  const goBack = () => {
    history.push('/admin/team/ready-to-work');
  };

  const fetchBooking = (b) => {
    if (b.reorder) {
      checkRebooking(b.reorder);
      setBookingDate(b.start);
      setGo(`/admin/order-management/repeat-orders/order/${b.reorder}`);
      handleShow();
    } else if (b.order) {
      checkBooking(b.order);
      setBookingDate(b.start);
      setGo(`/admin/order-management/once-off/order/${b.order}`);
      handleShow();
    }
  };
  return (
    <div className="container">
      <Row>
        <Col xs={12} md={4}>
          <Alert variant="info">Dust Buster Info</Alert>
          <ListGroup variant="flush">
            <ListGroup.Item>Name: {dustBuster.name}</ListGroup.Item>
            <ListGroup.Item>Surname: {dustBuster.surname} </ListGroup.Item>
            <ListGroup.Item>ID Number: {dustBuster.idNum} </ListGroup.Item>
            <ListGroup.Item>Cellphone: {dustBuster.cellphone} </ListGroup.Item>
            <ListGroup.Item>Email: {dustBuster.email} </ListGroup.Item>
            <ListGroup.Item>Address: {dustBuster.address} </ListGroup.Item>
            <ListGroup.Item>Gender: {gender} </ListGroup.Item>
            <ListGroup.Item>Nationality: {nationality} </ListGroup.Item>
            <ListGroup.Item>
              <EditDuster dustBuster={dustBuster} dusterId={dusterId} />
              {rate ? (
                <EditRate
                  dusterId={dusterId}
                  changeRate={changeRate}
                  dustBuster={dustBuster}
                  custom={rate}
                  note={note}
                />
              ) : (
                <RateCard
                  dusterId={dusterId}
                  cleanerRate={cleanerRate}
                  dustBuster={dustBuster}
                  note={note}
                />
              )}
            </ListGroup.Item>
          </ListGroup>

          <Alert variant="secondary mt-3">
            Dust Buster Bookings{' '}
            {showBookings ? (
              <Button
                variant="outline-warning float-right"
                size="sm"
                onClick={() => setShowBookings(false)}
              >
                Hide Bookings <i class="fas fa-arrow-alt-circle-up"></i>
              </Button>
            ) : (
              <Button
                variant="outline-info float-right"
                size="sm"
                onClick={() => setShowBookings(true)}
              >
                Show Bookings <i class="fas fa-arrow-alt-circle-down"></i>
              </Button>
            )}
          </Alert>
          {showBookings && (
            <>
              {myBookings.length === 0
                ? 'No Bookings'
                : myBookings.map((b) => (
                    <Bookings key={b._id} b={b} fetchBooking={fetchBooking} />
                  ))}

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    Cleaning date; {moment(bookingDate).format('ddd MMM D')}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Cleaning Address: {adr[0]} {adr[1]}{' '}
                  {complex && `| ${complex}`}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    as={Link}
                    to={`${go}`}
                    onClick={handleClose}
                  >
                    View Booking
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </Col>
        <Col xs={12} md={8}>
          <Alert variant="success">
            Dust Buster Cleans
            <Button
              variant="secondary float-right"
              size="sm"
              onClick={() => goBack()}
            >
              back
            </Button>
          </Alert>
          <Row>
            <Col>
              {myOrders.length === 0 ? (
                <ListGroup.Item>No Duster Cleans</ListGroup.Item>
              ) : (
                myOrders.map((order) => (
                  <CleanItem key={order._id} order={order} />
                ))
              )}
            </Col>
            <Col>
              {myReOrders.length === 0 ? (
                <ListGroup.Item>No Duster Repeat Cleans</ListGroup.Item>
              ) : (
                myReOrders.map((order) => (
                  <CleanItem key={order._id} order={order} />
                ))
              )}
            </Col>
          </Row>

          <hr />
          <Alert variant="secondary text-center">
            Documentation
            {update === true && (
              <Button
                variant="primary float-right"
                size="sm"
                ref={updateBtn}
                onClick={refresh}
              >
                Update
              </Button>
            )}
            {updateUploads === true && (
              <Button
                variant="warning float-right"
                size="sm"
                onClick={uploadsUpdate}
                ref={uploadBtn}
              >
                Update Uploads
              </Button>
            )}
            {showDocs ? (
              <Button
                variant="outline-secondary float-right"
                size="sm"
                onClick={() => setShowDocs(false)}
              >
                Hide documentation
                <i class="fas fa-arrow-alt-circle-up"></i>
              </Button>
            ) : (
              <Button
                variant="outline-info float-right"
                size="sm"
                onClick={() => setShowDocs(true)}
              >
                View/ Edit documentation{' '}
                <i class="fas fa-arrow-alt-circle-down"></i>
              </Button>
            )}
          </Alert>
          {showDocs && (
            <UpdateDusterDocs
              idTrash={idTrash}
              poaTrash={poaTrash}
              blTrash={blTrash}
              ppTrash={ppTrash}
              ecTrash={ecTrash}
              wpTrash={wpTrash}
              idBtn={idBtn}
              poaBtn={poaBtn}
              blBtn={blBtn}
              ppBtn={ppBtn}
              ecBtn={ecBtn}
              wpBtn={wpBtn}
              id_uploaded={id_uploaded}
              uploadID={uploadID}
              IDbtn={IDbtn}
              uploadPOA={uploadPOA}
              poa_uploaded={poa_uploaded}
              POAbtn={POAbtn}
              uploadBL={uploadBL}
              BLbtn={BLbtn}
              bl_uploaded={bl_uploaded}
              uploadPP={uploadPP}
              PPbtn={PPbtn}
              pp_uploaded={pp_uploaded}
              ec_uploaded={ec_uploaded}
              uploadEC={uploadEC}
              ECbtn={ECbtn}
              wp_uploaded={wp_uploaded}
              uploadWP={uploadWP}
              WPbtn={WPbtn}
              idDoc={idDoc}
              poa={poa}
              bankLetter={bankLetter}
              pic={pic}
              ec={ec}
              nationality={nationality}
              workPermit={workPermit}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default connect(null, {
  dusterOrders,
  dusterRepeat,
  checkBooking,
  checkRebooking,
  cleanerRate,
  changeRate,
})(WorkingDuster);
