import React from 'react';
import Bottom from '../Layout/Bottom';

const Agreement = () => {
  return (
    <>
      <div className="container">
        <div>
          <h3 className="text-center">
            INDEPENDENT CONTRACTOR AGREEMENT THIS INDEPENDENT CONTRACTOR
            AGREEMENT (the “Agreement”), dated at the time you accepted our
            terms and conditions on our web portal
          </h3>
        </div>
        <div>
          <b>Between:</b>
          <div align="center">
            <p>DustingDown Ltd</p>
            <p>13530029</p>
            <p>Address</p>
            <p>(the "Client")</p>
            <p className="mt-3 mb-3">-AND-</p>
            <p>Name</p>
            <p>passport No</p>
            <p>Address</p>
            <p>(the "Contractor")</p>
          </div>
          <b>BACKGOUND</b>
          <p className="ml-3">
            A. The Client is of the opinion that the Contractor has the
            necessary qualifications, experience and abilities to provide
            services to the Client.
          </p>
          <p className="ml-3">
            B. The Contractor is agreea to providing such services to the Client
            on the terms and conditions sent out in this Agreement.
          </p>
          <b>IN CONSIDERATION OF </b>
          <p>
            the matters described above and of the mutual benefits and
            obligations set forth in this Agreement, the receipt and sufficiency
            of which consideration is hereby acknowledged, the Client and the
            Contractor (individually the “Party” and collectively the “Parties”
            to this Agreement) agree as follows:
          </p>
          <b>1. Services Provided</b>
          <p className="ml-2">
            a. a. The Client hereby agrees to engage the Contractor to provide
            the Client with services (the “Services”) consisting of :cleaning
            and housekeeping and that of a general cleaner.
          </p>
          <p className="ml-2">
            b. b. The Services will also include any other tasks which the
            Parties may agree on. The Contractor hereby agrees to provide such
            Services to the Client.
          </p>
          <b>2. Term of Agreement</b>
          <p className="ml-2">
            a. The term of this Agreement (the “Term”) will begin on the date of
            this Agreement and will remain in full force and effect indefinitely
            until terminated as provided in this Agreement.
          </p>
          <p className="ml-2">
            b. This Agreement may be terminated at any time by mutual agreement
            of the Parties. In the event that either Party wishes to terminate
            this Agreement, that Party will be required to provide at least 30
            days’ notice to the other Party.
          </p>
          <p className="ml-2">
            c. In the event that either Party breaches a material provision
            under this Agreement, the non-defaulting Party may terminate this
            Agreement and require the defaulting Party to indemnify the
            non-defaulting Party against all reasonable damages.
          </p>
          <p className="ml-2">
            d. Except, as otherwise provided in this Agreement, the obligations
            of the Contractor will end upon the termination of this Agreement.
          </p>
          <b>3. Performance</b>
          <p className="ml-2">
            a. The Parties agree to do everything necessary to ensure that the
            terms of this Agreement take effect.
          </p>
          <b>4. Currency</b>
          <p className="ml-2">
            a. Except as otherwise provided in this Agreement, all monetary
            amounts referred to in this Agreement are in UK Pounds.
          </p>
          <b>5. Compensation</b>
          <p className="ml-2">
            a. For the services rendered by the Contractor as required by this
            Agreement, the Client will provide compensation (the “Compensation”)
            to the Contractor as follows:
          </p>
          <p className="ml-2">b. Cleaning and Janetorial Services rendered.</p>
          <p className="ml-2">c. Aprtment Check in’s </p>
          <p className="ml-2">
            d. The Compensation as stated in this Agreement does not include
            value added tax, or other applicable duties as may be required by
            law.{' '}
          </p>
          <p className="ml-2">
            e. Any value added tax and duties required by law will be charged to
            the Client in addition to the Compensation.
          </p>
          <b>6. Reimbursement of Expenses</b>
          <p className="ml-2">
            a. In connection with providing the Services hereunder, the
            Contractor will only be reimbursed for expenses that have been
            approved in advance.
          </p>
          <p className="ml-2">
            b. The Contractor will furnish vouchers to the Client for all such
            expenses.
          </p>
          <b>7. Confidentiality</b>
          <p className="ml-2">
            a. Confidential information (the “Confidential Information”) refers
            to any data or information relating to the Client, whether business
            or personal, which would reasonably be considered to be the private
            or proprietary to the Client and that is not generally known and
            where the release of that Confidential Information could reasonably
            be expected to cause harm to the Client.
          </p>
          <p className="ml-2">
            b. The Contractor agrees that it will not disclose, divulge, reveal,
            report or use, for any purpose, any Confidential Information which
            the Contractor has obtained, except as authorised by the Client.
            This obligation will end on the expiration or termination of this
            Agreement.
          </p>
          <p className="ml-2">
            c. All written and oral information and materials disclosed or
            provided by the Client to the Contractor under this Agreement is
            Confidential Information regardless of whether it was provided
            before or after the date of this Agreement or how it was provided to
            the Contractor.
          </p>
          <b>8. Non Completion</b>
          <p className="ml-2">
            a. Other than with the express written consent of the Client, which
            will not be unreasonably withheld, the Contractor will not be
            directly or indirectly involved with a business which is in direct
            competition with the particular business line of the Client, divert
            or attempt to divert from the Client any business the Client has
            enjoyed, solicited, or attempted to solicit, from other individuals
            or corporations, prior to the expiration or termination of this
            Agreement.{' '}
          </p>
          <p className="ml-2">
            b. This obligation will end on the expiration or termination of this
            Agreement.
          </p>
          <b>9. Non-Solicitation</b>
          <p className="ml-2">
            a. The Contractor understands and agrees that any attempt on the
            part of the Contractor to induce other employees or contractors to
            leave the Client’s employ, or any effort by the Contractor to
            interfere with the Client’s relationship with its employees or other
            service providers would be harmful and damaging to the Client.
          </p>
          <p className="ml-2">
            b. During the term of this Agreement and for the period of one (1)
            year after the expiration or termination of this Agreement, the
            Contractor will not in any way directly or indirectly:
          </p>
          <p className="ml-2">
            c. Induce or attempt to induce any employee or other service
            provider of the Client to quit employment or retainer with the
            Client;
          </p>
          <p className="ml-2">
            d. Otherwise interfere with or disrupt the Client’s relationship
            with its employees or other service providers;
          </p>
          <p className="ml-2">
            e. Discuss employment opportunities or provide information about
            competitive employment to any of the Client’s employees or other
            service providers; or
          </p>
          <p className="ml-2">
            f. Solicit, entice, or hire away any employee or other service
            provider of the Client.
          </p>
          <p className="ml-2">
            g. In the event of the above being breached the parties agree to a
            placeemnt fee being chatrged to the contractor on the client of no
            less than 150 hours of the last invoced amount.
          </p>
          <b>10. Ownership of Materials and Intellectual Property</b>
          <p className="ml-2">
            a. All intellectual property and related materials (the
            ‘’Intellectual Property”) including any related work in progress
            that is developed or produced under this Agreement, will be the sole
            property of the Client. The use of the Intellectual Property by the
            Client will not be restricted in any manner.
          </p>
          <p className="ml-2">
            b. The Contractor may not use the Intellectual Property for any
            purpose other than that contracted for this Agreement except with
            the written consent of the Client. The Contractor will be
            responsible for any and all damages resulting from the unauthorised
            use of the Intellectual Property.
          </p>
          <b>11. Return of Property</b>
          <p className="ml-2">
            a. Upon the expiry or termination of this Agreement, the Contractor
            will return to the Client any property, documentation, records, or
            Confidentional Information which is the property of the Client.
          </p>
          <p className="ml-2">
            b. The contractor will also be removed from the online application.
          </p>
          <b>12. Independent Contractor</b>
          <p className="ml-2">
            a. In providing the Services under this Agreement, it is expressly
            agreed that the Contractor is acting as an independent contractor
            and not as an employee.{' '}
          </p>
          <p className="ml-2">
            b. The Contractor and the Client acknowledge that this Agreement
            does not create a partnership or joint venture between them, and is
            exclusively a contract for service.
          </p>
          <b>13. Notice</b>
          <p className="ml-2">
            a. All notices, requests, demands or other communications required
            or permitted by the terms of this Agreement will be given in writing
            and delivered to the Parties of this Agreement as follows:
          </p>
          <p className="ml-4">
            i. The Contactor , this will be the last known address used on the
            locator on the app.
          </p>
          <p className="ml-4">
            ii. The Company , this will be as above, or as adervitsed on the
            app.
          </p>
          <p className="ml-4">
            iii. The address used on the Dustingdown Application will also be
            deemed as the latest contact point.
          </p>
          <p className="ml-4">
            iv. Or to such other address as any Party may from time to time
            notify the other.
          </p>
          <b>14. Indemnification </b>
          <p className="ml-2">
            a. Except to the extent paid in settlement from any applicable
            insurance policies, and to the extend permitted by applicable law,
            each Party agrees to indemnify and hold harmless the other Party,
            and its respective affiliates, officers, agents, employees, and
            permitted successors and assigns against any and all claims, losses,
            damages, liabilities, penalties, punitive damages, expenses,
            reasonable legal fees and costs of any kind or amount whatsoever,
            which result from or arise out of any act or omission of the
            indemnifying party, its respective affiliates, officers, agents,
            employees and permitted successors and assigns that occur in
            connection with this Agreement. This Indemnification will survive
            the termination of this Agreement.
          </p>
          <b>15. Legal Expenses</b>
          <p className="ml-2">
            a. In the event that legal action is brought to enforce or interpret
            any term of this Agreement, the prevailing Party will be entitled to
            recover, in addition to any other damages or award, all reasonable
            legal costs and fees associated with the action.
          </p>
          <b>16. Modification of Agreement</b>
          <p className="ml-2">
            a. Any amendment or modification of this Agreement or additional
            obligation assumed by either Party in connection with this Agreement
            will only be binding if evidenced in writing signed by each Party or
            an authorised respresentative of each Party.
          </p>
          <b>17. Time is of the Essence</b>
          <p className="ml-2">
            a. Time is of the essence in this Agreement. No extension or
            variation of this Agreement will operate as a waiver of this
            provision.
          </p>
          <b>18. Assignment</b>
          <p className="ml-2">
            a. The Contractor will not voluntarily, or by operation of law,
            assign or otherwise transfer its obligations under this Agreement
            without the prior written consent of the Client.{' '}
          </p>
          <b>19. Entire Agreement</b>
          <p className="ml-2">
            a. It is agreed that there is no representation, warranty,
            collateral agreement or condition affecting this Agreement except as
            expressly provided in this Agreement
          </p>
          <b>20. Enurement</b>
          <p className="ml-2">
            a. This Agreement will ensure to the benefit of and be binding on
            the Parties and their respective Heirs, Executors, Administrators,
            Successors and Permitted Assigns.
          </p>
          <b>21. Titles / Headings</b>
          <p className="ml-2">
            a. Headings are inserted for the convenience of the Parties only and
            are not to be considered when interpreting this Agreement.
          </p>
          <b>22. Gender</b>
          <p className="ml-2">
            a. Words in this singular mean and include the plural and vice
            versa. Words in the masculine mean and include the feminine and vice
            versa.
          </p>
          <b>23. Governing Law</b>
          <p className="ml-2">
            a. It is the intention of the Parties to this Agreement that this
            Agreement and the performance under this Agreement, and all the
            suits and special proceedings under this Agreement, be construed in
            accordance with and governed, to the exclusion of the law of any
            other forum, by the laws of United Kingdom, without regard to the
            jurisdiction in which any action or special proceeding may be
            instituted.
          </p>
          <b>24. Severability</b>
          <p className="ml-2">
            a. In the event any of the provisions of this Agreement are held to
            be invalid or unenforceable in whole or in part, all other
            provisions will nevertheless continue to be valid and enforceable
            with the invalid or unenforceable parts severed from the remainder
            of this Agreement.
          </p>
          <b>25. Waiver</b>
          <p className="ml-2">
            a. The waiver by either Party of a breach, default, delay or
            omission of any of the provisions of this Agreement by the other
            Party will not be construed as a waiver of any subsequent breach of
            the same or other provisions.
          </p>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default Agreement;
