import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Alert,
  Button,
  ListGroup,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import RedFlags from './RedFlags';
import DeleteDuster from './DeleteDuster';

const StageOne = ({
  dustBuster,
  dusterId,
  completeApp,
  deleteBtn,
  removeDuster,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [nationality, setNationality] = useState(null);
  const [gender, setGender] = useState(null);
  const [flags, setFlags] = useState(null);
  let history = useHistory();

  useEffect(() => {
    if (dustBuster) {
      setFlags(dustBuster.redflags);

      if (dustBuster.local === true) {
        setNationality('UK Citizen');
      } else {
        setNationality('Foreign Citizen');
      }
      if (dustBuster.female === true) {
        setGender('Female');
      } else {
        setGender('Male');
      }
    }
  }, [dustBuster]);

  if (dustBuster === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  const sendEmail = () => {
    completeApp({ dusterId });
  };

  const goBack = () => {
    history.push('/admin/team/stage-one');
  };

  return (
    <Row>
      <Col md={4} xs={12}>
        <Alert variant="info">
          <Button
            variant="secondary float-right ml-2"
            size="sm"
            onClick={() => goBack()}
          >
            back
          </Button>
          Duster Info
          <DeleteDuster
            dusterId={dusterId}
            dustBuster={dustBuster}
            deleteBtn={deleteBtn}
            removeDuster={removeDuster}
          />
          <Button size="sm" variant="warning float-right" disabled>
            Stage {dustBuster.stage}
          </Button>
        </Alert>
        <ListGroup variant="flush">
          <ListGroup.Item>Name: {dustBuster.name}</ListGroup.Item>
          <ListGroup.Item>Surname: {dustBuster.surname} </ListGroup.Item>
          <ListGroup.Item>ID Number: {dustBuster.idNum} </ListGroup.Item>
          <ListGroup.Item>Cellphone: {dustBuster.cellphone} </ListGroup.Item>
          <ListGroup.Item>Email: {dustBuster.email} </ListGroup.Item>
          <ListGroup.Item>Post code: {dustBuster.code} </ListGroup.Item>
          <ListGroup.Item>Address: {dustBuster.address} </ListGroup.Item>
          <ListGroup.Item>Gender: {gender} </ListGroup.Item>
          <ListGroup.Item>Nationality: {nationality} </ListGroup.Item>
        </ListGroup>
      </Col>
      <Col md={8} xs={12} align="center">
        <Button variant="warning" onClick={handleShow}>
          Send Email <i className="far fa-paper-plane float-right"></i>
        </Button>
        {flags
          ? flags.length > 0 && (
              <Col xs={12} md={4}>
                <Alert variant="warning mt-3">
                  Duster has flags
                  <i className="fab fa-font-awesome-flag float-right"></i>
                </Alert>
              </Col>
            )
          : ''}
        <Col xs={12} md={8}>
          {flags && flags.map((flag) => <RedFlags flag={flag} />)}
        </Col>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Send {dustBuster.name} an email</Modal.Title>
          </Modal.Header>
          <Modal.Body>We noticed you started applying ...</Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={handleClose}>
              <i className="far fa-window-close"></i>
            </Button>
            <Button variant="primary" onClick={sendEmail}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
      </Col>
    </Row>
  );
};

export default StageOne;
