import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Row,
  Col,
  Alert,
  ListGroup,
  Spinner,
  Card,
  Button,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import {
  GetReOrder,
  fetchDuster,
  cleanDuster,
  delReOrder,
  reComment,
  fetchReComments,
} from '../../../actions/user/order';
import { getInvoice } from '../../../actions/user/invoice';
import { paymentReUrl } from '../../../actions/user/payment';
import moment from 'moment';
import ExtraItem from '../Booking/Payments/ExtraItem';
import Bottom from '../../Layout/Bottom';
import Schedule from './Schedule';
import CompletedClean from './CompletedClean';
import DeleteReOrder from './DeleteReOrder';
import AddReComment from './AddReComment';

const MyReOrder = ({
  GetReOrder,
  orders: { repeatOrder, bookedCleaner, re_comments },
  match,
  fetchDuster,
  cleanDuster,
  delReOrder,
  note,
  reComment,
  fetchReComments,
  getInvoice,
  invoice,
  paymentReUrl,
  stripeUrl,
}) => {
  useEffect(() => {
    GetReOrder(match.params.orderId);
    fetchReComments(match.params.orderId);
    getInvoice(match.params.orderId);
  }, [GetReOrder, match, getInvoice, fetchReComments]);

  useEffect(() => {
    if (stripeUrl) {
      window.location.href = stripeUrl;
    }
  }, [stripeUrl]);

  const [reOrderId, setReOrderId] = useState('');
  const [nte, setMsg] = useState(note);
  const [mrx, SetMrx] = useState([]);
  const [it, SetIT] = useState('');
  const [bed, SetBed] = useState('');
  const [bath, SetBath] = useState('');
  const [inv, SetIV] = useState('');
  const [invoiceHours, SetIH] = useState('');
  const [code, SetCode] = useState('');
  const [address, SetAdress] = useState('');
  const [complex, SetComplex] = useState(null);
  const [cleans, SetCleans] = useState('');
  const [weekdays, SetWeekDays] = useState([]);
  const [schedule, SetSchedule] = useState([]);
  const [checkedIn, SetCheckedIn] = useState([]);
  const [whatsLeft, SetWhatsLeft] = useState(null);
  const [checkedOut, SetCheckedOut] = useState([]);
  const [complete, SetComplete] = useState(null);
  const [remove, setRemove] = useState(false);
  const [completedCleans, setCompletedCleans] = useState(null);
  const [assigned, SetAssigned] = useState(null);
  const [monthly, setMonthly] = useState(null);
  const [paymentRef, setPaymentRef] = useState(null);
  const [payText, setPayText] = useState('Make Payment');
  let btn = useRef();
  let delBtn = useRef();

  let history = useHistory();

  useEffect(() => {
    if (note && delBtn.current) {
      setMsg(note);
      delBtn.current.removeAttribute('disabled');
      history.push('/user/my-profile');
    }
  }, [note, nte, setMsg, history]);

  useEffect(() => {
    if (it) {
      let tomorrow = moment().add(1, 'day').local().format();
      if (tomorrow < it) {
        setRemove(true);
      }
    }
    return () => {
      setRemove(false);
    };
  }, [it, assigned]);

  useEffect(() => {
    if (repeatOrder) {
      setReOrderId(repeatOrder._id);
      SetMrx(repeatOrder.mrx);
      SetIT(repeatOrder.firstDate);
      SetBath(repeatOrder.bathroom);
      SetBed(repeatOrder.bedroom);
      SetAdress(repeatOrder.address);
      SetComplex(repeatOrder.complex);
      SetCode(repeatOrder.code);
      SetIV(repeatOrder.invoice);
      SetIH(repeatOrder.invoiceHours);
      SetCleans(repeatOrder.cleans);
      SetWeekDays(repeatOrder.weekdays);
      SetSchedule(repeatOrder.schedule);
      SetAssigned(repeatOrder.team);
      SetCheckedIn(repeatOrder.checkIn);
      SetCheckedOut(repeatOrder.checkOut);
      setCompletedCleans(checkedOut.length);
      setMonthly(repeatOrder.monthEnd);
      setPaymentRef(repeatOrder.paymentRef);
    }
  }, [repeatOrder, checkedOut.length]);

  useEffect(() => {
    if (assigned) {
      let dusterId = assigned;
      fetchDuster(dusterId);
    }
    return () => {
      cleanDuster(null);
    };
  }, [assigned, fetchDuster, cleanDuster]);

  useEffect(() => {
    let whatsLeft = cleans - completedCleans;
    SetWhatsLeft(whatsLeft);
  }, [cleans, completedCleans]);

  const invoiceTime = moment.utc(it).format('ddd MMM D, h:mm a');

  if (repeatOrder === null || re_comments === null) {
    return <Spinner animation="border" variant="primary" />;
  }

  const downloadCode = () => {
    const link = document.createElement('a');
    link.href = code;
    link.setAttribute('download', 'DDCode.png');
    document.body.appendChild(link);
    link.click();
  };

  const delOrder = () => {
    delReOrder(reOrderId);
  };
  const tryAgain = () => {
    setPayText('Processing Payment...');
    paymentReUrl(reOrderId);
    if (btn.current) {
      btn.current.setAttribute('disabled', 'disabled');
    }
  };

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Alert variant="primary">
              <h5>
                Thank you for your order
                {!code ? (
                  <DeleteReOrder delOrder={delOrder} delBtn={delBtn} />
                ) : invoice ? (
                  <Button
                    as={Link}
                    to={`/user/my-order-invoice/${reOrderId}`}
                    variant="info float-right"
                    size="sm"
                  >
                    Invoice
                  </Button>
                ) : (
                  ''
                )}
              </h5>
            </Alert>
            <ListGroup variant="flush" className="mt-2">
              <ListGroup.Item variant="info">
                {invoiceHours}hr Order Summary!
                <p className="float-right">Initial Date: {invoiceTime}</p>
              </ListGroup.Item>
              <ListGroup.Item>
                {bed} Bedroom/s {bath} Bathroom/s
              </ListGroup.Item>
              <ListGroup.Item align="center">
                <div className="float-left">Your Extras;</div>
                {mrx.length === 0 ? (
                  <span>No Extras</span>
                ) : (
                  mrx.map((x) => {
                    return (
                      <div
                        className="float-right"
                        key={x.id}
                        style={{ width: '20rem' }}
                      >
                        <ExtraItem x={x} />
                      </div>
                    );
                  })
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                Address:
                <p className="float-right">
                  {address} {complex && `| ${complex}`}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>
                  The order has {cleans} cleans weekly on
                  {weekdays &&
                    weekdays.map((w) => (
                      <div key={w} className="float-right">
                        {w}
                      </div>
                    ))}
                </b>
              </ListGroup.Item>
              <ListGroup.Item className="text-center">
                On these dates:
                <hr style={{ width: '10rem' }} />
                {schedule &&
                  schedule.map((s) => <Schedule key={s.start} s={s} />)}
              </ListGroup.Item>
              <ListGroup.Item variant="warning">
                Invoice Total; <b className="float-right">£ {inv}</b>
              </ListGroup.Item>
              <ListGroup.Item>
                <Button variant="danger float-right" block>
                  I need help with my booking!
                </Button>
              </ListGroup.Item>
            </ListGroup>
            <AddReComment
              reComment={reComment}
              orderId={reOrderId}
              note={note}
            />
            {re_comments && re_comments.length === 0 ? (
              <p className="text-center mt-2">No instructions</p>
            ) : (
              re_comments.map((comment) => (
                <ListGroup.Item className="mt-2">{comment.text}</ListGroup.Item>
              ))
            )}
          </Col>
          <Col align="center">
            {code ? (
              <>
                {monthly && paymentRef === undefined ? (
                  <Card style={{ width: '20rem' }}>
                    <Alert variant="info">Make payment for your order</Alert>
                    <Card.Body>
                      <b>***Please Note***</b>
                      <p>
                        You will be re-direcrected when you select a payment
                        method.
                      </p>
                      <Button
                        variant="success"
                        ref={btn}
                        onClick={tryAgain}
                        block
                      >
                        {payText} £ {inv}
                      </Button>
                    </Card.Body>
                  </Card>
                ) : monthly === undefined && paymentRef === undefined ? (
                  <Card style={{ width: '20rem' }}>
                    <Alert variant="info">Make payment for your order</Alert>
                    <Card.Body>
                      <b>***Please Note***</b>
                      <p>
                        You will be re-direcrected when you select a payment
                        method.
                      </p>
                      <Button
                        variant="success"
                        ref={btn}
                        onClick={tryAgain}
                        block
                      >
                        {payText} £ {inv}
                      </Button>
                    </Card.Body>
                  </Card>
                ) : null}
                <Card style={{ width: '15rem', marginTop: '5%' }}>
                  <Card.Img variant="top" src={code} />
                  <Card.Body>
                    <Card.Title className="text-center">
                      Please download the qr code
                    </Card.Title>
                    <Button variant="primary" onClick={downloadCode}>
                      Download!
                    </Button>
                  </Card.Body>
                </Card>
              </>
            ) : (
              <Card style={{ width: '20rem' }}>
                <Alert variant="danger">Your payment failed!</Alert>
                <Card.Body>
                  <Card.Title className="text-center">
                    <Alert variant="warning">Please try pay again</Alert>
                  </Card.Title>
                  <hr />
                  <b>***Please Note***</b>
                  <p>You will be re-direcrected to make payment.</p>

                  <Button variant="success mt-2" onClick={tryAgain} block>
                    {payText} £{inv}
                  </Button>
                </Card.Body>
              </Card>
            )}
          </Col>
          {bookedCleaner ? (
            <Col>
              <Alert variant="info">
                Assigned Duster
                <i className="fas fa-user-check float-right"></i>
              </Alert>
              <ListGroup>
                <ListGroup.Item>
                  <b> Duster Name:</b>
                  <p className="float-right">
                    {bookedCleaner.name}
                    {bookedCleaner.surname}
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Star Rating: </b>
                  <p className="float-right">3 Star Duster</p>
                </ListGroup.Item>
                <ListGroup.Item className="text-center">
                  {whatsLeft} Clean/s left on the schedule
                </ListGroup.Item>
              </ListGroup>
              {checkedIn.length > checkedOut.length && (
                <ListGroup.Item align="center">
                  <Button variant="warning" size="sm" disabled>
                    Cleaning In Progress...
                  </Button>
                </ListGroup.Item>
              )}
              {checkedOut.length > 0 &&
                checkedOut.map((c) => <CompletedClean key={c} c={c} />)}
              {complete && 'Order marked as complete'}
            </Col>
          ) : (
            <Col>
              <Alert variant="warning text-center">Cleaner not assgned</Alert>
            </Col>
          )}
        </Row>
      </Container>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders,
  note: state.note,
  invoice: state.invoice.invoice,
  stripeUrl: state.payment.paymentUrl,
});

export default connect(mapStateToProps, {
  GetReOrder,
  fetchDuster,
  cleanDuster,
  delReOrder,
  getInvoice,
  reComment,
  fetchReComments,
  paymentReUrl,
})(MyReOrder);
