import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { runningLate } from '../../../actions/admin/orders';
import { Container, Spinner, Alert, Button, ListGroup } from 'react-bootstrap';
import OrderItems from './OrderItems';
import { useHistory } from 'react-router-dom';

const RunningLate = ({ runningLate, orders }) => {
  useEffect(() => {
    runningLate();
  }, [runningLate]);

  let history = useHistory();

  if (orders === null) {
    return <Spinner variant="primary" animation="grow" />;
  }
  const goback = () => {
    history.push('/admin/order-management');
  };
  return (
    <div>
      <Container>
        <Alert variant="secondary text-center">
          <Button
            variant="outline-secondary float-left"
            size="sm"
            onClick={() => goback()}
          >
            Operations
          </Button>
          Order Where cleaner is running late
        </Alert>
        <ListGroup variant="flush text-center">
          {orders.length === 0 ? (
            <Alert variant="success">There are no cleaners running-late</Alert>
          ) : (
            orders.map((order) => <OrderItems key={order._id} order={order} />)
          )}
        </ListGroup>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.admin_orders.running_late,
});

export default connect(mapStateToProps, { runningLate })(RunningLate);
