import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { invoices } from '../../../../../actions/admin/main';
import {
  ListGroup,
  Spinner,
  Container,
  Col,
  Row,
  Alert,
  Button,
} from 'react-bootstrap';
import InvoiceItem from './InvoiceItem';

const Invoices = ({ invoices, inv }) => {
  useEffect(() => {
    invoices();
  }, [invoices]);
  let history = useHistory();

  const goBack = () => {
    history.push('/admin/admin-accounts');
  };

  if (inv === null) return <Spinner animation="grow" variant="primary" />;
  return (
    <Container>
      <Row>
        <Col>
          <Alert variant="info text-center">
            <Button variant="light float-left" size="sm" onClick={goBack}>
              Accounts
            </Button>
            All invoices...
          </Alert>
          <ListGroup as="ol">
            {inv.length === 0
              ? 'No Invoices'
              : inv.map((invoice) => (
                  <InvoiceItem key={invoice._id} invoice={invoice} />
                ))}
          </ListGroup>
        </Col>
        <Col>Invoice view options...</Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  inv: state.main.invoices,
});

export default connect(mapStateToProps, { invoices })(Invoices);
