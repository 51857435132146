import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner, Alert, Row, Button, ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { lateRepeatCleaners } from '../../../actions/admin/orders';
import ReOrderItems from './ReOrderItems';

function ReOrdersRunningLate({ lateRepeatCleaners, orders }) {
  useEffect(() => {
    lateRepeatCleaners();
  }, [lateRepeatCleaners]);

  let history = useHistory();

  if (orders === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  const goback = () => {
    history.push('/admin/order-management');
  };

  return (
    <div className="container">
      <Alert variant="warning text-center">
        <Button
          variant="outline-warning float-left"
          size="sm"
          onClick={() => goback()}
        >
          Operations
        </Button>
        Repeat Orders Running Late
      </Alert>
      <ListGroup variant="flush text-center">
        {orders.length === 0 ? (
          <Alert variant="success">There are no cleaners running late</Alert>
        ) : (
          orders.map((order) => <ReOrderItems key={order._id} order={order} />)
        )}
      </ListGroup>
    </div>
  );
}

const mapStateToProps = (state) => ({
  orders: state.admin_orders.re_orders_late,
});

export default connect(mapStateToProps, { lateRepeatCleaners })(
  ReOrdersRunningLate
);
