import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

const AdminRoute = ({
  component: Component,
  admin: { isAdmin, loading },
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      loading === true ? (
        <Spinner animation="border" variant="primary" />
      ) : isAdmin ? (
        <Component {...props} />
      ) : (
        <Redirect to="/admin/signin" />
      )
    }
  />
);

AdminRoute.propTypes = {
  admin: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps)(AdminRoute);
