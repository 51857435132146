import React from 'react';
import { Button } from 'react-bootstrap';

const ChangeTheDate = () => {
  return (
    <div>
      <Button variant="outline-warning" block>
        Change Booking Date
      </Button>
    </div>
  );
};

export default ChangeTheDate;
