import {
  USER_LOADED,
  CLEAR_USER,
  USER_FAIL,
  USER_REGISTERED,
  USER_LOGIN,
  USER_LOGOUT,
  MY_ADDRESSES,
  CLEAR_ADDRESSES,
  THIS_ADR,
  MY_RATE_CARD,
  MOTHER_CARD,
} from '../../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isUser: null,
  user: null,
  loading: true,
  myAddress: null,
  thisAdr: null,
  myRateCard: null,
  mother: null,
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_USER:
      return {
        ...state,
        isUser: null,
        user: null,
        loading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isUser: true,
        user: payload,
        loading: false,
      };
    case USER_REGISTERED:
      return {
        ...state,
      };
    case USER_LOGIN:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
      };
    case MY_ADDRESSES:
      return {
        ...state,
        myAddress: payload,
      };
    case CLEAR_ADDRESSES:
      return {
        ...state,
        myAddress: null,
      };
    case THIS_ADR:
      return {
        ...state,
        thisAdr: payload,
      };
    case MOTHER_CARD:
      return {
        ...state,
        mother: payload,
      };
    case MY_RATE_CARD:
      return {
        ...state,
        myRateCard: payload,
      };
    case USER_FAIL:
      return {
        ...state,
        isUser: null,
        user: null,
        loading: false,
      };
    case USER_LOGOUT:
      return {
        ...state,
        token: localStorage.removeItem('token'),
        isUser: null,
        user: null,
        loading: false,
      };

    default:
      return state;
  }
}

export default userReducer;
