import React, { useState, useRef, useEffect } from 'react';
import {
  ListGroup,
  InputGroup,
  Button,
  Modal,
  Form,
  Spinner,
} from 'react-bootstrap';

const AddressItem = ({ adr, addComplex, removeComplex, deleteAddress }) => {
  const [complex, setComplex] = useState(null);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (adr) {
      setComplex(adr.complex);
    }
  }, [adr]);
  let deletebtn = useRef();
  const handleClose = () => {
    setComplex(null);
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const clearComplex = () => {
    let adrId = adr._id;

    removeComplex(adrId);
  };
  const saveComplex = () => {
    let adrId = adr._id;
    addComplex({ adrId, complex });
    setShow(false);
  };

  const removeAddress = () => {
    let adrId = adr._id;
    deleteAddress(adrId);
    if (deletebtn.current) {
      deletebtn.current.setAttribute('disabled', 'disabled');
    }
  };

  if (adr === null) {
    return <Spinner animation="grow" variant="dark" />;
  }

  return (
    <ListGroup.Item>
      <h6>
        Post code: {adr.code}, Address: {adr.text}{' '}
        <Button
          variant="outline-danger ml-4"
          className="float-right"
          onClick={removeAddress}
          size="sm"
          ref={deletebtn}
        >
          <i className="fas fa-trash"></i>
        </Button>
      </h6>
      <InputGroup>
        <Button variant="dark" disabled>
          Do you live in complex?
        </Button>
        <InputGroup.Append>
          <Button
            variant={complex ? 'info' : 'outline-info'}
            onClick={handleShow}
          >
            Yes
          </Button>
          <Button
            variant={complex ? 'outline-primary' : 'primary'}
            onClick={clearComplex}
          >
            {complex ? 'Clear' : 'No'}
          </Button>
          {complex ? (
            <Button variant="info" className="ml-3">
              Your Complex details: {complex}
            </Button>
          ) : (
            ''
          )}
        </InputGroup.Append>
      </InputGroup>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Please enter you complex details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Please enter complex details"
            name="complex"
            value={complex}
            onChange={(e) => setComplex(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Dont save
          </Button>
          <Button variant="primary" onClick={saveComplex}>
            Save Details
          </Button>
        </Modal.Footer>
      </Modal>
    </ListGroup.Item>
  );
};

export default AddressItem;
