import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <Row>
      <Navbar
        fixed="bottom"
        className="text-primary"
        style={{ backgroundColor: '#f5f5f5' }}
      >
        <Col>
          Copyright 2022 <i className="fas fa-copyright ml-1 mr-2"></i>
          <Link to="/" className="text-primary">
            Dusting Down
          </Link>
        </Col>
        <Col>
          <span style={{ display: 'block', float: 'right' }}>
            <Link to="/user/agreement" className="text-primary">
              Terms & Privacy Policy
            </Link>
          </span>
        </Col>
      </Navbar>
    </Row>
  );
};
export default Footer;
