import React from 'react';
import { Link } from 'react-router-dom';
import { Button, ListGroupItem } from 'react-bootstrap';

const ListDuster = ({ duster }) => {
  return (
    <ListGroupItem>
      <b>Duster Name:</b> {duster.name} {duster.surname}
      <Button
        variant="light float-right"
        size="sm"
        as={Link}
        to={`/admin/admin-accounts/duster-payslip/${duster._id}`}
      >
        View Payslip
      </Button>
    </ListGroupItem>
  );
};

export default ListDuster;
