import React from 'react';
import { ListGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

const MyReWork = ({ rw }) => {
  let time = moment.utc(rw.firstDate).format('ddd MMM D');
  let address = rw.address.split(',');
  const complex = rw.complex;

  return (
    <div className="mt-2">
      <ListGroup.Item>
        {time} @ {address[0]}, {address[1]} {complex && `| ${complex}`}
      </ListGroup.Item>
      <Button
        as={Link}
        to={`/team/my-rebooking/${rw._id}`}
        variant="outline-info"
        block
      >
        View
      </Button>
    </div>
  );
};

export default MyReWork;
