import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert, ListGroup, Button, Spinner } from 'react-bootstrap';
import Sticky from 'react-sticky-el';

import Bottom from '../../Layout/Bottom';
import BedAndBath from './BedAndBath';

import DateAndTime from './DateAndTime';
import Time from './Time';
import WhereYou from './WhereYou';
import NityGrity from './NityGrity';
import {
  rateCard,
  regularRate,
  customRate,
  theNityGrity,
  changeRate,
  whyChangeHours,
} from '../../../actions/user/bookings';
import { fetchMyRate, getMother, getAddress } from '../../../actions/user';
import moment from 'moment';
import './Booking.css';
import IconExtras from './IconExtras';
import { setNote } from '../../../actions/note';

const Booking = ({
  rateCard,
  bookings: {
    orderTime,
    frequency,
    bedroom,
    address,
    postCode,
    coordinates,
    complex,
    bathroom,
    bedbathHours,
    extraTotal,
    extraHours,
    invoice,
    invoiceHours,
    regInvoice,
    regular,
    schedule,
    availableCleaners,
    nityGrity,
    iconExtras,
  },
  user: { isUser, user, myRateCard, mother, myAddress },
  regularRate,
  customRate,
  fetchMyRate,
  getMother,
  getAddress,
  theNityGrity,
  changeRate,
  whyChangeHours,
  setNote,
}) => {
  //Included Variables//
  const [inchours, SetIncHours] = useState(0);
  const [stage, setStage] = useState(1);
  const [arrivalTime, setArrivalTime] = useState(null);
  const [prettyDay, setPrettyDay] = useState(null);
  const [page, setPage] = useState(null);
  const [change, setChange] = useState(null);
  const [time, setTime] = useState(null);
  const [whyLess, setWhyLess] = useState('');

  useEffect(() => {
    if (whyLess.length > 0) {
      whyChangeHours(whyLess);
    }
  }, [whyLess, whyChangeHours]);

  useEffect(() => {
    getMother();
    getAddress();
  }, [getMother, getAddress]);

  useEffect(() => {
    if (user) {
      if (user.customRate === true) {
        let userId = user._id;
        fetchMyRate(userId);
      }
    }
  }, [user, fetchMyRate]);
  //Clear Changed time when recaulculating//
  useEffect(() => {
    if (bedroom || bathroom || extraTotal) {
      setChange(null);
    }
  }, [bedroom, bathroom, extraTotal]);

  //Calculating the Regular Clean//
  useEffect(() => {
    let juice = invoice;
    let a = schedule.length;
    let monthly = juice * a;
    let discount = monthly * 0.1;
    let amount = monthly - discount;

    if (myRateCard) {
      return regularRate(monthly.toFixed(2));
    }

    regularRate(amount.toFixed(2));
  }, [frequency, regularRate, invoice, schedule.length, myRateCard]);

  //Calculating The Once Off Clean//
  useEffect(() => {
    SetIncHours(parseFloat(2));
    let bbhours = parseFloat(bedbathHours);
    let exhours = parseFloat(extraHours);
    let tt = bbhours + exhours + inchours;
    let invoiceHours = parseFloat(tt);

    if (change) {
      let invoiceHours = change;
      return changeRate({ invoiceHours, mother });
    }

    if (myRateCard) {
      return customRate({ myRateCard, invoiceHours });
    }
    if (mother) {
      rateCard({ invoiceHours, mother });
    }
  }, [
    bedbathHours,
    extraHours,
    inchours,
    rateCard,
    frequency,
    myRateCard,
    customRate,
    mother,
    change,
    changeRate,
  ]);

  const invoiceTime = moment.utc(orderTime).format('ddd MMM D, h:mm a');

  if (mother === null) {
    return <Spinner variant="primary" animation="grow" />;
  }

  return (
    <>
      <div className="container">
        <Row className="mb-5">
          <Col className="position-relative" id="invoice">
            <Sticky
              stickyStyle={{
                top: 58,
                zIndex: 2,
                backgroundColor: 'white',
              }}
            >
              <div>
                <Alert variant="primary">
                  <i className="fas fa-shopping-cart"></i>
                  <p className="float-right">
                    {change ? `${change}` : `${invoiceHours}`} Hours
                  </p>
                </Alert>
                <ListGroup variant="flush" id="order-details">
                  {orderTime && (
                    <ListGroup.Item variant="secondary">
                      <b>Order Date:</b> {invoiceTime}
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item>
                    {bedroom} Bed/s And {bathroom} Bath/s
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Included Cost <p className="float-right">£ 30.50</p>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    Service Fee <p className="float-right">£ 3.50</p>
                  </ListGroup.Item>
                </ListGroup>
                <hr />
                {regular ? (
                  <>
                    <ListGroup.Item variant="warning">
                      Invoice Total;
                      <p className="float-right">
                        <b>£ {regInvoice}</b> this month
                      </p>
                    </ListGroup.Item>
                    <Alert variant="success">
                      10% discount applied on all regular cleans
                    </Alert>
                  </>
                ) : (
                  <ListGroup.Item variant="warning">
                    Invoice Total;
                    <p className="float-right">
                      <b>£ {invoice}</b>
                    </p>
                  </ListGroup.Item>
                )}
              </div>
            </Sticky>
          </Col>

          <Col md={8} xs={12}>
            <Alert variant="info text-center">
              Step {stage} of booking a duster
            </Alert>
            <Row className="mb-3">
              <Col xs={12} md={3}>
                {stage === 1 ? (
                  <>
                    <Button variant="outline-info" block>
                      1. Cleaning Brief
                    </Button>
                    <hr color="#5bc0de" style={{ height: '2px' }} />
                  </>
                ) : (
                  <>
                    <Button variant="info" block onClick={() => setStage(1)}>
                      <i className="fas fa-check"></i> Cleaning Brief
                    </Button>
                    <hr color="#5bc0de" style={{ height: '2px' }} />
                  </>
                )}
              </Col>
              <Col xs={12} md={3}>
                {stage === 2 ? (
                  <>
                    <Button
                      variant="outline-info"
                      onClick={() => setStage(2)}
                      block
                    >
                      2. Date And Time
                    </Button>
                    <hr color="#5bc0de" style={{ height: '2px' }} />
                  </>
                ) : stage > 2 ? (
                  <>
                    <Button variant="info" block onClick={() => setStage(2)}>
                      <i className="fas fa-check"></i> Date And Time
                    </Button>
                    <hr color="#5bc0de" style={{ height: '2px' }} />
                  </>
                ) : (
                  <>
                    <Button variant="outline-secondary" block>
                      2. Date And Time
                    </Button>
                  </>
                )}
              </Col>
              <Col xs={12} md={3}>
                {stage === 3 ? (
                  <>
                    <Button variant="outline-info" block>
                      3. Nity Grity
                    </Button>
                    <hr color="#5bc0de" style={{ height: '2px' }} />
                  </>
                ) : stage > 3 ? (
                  <Button variant="info" block>
                    <i className="fas fa-check"></i> Nity Grity
                  </Button>
                ) : (
                  <Button variant="outline-secondary" block>
                    3. Nitty Gritty
                  </Button>
                )}
              </Col>
              <Col xs={12} md={3}>
                <Button variant="outline-secondary" block>
                  4. Review & Pay
                </Button>
              </Col>
            </Row>
            <WhereYou
              user={user}
              myAddress={myAddress}
              availableCleaners={availableCleaners}
              address={address}
              postCode={postCode}
              coordinates={coordinates}
              complex={complex}
              setNote={setNote}
            />
            {stage === 1 ? (
              <>
                <BedAndBath invoiceHours={invoiceHours} />

                <hr />

                <IconExtras
                  iconExtras={iconExtras}
                  invoiceHours={invoiceHours}
                />

                <hr />
                <Time
                  invoiceHours={invoiceHours}
                  setChange={setChange}
                  change={change}
                  time={time}
                  setTime={setTime}
                  whyLess={whyLess}
                  setWhyLess={setWhyLess}
                />
                {invoiceHours > time ? (
                  whyLess.length > 0 ? (
                    <Button
                      variant="outline-info"
                      id="next"
                      onClick={() => setStage(2)}
                    >
                      Next Step <i className="fas fa-fast-forward ml-3"></i>
                    </Button>
                  ) : (
                    <Button variant="secondary" id="next">
                      Please tell us what to prioritise
                      <i className="fas fa-fast-forward ml-3"></i>
                    </Button>
                  )
                ) : (
                  <Button
                    variant="outline-info"
                    id="next"
                    onClick={() => setStage(2)}
                  >
                    Next Step <i className="fas fa-fast-forward ml-3"></i>
                  </Button>
                )}
              </>
            ) : stage === 2 ? (
              <>
                <DateAndTime
                  regular={regular}
                  setStage={setStage}
                  arrivalTime={arrivalTime}
                  setArrivalTime={setArrivalTime}
                  page={page}
                  setPage={setPage}
                  prettyDay={prettyDay}
                  setPrettyDay={setPrettyDay}
                  invoiceTime={invoiceTime}
                />
              </>
            ) : stage === 3 ? (
              <>
                <NityGrity
                  isUser={isUser}
                  regular={regular}
                  theNityGrity={theNityGrity}
                  nityGrity={nityGrity}
                />
              </>
            ) : (
              'stage 4'
            )}
          </Col>
        </Row>
      </div>

      <Bottom />
    </>
  );
};

const mapStateToProps = (state) => ({
  bookings: state.bookings,
  user: state.user,
});

export default connect(mapStateToProps, {
  rateCard,
  regularRate,
  customRate,
  fetchMyRate,
  getMother,
  getAddress,
  theNityGrity,
  changeRate,
  whyChangeHours,
  setNote,
})(Booking);
