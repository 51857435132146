import axios from 'axios';
import {
  SET_USERS,
  SET_USER,
  USER_ORDERS,
  USER_ADDRESS,
  CLEAR_USERS,
  CLEAR_ADMIN_USER,
  CLEAR_RATE,
  LOCAL_USERS,
} from '../types';
import { setNote } from '../note';

export const GetUsers = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USERS });
    dispatch({ type: CLEAR_RATE });
    const res = await axios.get('/api/admin/admin_user');
    dispatch({ type: SET_USERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Local Users//
export const localUsers = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/admin_user/local');
    dispatch({ type: LOCAL_USERS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const GetUser = (userId) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ADMIN_USER });
    const res = await axios.get(`/api/admin/admin_user/specific/${userId}`);
    dispatch({ type: SET_USER, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const GetUserAddress = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/admin/admin_user/user-address/${userId}`);
    dispatch({ type: USER_ADDRESS, payload: res.data });
  } catch (error) {
    console.error(error.message);
  }
};

export const userOrders = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/admin/admin_user/user-orders/${userId}`);
    dispatch({ type: USER_ORDERS, payload: res.data });
  } catch (error) {
    console.error();
  }
};

export const pauseUser =
  ({ userId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ userId });
    try {
      const res = await axios.put(
        '/api/admin/admin_user/block-user',
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

export const deleteUser = (userId) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `/api/admin/admin_user/delete-user/${userId}`
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
  } catch (error) {
    console.error(error.message);
  }
};

export const updateClient = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(formData);
  let userId = formData.userId;
  try {
    const res = await axios.put(
      '/api/admin/admin_user/update_user',
      body,
      config
    );
    const msgs = res.data.message;
    if (msgs) {
      msgs.forEach((msg) => dispatch(setNote(msg.msg, 'success')));
    }
    dispatch(GetUser(userId));
  } catch (error) {
    console.error(error.messgae);
  }
};

export const createOrder = () => async (dispatch) => {
  try {
    const res = await axios.post('/api//admin/admin_user/create-clean');
    console.log(res.data);
  } catch (error) {
    console.error(error.message);
  }
};
