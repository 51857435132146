import React, { useState } from 'react';
import { connect } from 'react-redux';
import { monthlyAcc } from '../../../../actions/admin/main';
import { Button, Modal } from 'react-bootstrap';

const Monthly = ({ currentUser, monthlyAcc, month, userId }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleMonthly = () => {
    let data = !month;
    monthlyAcc({ data, userId });
    setShow(false);
  };

  return (
    <div>
      {month === false ? (
        <>
          <Button variant="outline-info" onClick={handleShow} block>
            Make Monthly Customer
          </Button>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-center">
                Would you like to turn {currentUser} into a monthly account
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer align="center">
              <Button variant="warning" onClick={handleClose} block>
                No
              </Button>
              <Button variant="success" onClick={handleMonthly} block>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <>
          <Button variant="info" onClick={handleShow} block>
            {currentUser} is a monthly customer
          </Button>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-center">
                Would you like to change {currentUser} into a regular user
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer align="center">
              <Button variant="outline-warning" onClick={handleClose} block>
                No
              </Button>
              <Button variant="outline-success" onClick={handleMonthly} block>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
};

export default connect(null, { monthlyAcc })(Monthly);
