import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

const MyWork = ({ aw }) => {
  const address = aw.address.split(',');
  const time = moment.utc(aw.orderTime).format('ddd MMM D');
  const complex = aw.complex;

  return (
    <div className="mt-2">
      <ListGroup.Item>
        {time} @ {address[0]}, {address[1]} {complex && `| ${complex}`}
      </ListGroup.Item>
      <Button
        as={Link}
        to={`/team/my-booking/${aw._id}`}
        variant="outline-info"
        block
      >
        View
      </Button>
    </div>
  );
};

export default MyWork;
