import moment from 'moment';
import { Button } from 'react-bootstrap';

const Bookings = ({ b, fetchBooking }) => {
  let date = moment.utc(b.start).format('ddd MMM D');

  return (
    <div>
      <Button variant="outline-info mt-2" block onClick={() => fetchBooking(b)}>
        {date}
      </Button>
    </div>
  );
};

export default Bookings;
