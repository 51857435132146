import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { setNote } from '../../../actions/note';
import { verifyUser } from '../../../actions/user';
import Bottom from '../../Layout/Bottom';

const Verify = ({ verifyUser, setNote, msgs, match }) => {
  const [msg, setMsg] = useState(msgs);
  const [token, setToken] = useState('');
  let btn = useRef();
  useEffect(() => {
    setToken(match.params.token);
  }, [token, setToken, match.params.token]);

  useEffect(() => {
    if (msgs && btn.current) {
      setMsg(msgs);
      btn.current.removeAttribute('disabled');
    }
  }, [setMsg, msg, msgs]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (token === '') {
      setNote('Please insert token', 'danger');
    } else {
      verifyUser({ token });

      if (btn.current) {
        btn.current.setAttribute('disabled', 'disabled');
      }
    }
  };
  return (
    <div>
      <div className="container">
        <Form onSubmit={onSubmit}>
          <Form.Group controlId="formBasicText">
            <Form.Label>Verification code</Form.Label>
            <Form.Control
              type="text"
              name="token"
              value={token}
              placeholder="Please Paste Verifcation Code Here"
              onChange={(e) => setToken(e.target.value)}
              disabled
            />
          </Form.Group>
          <Button variant="success btn btn-block" type="submit" ref={btn}>
            Verify Email <i className="fas fa-user-check ml-2"></i>
          </Button>
        </Form>
      </div>
      <Bottom />
    </div>
  );
};

const mapStateToProps = (state) => ({
  msgs: state.team.msgs,
});

export default connect(mapStateToProps, { verifyUser, setNote })(Verify);
