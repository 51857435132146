import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  readyToWork,
  nullDuster,
} from '../../../../actions/admin/team_members';
import { Spinner, Alert, Row, Button } from 'react-bootstrap';
import TeamItem from './TeamItem';
import './AdminDusters.css';

const AllReady = ({ readyToWork, teams, nullDuster }) => {
  const [searchEmail, setSearchEmail] = useState('');
  useEffect(() => {
    readyToWork();
    nullDuster(null);
  }, [readyToWork, nullDuster]);
  let history = useHistory();

  if (teams === null) {
    return <Spinner animation="border" variant="primary" />;
  }

  const goBack = () => {
    history.push('/admin/admin-dusters');
  };

  return (
    <div className="container">
      <Alert variant="info text-center">
        <Button
          variant="outline-info float-left"
          size="sm"
          onClick={() => goBack()}
        >
          Duster Dashboard
        </Button>
        Dusters Ready For Work
        <span className="float-right">
          <input
            className="text-center"
            placeholder="search by email"
            style={{ border: 'none' }}
            onChange={(e) => setSearchEmail(e.target.value)}
          />
        </span>
      </Alert>
      <Row id="dust-buster">
        {teams.length === 0 ? (
          <Alert variant="secondary">No Dust Busters ready for work!</Alert>
        ) : (
          teams
            .filter((val) => {
              if (searchEmail === '') {
                return val;
              } else if (
                val.email.toLowerCase().includes(searchEmail.toLowerCase())
              ) {
                return val;
              }
            })
            .map((team) => <TeamItem team={team} key={team._id} />)
        )}
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  teams: state.team_members.ready,
});
export default connect(mapStateToProps, { readyToWork, nullDuster })(AllReady);
