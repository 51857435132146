import React, { useEffect, useState } from 'react';
import { ListGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

const CleanItem = ({ order }) => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    if (order.orderTime) {
      setUrl('/admin/order-management/once-off/order');
    } else {
      setUrl('/admin/order-management/repeat-orders/order');
    }
  });
  const address = order.address.split(',');
  const time = moment.utc(order.orderTime).format('ddd MMM D');
  const complex = order.complex;

  return (
    <div className="mt-2">
      <ListGroup.Item>
        {time} @ {address[0]}, {address[1]} {complex && `| ${complex}`}
      </ListGroup.Item>
      <Button as={Link} to={`${url}/${order._id}`} variant="outline-info" block>
        View
      </Button>
    </div>
  );
};

export default CleanItem;
