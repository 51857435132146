import React from 'react';
import { ListGroup } from 'react-bootstrap';

const OrderItem = ({ order }) => {
  return (
    <div>
      <ListGroup.Item>
        {order.address} : Total: {order.invoice}
      </ListGroup.Item>
    </div>
  );
};

export default OrderItem;
